import React from 'react';
import OnlineDoor from 'model/OnlineDoor';
import { Checked } from 'model/MultiStateCheckbox';
import { styled } from 'styled-components';
import MultiStateCheckBox from '../controls/MultiStateCheckBox';
import EnabledAccess from 'media/enabledAccess.svg';
import DeniedAccess from 'media/deniedAccess.svg';
import OfflineAccess from 'media/offlineAccess.svg';
import LockedPadlock from 'media/lockedLock.svg';
import UnlockedPadlock from 'media/unlockedLock.svg';
import OfflinePadlock from 'media/offlineLock.svg';
import OpenedDoor from 'media/openedDoor.svg';
import ClosedDoor from 'media/closedDoor.svg';
import OfflineDoor from 'media/offlineDoor.svg';
import {
  DoorStatus,
  LockDownStatus,
  LockStatus,
  OnlineDoorCommand,
} from 'model/enums';
import Building from 'model/Building';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import { useSelector } from 'react-redux';
import { postDoorCommand } from 'API/commands/DoorCommands';
import { selectEventsByDoorId } from 'helper/DoorStatusMethods';
import DoorCardButtons from 'components/controls/DoorCardButtons';
import ClipLoader from 'react-spinners/ClipLoader';
import { mainBlue } from 'constants/theme';
import { getStyleByPosition } from 'helper/DoorCardPosition';

type Props = {
  door: OnlineDoor;
  setSelectedDoors: (door: OnlineDoor) => void;
  selectedDoors: OnlineDoor[];
  backgroundColor: string;
  position: 'left' | 'right' | 'center';
  confirmCommand: (
    commandType?: OnlineDoorCommand,
    buildings?: Building[],
    floorsWithBuildingId?: FloorWithBuildingName[],
    doors?: OnlineDoor[],
  ) => void;
  siteName: string;
};

const DoorCard = ({
  door,
  setSelectedDoors,
  selectedDoors,
  backgroundColor,
  position,
  confirmCommand,
  siteName,
}: Props) => {
  const doorStatus = useSelector((state: any) =>
    selectEventsByDoorId(state, door.Id),
  );
  // Select the floor with this method
  const handleClick = () => {
    setSelectedDoors(door);
  };

  const leftOrRightStyle = getStyleByPosition(position);

  const getSelectedValue = (): Checked => {
    if (
      selectedDoors.find(
        (selectedDoor: OnlineDoor) => selectedDoor.Id === door.Id,
      )
    ) {
      return 2;
    } else {
      return 0;
    }
  };

  const lockOrUnlock = () => {
    switch (doorStatus[0].LockStatus) {
      case LockStatus.OpenMode:
        return OnlineDoorCommand.LockRoom;

      case LockStatus.Normal:
        return OnlineDoorCommand.UnlockRoom;

      default:
        return undefined;
    }
  };

  const accessOrDeny = () => {
    switch (doorStatus[0].LockDownStatus) {
      case LockDownStatus.AccessEnabled:
        return OnlineDoorCommand.DenyAccess;

      case LockDownStatus.Denied:
        return OnlineDoorCommand.EnableAccess;

      default:
        return undefined;
    }
  };

  const commandDoor = async (command: OnlineDoorCommand) => {
    await postDoorCommand(siteName, door.Id, command);
  };

  const displayIconsAndButtons = () => {
    if (doorStatus.length > 0) {
      const lock = lockOrUnlock();
      const access = accessOrDeny();
      if (lock && access) {
        return (
          <>
            <ImgContainer>
              <IMG
                src={
                  doorStatus[0].DoorStatus === DoorStatus.Open
                    ? OpenedDoor
                    : ClosedDoor
                }
                alt={
                  doorStatus[0].DoorStatus === DoorStatus.Open
                    ? 'Door opened'
                    : 'Door closed'
                }
              />
            </ImgContainer>
            <ImgContainer>
              {doorStatus[0].Loading.LockStatusLoading ? (
                <ClipLoader
                  color={mainBlue}
                  loading={doorStatus[0].Loading.LockStatusLoading}
                  size={18}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <IMG
                  src={
                    doorStatus[0].LockStatus === LockStatus.Normal
                      ? LockedPadlock
                      : UnlockedPadlock
                  }
                  alt={
                    doorStatus[0].LockStatus === LockStatus.Normal
                      ? 'Door locked'
                      : 'Door unlocked'
                  }
                />
              )}
            </ImgContainer>
            <ImgContainer>
              {doorStatus[0].Loading.LockDownStatusLoading ? (
                <ClipLoader
                  color={mainBlue}
                  loading={doorStatus[0].Loading.LockDownStatusLoading}
                  size={18}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <IMG
                  src={
                    doorStatus[0].LockDownStatus ===
                    LockDownStatus.AccessEnabled
                      ? EnabledAccess
                      : DeniedAccess
                  }
                  alt={
                    doorStatus[0].LockDownStatus ===
                    LockDownStatus.AccessEnabled
                      ? 'Access enabled'
                      : 'Access denied'
                  }
                />
              )}
            </ImgContainer>

            <DoorCardButtons
              lock={lock}
              access={access}
              commandDoor={commandDoor}
              confirmCommand={confirmCommand}
              door={door}
            />
          </>
        );
      } else {
        return (
          <>
            <ImgContainer>
              <IMG src={OfflineDoor} alt="Door is offline" />
            </ImgContainer>
            <ImgContainer>
              <IMG src={OfflinePadlock} alt="Offline Lock" />
            </ImgContainer>
            <ImgContainer>
              <IMG src={OfflineAccess} alt="Offline Access" />
            </ImgContainer>
          </>
        );
      }
    } else {
      return (
        <>
          <ImgContainer>
            <IMG src={OfflineDoor} alt="Door is offline" />
          </ImgContainer>
          <ImgContainer>
            <IMG src={OfflinePadlock} alt="Offline Lock" />
          </ImgContainer>
          <ImgContainer>
            <IMG src={OfflineAccess} alt="Offline Access" />
          </ImgContainer>
        </>
      );
    }
  };
  return (
    <Container
      style={{ backgroundColor: backgroundColor, ...leftOrRightStyle }}
    >
      <Spacer>
        <Coupler>
          <MultiStateCheckBox
            onChange={handleClick}
            value={getSelectedValue()}
          />
          <p>{door.Name}</p>
        </Coupler>
        {displayIconsAndButtons()}
      </Spacer>
    </Container>
  );
};

export default DoorCard;

const ImgContainer = styled.div`
  width: 40px;
  margin-left: 5px;
`;

const Container = styled.div`
  display: flex;
  margin: 0px 10px;
  width: 100%;
  justify-content: center;
  max-height: 53px;
`;

const Spacer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`;

const IMG = styled.img`
  height: 40%;
`;

// Desired compoennts stays close to each other
const Coupler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 25%;
`;


import React  from 'react';
import UserCardDialog from './userCardDialog/UserCardDialog';
import ReportLostCardDialog from './ReportLostCardDialog';
import ReissueCardDialog from './ReissueCardDialog';
import DeleteDialog from './DeleteDialog';
import { CardTypes, DialogToOpen } from 'model/enums';
import { useSelector } from 'react-redux';
import { selectOpenedDialog, selectSelectedUserRow, selectUser } from 'redux/selectors/userSelector';
import CardDialog from './cardDialog/CardDialog';
import { isCardDialogOpened, isUserDialogOpened } from 'helper/DialogUtils';
import { useDispatch } from 'react-redux';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { setCardDialogIsEdited, setCardDialogIsOpen } from 'redux/actions/cardDialogActions';
import { isUserDeleteDialogShow } from 'hooks/useIsUserNotDeletable';
import EraseCardDialog from './eraseCardDialog/EraseCardDialog';
import ReadCardDialog from './readCardDialog/ReadCardDialog';

const DialogGroup = () => {

  const selectedUserRow = useSelector(selectSelectedUserRow);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const selectedUserModel = useSelector(selectUser(selectedUserRow?.globalId ?? -1));
  const dialogToOpen = useSelector(selectOpenedDialog);
  const dispatch = useDispatch();

  const handleRefreshUserPage = () => {
    handleOpenedDialogToClose();
    dispatch(setUsersIsRefreshUsersList(true));
  }

  const handleOpenedDialogToClose = () => {
    dispatch(setOpenedDialog(undefined));
  }

  React.useEffect(() => {
    if (dialogToOpen) {
      switch(dialogToOpen) {
        case DialogToOpen.DeleteUserDialog:
          handleOpenedDialogToClose();
          if (isUserDeleteDialogShow(selectedUserModel)) {
            setDeleteDialogOpen(true);
          }
          break;
        case DialogToOpen.CreateKeyCardDialog:
        case DialogToOpen.CreateMasterCardDialog:
        case DialogToOpen.CreateFloorCardDialog:
        case DialogToOpen.CreateEmergencyCardDialog:
          dispatch(setCardDialogIsOpen(isCardDialogOpened(dialogToOpen)));
          break;
        case DialogToOpen.EditCardDialog:
          switch(selectedUserRow?.cardType) {
            case CardTypes.KeyCard:
              dispatch(setOpenedDialog(DialogToOpen.CreateKeyCardDialog));
              break;
            case CardTypes.MasterCard:
              dispatch(setOpenedDialog(DialogToOpen.CreateMasterCardDialog));
              break;
            case CardTypes.FloorCard:
              dispatch(setOpenedDialog(DialogToOpen.CreateFloorCardDialog));
              break;
            case CardTypes.EmergencyCard:
              dispatch(setOpenedDialog(DialogToOpen.CreateEmergencyCardDialog));
              break;
          }
          dispatch(setCardDialogIsEdited(true));
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogToOpen]);

  return (<React.Fragment>
      <React.Fragment>
        <UserCardDialog
          openAddUserDialog={isUserDialogOpened(dialogToOpen)}
          setOpenAddUserDialog={handleOpenedDialogToClose}
          selectedUserTableRecordRow={selectedUserRow}
          dialogToOpen={dialogToOpen}
          setRefreshUserList={handleRefreshUserPage}
        />
        <CardDialog />
        <ReportLostCardDialog />
        <DeleteDialog 
          open={isDeleteDialogOpen} 
          userId={selectedUserRow?.globalId} 
          handleSubmit={() => {
            handleRefreshUserPage();
            setDeleteDialogOpen(false);
          }} 
          handleClose={() => setDeleteDialogOpen(false)} 
        />
        <ReissueCardDialog />
        <EraseCardDialog />
        <ReadCardDialog />
      </React.Fragment>
  </React.Fragment>)
}

export default DialogGroup;

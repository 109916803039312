import Building from "model/Building"
import { BuildingAction } from "redux/constants/buildingActions"
import BuildingActionTypes from "redux/constants/buildingActionTypes"

type BuildingType = {
    loading: boolean,
    buildings: Building[],
    error: string
}

const initialState: BuildingType = {
    loading: true,
    buildings: [],
    error: ''
}

export default function buildingReducer(state: BuildingType = initialState, action: BuildingAction): BuildingType {
    switch (action.type) {
        case BuildingActionTypes.FETCH_BUILDINGS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case BuildingActionTypes.FETCH_BUILDINGS_SUCCESS:
            return {
                loading: false,
                buildings: action.payload,
                error: ''
            }
        case BuildingActionTypes.FETCH_BUILDINGS_FAILURE:
            return {
                loading: false,
                buildings: [],
                error: action.payload
            }
        case BuildingActionTypes.CLEAR_BUILDINGS:
            return {
                ...state,
                buildings: [],
            }
        default:
            return state;
    }
}
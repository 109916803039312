import CardActionTypes from 'redux/constants/cardActionTypes';
import { Dispatch } from 'redux';
import { CardStatus } from 'model/enums';

export const fetchCardsRequest = () => {
  return { type: CardActionTypes.FETCH_CARDS_REQUEST };
};
export const fetchCardsSuccess = (data: CardStatus[]) => {
  return {
    type: CardActionTypes.FETCH_CARDS_SUCCESS,
    payload: data,
  };
};
export const fetchCardsFailure = (err: any) => {
  return {
    type: CardActionTypes.FETCH_CARDS_FAILURE,
    payload: err,
  };
};
export const fetchAllCardStatus = () => {
    return (dispatch: Dispatch) => {
        dispatch(fetchCardsRequest());
        const cardStatuses = Object.values(CardStatus);
        if (cardStatuses && cardStatuses.length > 0) {
            dispatch(fetchCardsSuccess(cardStatuses));
        } else {
            dispatch(fetchCardsFailure([]));
        }
  };
};
import * as React from 'react';
import styled from 'styled-components';
import { black, blue, dialogMenuOptionBorderGray, lightGrayBackground, OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import { alpha } from '@mui/material';
import { ReactComponent as InfoCircle} from 'media/info-circle.svg';

type Props = {
  dialogMenuName: string;
  selectedPageName: string;
  isDisabled?: boolean;
  isInfoCircleDisplayed?: boolean;
  setSelectedPageName: (selectedPageName: string) => void;
};

const MenuOptionDialog = ({ 
  dialogMenuName, 
  selectedPageName,
  isDisabled,
  isInfoCircleDisplayed = false,
  setSelectedPageName,
}: Props) => {

  const IS_OPTION_NAME_EQUAL_SELECTED_PAGE = dialogMenuName === selectedPageName;

  const handleClickOnMenuOption = () => {
    if (!isDisabled) {
      setSelectedPageName(dialogMenuName);
    }
  }

  return (
    <StyledMenuOptionHolder
      onClick={handleClickOnMenuOption}
    >
      <StylesMenuOption 
        $isSelected={IS_OPTION_NAME_EQUAL_SELECTED_PAGE}
      >
        <StyledText 
          $isSelected={IS_OPTION_NAME_EQUAL_SELECTED_PAGE}
          $isDisabled={isDisabled}
        >
          {dialogMenuName}
          { isInfoCircleDisplayed ? <InfoCircle /> : null }
        </StyledText>
      </StylesMenuOption>
    </StyledMenuOptionHolder>
  );
};

export default MenuOptionDialog;

const StyledMenuOptionHolder = styled.div({ padding: '4px 0px 4px 0px' });

const StylesMenuOption = styled.div<{ $isSelected?: boolean }>((props) => ({
  width: '226px',
  height: '45px',
  maxWidth: '226px',
  maxHeight: '45px',
  borderRadius: '2px',
  backgroundColor: (props.$isSelected ? lightGrayBackground : white ),
  '& :hover': { backgroundColor: (props.$isSelected ? alpha(lightGrayBackground, OPACITY_OF_ROWS*2) : alpha(veryLightGrey, OPACITY_OF_ROWS/2) ) },
}));

const StyledText = styled.div<{ $isSelected?: boolean, $isDisabled?: boolean, }>((props) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '21.31px',
  textAlign: 'left',
  padding: '12px 16px 12px 16px',
  color: ( props.$isDisabled ? dialogMenuOptionBorderGray : (props.$isSelected ? blue : black)),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

import { getBuildings, getRoomsByBuilding} from 'API/commands/BuildingCommands';
import { getRoomTypes } from 'API/commands/RoomCommands';
import LockType from 'model/LockType';
import RoomModel from 'model/RoomModel';
import { Dispatch } from 'redux';
import RoomActionTypes from 'redux/constants/roomActionTypes';

export const fetchRoomsRequest = () => {
  return { type: RoomActionTypes.ROOMS_REQUESTED };
};
export const fetchRoomsSuccess = (data: RoomModel[] | null) => {
  return {
    type: RoomActionTypes.REQUEST_ROOMS_SUCCESS,
    payload: data,
  };
};
export const fetchRoomsFailure = (err: any) => {
  return {
    type: RoomActionTypes.REQUEST_ROOMS_FAILURE,
    payload: err,
  };
};
export const setLockTypesRequest = () => {
  return { type: RoomActionTypes.LOCK_TYPES_REQUESTED };
};
export const setLockTypesSuccess = (data: LockType[]) => {
  return {
    type: RoomActionTypes.REQUEST_LOCK_TYPES_SUCCESS,
    payload: data,
  };
};
export const setLockTypesFailure = (err: any) => {
  return {
    type: RoomActionTypes.REQUEST_LOCK_TYPES_FAILURE,
    payload: err,
  };
};
export const fetchAllRooms = async (siteName: string, dispatch: Dispatch) => {
  dispatch(fetchRoomsRequest());
  try {
    const buildings = await getBuildings(siteName);
    if (buildings) {
      const roomPromises = buildings.map((building) =>
        getRoomsByBuilding(siteName, building.Id),
      );

      const roomsArray = await Promise.all(roomPromises);

      const roomsToStore: RoomModel[] = roomsArray
        .filter((rooms): rooms is RoomModel[] => rooms !== null)
        .flat();

      dispatch(fetchRoomsSuccess(roomsToStore));
    }
  } catch (err) {
    dispatch(fetchRoomsFailure(err));
  }
};
export const clearRooms = () => {
  return { type: RoomActionTypes.SET_EMPTY_ROOMS };
};

export const requestLockTypes = async (
  siteName: string,
  dispatch: Dispatch,
) => {
  dispatch(setLockTypesRequest());
  try {
    const lockTypes = await getRoomTypes(siteName);
    if (lockTypes && lockTypes.length > 0) {
      dispatch(setLockTypesSuccess(lockTypes));
    } else {
      dispatch(setLockTypesFailure(null));
    }
  } catch (err) {
    dispatch(setLockTypesFailure(err));
  }
};
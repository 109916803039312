import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogTexts from 'model/DialogTexts';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectIsAwaitsEncoderResponse } from 'redux/selectors/encoderSelector';
import LinearProgress from '@mui/material/LinearProgress';
import { buttonAndLinearProgressBarCommonStyles } from 'constants/globalStyle';

type Props = {
    handleSubmit: () => void;
    handleClose: () => void;
    dialogText: DialogTexts;
    open: boolean;
    style?: {
      dialog?: CSSProperties;
      title?: CSSProperties;
      content?: CSSProperties;
      contentText?: any;
      actions?: CSSProperties;
      submitButton?: CSSProperties;
      cancelButton?: CSSProperties;
  };
    additionalFooterRowContent?: JSX.Element;
    isLinearProgressBarHidden?: boolean;
}

const TemplateDialog = ({
    handleSubmit,
    handleClose,
    dialogText,
    open,
    style,
    additionalFooterRowContent,
    isLinearProgressBarHidden,
}: Props) => {
    const isAwaitsEncoderResponse = useSelector(selectIsAwaitsEncoderResponse);
  
    return (
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              style: style?.dialog,
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                handleSubmit();
              },
            }}
            disableScrollLock={true}
          >
            <DialogTitle style={style?.title}>{dialogText.title}</DialogTitle>
            <DialogContent style={style?.content}>
              <DialogContentText style={style?.contentText}>
                <React.Fragment>
                  {dialogText.contentText}
                </React.Fragment>
              </DialogContentText>
            </DialogContent>
            <CustomDialogActions>
              <div >
                {additionalFooterRowContent 
                ? <div>
                    {additionalFooterRowContent}
                  </div>
                : null}
              </div>
              <StyledActionButtonHolder style={style?.actions}>
                <StyledButton onClick={handleClose} style={style?.cancelButton}>{dialogText.cancelButton}</StyledButton>
                <ButtonHolder>
                  <StyledButton type="submit" style={style?.submitButton}>{dialogText.submitButton}</StyledButton>
                  { !isLinearProgressBarHidden && isAwaitsEncoderResponse ? <StyledLinearProgressBar /> : null}
                </ButtonHolder>
              </StyledActionButtonHolder>
            </CustomDialogActions>
          </Dialog>
      );
}

export default TemplateDialog;

const StyledActionButtonHolder = styled.div`
  display: flex;
  gap: 1vw;
`;

export const StyledLinearProgressBar = styled(LinearProgress)((props) => ({ 
  borderRadius: '5px',
  position: 'relative',
  top: '2px',
}));

export const ButtonHolder = styled.div`${buttonAndLinearProgressBarCommonStyles}`;

const StyledButton = styled(Button)`${buttonAndLinearProgressBarCommonStyles}`;

const CustomDialogActions = styled(DialogActions)`
  justify-content: space-between;
  padding: 0px;
`;
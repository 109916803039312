import React from 'react';
import styled from 'styled-components';
import { mainBlue, lightGrey, white } from 'constants/theme';

type Props = {
  onClick: () => void;
  text: string;
};

const Button = ({ onClick, text }: Props) => {
  return <CustomButton onClick={() => onClick()}>{text}</CustomButton>;
};
export default Button;

const CustomButton = styled.button`
  height: 36px;
  font-size: 13px;

  border: 1px solid ${lightGrey};
  border-radius: 6px;
  background-color: ${white};

  margin: 0px 10px;

  &:active {
    background: ${mainBlue};
    color: ${white};
  }
  transition: 0.1s;
  &:hover {
    cursor: pointer;
  }

`;

import OnlineDoor from "model/OnlineDoor"
import DoorActionTypes from "redux/constants/doorActionTypes"
import { DoorAction } from "redux/constants/doorActions"


type OnlineDoorType = {
    loading: boolean,
    onlineDoors: OnlineDoor[],
    error: string
}

const initialState: OnlineDoorType = {
    loading: true,
    onlineDoors: [],
    error: ''
}

export default function onlineDoorReducer(state: OnlineDoorType = initialState, action: DoorAction) {
    switch (action.type) {
        case DoorActionTypes.FETCH_DOOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DoorActionTypes.FETCH_DOOR_SUCCESS:
            return {
                loading: false,
                data: action.payload as OnlineDoor,
                error: ''
            }
        case DoorActionTypes.FETCH_DOOR_FAILURE:
            return {
                loading: false,
                data: [] as OnlineDoor[],
                error: action.payload
            }
        default:
            return state;
    }
}

import { isNotFilledOutAnything } from 'helper/DialogUtils';
import { useCardFormBuilder } from 'hooks/useFormBuilder';
import {
  handleEncoderNotRespondedYetError,
  setEncoderRequestToInactive,
} from 'hooks/useSignalrEncoder';
import { useCardDialogValidation } from 'hooks/useValidations';
import { DialogToOpen } from 'model/enums';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCardDialogIsConfirmationDialogOpen,
  setCardDialogIsDialogCloseTriggered,
  setCardDialogIsSaveButtonDisabled,
  setCardDialogSelectedUserId,
  setCardDialogShouldConfirmationDialogSubmit,
  setCardDialogStorageReset,
} from 'redux/actions/cardDialogActions';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { selectIsSignalrResponseStatusPending } from 'redux/selectors/encoderSelector';
import {
  selectNewUsersOnPending,
  selectOpenedDialog,
  selectSelectedUserRow,
} from 'redux/selectors/userSelector';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';

/**
 * A react component for the purpose of running background tasks once
 * the states stored in the redux storage are updated. This component will be
 * rerendered every time a state from the storage is updated, and due to this,
 * only this component will be rerendered and not the parent component (in our case
 * the {@link CardDialog} itself).
 * 
 * With this, we can reduce the number of times the {@link CardDialog} is rendered,
 * and improving the performance of the dialog.
 */
export const CardDialogBackgroundProcess = () => {
  const {
    form,
    initForm,
    isDialogOpen,
    isEdited,
    selectedUserId,
    shouldConfirmationDialogSubmit,
    isDialogCloseTriggered,
  } = useSelector(selectCardDialogStorage);
  const selectedUserRow = useSelector(selectSelectedUserRow);
  const newUserOnPendingModels = useSelector(selectNewUsersOnPending);
  const openedDialog = useSelector(selectOpenedDialog);
  const isSignalrResponseStatusPending = useSelector(selectIsSignalrResponseStatusPending);
  useCardDialogValidation();
  useCardFormBuilder(selectedUserId);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isDialogOpen) {
      return;
    }

    dispatch(
      setCardDialogSelectedUserId(
        isEdited
          ? selectedUserRow?.globalId
          : form?.cardDetails.user?.userGlobalId,
      ),
    );
  }, [
    isDialogOpen,
    isEdited,
    selectedUserRow?.globalId,
    form?.cardDetails.user?.userGlobalId,
  ]);

  React.useEffect(() => {
    if (!shouldConfirmationDialogSubmit) {
      return;
    }

    dispatch(setCardDialogStorageReset());
    dispatch(setOpenedDialog(undefined));
    refreshUserIfNewUserCreated();
    setEncoderRequestToInactive(dispatch);
  }, [shouldConfirmationDialogSubmit]);

  React.useEffect(() => {
    if (!isDialogCloseTriggered) {
      return;
    }

    dispatch(setCardDialogIsDialogCloseTriggered(false));

    if (isSignalrResponseStatusPending) {
      handleEncoderNotRespondedYetError();
      return;
    }

    isNotFilledOutAnything(form, initForm)
      ? dispatch(setCardDialogShouldConfirmationDialogSubmit(true))
      : dispatch(setCardDialogIsConfirmationDialogOpen(true));
  }, [isDialogCloseTriggered, form, initForm, isSignalrResponseStatusPending]);

  /**
   * Refresh the user list if a new user have been created on the
   * Card Creation Dialog. Without this refresh, the newly created user will not
   * be added automatically to this user list and will not be displayed on the table of the Manage Cards
   * and Manage Users pages.
   */
  const refreshUserIfNewUserCreated = () => {
    if (newUserOnPendingModels.length === 0) {
      return;
    }

    dispatch(setUsersIsRefreshUsersList(true));
  };

  React.useEffect(() => {
    if (!isDialogOpen 
        || !form 
        || (
            form.cardDetails.isUserSelected 
            && !initForm?.cardDetails.isUserSelected 
            && openedDialog !== DialogToOpen.CreateEmergencyCardDialog
        )
        || (
            isNotFilledOutAnything(form, initForm) 
            && openedDialog !== DialogToOpen.CreateEmergencyCardDialog
        )
        // It is mandatory to select a user.
        || !form.cardDetails.user
    ) {
      dispatch(setCardDialogIsSaveButtonDisabled(true));
        return;
    }

    if (!openedDialog || isEdited) {
      dispatch(setCardDialogIsSaveButtonDisabled(false));
      return;
    }

    const handler = Object.freeze({
      [DialogToOpen.CreateKeyCardDialog]: () => form?.cardDoors.length === 0 && form?.doorGroups?.length === 0,
      [DialogToOpen.CreateMasterCardDialog]: () => form?.cardLevels.every(level => !level.isChecked),
      [DialogToOpen.CreateEmergencyCardDialog]: () => false,
      [DialogToOpen.CreateFloorCardDialog]: () => form.cardDetails.building === undefined || form.cardDetails.floor === undefined,
    });
    dispatch(setCardDialogIsSaveButtonDisabled(handler[openedDialog.toString()].call()));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isDialogOpen]);

  return <React.Fragment></React.Fragment>;
};

enum CardDialogTypes {
  SET_CARD_DIALOG_FORM = 'SET_CARD_DIALOG_FORM',
  SET_CARD_DIALOG_FORM_ERROR = 'SET_CARD_DIALOG_FORM_ERROR',
  SET_CARD_DIALOG_IS_EDITED = 'SET_CARD_DIALOG_IS_EDITED',
  SET_CARD_DIALOG_IS_VALIDATION_OPEN = 'SET_CARD_DIALOG_IS_VALIDATION_OPEN',
  SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT = 'SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT',
  SET_CARD_DIALOG_IS_OPEN = 'SET_CARD_DIALOG_IS_OPEN',
  SET_CARD_DIALOG_SELECTED_PAGE_NAME = 'SET_CARD_DIALOG_SELECTED_PAGE_NAME',
  SET_CARD_DIALOG_INIT_FORM = 'SET_CARD_DIALOG_INIT_FORM',
  SET_CARD_DIALOG_SELECTED_USER_ID = 'SET_CARD_DIALOG_SELECTED_USER_ID',
  SET_CARD_DIALOG_IS_DIALOG_CLOSE_TRIGGERED = 'SET_CARD_DIALOG_IS_DIALOG_CLOSE_TRIGGERED',
  SET_CARD_DIALOG_IS_CONFIRMATION_DIALOG_OPEN = 'SET_CARD_DIALOG_IS_CONFIRMATION_DIALOG_OPEN',
  SET_CARD_DIALOG_IS_SAVE_BUTTON_DISABLED = 'SET_CARD_DIALOG_IS_SAVE_BUTTON_DISABLED',
  SET_CARD_DIALOG_STORAGE_RESET = 'SET_CARD_DIALOG_STORAGE_RESET',
  SET_CARD_DIALOG_FORM_BUILDER = 'SET_CARD_DIALOG_FORM_BUILDER',
}

export default CardDialogTypes;

import { CardStatus, CardToolTipViewOptions, PageNavigation } from "model/enums";
import { blue, green, lightBlue, lightGreen, lightRed, lightYellow, red, yellow } from "./theme";
import cardInUse from 'media/card-in-use.svg';
import cardExpired from 'media/card-expired.svg';
import cardLost from 'media/card-lost.svg';
import cardOverwritten from 'media/card-overwritten.svg';
import noCard from 'media/no-card.svg';
import cardErased from 'media/cardErased.svg';
import cardDeleted from 'media/cardDeleted.svg';
import cardPending from 'media/cardPending.svg';
import createCard from 'media/create-card.svg';
import deleteUserBinBlack from 'media/deleteUserBinBlack.svg';
import editPencil from 'media/editPencil.svg';
import arrowLeft from 'media/arrow-left.svg';
import reissueCard from 'media/reissueCard.svg';
import eraseCardBlack from 'media/eraseCardBlack.svg';
import lostCard from 'media/lost-card.svg';
import deleteCard from 'media/deleteCard.svg';
import eraseCardRed from 'media/eraseCardRed.svg';

export const getColorByCardStatus = (cardStatus: CardStatus | undefined) => {
    switch(cardStatus) {
        case CardStatus.InUse:
            return green;
        case CardStatus.Lost:
        case CardStatus.Overwritten:
            return yellow;
        case CardStatus.Expired:
        case CardStatus.Erased:
        case CardStatus.Deleted:
            return red;
        default:
            return blue;
    }
};

export const getBackgroundColorByCardStatus = (cardStatus: CardStatus | undefined) => {
    switch(cardStatus) {
        case CardStatus.InUse:
            return lightGreen;
        case CardStatus.Lost:
        case CardStatus.Overwritten:
            return lightYellow;
        case CardStatus.Expired:
        case CardStatus.Erased:
        case CardStatus.Deleted:
            return lightRed;
        default:
            return lightBlue;
    }
};

export const getCardImageByCardStatus = (cardStatus: CardStatus | undefined) => {
    switch(cardStatus) {
        case CardStatus.InUse:
            return cardInUse;
        case CardStatus.Lost:
            return cardLost;
        case CardStatus.Expired:
            return cardExpired;
        case CardStatus.Overwritten:
            return cardOverwritten;
        case CardStatus.Erased:
            return cardErased;
        case CardStatus.Deleted:
            return cardDeleted;
        case CardStatus.PendingUpdate:
            return cardPending;
        default:
            return noCard;
    }
};

export const getCardTextByCardStatus = (cardStatus: CardStatus | undefined) => {
    if(!cardStatus) {
        return 'No Card';
    } else if (cardStatus === CardStatus.Overwritten) {
        return CardStatus.Overwritten;
    } else {
        return `Card ${cardStatus}`
    }
}

export const getIconByCardToolTip = (cardToolTip: CardToolTipViewOptions | undefined, isIconBlack: boolean) => {
    switch(cardToolTip) {
        case CardToolTipViewOptions.ReportLost:
            return lostCard;
        case CardToolTipViewOptions.EraseCard:
            return isIconBlack ? eraseCardBlack : eraseCardRed;
        case CardToolTipViewOptions.ReissueCard:
            return reissueCard;
        case CardToolTipViewOptions.Back:
            return arrowLeft;
        case CardToolTipViewOptions.CreateCard:
            return createCard;
        case CardToolTipViewOptions.DeleteUser:
            return isIconBlack ? deleteUserBinBlack : deleteCard;
        case CardToolTipViewOptions.EditUser:
        case CardToolTipViewOptions.EditCard:
            return editPencil;
        default:
            return undefined;
    }
}

const initCardToolTipListByCardStatus = (cardStatus: CardStatus | undefined, isDetailedWindowView: boolean) => {
    switch(cardStatus) {
        case undefined:
            return isDetailedWindowView ? [] : [CardToolTipViewOptions.CreateCard];
        case CardStatus.Overwritten:
        case CardStatus.Lost:
        case CardStatus.Expired:
        case CardStatus.Erased:
            return [CardToolTipViewOptions.ReissueCard];
        case CardStatus.InUse:
            return [CardToolTipViewOptions.ReportLost, CardToolTipViewOptions.EraseCard];
        case CardStatus.Deleted:
            return isDetailedWindowView ? [] : [CardToolTipViewOptions.CreateCard];
        case CardStatus.PendingUpdate:
        default:
            return [];
    }
}

export const getCardToolTipsByCardStatus = (cardStatus: CardStatus | undefined, pageNavigation: PageNavigation, isDetailedWindowView: boolean) => {
    return [
        ...(pageNavigation === PageNavigation.ManageUsers
            ? [CardToolTipViewOptions.EditUser]
            : cardStatus === undefined || cardStatus === CardStatus.Erased
            ? []
            : [CardToolTipViewOptions.EditCard]),
        ...initCardToolTipListByCardStatus(cardStatus, isDetailedWindowView),
        CardToolTipViewOptions.DeleteUser
    ].filter(cardToolTip => 
        (pageNavigation === PageNavigation.ManageCards && cardToolTip !== CardToolTipViewOptions.DeleteUser)
        || pageNavigation === PageNavigation.ManageUsers);
}
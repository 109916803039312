import React, { useState } from 'react';
import styled from 'styled-components';
import { veryLightGrey, blue } from 'constants/theme';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Check as CheckIcon, ExpandMore } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { SelectFieldMenuProps } from 'helper/Util';

type Props = {
    filterValue?: string;
    filterOnChangeHandle?: (event: any) => void;
    filterOnOpenHandle?: () => void;
    filterOnCloseHandle?: () => void;
    filterName: string;
    filterOptions: string[];
    isFilterOpen: boolean;
    selectedFilterOptionIndex: any;
    minWidth?: number;
    maxWidth?: number;
    width?: string;
    height?: string;
    setSelectedFilterOptionIndex: (index: number) => void;
};

type CustomFilterProps = {
    minwidth?: number;
    maxwidth?: number;
    width?: string;
    height?: string;
};

const CustomUserFilter = ({
    filterValue,
    filterOptions,
    isFilterOpen,
    filterName,
    filterOnChangeHandle,
    filterOnOpenHandle,
    filterOnCloseHandle,
    selectedFilterOptionIndex,
    minWidth = FILTER_MIN_WIDTH,
    maxWidth = FILTER_MAX_WIDTH,
    width,
    height,
    setSelectedFilterOptionIndex,
}: Props) => {
    const [open, setOpen] = useState(false);

    const handleMenuItemClickDepartment = (index: number) => {
        setSelectedFilterOptionIndex(index);
    }

    function handleOpen() {
        if (filterOnOpenHandle) filterOnOpenHandle();
        setOpen(true);
    }

    function handleClose() {
        if (filterOnCloseHandle) filterOnCloseHandle();
        setOpen(false);
    }
    return (
        <CustomFilter
            minwidth={minWidth}
            maxwidth={maxWidth}
            width={width}
            height={height}
        >
            <StyledSelect
                value={filterValue}
                onChange={filterOnChangeHandle}
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
                input={
                    <OutlinedInput
                        id="simple-menu"
                        startAdornment={
                            <InputAdornment
                                position="start"
                                sx={SelectTitleStyles}
                                onClick={open ? handleClose : handleOpen}
                            >
                                <Typography noWrap>{filterName}</Typography>
                            </InputAdornment>
                        }
                        sx={OutlinedInputStyles}
                    />
                }
                renderValue={(selected) => (
                    <Typography sx={SelectedMenuItemStyles}>
                        {selected}
                    </Typography>
                )}
                MenuProps={SelectFieldMenuProps()}
                IconComponent={ExpandMore}
            >
            {filterOptions.map((filterOptions, index) => (
                <StyledMenuItem onClick={() => handleMenuItemClickDepartment(index)}  
                    color={index === selectedFilterOptionIndex ? blue : 'inherit'}
                    value={filterOptions} 
                    key={index} 
                    selected={index === selectedFilterOptionIndex} 
                > 
                    <strong>{filterOptions}</strong>{isFilterOpen && index === selectedFilterOptionIndex && (
                        <ListItemIcon>
                            <FilterCheckIcon/>
                        </ListItemIcon>
                    )}
                </StyledMenuItem>
            ))}
            </StyledSelect>
        </CustomFilter>
    );
};

export default CustomUserFilter;

export const FILTER_MIN_WIDTH = 150;

export const FILTER_MAX_WIDTH = 270;

const CustomFilter = styled.div<CustomFilterProps>((props) => ({
    width: props.width ?? '100%',
    height: props.height ?? '4.4vh',
    backgroundColor: 'transparent',
    minWidth: props.minwidth,
    maxWidth: props.maxwidth,
  }));

const FilterCheckIcon = styled(CheckIcon) ({
    color: blue, 
    fontSize: 20,
});

const StyledSelect = styled(Select)(() => ({
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    border: `1px solid ${veryLightGrey}`,
    cursor: 'pointer',
    backgroundColor: 'transparent'
}));

const StyledMenuItem = styled(MenuItem)<{ color?: string }>((props) => ({
    color: props.color,
    fontWeight: 'bold',
    maxWidth: '100%',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    border: `1px solid ${veryLightGrey}`,
    '&:hover': {backgroundColor: veryLightGrey,},
    '&.Mui-selected': {backgroundColor: 'white',},
    '&.Mui-selected:hover': {backgroundColor: veryLightGrey,},
    '& .MuiListItemIcon-root': { minWidth: 0 }
  }));

const OutlinedInputStyles = {
    padding: 0,
    textAlign: 'right',
    '& .MuiOutlinedInput-input': { padding: '0 !important', backgroundColor: 'transparent', height: '100% !important', alignContent: 'center' },
    '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
};

const SelectTitleStyles = {
    position: 'initial',
    height: '100%',
    maxHeight: 'none',
    marginRight: '0',
    '& .MuiTypography-root': { marginLeft: '0.5vw' }
};

const SelectedMenuItemStyles = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    marginRight:'1.5vw',
    '@media (max-width: 1600px)': { marginRight: '2vw' }
};
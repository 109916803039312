import dayjs, { Dayjs, isDayjs } from "dayjs";
import { DateFormat } from "model/DateFormat";
import { UserAccess, UserAccessDateData } from "model/NewUserModel";

export class DatesUtil {

    public static readonly CUSTOM_DATE_FORMAT  = 'YYYY-MM-DD';

    public static readonly CUSTOM_DATE_FORMAT_REVERSED  = 'DD/MM/YYYY';

    public static readonly CUSTOM_DATE_TIME_FORMAT = 'MM-DD-YYYY HH:mm';

    public static readonly CUSTOM_FULL_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

    public static readonly CUSTOM_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

    public static readonly CUSTOM_TIME_FORMAT = 'HH:mm:ss';

    public static readonly DEFAULT_TIME_VALUE = '00:00:00';

    public static readonly CURRENT_DATE = new Date().toISOString().split('.')[0];

    public static readonly getCurrentDateAndTimeInDayJs = () => dayjs();

    public static readonly ONE_YEAR_AGO_OF_CURRENT_DATE = new Date(
            new Date().setFullYear(new Date().getFullYear() - 1
        )).toISOString().split('.')[0];

    public static convertDayjsToDateFormat = (dayjsDate: dayjs.Dayjs | null, format: string) => {
        return (dayjsDate ? dayjsDate.format(format) : "");
    }

    public static convertFormatToDayjs = (date: string | null | undefined, format: string) => {
        if (!date) {
            return null;
        }

        if (format === this.CUSTOM_DATE_TIME_FORMAT) {
            return this.convertDDMMYYYYhhmmssFormatToDayjs(date);
        } else {
            return this.convertCustomFormatToDayjs(date, format);
        }
    }

    private static convertCustomFormatToDayjs = (date: string, format: string) => {
        return !isNaN(new Date(date).getTime())
            ? dayjs(date, format)
            : null;
    }

    private static convertDDMMYYYYhhmmssFormatToDayjs = (date: string) => {
        const textDateElements = date.split('-');
        // This line is converting the "DD-MM-YYYY hh:mm:ss" string format to "MM-DD-YYYY hh:mm:ss" format, so we could use the Date.parse() on it.
        const formattedTextDate = textDateElements ? `${textDateElements[1]}-${textDateElements[0]}-${textDateElements[2]}` : undefined;
        return formattedTextDate
            && !isNaN(new Date(Date.parse(formattedTextDate)).getTime())
                ? dayjs(formattedTextDate, this.CUSTOM_DATE_TIME_FORMAT)
                : null;
    }

    public static isEveryEntryNotNaN = (entries: DateFormat | undefined) => {
        return !!entries && Object.values(entries).every(time => !isNaN(time));
    }

    public static getDateFormatFromDate = (date: Date | undefined) => {
        if (!date) {
            return undefined;
        }
        const dateFormat: DateFormat = {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
        };
        return (this.isEveryEntryNotNaN(dateFormat) 
            ? dateFormat 
            : undefined)
    }

    public static isSelectedDateIsInRangeOfXYearsFromCurrentDate = (date: string, yearsFromCurrentDate: number) => {
        const selectedDate = DatesUtil.getDateFormatFromDate(new Date(date));
        if (selectedDate) {
            const legalAgeThreshold = new Date(
                new Date().setFullYear(
                    new Date().getFullYear() - yearsFromCurrentDate,
                    new Date().getMonth(),
                    new Date().getDate()
                )
            );
            const selectedUserAge = new Date(
                new Date().setFullYear(
                    selectedDate.year,
                    selectedDate.month,
                    selectedDate.day
                )
            );
            return (selectedUserAge.getTime() > legalAgeThreshold.getTime());
        } else {
            return false;
        }
    }

    public static combineDateAndTime(startDate: Dayjs | null | undefined, startTime: Dayjs | null | undefined): dayjs.Dayjs | undefined {
        if (startDate && startTime) {

            const year = startDate.year();
            const month = startDate.month();
            const date = startDate.date();

            const hour = startTime.hour();
            const minute = startTime.minute();
            const second = startTime.second();

            const combinedDate = dayjs(new Date(year, month, date, hour, minute, second));
            return combinedDate;
        }
        return undefined;
    }

    public static combineDateAndTimeToFormat(startDate: Dayjs | null | undefined, startTime: Dayjs | null | undefined): string | null {
        const combinedDate = this.combineDateAndTime(startDate, startTime);
        return combinedDate ? combinedDate.format(DatesUtil.CUSTOM_ISO_FORMAT) : null;
    }

    public static isFirstDateBeforeSecond(firstDate: string | null | Dayjs, secondDate: string | null | Dayjs): boolean {
        if (typeof firstDate === "string" && typeof secondDate === "string") {
            const date1 = dayjs(firstDate);
            const date2 = dayjs(secondDate);

            return date1.isBefore(date2);
        } else if (isDayjs(firstDate) && isDayjs(secondDate)) {
            return firstDate.isBefore(secondDate);
        }

        return false;
    }

    public static isDateInThePast(date: string | null): boolean {
        return date ? dayjs(date).isBefore(dayjs()) : false;
    }

    public static convertStringToDateData = (isoString: string | null | undefined): { date: Dayjs; time: Dayjs } => {
        const dateTime = dayjs(isoString);

        return {
          date: dateTime.startOf('day'),
          time: dateTime,
        };
    };

    public static getUserAccessDateDataByUserAccess = (userAccess: UserAccess | undefined): UserAccessDateData => {
        const beginDateData = DatesUtil.convertStringToDateData(userAccess?.BeginDate);
        const endDateData = DatesUtil.convertStringToDateData(userAccess?.EndDate);
    
        return {
          StartDate: beginDateData.date,
          EndDate: endDateData.date,
          StartTime: beginDateData.time,
          EndTime: endDateData.time,
        };
    };
}
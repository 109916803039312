import { DialogToOpen } from "model/enums";
import { useSelector } from "react-redux"
import { selectOpenedDialog } from "redux/selectors/userSelector"

export const useIsCardFieldDisplayed = () => {
    const openedDialog = useSelector(selectOpenedDialog);
    const isDepartmentDisplayed = openedDialog !== DialogToOpen.CreateEmergencyCardDialog;
    const isLockReleaseDisplayed = openedDialog === DialogToOpen.CreateKeyCardDialog || openedDialog === DialogToOpen.CreateMasterCardDialog;
    const isBuildingAndFloorSelectorDisplayed = openedDialog === DialogToOpen.CreateFloorCardDialog;

    return { 
        isDepartmentDisplayed, 
        isLockReleaseDisplayed,
        isBuildingAndFloorSelectorDisplayed,
    }
}
import * as React from 'react';
import { DialogToOpen, ReadCardDialogTexts, SignalrResponseStatuses } from 'model/enums';
import { useDispatch } from 'react-redux';
import { setOpenedDialog, setUsersSelectedUserRow } from 'redux/actions/userActions';
import { ReactComponent as ReadCardInfoFrame } from 'media/readCardInfoFrame.svg';
import { ReactComponent as ReadCardFailedFrame } from 'media/readCardFailedFrame.svg';
import DialogTexts from 'model/DialogTexts';
import { blue } from 'constants/theme';
import { useSelector } from 'react-redux';
import { selectOpenedDialog, selectUser } from 'redux/selectors/userSelector';
import DialogTemplate from '../DialogTemplate';
import { selectEncoderStorage, selectIsSignalrResponseStatusPending } from 'redux/selectors/encoderSelector';
import { notifyErrorEncoderIsNotSelected } from 'helper/EncoderUtils';
import { readCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { handleEncoderNotRespondedYetError, ignoreSignalrResponseStatus, setEncoderRequestToActive, setEncoderRequestToInactive } from 'hooks/useSignalrEncoder';

const ReadCardDialog = () => {
    const isOpenedDialog = useSelector(selectOpenedDialog);
    const { signalrResponseStatus, encoderCardResponse, selectedEncoder } = useSelector(selectEncoderStorage);
    const isDialogOpen = isOpenedDialog === DialogToOpen.ReadCardDialog;
    const selectedUser = useSelector(selectUser(Number(encoderCardResponse?.UserId)));
    const isSignalrResponseStatusPending = useSelector(selectIsSignalrResponseStatusPending);
    const [cardResponseStatus, setCardResponseStatus] = React.useState(SignalrResponseStatuses.None);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    const handleDialogClose = () => {
        if (isSignalrResponseStatusPending) {
            handleEncoderNotRespondedYetError();
            return;
        }

        dispatch(setOpenedDialog());
        setEncoderRequestToInactive(dispatch);
        setCardResponseStatus(SignalrResponseStatuses.None);
    }

    const fetchDialogTextByReadCardStatus = () => {
        switch(cardResponseStatus) {
            case SignalrResponseStatuses.None:
                return (<React.Fragment>
                    <div><ReadCardInfoFrame /></div>
                    <div>Please, place your card on the encoder</div>
                    <div>to see details about the card.</div>
                </React.Fragment>);
            case SignalrResponseStatuses.Failed:
                return (<React.Fragment>
                    <div><ReadCardFailedFrame /></div>
                    <div>Something went wrong.</div>
                    <div>Please try again.</div>
                </React.Fragment>);
            case SignalrResponseStatuses.CardIsBlank:
                return (<React.Fragment>
                    <div><ReadCardFailedFrame /></div>
                    <div>This card is blank.</div>
                    <div>Please try again with a different card.</div>
                </React.Fragment>);
            default:
                return (<React.Fragment>
                    <CircularProgressHolder>
                        <CircularProgress size={60} />
                    </CircularProgressHolder>
                </React.Fragment>)
        }
    }

    const readCardDialogText: DialogTexts ={
        title: ReadCardDialogTexts.ReadDialogTitle,
        contentText: fetchDialogTextByReadCardStatus(),
        submitButton: 'Read Card',
        cancelButton: 'Cancel',
    };

    const handleSubmit = async () => {
        if (!selectedEncoder) {
            notifyErrorEncoderIsNotSelected();
            return;
        }

        const response = await readCard(actualSite, selectedEncoder.Id);

        if (!response) {
            return;
        }

        setEncoderRequestToActive(dispatch);
        setCardResponseStatus(SignalrResponseStatuses.Pending);
    }

    React.useEffect(() => {
        if (!isDialogOpen || ignoreSignalrResponseStatus.includes(signalrResponseStatus)) {
            return;
        }

        if (signalrResponseStatus !== SignalrResponseStatuses.Success) {
            setCardResponseStatus(signalrResponseStatus);
            setEncoderRequestToInactive(dispatch);
            return;
        }

        if (selectedUser) {
            dispatch(setUsersSelectedUserRow({
                name: selectedUser.Name,
                department: selectedUser.Department.DepartmentName,
                userId: Number(selectedUser.UserNumber),
                globalId: selectedUser.Id,
                cardId: selectedUser.CardID,
                group: selectedUser.Group.GroupName,
                cardInUse: selectedUser.Card?.CardStatus,
                cardType: selectedUser.Card?.CardType,
                limitedDate: selectedUser.Card?.Expiry
            }));
        }

        handleDialogClose();
    }, [isDialogOpen, signalrResponseStatus, selectedUser]);

    return (
        <React.Fragment>
            <DialogTemplate
                handleSubmit={handleSubmit}
                handleClose={handleDialogClose}
                open={isDialogOpen}
                dialogText={readCardDialogText}
                submitButtonColor={blue}
                dialogId={ReadCardDialogTexts.ReadDialogTitle}
                isSubmitButtonHidden={signalrResponseStatus === SignalrResponseStatuses.Pending}
            />
        </React.Fragment>
    );
}

export default ReadCardDialog;

const CircularProgressHolder = styled.div((props) => ({
    height: "100px", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
}));
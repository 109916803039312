import { useBuildingsInit, useRoomsInit } from 'hooks/useStorageInits';
import Floor from "model/Floor";
import RoomModel from 'model/RoomModel';
import { useEffect, useState } from "react";

export const useListFloorsByBuildingId = (buildingId?: number) => {
    const [listOfFloors, setListOfFloors] = useState<Floor[]>([]);
    const listOfBuildings = useBuildingsInit();
    
    useEffect(() => {
        setListOfFloors([]);
        listOfBuildings.forEach(building => building.Id === buildingId && building.Floors.forEach(floor => setListOfFloors((prev) => [...prev, floor])));
    }, [listOfBuildings, buildingId]);

    return listOfFloors;
}

export const useListRoomsByFloorIdAndBuildingId = (floorId?: number, buildingId?: number) => {
    const [filteredListOfRooms, setFilteredListOfRooms] = useState<RoomModel[]>([]);
    const listOfRooms = useRoomsInit();
    
    useEffect(() => {
        setFilteredListOfRooms(listOfRooms.filter(room => room.FloorNo === floorId && room.BuildingNo === buildingId));
    }, [listOfRooms, floorId]);

    return filteredListOfRooms;
}

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCardDialogIsConfirmationDialogOpen } from 'redux/selectors/cardDialogSelector';
import ConfirmationDialog from '../../ConfirmationDialog';
import {
  setCardDialogIsConfirmationDialogOpen,
  setCardDialogShouldConfirmationDialogSubmit,
} from 'redux/actions/cardDialogActions';

export const CardDialogConfirmationDialog = () => {
  const isOpen = useSelector(selectCardDialogIsConfirmationDialogOpen);
  const dispatch = useDispatch();

  const handleOnSubmit = () => dispatch(setCardDialogShouldConfirmationDialogSubmit(true));

  const handleOnClose = () => dispatch(setCardDialogIsConfirmationDialogOpen(false));

  return (
    <ConfirmationDialog
      open={isOpen}
      handleSubmit={handleOnSubmit}
      handleClose={handleOnClose}
    />
  );
};

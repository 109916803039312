import { CommandCategory, OperatorCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';
import OperatorModel from 'model/OperatorModel';

export const getOperators = async (siteName: string): Promise<OperatorModel[]> => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.OperatorService,
        OperatorCommand.GetOperators,
        [],
      );

      if (!data || !data.data) {
        notifyCommandError('No Operator found or the server timed out.', null);
        return [];
      }

      return data.data;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyCommandError('Error while executing get master level command.', error);
      return [];
    }
}
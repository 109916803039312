import { DoorEvent, Events } from "model/event";
import EventActionTypes from "redux/constants/eventActionTypes";

const initMessage: DoorEvent[] = []

const initState: Events = { events: initMessage }

export const eventReducer = function(state: Events = initState, action) {
    switch (action.type) {
        case EventActionTypes.ADD_EVENT:
            
            let index = state.events.findIndex((e) => e.siteName === action.payload.siteName);
            let events = state.events.slice();

            if(index > -1) {
                events[index].messages.push(action.payload.messages);
                return {
                    ...state,
                    events: [
                        ...events
                    ]
                }
            } else {
                return {
                    ...state,
                    events: [
                        ...state.events,{siteName: action.payload.siteName, messages: [action.payload.messages]}
                    ]
                }
            } 
    
        default:
            return state;
    }
}
import * as React from 'react';
import styled, { css } from 'styled-components';
import { lightGrey, mainBlue, white } from 'constants/theme';

type Props = {
  tabs: string[];
  selectedTab: number;
  height: number,
  width: number,
  isBorder?: boolean,
  handleOnClick: (index: number) => void;
};

const TabsField = ({
  tabs,
  selectedTab,
  height,
  width,
  isBorder,
  handleOnClick,
}: Props) => {

    return (
      <StyledList
        width={width}
        height={height}
        $isBorder={isBorder}
      >
        {tabs.map((tab, index) => (
          <StyledListItem
              key={index}
              location={tab}
              selected={selectedTab === index}
              onClick={()=>handleOnClick(index)}
            />
        ))}
      </StyledList>
    );
};

export default TabsField;

const StyledList = styled.ul<{ height?: number, width?: number, $isBorder?: boolean }>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: `${props.width}px`,
  height: `${props.height}px`,
  backgroundColor: white,
  textDecoration: 'none',
  listStyleType: 'none',
  borderRadius: '5px',
  paddingInlineStart: '0px !important',
  margin: '0px',
  border: (props.$isBorder ? `1px solid ${lightGrey}` : ``),
}));

interface ItemProps{
  selected?: boolean;
}
  
const StyledListItem = (props?: any) => {
  const Item = styled.li<ItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;
    border-radius: 5px;
    margin: 3px 4px 3px 4px;

    &:hover {
      cursor: pointer;
      background-color: ${mainBlue};
      color: ${white};
    }

    ${(props: any) =>
      props.selected &&
      css`
      background-color: ${mainBlue};
      color: ${white};
    `}
  `;
  return (
    <Item
      selected={props.selected}
      onClick={props.onClick}
    >
        {props.location}
    </Item>
  );
}
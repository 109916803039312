import { Dispatch } from 'redux';
import UserActionTypes from '../constants/userActionTypes';
import { getDepartments } from 'API/commands/DepartmentCommands';
import { getUsersByDepartment } from 'API/commands/UserCommands';
import UserModel from 'model/UserModel';
import UserTableRecord from 'model/UserTableRecord';
import { DialogToOpen, PageNavigation } from 'model/enums';
import { UserDeletion } from 'model/UserDeletion';

export const setUsersRequest = () => {
  return { type: UserActionTypes.USERS_REQUESTED };
};
export const setUsersSuccess = (data: UserModel[]) => {
  return {
    type: UserActionTypes.REQUEST_USERS_SUCCESS,
    payload: data,
  };
};
export const setUsersFailure = (err: any) => {
  return {
    type: UserActionTypes.REQUEST_USERS_FAILURE,
    payload: err,
  };
};
export const setEmptyUserList = () => {
  return {
    type: UserActionTypes.SET_EMPTY_USER_LIST,
    payload: [],
  }
};

export const setUsersIsRefreshUsersList = (data: boolean) => {
  return {
    type: UserActionTypes.SET_USERS_LIST_REFRESH,
    payload: data,
  }
};
export const setUsersSelectedUserRow = (data?: UserTableRecord) => {
  return {
    type: UserActionTypes.SET_SELECTED_USER,
    payload: data,
  }
};
export const setOpenedDialog = (data?: DialogToOpen) => {
  return {
    type: UserActionTypes.SET_OPENED_DIALOG,
    payload: data,
  }
};
export const setUsersToDelete = (data: UserDeletion[]) => {
  return {
    type: UserActionTypes.SET_USERS_TO_DELETE,
    payload: data,
  }
};
export const setPageNavigation = (data?: PageNavigation) => {
  return {
    type: UserActionTypes.SET_PAGE_NAVIGATION,
    payload: data,
  }
};
export const setNewUsersToPending = (data: UserModel[]) => {
  return {
    type: UserActionTypes.SET_NEW_USERS_ON_PENDING,
    payload: data,
  }
};
export const setBleUserLoading = (data: boolean) => {
  return { 
    type: UserActionTypes.SET_BLE_USER_LOADING,
    payload: data,
  };
};
export const setOperator = (data?: number) => {
  return { 
    type: UserActionTypes.SET_OPERATOR,
    payload: data,
  };
};

export const fetchAllUsers = (
  actualSite:string,
) => {
    return (dispatch: Dispatch) => {
      dispatch(setUsersRequest());
      dispatch(setEmptyUserList());
      getDepartments(actualSite).then((departments) => {
        if(departments && departments.length > 0) {
          let listOfUsers: UserModel[] = [];
          let processedDepartmentsCount = 0;
          departments.forEach((department) => {
            _getAllOfTheUsersByDepartmentId(actualSite, department.Id).then((userModels: UserModel[]) => {
              listOfUsers.push(...userModels);
              processedDepartmentsCount++;
              if (processedDepartmentsCount === departments.length) {
                dispatch(setUsersSuccess(listOfUsers));
              }
            }).catch((err) => {
              dispatch(setUsersFailure(err));
            });
          });
        } else {
          dispatch(setUsersFailure([]));
        }
      }).catch((err) => {
        dispatch(setUsersFailure(err));
      });
  };
};

const _getAllOfTheUsersByDepartmentId = async(actualSite: string, departmentId: number): Promise<UserModel[]> => {
  return await getUsersByDepartment(actualSite, departmentId) ?? [];
}

export const deleteUser = (actualSite: string, userId: number) => {
  deleteUser(actualSite, userId);
}
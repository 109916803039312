import * as React from 'react';
import { UserDialogInformationPageFormText, UserDialogInformationPageFormPlaceholderText } from 'model/enums';
import SelectField from 'components/controls/selects/SelectField';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import TimeGroup from 'model/TimeGroup';
import DepartmentDetail from 'model/DepartmentDetail';
import { useSelector } from 'react-redux';
import { StyledContentHolder, StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import { useTimeGroupsInit } from 'hooks/useStorageInits';
import { selectUserDialogFormInformationError, selectUserDialogInformationForm, selectUserDialogStorage } from 'redux/selectors/userDialogSelector';
import { InformationForm } from 'model/UserDialogForm';
import { useDispatch } from 'react-redux';
import { setUserDialogForm } from 'redux/actions/userDialogActions';

const UserInformationPage = ()  => {

  const [listOfGroups, setListOfGroups] = React.useState<string[]>([]);
  const [listOfDepartments, setListOfDepartments] = React.useState<string[]>([]);
  const groupNameList: TimeGroup[] = useTimeGroupsInit();
  const departmentList: DepartmentDetail[] = useSelector(selectDepartmentList);
  const {selectedUserRecord, userDialogForm} = useSelector(selectUserDialogStorage);
  const form = useSelector(selectUserDialogInformationForm);
  const error = useSelector(selectUserDialogFormInformationError);
  const disabled = !!selectedUserRecord;
  const dispatch = useDispatch();

  React.useEffect(() => {
    setListOfGroups([...groupNameList.map(item => item.GroupName)]);
  }, [groupNameList]);

  React.useEffect(() => {
    setListOfDepartments([...departmentList.map(item => item.Name)]);
  }, [departmentList]);

  const setForm = (information: InformationForm) => {
     dispatch(setUserDialogForm({
        ...userDialogForm,
        information,
        bluetoothForm: {
            ...userDialogForm.bluetoothForm,
            username: information.name,
        }
    }))
  }

  return (
    <StyledUserDialogContent
      width={684}
      height={289}
    >
      <StyledContentHolder>
        <StyledContentRowHolder>
          { disabled ? 
            <MultiTypeInputField
              inputFieldHeight={"40px"}
              inputFieldWidth={"177px"}
              displayedName={UserDialogInformationPageFormText.UserId}
              placeholderInput={UserDialogInformationPageFormPlaceholderText.UserId}
              setValue={(userId) => setForm({...form, userId})}
              inputValue={form.userId}
              invalidFormMessage={error.userIdError}
              inputType='number'
              isDisabled
            />
          : null
          }
          <MultiTypeInputField
            inputFieldHeight={"40px"}
            inputFieldWidth={disabled ? "483px" : "684px"}
            displayedName={UserDialogInformationPageFormText.Name}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.Name}
            setValue={(name) => setForm({...form, name})}
            inputValue={form.name}
            isRequired
            invalidFormMessage={error.userNameError}
            isDisabled={disabled}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <SelectField
            inputFieldHeight={"40px"}
            inputFieldWidth={"684px"}
            displayedName={UserDialogInformationPageFormText.DepartmentName}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.DepartmentName}
            selectOptions={listOfDepartments}
            setValue={(departmentName) => setForm({...form, departmentName})}
            inputValue={form.departmentName}
            isRequired
            invalidFormMessage={error.departmentNameError}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <SelectField
            inputFieldHeight={"40px"}
            inputFieldWidth={"684px"}
            displayedName={UserDialogInformationPageFormText.GroupName}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.GroupName}
            selectOptions={listOfGroups}
            setValue={(groupName) => setForm({...form, groupName})}
            inputValue={form.groupName}
            isRequired
            invalidFormMessage={error.groupNameError}
          />
        </StyledContentRowHolder>
      </StyledContentHolder>
    </StyledUserDialogContent>
  );
};

export default UserInformationPage;
import * as React from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';

type Props = {
    buttonHeight?: number;
    buttonWidth?: number;
    icon: any;
    handleOnClick: () => void;
};

const StyledIconButton = ({
    buttonHeight = 36,
    buttonWidth = 36,
    icon,
    handleOnClick,
}: Props) => {

    return (
        <React.Fragment>
            <StyledIconButtonInnerContainer 
                height={buttonHeight}
                width={buttonWidth}
                onClick={handleOnClick}
            >
                <StyledIconHolder>                    
                    <img src={icon} alt="Back icon button" />
                </StyledIconHolder>
            </StyledIconButtonInnerContainer>
        </React.Fragment>
    );
};

export default StyledIconButton;

const StyledIconHolder = styled.div<{}>((props) => ({ display: 'flex' }));

const StyledIconButtonInnerContainer = styled(IconButton)<{ height?: number, width?: number }>((props) => ({ 
    height: `${props.height}px`,
    width: `${props.width}px`,
    maxHeight: `${props.height}px`,
    maxWidth: `${props.width}px`,
}));
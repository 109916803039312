import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import { ReactComponent as CheckboxNotChecked} from 'media/checkboxNotChecked.svg';
import { ReactComponent as CheckboxChecked} from 'media/CheckboxChecked.svg';
import { ReactComponent as CheckboxHalfChecked} from 'media/CheckboxHalfChecked.svg';
import { displayInputName} from 'helper/Util';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';

type Props = {
    boxHeight?: string;
    boxWidth: string;
    displayedName?: string;
    inputValue?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
    isIndeterminate?: boolean;
    textAlign?: string;
    setValue: (value: any) => void;
};

const CheckboxField = ({
    boxHeight,
    boxWidth,
    displayedName = "",
    inputValue = false,
    isDisabled = false,
    isRequired = false,
    isIndeterminate = false,
    textAlign,
    setValue,
}: Props) => {

    return (
        <StyledInputFieldComponents.StyledInputFieldHolder
            height={boxHeight}
            width={boxWidth}
            $textAlign={textAlign}
        >
            <StyledInputFieldComponents.StyledInputHolder>
                <FormControlLabel
                    sx={{ margin: 0, 'span' : { fontWeight: "bold" } }}
                    control={ <StyledCheckbox 
                        onClick={() => setValue(!inputValue)}
                        icon={<CheckboxNotChecked />}
                        checkedIcon={< CheckboxChecked />}
                        disableRipple
                        checked={inputValue}
                        disabled={isDisabled}
                        indeterminate={isIndeterminate}
                        indeterminateIcon={< CheckboxHalfChecked /> }
                    />}
                    label={displayInputName(displayedName, isRequired)}
                />
            </StyledInputFieldComponents.StyledInputHolder>
        </StyledInputFieldComponents.StyledInputFieldHolder>
    );
};

export default CheckboxField;

const StyledCheckbox = styled(Checkbox)`
    padding: 0px 9px 0px 0px;
`;

import * as React from 'react';
import { ReactComponent as ShevronLeftArrow} from 'media/chevron-left.svg';
import { CustomStyledDialogContentHolder, StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder, StyledDialogCardHolder } from "../../UserCardDialog";
import { CustomDialogBackIcon, CustomDialogTitleComponent, CustomStyledDialogCardTitle } from '../../UserDialogCommonStyle';
import StyledButton from 'components/controls/button/StyledButton';
import { blue, white } from 'constants/theme';
import { dialogContainer } from 'constants/globalStyle';
import { Dialog } from '@mui/material';

type Props = {
    isDialogOpen: boolean;
    setDialogOpen: (isDialogOpen: boolean) => void;
    isNoActionDialog: boolean;
    dialogResourceName: string;
    MainDialogComponent: React.ReactNode;
    handleConfirm?: () => void;
    selectedResourceNumber?: number;
};

const BaseUserAccessDialog = ({ 
    isDialogOpen,
    setDialogOpen,
    isNoActionDialog,
    dialogResourceName,
    MainDialogComponent,
    handleConfirm,
    selectedResourceNumber,
}: Props) => {

    const handleClickOnClose = () => {
        setDialogOpen(false);
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
            <CustomStyledDialogCardTitle>
                    <CustomDialogTitleComponent>
                        <CustomDialogBackIcon 
                            onClick={handleClickOnClose}
                        >
                            <ShevronLeftArrow />
                        </CustomDialogBackIcon>
                    </CustomDialogTitleComponent>
                    <div>
                        {dialogResourceName}
                    </div>
                    <CustomDialogTitleComponent />
                </CustomStyledDialogCardTitle>
                <CustomStyledDialogContentHolder>
                    {MainDialogComponent}
                </CustomStyledDialogContentHolder>
                <StyledDialogActions>
                    <StyledDialogActionsHolder>
                        <StyledButtonHolder>
                            {!isNoActionDialog && handleConfirm ? 
                                <>
                                    <StyledButton
                                        buttonHeight={37}
                                        buttonWidth={95}
                                        displayedName={"Cancel"}
                                        handleOnClick={handleClickOnClose}
                                        backgroundColor={white}
                                    />
                                    <StyledButton
                                        buttonHeight={37}
                                        buttonWidth={130}
                                        displayedName={`Add ${dialogResourceName}` + (selectedResourceNumber ? ` (${selectedResourceNumber})` : '')}
                                        handleOnClick={handleConfirm}
                                        backgroundColor={blue}
                                        isFilledButton={true}
                                    />
                                </> :
                                <StyledButton
                                    buttonHeight={37}
                                    buttonWidth={95}
                                    displayedName={"Cancel"}
                                    handleOnClick={handleClickOnClose}
                                    backgroundColor={white}
                                /> 
                            }
                        </StyledButtonHolder>
                    </StyledDialogActionsHolder>
                </StyledDialogActions>
            </StyledDialogCardHolder>
        </Dialog>
    );
};

export default BaseUserAccessDialog;

import { IRootState } from "redux/store";

export const selectUserDialogForm = (state: IRootState) => state.userDialogStorage.userDialogForm;

export const selectUserDialogInformationForm = (state: IRootState) => state.userDialogStorage.userDialogForm.information;

export const selectUserDialogAccessForm = (state: IRootState) => state.userDialogStorage.userDialogForm.accessForm;

export const selectUserDialogFormError = (state: IRootState) => state.userDialogStorage.userDialogFormError;

export const selectUserDialogFormInformationError = (state: IRootState) => state.userDialogStorage.userDialogFormError.informationError;

export const selectUserDialogFormAccessError = (state: IRootState) => state.userDialogStorage.userDialogFormError.accessError;

export const selectUserDialogPage = (state: IRootState) => state.userDialogStorage.selectedPageName;

export const selectUserDialogUserRecord = (state: IRootState) => state.userDialogStorage.selectedUserRecord;

export const selectUserDialogStorage = (state: IRootState) => state.userDialogStorage;

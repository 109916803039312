import * as React from 'react';
import InputFieldTemplate from './InputFieldTemplate';
import { KeyCode } from 'model/enums';
import { InputProps } from 'model/InputProps';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string;
    invalidFormMessage?: string;
    adornmentIcon?: string;
    restrictKeysToPress?: KeyCode[];
    inputProps?: InputProps;
    defaultValue?: number;
    isStepperDisplayed?: boolean;
    isRequired?: boolean;
    disabled?: boolean;
    setValue?: (value?: string) => void;
};

const NumberInputField = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName,
    placeholderInput,
    inputValue,
    invalidFormMessage,
    adornmentIcon,
    restrictKeysToPress = [],
    inputProps,
    defaultValue,
    isStepperDisplayed = true,
    isRequired,
    disabled,
    setValue,
}: Props) => {

    return (
        <InputFieldTemplate
            inputFieldHeight={inputFieldHeight}
            inputFieldWidth={inputFieldWidth}
            displayedName={displayedName}
            placeholderInput={placeholderInput}
            inputValue={inputValue}
            setValue={setValue}
            invalidFormMessage={invalidFormMessage}
            adornmentIcon={adornmentIcon}
            inputType='number'
            restrictKeysToPress={restrictKeysToPress}
            inputProps={inputProps}
            defaultValue={defaultValue}
            isStepperDisplayed={isStepperDisplayed}
            isRequired={isRequired}
            isDisabled={disabled}
        />
    );
};

export default NumberInputField;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getBuildings } from 'API/commands/BuildingCommands';
import Building from 'model/Building';
import BuildingCard from './BuildingCard';
import Button from 'components/controls/Button';
import OnlineDoor from 'model/OnlineDoor';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import GeneralTopLoadingBar from 'components/GeneralTopLoadingBar';
import { getOnlineDoors } from 'API/commands/DoorCommands';
// Test datas
// import { testBuildings, testOnlineDoors } from '../../constants/testDatas';
import { OnlineDoorCommand } from 'model/enums';
import { lightGrey, lighterGrey, white } from 'constants/theme';
import BuildingFilter from './BuildingFilter'

type SiteCardProps = {
  siteName: string;
  confirmCommand: (
    commandType?: OnlineDoorCommand,
    buildings?: Building[],
    floorsWithBuildingId?: FloorWithBuildingName[],
    doors?: OnlineDoor[],
  ) => void;
};
const ManageSiteCard = ({ 
  siteName, 
  confirmCommand 
}: SiteCardProps) => {
  const [isBuildingsLoading, setBuildingsLoading] = useState<boolean>(true);
  const [isDoorsLoading, setDoorsLoading] = useState<boolean>(true);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [doors, setDoors] = useState<OnlineDoor[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>([]);
  const [selectedFloors, setSelectedFloors] = useState<FloorWithBuildingName[]>(
    [],
  );
  const [selectedDoors, setSelectedDoors] = useState<OnlineDoor[]>([]);
  const [filterCheckedValues, setCheckedValues] = useState<any>([]);
  const [listedBuildings, setListedBuildings] = useState<Building[]>([]);

  // Data loading

  useEffect(() => {
    loadBuildings();
    loadDoors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteName]);

  const loadBuildings = async () => {
    try {
      const buildings = await getBuildings(siteName);
      if (buildings && buildings.length > 0) {
        setBuildings(buildings);
      }
      setBuildingsLoading(false);
    } catch (err) {
      //TODO notification NSP-65
      console.error(err);
      setBuildingsLoading(false);
    }
  };

  const loadDoors = async () => {
    try {
      const doors = await getOnlineDoors(siteName);
      if (doors && doors.length > 0) {
        setDoors(doors);
      }
      setDoorsLoading(false);
    } catch (err) {
      //TODO notification NSP-65
      console.error(err);
      setDoorsLoading(false);
    }
  };

  // Autoselect building based on floor selection
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    buildings.map((building) => {
      const floorCount = building.Floors.length;
      const selectedFloorCount = selectedFloors.filter(
        (selected) => selected.buildingName === building.Name,
      ).length;
      if (
        floorCount === selectedFloorCount &&
        !selectedBuildings.find((selected) => building.Id === selected.Id)
      ) {
        setSelectedBuildings([...selectedBuildings, building]);
      } else if (
        selectedFloorCount < floorCount &&
        selectedBuildings.find((selected) => selected.Id === building.Id)
      ) {
        setSelectedBuildings(
          selectedBuildings.filter(
            (selectedBuilding) => selectedBuilding.Id !== building.Id,
          ),
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloors]);

  //Autoselect floor based on door selection
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    buildings.map((building) => {
      // eslint-disable-next-line array-callback-return
      building.Floors.map((floor) => {
        const doorsOnFloor = doors.filter(
          (door) =>
            door.Building === building.Name && door.Floor === floor.Name,
        ).length;
        const doorsSelectedFromFloor = selectedDoors.filter(
          (door) =>
            door.Building === building.Name && door.Floor === floor.Name,
        ).length;

        if (
          doorsSelectedFromFloor > 0 &&
          doorsOnFloor === doorsSelectedFromFloor &&
          !selectedFloors.find((selected) => floor.Id === selected.floor.Id)
        ) {
          setSelectedFloors([
            ...selectedFloors,
            { floor: floor, buildingName: building.Name },
          ]);
        } else if (
          doorsSelectedFromFloor > 0 &&
          doorsSelectedFromFloor < doorsOnFloor &&
          selectedFloors.find((selected) => floor.Id === selected.floor.Id)
        ) {
          setSelectedFloors(
            selectedFloors.filter(
              (selectedFloor) => selectedFloor.floor.Id !== floor.Id,
            ),
          );
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoors]);

  // Select rooms, floors, buildings

  const handleBuildingSelection = (building: Building) => {
    if (
      !selectedBuildings.find(
        (buildingInState) => building.Id === buildingInState.Id,
      )
    ) {
      // If building is not selected, add building
      setSelectedBuildings([...selectedBuildings, building]);
      // add contained floors (adding without checking if it is already in is faster)
      let addFloors: FloorWithBuildingName[] = [];
      // eslint-disable-next-line array-callback-return
      building.Floors.map((floor) => {
        // If already contained: do nothing
        if (selectedFloors.find((selected) => selected.floor.Id === floor.Id)) {
          // eslint-disable-next-line array-callback-return
          return;
        }
        const floorWithId: FloorWithBuildingName = {
          floor: floor,
          buildingName: building.Name,
        };
        addFloors.push(floorWithId);
      });
      setSelectedFloors(selectedFloors.concat(addFloors));
      // add contained doors
      let addDoors: OnlineDoor[] = [];
      // eslint-disable-next-line array-callback-return
      doors.map((door) => {
        if (
          !selectedDoors.find((selected) => door.Id === selected.Id) &&
          door.Building === building.Name
        ) {
          addDoors.push(door);
        }
      });
      setSelectedDoors(selectedDoors.concat(addDoors));
    } else {
      // If it was selected, remove building
      setSelectedBuildings(
        selectedBuildings.filter(
          (buildingInState) => building.Id !== buildingInState.Id,
        ),
      );
      // remove contained floors
      setSelectedFloors(
        selectedFloors.filter(
          (selectedFloor) => selectedFloor.buildingName !== building.Name,
        ),
      );
      // remove contained doors
      setSelectedDoors(
        selectedDoors.filter((selected) => selected.Building !== building.Name),
      );
    }
  };

  const handleFloorSelection = (floor: FloorWithBuildingName) => {
    // Determine if it's selected or not
    if (
      !selectedFloors.find(
        (floorInState) => floor.floor.Id === floorInState.floor.Id,
      )
    ) {
      // set floor selected
      setSelectedFloors([...selectedFloors, floor]);
      // set contained doors selected
      let addDoors: OnlineDoor[] = [];
      // eslint-disable-next-line array-callback-return
      doors.map((door) => {
        if (
          door.Floor === floor.floor.Name &&
          door.Building ===
            buildings.find((building) => floor.buildingName === building.Name)
              ?.Name
        ) {
          addDoors.push(door);
        }
      });
      setSelectedDoors(selectedDoors.concat(addDoors));
    } else {
      // remove floors
      setSelectedFloors(
        selectedFloors.filter(
          (floorInState) => floor.floor.Id !== floorInState.floor.Id,
        ),
      );
      // remove contained doors
      setSelectedDoors(
        selectedDoors.filter(
          (door) =>
            door.Floor !== floor.floor.Name &&
            door.Building !==
              selectedBuildings.find(
                (building) => building.Name === floor.buildingName,
              )?.Name,
        ),
      );
    }
  };

  const handleDoorSelection = (door: OnlineDoor) => {
    if (!selectedDoors.find((doorInState) => door.Id === doorInState.Id)) {
      setSelectedDoors([...selectedDoors, door]);
    } else {
      setSelectedDoors(
        selectedDoors.filter((doorInState) => door.Id !== doorInState.Id),
      );
    }
  };

  // Rendering

  const renderBuildings =
    listedBuildings.length > 0 ? (
      listedBuildings.map((building, index) => (
        <BuildingCard
          building={building}
          buildings={buildings}
          doors={doors}
          key={index}
          setSelectedBuildings={handleBuildingSelection}
          setSelectedFloors={handleFloorSelection}
          setSelectedDoors={handleDoorSelection}
          selectedBuildings={selectedBuildings}
          selectedFloors={selectedFloors}
          selectedDoors={selectedDoors}
          confirmCommand={confirmCommand}
          siteName={siteName}
        />
      ))
    ) : (
      <ErrorMessageTitle>Looks like this site is empty!</ErrorMessageTitle>
    );

  return (
    <Container>
      <GlobalControl>
        <div></div>
        <ButtonsContainer>
          <Button
            onClick={() =>
              confirmCommand(
                OnlineDoorCommand.SpreadLostCard,
                selectedBuildings,
                selectedFloors,
                selectedDoors,
              )
            }
            text={'Spread Lost Card'}
          />
          <Button
              onClick={() =>
                  confirmCommand(
                    OnlineDoorCommand.RemoteConfigure, 
                    selectedBuildings,
                    selectedFloors,
                    selectedDoors,
                    )
              }
              text={'Remote Configure'}
              />
          <Button
            onClick={() =>
              confirmCommand(
                OnlineDoorCommand.DenyAccess,
                selectedBuildings,
                selectedFloors,
                selectedDoors,
              )
            }
            text={'Deny Access'}
          />
          <Button
            onClick={() =>
              confirmCommand(
                OnlineDoorCommand.EnableAccess,
                selectedBuildings,
                selectedFloors,
                selectedDoors,
              )
            }
            text={'Enable Access'}
          />
        </ButtonsContainer>
        <BuildingFilter
          setListedBuildings={setListedBuildings}
          buildings={buildings}
          setCheckedValues={setCheckedValues}
          checkedValues={filterCheckedValues}
        />
      </GlobalControl>
      {isBuildingsLoading || isDoorsLoading ? (
        <GeneralTopLoadingBar />
      ) : (
        <CheckboxContainer><>{renderBuildings}</></CheckboxContainer>
      )}
    </Container>
  );
};
export default ManageSiteCard;

const CheckboxContainer = styled.div`
  margin: 0px 20px;
`;

const ErrorMessageTitle = styled.h2`
  height: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
`;
const Container = styled.div`
  margin: 5% auto;
  background-color: ${white};
  border-radius: 6px;
  height: 45vh;
  width: 90vw;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    border: 10px solid ${white};
    padding: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${white};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${lightGrey};
    border-radius: 6px;
    &:hover {
      background: ${lighterGrey};
    }
  }
`;

const GlobalControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`

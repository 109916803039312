import { Dispatch } from 'redux';
import GroupNameActionTypes from 'redux/constants/groupNameActionTypes';
import TimeGroup from 'model/TimeGroup';
import { getTimeGroups } from 'API/commands/TimeGroupCommands';

export const fetchGroupNameRequest = () => {
  return { type: GroupNameActionTypes.FETCH_GROUP_NAME_REQUEST };
};
export const fetchGroupNameSuccess = (data: TimeGroup[]) => {
  return {
    type: GroupNameActionTypes.FETCH_GROUP_NAME_SUCCESS,
    payload: data,
  };
};
export const fetchGroupNameFailure = (err: any) => {
  return {
    type: GroupNameActionTypes.FETCH_GROUP_NAME_FAILURE,
    payload: err,
  };
};
export const fetchAllGroupName = (
  actualSite:string,
  dispatch: Dispatch,
) => {
    dispatch(fetchGroupNameRequest());
    getTimeGroups(actualSite).then((timeGroupList) => {
      if(timeGroupList && timeGroupList.length > 0) {
        const sortedList = timeGroupList.sort((current: TimeGroup, next: TimeGroup) => current.GroupName.localeCompare(next.GroupName));
        dispatch(fetchGroupNameSuccess(sortedList));
      } else {
        dispatch(fetchGroupNameFailure([]));
      }
    });
};
import React from 'react';
import { mainBlue } from 'constants/theme';
import { Box, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

type Props = {
  onChange: (e) => void;
  element: any;
};

const FilterCheckBox = ({ element, onChange }: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon
              sx={{ '&, &.Mui-checked': { color: mainBlue } }}
            />
          }
          onChange={(e) => onChange(e)}
          checked={element.checked}
          value={element.value}
          id={element.value}
        />
      }
      label={
        <Box component="div" fontWeight={300}>
          {element.value}
        </Box>
      }
    />
  );
};
export default FilterCheckBox;

import Building from 'model/Building';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import OnlineDoor from 'model/OnlineDoor';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { notifySuccess, notifyWarning } from 'helper/NotificationService';
import {
  veryLightGrey,
  lightGrey,
  mainBlue,
  white,
  black,
  opaqueBlack,
} from 'constants/theme';
import { OnlineDoorCommand } from 'model/enums';
import { postDoorCommand } from 'API/commands/DoorCommands';

type ConfirmCommandProps = {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  commandType: OnlineDoorCommand | null;
  buildings: Building[];
  floorsWithBuildingId: FloorWithBuildingName[];
  doors: OnlineDoor[];
  isShown: boolean;
  siteName: string;
};

type floorsType = {
  floorName: string;
  doorName: string[];
};

type listedItem = {
  building: string;
  floor: floorsType[];
};

const ConfirmCommandCard = ({
  setIsShown,
  commandType,
  buildings,
  floorsWithBuildingId,
  doors,
  isShown,
  siteName,
}: ConfirmCommandProps) => {
  const [listedItems, setListedItems] = useState<listedItem[]>([
    {
      building: 'default',
      floor: [
        {
          floorName: 'default',
          doorName: ['default'],
        },
      ],
    },
  ]);

  useEffect(() => {
    let listedItemArray: listedItem[] =
      buildings.map((item) => ({
        building: item.Name,
        floor: [],
      })) || [];

    floorsWithBuildingId.forEach((floor) => {
      const foundBuilding = listedItemArray.findIndex(
        (element) => element.building === floor.buildingName,
      );
      let containThisBuilding = false;
      for (let i = 0; i < buildings.length; i += 1) {
        if (floor.buildingName === buildings[i].Name) {
          containThisBuilding = true;
        }
      }
      if (!containThisBuilding) {
        if (foundBuilding === -1) {
          listedItemArray.push({
            building: floor.buildingName,
            floor: [
              {
                floorName: floor.floor.Name,
                doorName: [],
              },
            ],
          });
        } else {
          listedItemArray[foundBuilding].building = floor.buildingName;
          listedItemArray[foundBuilding].floor.push({
            floorName: floor.floor.Name,
            doorName: [],
          });
        }
      }
    });

    doors.forEach((door) => {
      const foundBuilding = listedItemArray.findIndex(
        (element) => element.building === door.Building,
      );
      let containThisBuilding = false;
      for (let i = 0; i < buildings.length; i += 1) {
        if (door.Building === buildings[i].Name) {
          containThisBuilding = true;
        }
      }
      if (!containThisBuilding) {
        if (foundBuilding === -1) {
          listedItemArray.push({
            building: door.Building,
            floor: [
              {
                floorName: door.Floor,
                doorName: [door.Name],
              },
            ],
          });
        } else {
          const foundFloor = listedItemArray[foundBuilding].floor.findIndex(
            (element) => element.floorName === door.Floor,
          );
          let containThisFloor = false;
          for (let i = 0; i < floorsWithBuildingId.length; i += 1) {
            if (door.Floor === floorsWithBuildingId[i].floor.Name) {
              containThisFloor = true;
            }
          }
          if (!containThisFloor) {
            if (foundFloor === -1) {
              listedItemArray[foundBuilding].floor.push({
                floorName: door.Floor,
                doorName: [door.Name],
              });
            } else {
              listedItemArray[foundBuilding].floor[foundFloor].doorName.push(
                door.Name,
              );
            }
          }
        }
      }
    });
    setListedItems(listedItemArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  const listItems = listedItems.map((buildingItem, index) => {
    return (
      <BuildingUl key={index}>
        <BuildingLiBuilding>
          {Object.keys(buildingItem.floor).length === 0 ? (
            <>
              {buildingItem.building}
              <All>All</All>
            </>
          ) : (
            buildingItem.building
          )}
          <BuildingUl>
            {buildingItem.floor.map((floorItem, index) => {
              return (
                <BuildingLi key={index}>
                  {Object.keys(floorItem.doorName).length === 0 ? (
                    <>
                      {floorItem.floorName}
                      <All>All</All>
                    </>
                  ) : (
                    `${floorItem.floorName}: `
                  )}
                  {floorItem.doorName.join(', ')}
                </BuildingLi>
              );
            })}{' '}
          </BuildingUl>
        </BuildingLiBuilding>
      </BuildingUl>
    );
  });

  const handleCancel = (event) => {
    setIsShown((current) => !current);
  };

  const handleConfirm = async () => {

    if (!commandType) {
      notifyWarning('Command type not recognized', 'The command was not sent!');
      return;
    }

    if (commandType === OnlineDoorCommand.SpreadLostCard) {
      notifyWarning('Spread lost card not supported yet!', ''); //TODO
      setIsShown(false);
      return;
    }

    const success = await doors.every(async (door) => {
      const commandReply = await postDoorCommand(siteName, door.Id, commandType);
      return commandReply !== null;
    });

    if (success) {
      //Failure notifications happen in postCommand functions
      notifySuccess('Succesfully sent command to all doors', '');
    }
    setIsShown(false);
  };

  return (
    <>
      {isShown ? (
        <Window>
          <Container>
            <Title>{commandType}</Title>
            <List>{listItems}</List>
            <Buttons>
              <Cancel onClick={handleCancel}>Cancel</Cancel>
              <Confirm onClick={handleConfirm}>Confirm</Confirm>
            </Buttons>
          </Container>
        </Window>
      ) : (
        <></>
      )}
    </>
  );
};

export default ConfirmCommandCard;

const Window = styled.div`
  position: fixed;
  z-index: 2;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${opaqueBlack};
  z-index: 999;
`;
const Title = styled.h2`
  padding-top: 20px;
`;
const Container = styled.div`
  margin: 10vw auto;
  background-color: ${white};
  border-radius: 6px;
  height: 500px;
  width: 70vw;
`;
const List = styled.div`
  flex-direction: wrap;
  wrap-content: wrap;
  height: 375px;
  width: 70vw;
  overflow-y: scroll;
`;

const BuildingUl = styled.ul`
  font-weight: bold;
  flexbox: wrap;
  text-align: left;
`;
const BuildingLiBuilding = styled.li`
  white-space: wrap;
  list-style-type: disc;
`;
const BuildingLi = styled.li`
  white-space: wrap;
  list-style-type: disc;
  font-weight: normal;
  padding-top: 10px;
`;
const All = styled.span`
  font-weight: normal;
  padding: 2px 5px;
  margin: 5px;
  border-radius: 8px;
  background-color: ${veryLightGrey};
`;
const Cancel = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${black};
  background-color: ${white};
  border: 1px solid ${lightGrey};
  font-weight: bold;
  margin: 6px;
  cursor: pointer;
`;

const Confirm = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${white};
  background-color: ${mainBlue};
  border: 1px solid ${lightGrey};
  margin: 6px;
  cursor: pointer;
`;

const Buttons = styled.div`
  float: right;
  margin-right: 20px;
`;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Message, filterProp } from 'model/event';
import { emptyEvent } from 'constants/testDatas'; // TO DO redux
import { Button, FormControlLabel, Menu } from '@mui/material';
import { black, lightGrey, mainBlue, white } from 'constants/theme';
import magnifyingGlass from 'media/magnifyingGlass.svg';
import FilterCheckBox from 'components/controls/FilterCheckBox';
import filter from 'media/filter.svg';
import { FilterSelectDropDownValue } from 'model/enums';

type Props = {
  setListedEvents: Dispatch<SetStateAction<Message[]>>;
  events: Message[];
  setCheckedValues: Dispatch<SetStateAction<any[]>>;
  checkedValues: any[];
  setDropdownState: Dispatch<SetStateAction<FilterSelectDropDownValue>>;
  dropdownState: FilterSelectDropDownValue;
};

function Filter({ setListedEvents, events, setCheckedValues, checkedValues, setDropdownState, dropdownState }: Props) {
  const [listedFilters, setListedFilters] = useState<filterProp[]>([]);
  const [listedFiltersWithSearchbar, setListedFiltersWithSearchbar] =
    useState<filterProp[]>([]);
  const [selectAll, setSelectAll] = useState(true);
  const [searchbarValue, setSearchbarValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    makeTheListedFilters(dropdownState, true);
    makeFilterOnEventsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, events]);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterTheListedFiltersWithSearchBar = (e: {
    target: { value: string };
  }) => {
    if (e.target.value !== '') {
      setSearchbarValue(e.target.value);
      const filterTable = listedFilters.filter((o) =>
        Object.values(o).some((value) => {
          if (typeof value !== 'boolean')
            return String(value)
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          else return false;
        }),
      );
      setListedFiltersWithSearchbar([...filterTable]);
    } else {
      setSearchbarValue(e.target.value);
      makeTheListedFilters(dropdownState, true);
    }
  };

  const makeTheListedFilters = (value: string, resetToEmpty: boolean) => {
    let arrayAllEvents = events; // TO DO from redux
    let arrayFilters = listedFilters;
    if (!resetToEmpty) {
      arrayFilters = [];
    }
    for (let i = 0; i < arrayAllEvents.length; i += 1) {
      let contain = false;
      for (let j = 0; j < arrayFilters.length; j += 1) {
        if (arrayAllEvents[i][value] === arrayFilters[j].value) {
          contain = true;
          break;
        }
      }
      if (!contain) {
        arrayFilters.push({ value: arrayAllEvents[i][value], checked: false });
      }
    }
    setListedFilters(arrayFilters);
  };

  const handleCheckboxChange = (e: {
    target: { value: any; checked: any };
  }) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedValues((pre): any => [...pre, value]);
      for (let i = 0; i < listedFilters.length; i += 1) {
        if (listedFilters[i].value === value) {
          listedFilters[i].checked = true;
        }
      }
      setSelectAll(false);
    } else {
      setCheckedValues((pre) => {
        return [...pre.filter((skill) => skill !== value)];
      });
      let lastUncheck = true;
      for (let i = 0; i < listedFilters.length; i += 1) {
        if (
          listedFilters[i].checked === true &&
          listedFilters[i].value !== value
        ) {
          lastUncheck = false;
        }
        if (listedFilters[i].value === value) {
          listedFilters[i].checked = false;
        }
      }
      if (lastUncheck) {
        setSelectAll(true);
      }
    }
  };

  const makeFilterOnEventsList = () => {
    if (checkedValues.length === 0) {
      setListedEvents(events);
    } else {
      setListedEvents(emptyEvent);
      let allEvents = events;
      for (let i = 0; i < allEvents.length; i += 1) {
        let contain = false;
        for (let j = 0; j < checkedValues.length; j += 1) {
          if (allEvents[i][dropdownState] === checkedValues[j]) {
            contain = true;
          }
        }
        if (contain) {
          setListedEvents((pre: Message[]): Message[] => [
            ...pre,
            {
              EventDate: allEvents[i].EventDate,
              EventId: allEvents[i].EventId,
              RoomId: allEvents[i].RoomId,
              CardNo: allEvents[i].CardNo,
              Description: allEvents[i].Description,
            },
          ]);
        }
      }
    }
  };

  const listFilters = listedFilters.map((element, index) => (
    <ul key={index}>
      <FilterCheckBox element={element} onChange={handleCheckboxChange} />
    </ul>
  ));

  const listFiltersWithSearchbar = listedFiltersWithSearchbar.map(
    (element, index) => (
      <ul key={index}>
        <FilterCheckBox element={element} onChange={handleCheckboxChange} />
      </ul>
    ),
  );

  const checkSelectAll = () => {
    resetListedFiltersToDefault();
    setSelectAll(true);
    setCheckedValues([]);
  };

  const handleDropdownChange = (e: { target: { value } }) => {
    const value = e.target.value;
    setDropdownState(value);
    setSelectAll(true);
    setCheckedValues([]);
    makeTheListedFilters(value, false);
    setSearchbarValue('');
  };

  const resetFilterPageToDefault = () => {
    resetListedFiltersToDefault();
    setSelectAll(true);
    setCheckedValues([]);
    setSearchbarValue('');
    setListedEvents(events);
  };
  const resetListedFiltersToDefault = () => {
    for (let i = 0; i < listedFilters.length; i += 1) {
      listedFilters[i].checked = false;
    }
  };
  return (
    <>
      <ButtonDiv>
        <Button
          id="filter-button"
          aria-controls={open ? 'filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FilterIcon id="filterIcon" src={filter} />
        </Button>
      </ButtonDiv>
      <MenuContainer
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        disableScrollLock= {true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SearchBar>
          <SelectType
            name=""
            id=""
            value={dropdownState}
            onChange={handleDropdownChange}
          >
            <OptionType value={FilterSelectDropDownValue.EventId}>Event Id</OptionType>
            <OptionType value={FilterSelectDropDownValue.RoomId}>Room Id</OptionType>
            <OptionType value={FilterSelectDropDownValue.CardNo}>Card Number</OptionType>
            <OptionType value={FilterSelectDropDownValue.Description}>Description</OptionType>
          </SelectType>
          <SearchbarInput
            value={searchbarValue}
            className="input"
            id="searchbar"
            type="text"
            onChange={filterTheListedFiltersWithSearchBar}
          />
        </SearchBar>

        <ListedFilters>
          <ul>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={
                    <RadioButtonCheckedIcon
                      sx={{ '&, &.Mui-checked': { color: mainBlue } }}
                    />
                  }
                  onChange={checkSelectAll}
                  checked={selectAll}
                  value="select all"
                />
              }
              label={'Select all (' + listedFilters.length + ')'}
            />
          </ul>
          {searchbarValue.length > 0 ? listFiltersWithSearchbar : listFilters}
        </ListedFilters>
        <Buttons>
          <Reset onClick={resetFilterPageToDefault}>Reset</Reset>
          <Apply onClick={makeFilterOnEventsList}>Apply</Apply>
        </Buttons>
      </MenuContainer>
    </>
  );
}

export default Filter;

const OptionType = styled.option`
  background: ${white};
  color: ${black};
`;

const SelectType = styled.select`
  border: none;
  padding: 14px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${mainBlue};
  color: ${white};
`;

const ButtonDiv = styled.div`
  position: absolute;
  right: 0px;
`;

const FilterIcon = styled.img`
  position: relative;
  padding: 40px 15px 25px 15px;
`;

const SearchbarInput = styled.input`
  padding: 13px;
  border: 1px solid ${lightGrey};
  background-image: url(${magnifyingGlass});
  background-repeat: no-repeat;
  background-position: 95% center;
  border-radius: 0px 7px 7px 0;
`;

const ListedFilters = styled.div`
  text-align: left;
  height: 250px;
  overflow-y: scroll;
`;

const SearchBar = styled.div`
  padding: 15px;
`;
const MenuContainer = styled(Menu)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;

const Reset = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${black};
  background-color: ${white};
  border: 1px solid ${lightGrey};
  margin: 6px;
  cursor: pointer;
`;

const Apply = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${white};
  background-color: ${mainBlue};
  border: 1px solid ${lightGrey};
  margin: 6px;
  cursor: pointer;
`;

const Buttons = styled.div`
  position: relative;
  text-align: right;
  margin: 10px;
  right: 0px;
`;

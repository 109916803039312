import {
  UserForm,
  UserFormError,
} from 'components/cards/dialogs/userCardDialog/UserDialogConsts';
import { UserDialogPages } from 'model/enums';
import UserDialogForm from 'model/UserDialogForm';
import UserDialogFormError from 'model/UserDialogFormError';
import UserTableRecord from 'model/UserTableRecord';
import { UserDialogActions } from 'redux/constants/userDialogActions';
import UserDialogActionTypes from 'redux/constants/userDialogActionTypes';

type UserDialogType = {
  userDialogForm: UserDialogForm;
  userDialogFormError: UserDialogFormError;
  selectedPageName: UserDialogPages;
  selectedUserRecord?: UserTableRecord;
};

const initialState: UserDialogType = {
  userDialogForm: UserForm.initEmptyUserForm(),
  userDialogFormError: UserFormError.INIT_USER_DIALOG_FORM_ERROR,
  selectedPageName: UserDialogPages.Information,
  selectedUserRecord: undefined,
};

export const userDialogReducer = (
  state: UserDialogType = initialState,
  action: UserDialogActions,
): UserDialogType => {
  switch (action.type) {
    case UserDialogActionTypes.SET_USER_DIALOG_FORM:
      return {
        ...state,
        userDialogForm: action.payload,
      };
    case UserDialogActionTypes.SET_USER_DIALOG_FORM_ERROR:
      return {
        ...state,
        userDialogFormError: action.payload,
      };
    case UserDialogActionTypes.SET_USER_DIALOG_PAGE:
      return {
        ...state,
        selectedPageName: action.payload,
      };
    case UserDialogActionTypes.SET_USER_DIALOG_TABLE_RECORD:
      return {
        ...state,
        selectedUserRecord: action.payload,
      };
    default:
      return state;
  }
};

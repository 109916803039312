
export enum FilterName {
  DepartmentFilter = 'Department',
  GroupNameFilter = 'Group Name',
  CardStatusFilter = 'Card Status',
  CardType = 'Card Type',
  LockType = 'Lock Type',
}

export enum DefaultFilterValues {
  DepartmentFilter = 'All',
  TimeGroupFilter= 'All',
  CardStatusFilter = 'All',
  CardTypeFilter = 'All',
  LockTypeFilter = 'All',
}

export enum FilterSelectDropDownValue {
  EventId = 'EventId',
  RoomId = 'RoomId',
  CardNo = 'CardNo',
  Description = 'Description',
}
export enum EventMessageType {
  DoorEvent = 'DoorEvent',
  DoorStatus = 'DoorStatus',
  EncoderStatus = 'EncoderStatus',
}

export enum EncoderResponseDescription {
  CommandAck = "Command ACK",
  RequestFailed = "Request Failed",
  CardIsBlank = "Card is blank",
  EraseCard = 'EraseCard',
  ReadCard = 'ReadCard',
  WriteCard = 'WriteCard',
  CardNotDetected = 'Card Not Detected',
  Success = "SUCCESS",
  Busy = "Encoder Busy",
};

export enum EncoderStatus {
  EncoderIsActivated = "Encoder is activated",
  EncoderIsInactive = "Encoder is inactive",
  EncoderIsNotSelectedYet = "Encoder is not selected yet",
}

export enum SignalrResponseStatuses {
  None,
  Success,
  Failed,
  Pending,
  CardNotDetected,
  CardIsBlank,
  Busy,
  Unknown,
}

export enum LocalStorageKeys {
  Encoder = "encoder",
  SignalrConnection = "server-connection",
  EncoderStatusUpdate = 'encoder-status-update'
}

export enum EraseCardDialogTexts {
  EraseCardTitle = "Erase Card",
  ReadCardTitle = "Read Card",
}

export enum ReadCardDialogTexts {
  ReadDialogTitle = "Read Card"
}

export enum Status {
  Online = 'Online',
  Offline = 'Offline',
  Unknown = 'Unknown',
}

export enum CardStatus {
  InUse = 'In Use',
  Lost = 'Lost',
  Overwritten = 'Overwritten',
  Failed = 'Failed',
  Erased = 'Erased',
  Deleted = 'Deleted',
  PendingUpdate = 'Pending',
  Expired = 'Expired',
  CheckedOut = 'Checked Out'
}

export enum CardTypes {
  SiteCard = 'Site Card',
  RoomNoCard = 'Room No. Card',
  EraseHistoryCard = 'Erase History Card',
  AuthorisationCard = 'Authorisation Card',
  DeleteCard = 'Delete Card',
  LostCardCard = 'Lost Card Card',
  RoomRecordCard = 'Room Record Card',
  EmergencyCard = 'Emergency Card',
  MasterCard = 'Master Card',
  MultiFloorCard = 'Multi Floor Card',
  FloorCard = 'Floor Card',
  KeyCard = 'Key Card',
  ConfigurationCard = 'Configuration Card'
}

export enum CardActions {
  Edit = 'Edit',
  Delete = 'Delete',
  EraseCard = 'Erase Card',
  ReissueCard = 'Reissue Card',
  ReportLostCard = 'Report Lost Card',
  CreateCard = 'Create Card',
  CreateKeyCard = 'Key Card',
  CreateMasterCard = 'Master Card',
  CreateEmergencyCard = 'Emergency Card',
  CreateFloorCard = 'Floor Card'
}

export enum BluetoothSuspendedUserInfo {
  Message = `User suspended and can’t access assigned doors.`,
  Action = `Activate`,
}

export enum BluetoothDeletedUserInfo {
  Message = `User deleted and can’t access their account.`,
  Action = `Re-activate`,
}

export enum BluetoothUserStatuses {
  Undefined = -1,
  Activated = 0,
  Deleted = 1,
  Suspended = 2,
  DeactivatedDevice = 3,
}

export enum BluetoothMenuItems {
  ChangeMobile = 'Change Mobile',
  ReSend = 'Re-Send',
  Suspend = 'Suspend',
  Delete = 'Delete',
}

export enum BluetoothMenuDialogs {
  ChangeMobile = 'Change Mobile',
  ReSend = 'Re-Send',
  Suspend = 'Suspend',
  Delete = 'Delete',
  Suspended = 'Suspended',
  Deleted = 'Deleted',
}

export enum BluetoothMenuItemDescriptions {
  ChangeMobile = '',
  ReSend = 'The user will receive an SMS',
  Suspend = 'Removes the certificate from the user',
  Delete = 'The user will be deleted from the cloud server',
}

export enum UploadPhotoMenuItems {
  UploadPhoto = 'Upload photo',
  Delete = 'Delete'
}

export enum CardToolTipViewOptions {
  Back = 'Back',
  EditUser = 'Edit User',
  CreateCard = 'Create Card',
  ReissueCard = 'Reissue Card',
  DeleteUser = 'Delete User',
  EraseCard = 'Erase Card',
  ReportLost = 'Report Lost',
  EditCard = 'Edit Card',
}

export const CardTypesToCreate: CardActions[] = [
  CardActions.CreateKeyCard,
  CardActions.CreateMasterCard,
  CardActions.CreateEmergencyCard,
  CardActions.CreateFloorCard
]

export enum DoorStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export enum LockStatus {
  Normal = 'Normal',
  Unlocked = 'Unlocked',
  OpenMode = 'OpenMode',
}

export enum LockDownStatus {
  AccessEnabled = 'AccessEnabled',
  Denied = 'Denied',
}

export enum Week {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum DoorEventId {
  DoorOpenedWithoutCard = 176,
  DoorOpenedWithCard = 177,
  DoorUnlockedWithCard = 178,
  DoorLockedWithCard = 179,
  DeniedDoNotDisturb = 180,
  DeniedSiteCode = 181,
  DeniedRoomNumber = 182,
  DeniedTimeError = 183,
  DeniedTimeError1 = 184,
  DeniedTimeError2 = 185,
  DeniedLowBattery = 186,
  DeniedCardReportedLost = 187,
  DeniedBlockedCard = 188,
  DeniedCardNumberFull = 189,
  DeniedRoomLockedExitButton = 190,
  DeniedRoomLocked = 191,
  Reader1OpenedWithCard = 192,
  Reader1UnlockModeWithCard = 193,
  Reader1LockedWithCard = 194,
  Reader2OpenedWithCard = 195,
  Reader2UnlockModeWithCard = 196,
  Reader2LockedWithCard = 197,
  Reader1CardRemotelyWritten = 198,
  Reader2CardRemotelyWritten = 199,
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

// API Commands

export enum API_DoorCommand {
  Lock = 'Lock',
  Unlock = 'Unlock',
  Release = 'Release',
}

//

// Commands - - -

export enum CommandCategory {
  AlarmService = 'AlarmService',
  BuildingService = 'BuildingService',
  CardService = 'CardService',
  DepartmentService = 'DepartmentService',
  DoorGroupService = 'DoorGroupService',
  HolidayService = 'HolidayService',
  LoginService = 'LoginService',
  MasterLevelService = 'MasterLevelService',
  OnlineDoorService = 'OnlineDoorService',
  OperatorService = 'OperatorService',
  RoomService = 'RoomService',
  SystemService = 'SystemService',
  SystemVariableService = 'SystemVariableService',
  TimeGroupService = 'TimeGroupService',
  TimePatternService = 'TimePatternService',
  UserService = 'UserService',
  RemoteService = 'RemoteService',
  EncoderService = 'EncoderService',
  BleService = 'BleService',
}

export type AnyCommand =
  | AlarmCommand
  | OnlineDoorCommand
  | BuildingCommand
  | CardCommand
  | DepartmentCommand
  | DoorGroupCommand
  | HolidayCommand
  | MasterLevelCommand
  | OperatorCommand
  | RoomCommand
  | TimeGroupCommand
  | TimePatternCommand
  | UserCommand
  | EncoderCommand
  | BleCommand;

export enum AlarmCommand {
  GetDefaultAlarmSettings = 'GetDefaultAlarmSettings',
  GetCustomAlarmSettings = 'GetCustomAlarmSettings',
  GetCustomisedAlarmRecordById = 'GetCustomisedAlarmRecordById',
  CreateCustomisedAlarmRecord = 'CreateCustomisedAlarmRecord',
  UpdateCustomisedAlarmRecord = 'UpdateCustomisedAlarmRecord',
  DeleteCustomisedAlarmRecord = 'DeleteCustomisedAlarmRecord',
  DeleteCustomisedAlarmsByRoomId = 'DeleteCustomisedAlarmsByRoomId',
  AddCustomisedAlarmToRoom = 'AddCustomisedAlarmToRoom',
  UpdateAlarmRecord = 'UpdateAlarmRecord',
}

export enum OnlineDoorCommand {
  GetOnlineDoors = 'GetOnlineDoors',
  GetOnlineDoorById = 'GetOnlineDoorById',
  GetOnlineDoorByRoomId = 'GetOnlineDoorByRoomId',
  GetOnlineDoorByHub = 'GetOnlineDoorByHub',
  CreateOnlineDoor = 'CreateOnlineDoor',
  UpdateOnlineDoor = 'UpdateOnlineDoor',
  DeleteOnlineDoor = 'DeleteOnlineDoor',
  GetHubs = 'GetHubs',
  GetHubById = 'GetHubById',
  GetSummary = 'GetSummary',
  CreateHub = 'CreateHub',
  UpdateHub = 'UpdateHub',
  DeleteHub = 'DeleteHub',
  SetClock = 'SetClock',
  RemoteConfigure = 'RemoteConfigure',
  ReleaseRoom = 'ReleaseRoom',
  LockRoom = 'LockRoom',
  UnlockRoom = 'UnlockRoom',
  EnableAccess = 'EnableAccess',
  DenyAccess = 'DenyAccess',
  OpenTimePeriod = 'OpenTimePeriod',
  UseTimePeriod = 'UseTimePeriod',
  SpreadLostCard = 'SpreadLostCard',
  RemoteCardEdit = 'RemoteEdit',
  CancelRemoteEdit = 'CancelRemoteEdit',
  RemoteErase = 'RemoteErase',
  RemoteCommandCount = 'RemoteCommandCount',
}

export enum BuildingCommand {
  GetNextBuildingId = 'GetNextBuildingId',
  GetNextFloorId = 'GetNextFloorId',
  GetNextRoomId = 'GetNextRoomId',
  GetBuildings = 'GetBuildings',
  GetBuildingById = 'GetBuildingById',
  GetFloorById = 'GetFloorById',
  GetRoomsByBuilding = 'GetRoomsByBuilding',
  GetRoomsByBuildingAndFloor = 'GetRoomsByBuildingAndFloor',
  GetRoomsByCardId = 'GetRoomsByCardId',
  CreateBuilding = 'CreateBuilding',
  DeleteBuilding = 'DeleteBuilding',
  AddFloor = 'AddFloor',
  DeleteFloor = 'DeleteFloor',
  AddRoom = 'AddRoom',
  DeleteRoom = 'DeleteRoom',
  UpdateRoom = 'UpdateRoom',
}

export enum BleCommand {
  AddUpdateUser = 'AddUpdateUser',
  ActivateUser = 'ActivateUser',
  SuspendUser = 'SuspendUser',
  DeleteUser = 'DeleteUser',
  DeactivateDevice = 'DeactivateDevice',
}

export enum CardCommand {
  ReadCard = 'ReadCard',
  EraseCard = 'EraseCard',
  FormatCard = 'FormatCard',
  FactoryFormatCard = 'FactoryFormatCard',
  WriteCard = 'WriteCard',
  ReportCardLost = 'ReportCardLost',
  MakeSiteCard = 'MakeSiteCard',
  MakeRoomNoCard = 'MakeRoomNoCard',
  MakeClockCard = 'MakeClockCard',
  MakeEraseHistoryCard = 'MakeEraseHistoryCard',
  MakeAuthorisationCard = 'MakeAuthorisationCard',
  MakeDeleteCard = 'MakeDeleteCard',
  MakeReportLostCard = 'MakeReportLostCard',
  MakeRoomRecordCard = 'MakeRoomRecordCard',
  MakeEmergencyCard = 'MakeEmergencyCard',
  MakeMasterCard = 'MakeMasterCard',
  MakeMultiFloorCard = 'MakeMultiFloorCard',
  MakeFloorCard = 'MakeFloorCard',
  MakeKeyCard = 'MakeKeyCard',
  MakeConfigCard = 'MakeConfigCard',
  MakeOpenModeCard = 'MakeOpenModeCard',
  MakeCancelOpenModeCard = 'MakeCancelOpenModeCard',
  MakeSetModeCard = 'MakeSetModeCard',
  MakeBlockGuestCard = 'MakeBlockGuestCard',
  MakeUnblockGuestCard = 'MakeUnblockGuestCard',
  MakeGuestCard = 'MakeGuestCard',
  MakeReservationCard = 'MakeReservationCard',
  MakeOpenTimePeriodCard = 'MakeOpenTimePeriodCard',
  MakeUseTimePeriodCard = 'MakeUseTimePeriodCard',
  MakeSingleUseCard = 'MakeSingleUseCard',
  MakeMultiUseConfigCard = 'MakeMultiUseConfigCard',
  ReIssueCard = 'ReIssueCard',
  GetCards = 'GetCards',
  GetCardsByDepartment = 'GetCardsByDepartment',
  GetCardsByUser = 'GetCardsByUser',
  GetCardById = 'GetCardById',
  UpdateExpiryDate = 'UpdateExpiryDate',
}

export enum DepartmentCommand {
  GetDepartments = 'GetDepartments',
  GetDepartmentById = 'GetDepartmentById',
  GetDepartmentByDepCode = 'GetDepartmentByDeptCode',
  CreateDepartment = 'CreateDepartment',
  UpdateDepartment = 'UpdateDepartment',
  DeleteDepartment = 'DeleteDepartment',
}

export enum DoorGroupCommand {
  GetDoorGroupsDetails = 'GetDoorGroupsDetails',
  GetDoorGroup = 'GetDoorGroup',
  AddDoorGroup = 'AddDoorGroup',
  UpdateDoorGroup = 'UpdateDoorGroup',
  DeleteDoorGroup = 'DeleteDoorGroup',
}

export enum HolidayCommand {
  GetHolidays = 'GetHolidays',
}

export enum MasterLevelCommand {
  GetMasterLevels = 'GetMasterLevels',
  UpdateMasterLevels = 'UpdateMasterLevels',
}

export enum OperatorCommand {
  GetOperators = 'GetOperators',
  GetOperatorById = 'GetOperatorById',
  CreateOperator = 'CreateOperator',
  UpdateOperator = 'UpdateOperator',
  DeleteOperator = 'DeleteOperator',
  GetBuildingControls = 'GetBuildingControls',
  GetBuildingControlById = 'GetBuildingControlById',
  CreateBuildingControl = 'CreateBuildingControl',
  UpdateBuildingControl = 'UpdateBuildingControl',
  DeleteBuildingControl = 'DeleteBuildingControl',
  GetOperatorClass = 'GetOperatorClass',
  UpdateOperatorClass = 'UpdateOperatorClass',
  CreateOperatorClass = 'CreateOperatorClass',
  DeleteOperatorClass = 'DeleteOperatorClass',
}

export enum RoomCommand {
  GetRoomTypes = 'GetRoomTypes',
}

export enum TimeGroupCommand {
  GetTimeGroups = 'GetTimeGroups',
  GetTimeGroup = 'GetTimeGroup',
  CreateTimeGroup = 'CreateTimeGroup',
  UpdateTimeGroup = 'UpdateTimeGroup',
  DeleteTimeGroup = 'DeleteTimeGroup',
}

export enum TimePatternCommand {
  GetTimePatterns = 'GetTimePatterns',
  GetTimePatternById = 'GetTimePatternById',
  GetOpenTimePatterns = 'GetOpenTimePatterns',
  GetOpenTimePatternById = 'GetOpenTimePatternById',
  GetOpenTimePatternByName = 'GetOpenTimePatternByName',
  GetCardOpenTimePatterns = 'GetCardOpenTimePatterns',
  GetCardOpenTimePatternById = 'GetCardOpenTimePatternById',
  GetCardOpenTimePatternByName = 'GetCardOpenTimePatternByName',
  CreateTimePattern = 'CreateTimePattern',
  UpdateTimePattern = 'UpdateTimePattern',
  DeleteTimePattern = 'DeleteTimePattern',
  CreateOpenTimePattern = 'CreateOpenTimePattern',
  UpdateOpenTimePattern = 'UpdateOpenTimePattern',
  DeleteOpenTimePattern = 'DeleteOpenTimePattern',
  CreateCardOpenTimePattern = 'CreateCardOpenTimePattern',
  UpdateCardOpenTimePattern = 'UpdateCardOpenTimePattern',
  DeleteCardOpenTimePattern = 'DeleteCardOpenTimePattern',
}

export enum UserCommand {
  GetNextWorkNoByDepartment = 'GetNextWorkNoByDepartment',
  GetUsersByDepartment = 'GetUsersByDepartment',
  GetUserById = 'GetUserById',
  CreateNewUser = 'CreateNewUser',
  UpdateUser = 'UpdateUser',
  DeleteUser = 'DeleteUser',
  GetUserImage = 'GetUserImage',
  AddUserImage = 'AddUserImage',
  DeleteUserImage = 'DeleteUserImage',
  UpdateUserImage = 'UpdateUserImage',
  GetImagesByDepartment = 'GetImagesByDepartment',
}

export enum EncoderCommand {
  GetEncoders = 'GetEncoders',
  CreateEncoder = 'CreateEncoder',
  UpdateEncoder = 'UpdateEncoder',
  RemoveEncoder = 'RemoveEncoder',
  Beep = 'Beep',
}

// Commands end - - -

export enum PageNavigation {
  Overview = 'Overview',
  ManageCards = 'Manage Cards',
  ManageUsers = 'Manage Users',
}

export enum UserDialogPages {
  Information = 'Information',
  AdditionalInformation = 'Additional Information',
  Access = 'Access',
  Bluetooth = 'Bluetooth',
}

export enum CardDialogPages {
  CardDetails = "Card Details",
  Doors = "Doors",
  Levels = "Levels",
}

export enum UserDialogInformationPageFormText {
  UserId = 'User Id',
  Name = 'Name',
  DepartmentName = 'Department Name',
  GroupName = 'Group Name',
}

export enum UserDialogInformationPageFormPlaceholderText {
  UserId = '01',
  Name = 'Text',
  DepartmentName = 'Select...',
  GroupName = 'Select...',
}

export enum UserDialogAdditionalInformationPageFormText {
  BirthDate = 'Birth Date',
  Nationality = 'Nationality',
  Address = 'Address',
  Telephone = 'Telephone',
  Position = 'Position',
  Type = 'Type',
  Comment = 'Comment',
}

export enum UserDialogAdditionalInformationPageFormPlaceholderText {
  Nationality = 'Select...',
  Address = 'Text',
  Telephone = 'Number',
  Position = 'Text',
  Type = 'Text',
  Comment = 'Text',
}

export enum UserDialogBluetoothFormText {
  Username = 'Username',
  Email = 'Email',
  MobileNumber = 'Mobile number',
  MobileUser = 'Mobile User',
  RemoteOpenDoors = 'Allowed to Remotely Open Doors',
  TemporaryAccess = 'Allowed to Give Temporary Access',
  MeetingRoom = 'Can Book Meeting Room',
  LockReleaseTime = 'Lock Release Time',
}

export enum UserDialogFormBluetoothFormPlaceHolderText {
  Username = 'Username Test',
  Email = 'email@test.com',
  MobileNumber = '123456789',
  LockReleaseTime = '5',
}

export enum InformationElements {
  UserId = 'User Id',
  Name = 'Name',
  Department = 'Department',
  Group = 'Group',
  MasterNumber = 'Master Number',
  Issue = 'Issue',
}

export enum WindowCardRenders {
  Information,
  CardDetails,
  AdditionalInformation,
  Image,
  DoorList,
}

export enum DoorListElements {
  Building = 'Building',
  Floor = 'Floor',
  LockNumber = 'Lock Number',
  LockType = 'Lock Type',
}

export enum AdditionalInfoElements {
  BirthDate = 'Birth Date',
  Position = 'Position',
  Nationality = 'Nationality',
}

export enum CardDetailWindow {
  CardType = 'Card Type',
  MasterNumber = 'Master Number',
  WriteCardTime = 'Write Card Time',
  LimitedDate = 'Limited Date',
  OperatorLogonCode = 'Operator Logon Code',
  Issue = 'Issue',
  Remark = 'Remark',
  Building = 'Building',
  Floor = 'Floor',
  MasterCardLevels = 'Master Card Levels',
}

export enum ReissueCardDialogFieldName {
  CardType = 'Card Type',
  StartDate = 'Start Date',
  ReportedLost = 'Reported Lost',
  LimitedDate = 'Limited Date',
  UnlimitedDate = 'Unlimited Date'
}

export enum InputFieldValidator {
  SKIP_VALIDATION_IF_EMPTY,
  ERROR_IF_NULL,
  ERROR_IF_VALUE_IS_LOWER_THEN_X,
  ERROR_IF_VALUE_IS_MORE_OR_EQUAL_THEN_X,
  ERROR_IF_TEXT_LENGTH_IS_TOO_LONG_THEN_X,
  ERROR_IF_TEXT_EQUAL_TO_X,
  ERROR_IF_DATE_NOT_VALID_DATE,
  ERROR_IF_DATE_NOT_X_YEARS_OLDER_THEN_CURRENT_DATE,
  ERROR_IF_DATE_IS_IN_THE_PAST,
  ERROR_IF_FIRST_DATE_IS_BIGGER_THAN_SECOND,
  ERROR_IF_PHONE_NUMBER_NOT_VALID,
  ERROR_IF_REGEX_NOT_MATCHING,
  ERROR_IF_DATE_AND_TIME_HALFLY_SET,
  ERROR_IF_EMAIL_IS_INVALID_FORMAT,
}

export enum KeyCode {
  enter = 13,
  keyE = 69,
  comma = 188,
  slash = 189,
  dot = 190,
  numpadPlus = 107,
  numpadSubtract = 109,
  numpadDecimal = 110,
}

export enum MobileUserType {
  User = 'User',
  Administrator = 'Administrator',
  Engineer = 'Engineer',
}

export enum MobileUserTypeRequest {
  User = 0,
  Administrator = 1,
  Engineer = 2,
}

export enum DialogToOpen {
  CreateUserDialog,
  EditUserDialog,
  CreateCardDialog,
  CreateKeyCardDialog,
  CreateMasterCardDialog,
  CreateEmergencyCardDialog,
  CreateFloorCardDialog,
  ReissueCardDialog,
  DeleteUserDialog,
  EraseCardReadActionDialog,
  EraseCardConfirmationActionDialog,
  ReportLostDialog,
  ConfirmationDialog,
  EditCardDialog,
  ReadCardDialog,
  EncoderTesterDialog,
}

export enum EncoderTestingDialogTexts {
  DialogTitle = "Set Coder ID",
  ConfirmationDialogTitle = "Set Confirmation Dialog ID",
  EncoderFilterName = "Encoder",
  EncoderTestButtonName = "Test",
}

export enum AccessTabs {
  GroupsTab,
  RoomsTab,
}

export enum ImageActions {
  ImageAddition,
  ImageUpload,
  ImageDeletion,
}

export enum CardDialogCardDetailsInputFieldNames {
  UserSearchBar = "User",
  Department = "Department",
  AddUser = "Add User",
  LockReleaseTime = "Lock Release Time",
  StartDate = "Start Date",
  LimitedDate = "Limited Date",
  UnlimitedDate = "Unlimited Date",
  Building = "Building",
  Floor = "Floor",
}

export enum InputFieldPlaceholderValues {
  Select = "Select..."
}

export enum InformationWindowTitleName {
  Information = "Information",
  Employee = "Employee"
}
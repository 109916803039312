import { Dispatch } from 'redux';
import CardModelActionTypes from 'redux/constants/cardModelActionTypes';
import { getCardById } from 'API/commands/CardCommands';
import CardModel from 'model/CardModel';
import UserModel from 'model/UserModel';

export const fetchCardModelRequest = () => {
    return { type: CardModelActionTypes.FETCH_USER_CARD_REQUEST };
};
export const fetchCardModelSuccess = (data: CardModel) => {
    return {
        type: CardModelActionTypes.FETCH_USER_CARD_SUCCESS,
        payload: data,
    };
};
export const fetchCardModelFailure = (err: any) => {
    return {
        type: CardModelActionTypes.FETCH_USER_CARD_FAILURE,
        payload: err,
    };
};
export const fetchEmptyUserCardsRequest = () => {
    return {
        type: CardModelActionTypes.FETCH_EMPTY_USER_CARDS_REQUEST,
        payload: [],
    };
};
export const setUserCardModel = (data: CardModel | null) => {
    return {
        type: CardModelActionTypes.SET_USER_CARD_MODEL,
        payload: data,
    };
};

export const fetchUserCardsRequester = (actualSite: string, listOfUsers: UserModel[]) => {
    return (dispatch: Dispatch) => {
        dispatch(fetchCardModelRequest());
        dispatch(fetchEmptyUserCardsRequest());
        listOfUsers.forEach((user) => {
            if (user.CardID) {
                getCardById(actualSite, user.CardID).then((cardModel) => {
                    if (cardModel) {
                        dispatch(fetchCardModelSuccess(cardModel));
                    } else{
                        dispatch(fetchCardModelFailure([]));
                    }
                }).catch((err) => {
                    dispatch(fetchCardModelFailure(err));
                });
            }
        });
    };
};
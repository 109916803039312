import { CardFormError } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';
import { initialCardDialogPageName } from 'constants/globalConstants';
import CardDialogForm from 'model/CardDialogForm';
import CardDialogFormError from 'model/CardDialogFormError';
import { CardDialogPages } from 'model/enums';
import { CardDialogActions } from 'redux/constants/cardDialogActions';
import CardDialogTypes from 'redux/constants/cardDialogTypes';

type CardDialogType = {
  form?: CardDialogForm;
  formError?: CardDialogFormError;
  isEdited: boolean;
  isValidationOpen: boolean;
  shouldConfirmationDialogSubmit: boolean;
  isDialogOpen: boolean;
  selectedPageName: CardDialogPages;
  initForm?: CardDialogForm;
  selectedUserId?: number;
  isDialogCloseTriggered: boolean;
  isConfirmationDialogOpen: boolean;
  isSaveButtonDisabled: boolean,
};

const initialState: CardDialogType = {
  form: undefined,
  formError: undefined,
  isEdited: false,
  isValidationOpen: false,
  shouldConfirmationDialogSubmit: false,
  isDialogOpen: false,
  selectedPageName: initialCardDialogPageName,
  initForm: undefined,
  selectedUserId: undefined,
  isDialogCloseTriggered: false,
  isConfirmationDialogOpen: false,
  isSaveButtonDisabled: true,
};

export const cardDialogReducer = (
  state: CardDialogType = initialState,
  action: CardDialogActions,
): CardDialogType => {
  switch (action.type) {
    case CardDialogTypes.SET_CARD_DIALOG_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_FORM_ERROR:
      return {
        ...state,
        formError: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_EDITED:
      return {
        ...state,
        isEdited: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_VALIDATION_OPEN:
      return {
        ...state,
        isValidationOpen: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT:
      return {
        ...state,
        shouldConfirmationDialogSubmit: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_OPEN:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_SELECTED_PAGE_NAME:
      return {
        ...state,
        selectedPageName: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_INIT_FORM:
      return {
        ...state,
        initForm: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.payload,
      }
    case CardDialogTypes.SET_CARD_DIALOG_IS_DIALOG_CLOSE_TRIGGERED:
      return {
        ...state,
        isDialogCloseTriggered: action.payload,
      }
    case CardDialogTypes.SET_CARD_DIALOG_IS_CONFIRMATION_DIALOG_OPEN:
      return {
        ...state,
        isConfirmationDialogOpen: action.payload,
      }
    case CardDialogTypes.SET_CARD_DIALOG_IS_SAVE_BUTTON_DISABLED:
      return {
        ...state,
        isSaveButtonDisabled: action.payload,
      }
    case CardDialogTypes.SET_CARD_DIALOG_FORM_BUILDER:
      return {
        ...state,
        formError: CardFormError.INIT_CARD_DIALOG_FORM_ERROR,
        form: action.payload,
        initForm: action.payload,
        selectedPageName: initialCardDialogPageName,
      }
    case CardDialogTypes.SET_CARD_DIALOG_STORAGE_RESET:
      return { ...initialState }
    default:
      return state;
  }
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
  mainBlue,
  lightGrey,
  offlineHouse,
  screenSmallForCard,
  white,
} from 'constants/theme';
import blueHouseImg from 'media/blueHouse.svg';
import greyHouseImg from 'media/greyHouse.svg';
import Site from 'model/Site';
import { Status } from 'model/enums';
import { getSummary } from 'API/commands/DoorCommands';
import { notifyCommandError } from 'API/commands/Command';
import GeneralTopLoadingBar from 'components/GeneralTopLoadingBar';

ChartJS.register(ArcElement, Tooltip, Legend);

function SiteCard({ site }: { site: Site }) {

  const [doorSummaryIsLoading, setDoorSummaryIsLoading] = useState<boolean>(true);
  const [onlineLocks, setOnlineLocks] = useState<number>(0);
  useEffect(() => {
    if(site.Status === "Online") {
      loadDoorSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDoorSummary = async () => {
    try {
      const doors = await getSummary(site.SiteName);
      if (doors) {
        setOnlineLocks(doors?.TotalOnline);
      }
      setDoorSummaryIsLoading(false);
    } catch (err) {
      notifyCommandError(
        'No door summary found or the server timed out.',
        null,
      );
      setDoorSummaryIsLoading(false);
    }
  };
  
  // TODO get these datas from axios
  const offlineLocks = 0;

  const onlineDatas: ChartData<'doughnut', string[], string> = {
    labels: [],
    datasets: [
      {
        label: undefined,
        data: [onlineLocks.toString(), offlineLocks.toString()],
        backgroundColor: [mainBlue, lightGrey],
        borderColor: [mainBlue, lightGrey],
        borderWidth: 0,
        borderRadius: [offlineLocks, 0],
      },
    ],
  };
  const offlineDatas: ChartData<'doughnut', string[], string> = {
    labels: [],
    datasets: [
      {
        label: undefined,
        data: ['15'],
        backgroundColor: [offlineHouse],
        borderColor: [offlineHouse],
        hoverBackgroundColor: [offlineHouse],
        borderWidth: 0,
        borderRadius: [offlineLocks, 0],
      },
    ],
  };

  const navigate = useNavigate();
  const options = {
    plugins: { tooltip: { enabled: false } },
    animation: { duration: 0 },
    cutout: '90%',
  };

  const navigateToSite = () => {
    navigate(`/site/${site.SiteName}`);
  };

  return (
    <>
      {site.Status === Status.Online ? ( 
        <Container onClick={navigateToSite}>
          <TitleHelperContainer>
            <DoughnutChart data={onlineDatas} options={options}>
              -
            </DoughnutChart>
            <SiteTitleBox>
              {' '}
              <img src={blueHouseImg} alt="" />
              <SiteTitle>{site.SiteName}</SiteTitle>
            </SiteTitleBox>
          </TitleHelperContainer>
          <br />
          <Table>
            <tbody>
              <tr>
                <th>
                  <Underline>Locks</Underline>
                </th>
              </tr>
              <tr>
                <td>
                  <Online>
                  {doorSummaryIsLoading ? (
                  <LoadingBarStickToTop>
                    <GeneralTopLoadingBar></GeneralTopLoadingBar>
                  </LoadingBarStickToTop>
                  ) : (
                    <li>
                      <ListItemText>{` Online: ${onlineLocks}`}</ListItemText>
                    </li>
                    )}
                  </Online>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      ) : (
        <OfflineContainer>
          <TitleHelperContainer>
            <DoughnutChart data={offlineDatas} options={options}>
              -
            </DoughnutChart>
            <SiteTitleBox>
              {' '}
              <img src={greyHouseImg} alt="" />
              <SiteTitle>{site.SiteName}</SiteTitle>
            </SiteTitleBox>
          </TitleHelperContainer>
          <br />
          <Table>
            <tbody>
              <tr>
                <th>
                  <Underline>Locks</Underline>
                </th>
              </tr>
              <tr>
                <td>
                  <Offline>
                    <li>
                      <ListItemText>{`Offline`}</ListItemText>
                    </li>
                  </Offline>
                </td>
              </tr>
            </tbody>
          </Table>
        </OfflineContainer>
      )}
    </>
  );
}

export default SiteCard;

const LoadingBarStickToTop = styled.div`
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
`;

const DoughnutChart = styled(Doughnut)`
  display: inline !important;
`;

const SiteTitle = styled.h1`
  font-weight: bold;
`;
const Container = styled.div`
  border-radius: 1rem;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  margin: 25px 25px;
  position: relative;
  width: 10rem;
  @media (max-width: ${screenSmallForCard}) {
    width: 7rem;
    margin: 8px;
  }
  &:hover {
    cursor: pointer;
  }
`;
const OfflineContainer = styled.div`
  border-radius: 1rem;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  margin: 25px 25px;
  position: relative;
  width: 10rem;
  @media (max-width: ${screenSmallForCard}) {
    width: 7rem;
    margin: 8px;
  }
`;
const SiteTitleBox = styled.div`
  position: absolute;
  margin: auto;
  text-align: center;
  height: auto;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow-wrap: break-word;
  text-align: center;
  vertical-align: middle;
  font-size: 6pt;

  @media (max-width: ${screenSmallForCard}) {
    font-size: 5pt;
  }
`;

const TitleHelperContainer = styled.div`
  position: relative;
  text-align: center;
  align-items: center;
  display: inline;
`;

const Online = styled.span`
  color: ${mainBlue};
  font-weight: bold;
`;

const Offline = styled.span`
  color: ${offlineHouse};
  font-weight: bold;
`;

const Table = styled.table`
  white-space: nowrap;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const ListItemText = styled.span`
  position: relative;
  left: -8px;
`;

import * as React from 'react';
import { BluetoothForm } from 'model/UserDialogForm';
import { BluetoothError } from 'model/UserDialogFormError';
import { StyledUserDialogContent } from '../UserDialogCommonStyle';
import BluetoothHeaderCard from './bluetoothPageCards/BluetoothHeaderCard';
import BluetoothBodyCard from './bluetoothPageCards/BluetoothBodyCard';
import UserTableRecord from 'model/UserTableRecord';

type Props = {
  error: BluetoothError;
  form: BluetoothForm;
  isEdited: boolean;
  userTableRecord?: UserTableRecord;
  initBluetoothForm: BluetoothForm;
  setForm: (form: BluetoothForm) => void;
};

const UserBluetoothPage = ({
  error,
  form,
  isEdited,
  userTableRecord,
  initBluetoothForm,
  setForm,
} :Props) => {
  return (
    <StyledUserDialogContent
      width={684}
      height={460}
    >
      <BluetoothHeaderCard 
        isEdited={isEdited}
        userTableRecord={userTableRecord}
      />
      <BluetoothBodyCard 
        error={error}
        form={form}
        isEdited={isEdited}
        initBluetoothForm={initBluetoothForm}
        setForm={setForm}
      />
    </StyledUserDialogContent>
  );
};

export default UserBluetoothPage;

// TODO: Remove this whole file before shipping the app to the nsp team.

import SelectField from 'components/controls/selects/SelectField';
import {
  getServerConnectionModel,
  serverConnectionProperties,
  updateServerConnectionModelInLocalStorage,
} from 'helper/SignalrConnectionUtil';
import React from 'react';

export const ServerConnectionSelectionCard = () => {
  const serverConnectionModel = getServerConnectionModel();

  /**
   * Initialize a method that will run after the page is refreshed. It will
   * navigate the user to the dashboard page. It is necessary, if the user uses this
   * select input field on the /networkerror page.
   *
   * The browser will for the purpose of resetting both the axiosInstance,
   * and the signalrInstance. Once the reload is finished, these components will connect
   * to the newly set server.
   */
  const handlePageNavigationAfterReload = () => {
    window.onbeforeunload = () => {
      window.setTimeout(() => (window.location.href = '/'), 0);
      // necessary to prevent infinite loop.
      window.onbeforeunload = null;
    };
    document.location.reload();
  };

  const handleServerConnectionUpdate = (serverName: string) => {
    const selectedServerConnectionProperties =
      serverConnectionModel.servers.find(
        (server) => server.name === serverName,
      );

    if (selectedServerConnectionProperties) {
      updateServerConnectionModelInLocalStorage({
        servers: serverConnectionProperties,
        selectedServer: selectedServerConnectionProperties,
      });

      handlePageNavigationAfterReload();
    }
  };

  return (
    <React.Fragment>
      <SelectField
        inputFieldHeight={'50px'}
        inputFieldWidth={'200px'}
        selectOptions={serverConnectionModel.servers.map(
          (server) => server.name,
        )}
        setValue={handleServerConnectionUpdate}
        inputValue={serverConnectionModel.selectedServer.name}
        isTitleDisplayed={false}
      />
    </React.Fragment>
  );
};

export default ServerConnectionSelectionCard;

import { AxiosError } from 'axios';
import { CardCommand, CommandCategory } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import CardModel from 'model/CardModel';
import { notifySuccess } from 'helper/NotificationService';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';
import CardResponse from 'model/CardResponse';
import EncoderModel from 'model/EncoderModel';

const RESPONSE_SUCCESS = '0';
const RESPONSE_ACK = '1';
const RESPONSE_ERROR_OBJECT_NOTFOUND = '2';

const notifyCardStatusError = (error: AxiosError | null) => {
    notifyCommandError('Error while executing card status command', error);
};

export const getCardById = async (siteName: string, cardId: number): Promise<CardModel | null> => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.CardService,
        CardCommand.GetCardById,
        [`${cardId}`],
      );

      if (!data || !data.data || String(data.data.Result) === RESPONSE_ERROR_OBJECT_NOTFOUND) {
        notifyCommandError('No cards found or the server timed out.', null);
        return null;
      }

      const cardModel: CardModel = data.data;

      return cardModel;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyCardStatusError(error);
      return null;
    }
}

export const reportLostCard = async (siteName: string, operatorId: number, cardId: number): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.ReportCardLost,
      [operatorId, cardId]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    notifySuccess("Card is successfully reported as lost.", "");
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeKeyCard = async (siteName: string, operatorId: number, userId: number, encoderId: number, roomIds: number[], startDate: string | null, expiryDate: string | null) =>{
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeKeyCard,
      [operatorId, userId, encoderId, roomIds, startDate, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeMasterCard = async (siteName: string, operatorId: number, userId: number, encoderId: number, masterLevels: string, startDate: string | null, expiryDate: string | null) =>{
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeMasterCard,
      [operatorId, userId, encoderId, masterLevels, 'true', startDate, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeFloorCard = async (siteName: string, operatorId: number, userId: number, encoderId: number, buildingId: number, floorId: number, startDate: string | null, expiryDate: string | null) =>{
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeFloorCard,
      [operatorId, userId, encoderId, buildingId, floorId, startDate, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeEmergencyCard = async (siteName: string, operatorId: number, userId: number, encoderId: number, startDate: string | null, expiryDate: string | null) =>{
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeEmergencyCard,
      [operatorId, userId, encoderId, startDate, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeReportLostCard = async (siteName: string, operatorId: number, encoderId: number, cardId: number): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeReportLostCard,
      [operatorId, encoderId, cardId]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    notifySuccess("Report Lost Card is successfully created.", "");
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const eraseCard = async (siteName: string, operatorId: number, cardId: number, encoder: EncoderModel) => {
  try {
    const { data } = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.EraseCard,
      [operatorId, encoder.Id, cardId]
    );

    if (data.Result !== RESPONSE_SUCCESS && data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.Description, null);
      return null;
    }

    const cardResponse: CardResponse = data;
    return cardResponse;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError("Error while executing erase card command", error);
    return null;
  }
}

export const reissueCard = async (siteName: string, operatorId: number, encoderId: number, cardId: number, expiryDate: string | null): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.ReIssueCard,
      [operatorId, encoderId, cardId, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const readCard = async (siteName: string, encoderId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.ReadCard,
      [encoderId]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    const CardResponse: CardResponse = data.data;
    return CardResponse;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing read card command', error);
    return null;
  }
}

export const updateExpiryDate = async (siteName: string, operatorId: number, encoderId: number, cardId: number, expiryDate: string | null): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.UpdateExpiryDate,
      [operatorId, encoderId, cardId, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing the update expiry date command', error);

    return null;
  }
}
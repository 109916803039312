enum UserActionTypes {
    USERS_REQUESTED = "USERS_REQUESTED",
    REQUEST_USERS_SUCCESS = "REQUEST_USERS_SUCCESS",
    REQUEST_USERS_FAILURE = "REQUEST_USERS_FAILURE",
    SET_USERS_LIST_REFRESH = "SET_USERS_LIST_REFRESH",
    SET_SELECTED_USER = "SET_SELECTED_USER",
    SET_OPENED_DIALOG = "SET_OPENED_DIALOG",
    SET_EMPTY_USER_LIST = "SET_EMPTY_USER_LIST",
    SET_USERS_TO_DELETE = "SET_USERS_TO_DELETE",
    SET_PAGE_NAVIGATION = "SET_PAGE_NAVIGATION",
    SET_NEW_USERS_ON_PENDING = "SET_NEW_USERS_ON_PENDING",
    SET_OPERATOR = "SET_OPERATOR",
    SET_BLE_USER_LOADING = "SET_BLE_USER_LOADING",
};

export default UserActionTypes;

import { GridColDef } from '@mui/x-data-grid';
import { CardTypes, PageNavigation, SortOrder } from 'model/enums';
import SortModel from 'model/SortModel';
import UserTableRecord from 'model/UserTableRecord';
import CardStatusCell from 'components/cards/dataGridTableCells/CardStatusCell';
import CardSettingCell from 'components/cards/dataGridTableCells/CardSettingCell';
import CardSelectionCell from 'components/cards/dataGridTableCells/CardSelectionCell';

export const PAGINATION_SIZE: number = 14;

export const INITIAL_SORT: SortModel = new SortModel('department', SortOrder.Asc);

export const UNLIMITED_DATE_TEXT: string = 'Unlimited';

export const CARD_TYPE_TEXT: string = 'No Type';

export const CARD_LIMITED_DATE_TEXT: string = '-';

export const THREE_DOTS_HEADER_NAME = "Settings"; 

export const SELECTIONS_HEADER_NAME = "Selections"; 

const CARD_IN_USE_HEADER_NAME = "cardInUse";

export const isFieldEqualToSpecialHeaderNames = (fieldName: string) => {
  return [SELECTIONS_HEADER_NAME, THREE_DOTS_HEADER_NAME].some(headerName => headerName === fieldName);
}

export const generateColumns = (
  optionsOpen: boolean,
  setOptionsOpen: (isOpen: boolean) => void,
  pageNavigation: PageNavigation,
): GridColDef[] => {
  const demoUserTableRecord: UserTableRecord = {
    name: "John Doe",
    department: "NSP",
    group: "Group A",
    userId: 123,
    globalId: 1,
    cardId: 1,
    limitedDate: "",
    cardType: CardTypes.ConfigurationCard,
    cardInUse: undefined,
  };
  const fields = [SELECTIONS_HEADER_NAME, ...Object.keys(demoUserTableRecord), THREE_DOTS_HEADER_NAME];
  return fields
    .filter(
      (field) => (field !== 'id' 
      && field !== 'cardId'
      && field !== 'globalId')
      && (pageNavigation === PageNavigation.ManageUsers 
        ? (field !== 'cardType' && field !== 'limitedDate')
        : (field !== 'group' && field !== SELECTIONS_HEADER_NAME))
    )
    .map((field) => {
    const columnDef: GridColDef = {
      field,
      headerName: formatHeaderNames(field),
      renderCell: (param) => renderCellByFieldName(
        param,
        optionsOpen,
        pageNavigation,
        setOptionsOpen,
        field,
      ),
      disableColumnMenu: true,
      sortable: false,
      resizable: false,
      maxWidth: isFieldEqualToSpecialHeaderNames(field) ? 65 : Infinity,
      flex: 1,
    };

    return columnDef;
  });
};

export const formatHeaderNames = (input: string): string => {
    let result = input.replace(/([a-z])([A-Z])/g, '$1 $2');
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
}

const renderCellByFieldName = (
  params,
  optionsOpen: boolean,
  pageNavigation: PageNavigation,
  setOptionsOpen: (isOpen: boolean) => void,
  field: string,
) => {
  switch (field) {
    case CARD_IN_USE_HEADER_NAME:
      return CardStatusCell({userTableRecord: params.row.original});
    case THREE_DOTS_HEADER_NAME:
      return CardSettingCell({userTableRecord: params.row.original, optionsOpen, pageNavigation, setOptionsOpen});
    case SELECTIONS_HEADER_NAME:
      return CardSelectionCell({userTableRecord: params.row.original});
    default:
      return undefined;
  }
}

import { CardStatus } from "model/enums";
import CardActionTypes from "redux/constants/cardActionTypes";
import { CardActions } from "redux/constants/cardActions";

type CardListType = {
    loading: boolean,
    cardStatusList: CardStatus[],
    error: string
}

const initialState: CardListType = {
    loading: true,
    cardStatusList: [],
    error: ''
}

export const cardStatusReducer = (
    state: CardListType = initialState,
    action: CardActions,
  ) => {
    switch (action.type) {
      case CardActionTypes.FETCH_CARDS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CardActionTypes.FETCH_CARDS_SUCCESS:
        return {
          loading: false,
          cardStatusList: action.payload,
          error: '',
        };
      case CardActionTypes.FETCH_CARDS_FAILURE:
        return {
          loading: false,
          cardStatusList: [],
          error: action.payload,
        };
      default:
        return state;
    }
};
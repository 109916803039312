import * as React from 'react';
import EncoderSelectionDialog from './EncoderSelectionDialog';
import EncoderConfirmationDialog from './EncoderConformationDialog';

const EncoderTesterDialog = () => {
  return (
    <React.Fragment>
      <EncoderSelectionDialog/>
      <EncoderConfirmationDialog/>
    </React.Fragment>
  );
};

export default EncoderTesterDialog;
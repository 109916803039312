import { CommandCategory, MasterLevelCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import MasterLevel from 'model/MasterLevel';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

export const getMasterLevels = async (siteName: string) => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.MasterLevelService,
        MasterLevelCommand.GetMasterLevels,
        [],
      );
      if (!data || !data.data) {
        notifyCommandError('No Master Level found or the server timed out.', null);
        return [];
      }
      const result: MasterLevel[] = data.data;
      return result;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyCommandError('Error while executing get master level command.', error);
      return [];
    }
}
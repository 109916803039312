import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Event from './Event';
import DateRangePicker from 'components/controls/datePicker/DateRangePicker';
import { Message } from 'model/event';
import Filter from './Filter';
import { lightGrey, lighterGrey, veryLightGrey, white } from 'constants/theme';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { parse} from 'date-fns'
import { basicEventDateFormat } from 'constants/dateFormats';
import { FilterSelectDropDownValue } from 'model/enums';
import { useMatch } from 'react-router';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });

const todayStart = dayjs().set('hour', 0).set('minutes', 0).set('second', 0);
const todayEnd = dayjs().set('hour', 23).set('minutes', 59).set('seconds', 59);

function EventCard(events) {
  const [siteEvents, setSiteEvents] = useState<Message[]>([]);
  const [listedEvents, setListedEvents] = useState<Message[]>([]);
  const [dateRangeStart, setDateRangeStart] = useState<Date>(new Date());
  const [dateRangeEnd, setDateRangeEnd] = useState<Date>(new Date());
  const [filteredEventsFromDatePicker, setFilteredEventsFromDatePicker] = useState<Message[]>([]);
  const [filterCheckedValues, setCheckedValues] = useState<any>([]);
  const [filterDropdownState, setDropdownState] = useState(FilterSelectDropDownValue.EventId);
  const match = useMatch('site/:id');

  useEffect(() => {
    setSiteEvents(events.events.find((event) => event.siteName === match?.params?.id)?.messages);
    filterEventsByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])


  useEffect(() => {
    filterEventsByDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeStart, dateRangeEnd])

  const filterEventsByDate = () => {
    setFilteredEventsFromDatePicker([]);
    siteEvents?.forEach(event => {
      const eventDate = parse(event.EventDate, basicEventDateFormat, new Date());
      
      if (eventDate > dateRangeStart && eventDate < dateRangeEnd) {
        setFilteredEventsFromDatePicker((pre: Message[]): Message[] => [
          ...pre,
          {
            EventDate: event.EventDate,
            EventId: event.EventId,
            RoomId: event.RoomId,
            CardNo: event.CardNo,
            Description: event.Description,
          },
        ]);
      }
    });
  }

  const listFilteredEvents = listedEvents.map((event, index) => {
    return (
      <Event
        key={index}
        EventDate = {event.EventDate}
        EventId = {event.EventId}
        RoomId = {event.RoomId}
        CardNo = {event.CardNo}
        Description = {event.Description}
      />
  )});

  return (
    <EventContainer>
      <Header>
        <Title>Events</Title>
        <DatePicker>
          <DateRangePicker
            todayStart={todayStart}
            todayEnd={todayEnd}
            setDateRangeStart={setDateRangeStart}
            setDateRangeEnd={setDateRangeEnd}
          />
        </DatePicker>
        <Filter
          setListedEvents={setListedEvents}
          events={filteredEventsFromDatePicker}
          setCheckedValues={setCheckedValues}
          checkedValues={filterCheckedValues}
          setDropdownState={setDropdownState}
          dropdownState={filterDropdownState}
         />
      </Header>
      <TableContainer>
        <EventTable>
          <tbody>
            <TableRow>
              <TableColumn>Date</TableColumn>
              <TableColumn>Event Id</TableColumn>
              <TableColumn>Room Id</TableColumn>
              <TableColumn>Card Number</TableColumn>
              <TableColumn>Description</TableColumn>
            </TableRow>
            { !listedEvents.length ? (<TableRow><TableColumn>There is zero event!</TableColumn></TableRow>) : listFilteredEvents}
          </tbody>
        </EventTable>
      </TableContainer>
    </EventContainer>
  );
}

const getEventsFromStore = state => {
  return { events: state.eventList.events }
}


export default connect(getEventsFromStore)(EventCard);


const Title = styled.h1`
  text-align: left;
  background-color: ${white};
  padding: 25px 40px 20px 40px;
  margin: 0px;
  border-radius: 6px;
`;

const TableRow = styled.tr`
  background-color: ${veryLightGrey};
  padding: 20px 0px;
  background-color: ${white};
  &:nth-child(odd) td {
    background-color: ${white};
  }
`;
const TableColumn = styled.th`
  padding: 18px 0px;
  padding-left: 20px;
  font-weight: bold;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const EventContainer = styled.div`
  margin: auto;
  background-color: ${white};
  border-radius: 6px;
  position: relative;
  height: 550px;
  width: 90vw;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  height: 500px;
  padding: 0 40px 20px 40px;
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
    border: 10px solid ${white};
    padding: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${white};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${lightGrey};
    border-radius: 6px;
    &:hover {
      background: ${lighterGrey};
    }
  }
`;

const EventTable = styled.table`
  justify-content: space-between;
  border-collapse: separate;
  border-spacing: 0;
  padding-bottom: 20px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 30px;
  padding-top: 20px;
`;

const Header = styled.div`
  display: flex;
  position: relative;
`;

const DatePicker = styled.div`
  margin: auto;
  padding: 20px 20px;
  border: none;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
`;

import * as React from 'react';
import { displayInputName} from 'helper/Util';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import styled from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { dialogMenuOptionBorderGray, lightGrayBackground, red, white } from 'constants/theme';
import updateLocale from 'dayjs/plugin/updateLocale';
import { customScrollBarCSSProperties } from 'constants/globalStyle';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    inputValue?: dayjs.Dayjs | null;
    isDisabled?: boolean;
    isRequired?: boolean;
    invalidFormMessage?: string;
    hasLabel?: boolean;
    setValue: (value: dayjs.Dayjs) => void;
};

const SingleDatePicker = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName = "",
    inputValue = null,
    isDisabled = false,
    isRequired = false,
    invalidFormMessage,
    hasLabel = true,
    setValue,
}: Props) => {

    const IS_FORM_INVALID = !!invalidFormMessage;

    const handleOnChange = (e) => {
        setValue(e);
    }

    return (
        <StyledInputFieldComponents.StyledInputOuterContentHolder
            width={inputFieldWidth}
        >
            <StyledInputFieldComponents.StyledInputInnerContentHolder
                $hasLabel={hasLabel}
            >
                <StyledInputFieldComponents.StyledTextLabelHolder>
                    {displayInputName(displayedName, isRequired)}
                </StyledInputFieldComponents.StyledTextLabelHolder>
                <StyledInputFieldComponents.StyledInputHolder
                    height={inputFieldHeight}
                    width={inputFieldWidth}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <StyledDatePicker
                            format="DD/MM/YYYY"
                            height={inputFieldHeight}
                            width={inputFieldWidth}
                            value={inputValue}
                            onChange={(e) => handleOnChange(e)}                        
                            $isFormInvalid={IS_FORM_INVALID}
                            $isDisabled={isDisabled}
                            disabled={isDisabled}
                            slotProps={{ 
                                popper: { 
                                    sx: { 
                                    '& .MuiYearCalendar-root' : {
                                            width: 'auto',
                                            '&' : customScrollBarCSSProperties()
                                        }
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </StyledInputFieldComponents.StyledInputHolder>
                { IS_FORM_INVALID ? 
                    <StyledInputFieldComponents.StyledErrorTextHolder>
                        {invalidFormMessage}
                    </StyledInputFieldComponents.StyledErrorTextHolder>       
                : null }
            </StyledInputFieldComponents.StyledInputInnerContentHolder>
        </StyledInputFieldComponents.StyledInputOuterContentHolder>
    );
};

export default SingleDatePicker;

const StyledDatePicker = styled(DatePicker)<{ height?: string, width?: string, $isFormInvalid?: boolean, $isDisabled?: boolean }>((props) => ({ 
    '& div' : {
        height: `${props.height}`,
        maxHeight: `${props.height}`,
        width: `${props.width}`,
        maxWidth: `${props.width}`,
        padding: `0px 16px 0px 0px`,
        borderRadius: `8px`,
        fontSize: `16px`,
        fontWeight: `400`,
        lineHeight: `24.35px`,
        backgroundColor: (props.$isDisabled ? `${lightGrayBackground}` : `${white}`),
        '& div' : { display: 'contents' },
        '& fieldset' : { border: (props.$isFormInvalid ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}`) },
    }
}));
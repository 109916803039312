import { getBuildings } from "API/commands/BuildingCommands";
import Building from "model/Building";
import { Dispatch } from "redux";
import BuildingActionTypes from "redux/constants/buildingActionTypes";

export const fetchBuildingsRequest = () => {
    return { type: BuildingActionTypes.FETCH_BUILDINGS_REQUEST };
  };
  export const fetchBuildingsSuccess = (data: Building[] | null) => {
    return {
      type: BuildingActionTypes.FETCH_BUILDINGS_SUCCESS,
      payload: data,
    };
  };
  export const fetchBuildingsFailure = (err: any) => {
    return {
      type: BuildingActionTypes.FETCH_BUILDINGS_FAILURE,
      payload: err,
    };
  };
  export const fetchAllBuildings = (siteName: string, dispatch: Dispatch) => {
    dispatch(fetchBuildingsRequest());
    getBuildings(siteName)
      .then((res) => {
        dispatch(fetchBuildingsSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchBuildingsFailure(err));
      });
  };
  export const clearBuildings = () => {
    return { type: BuildingActionTypes.CLEAR_BUILDINGS };
  };
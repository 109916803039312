import getSites from 'API/Site';
import { notifyError } from 'helper/NotificationService';
import Site from 'model/Site';
import { Dispatch } from 'redux';
import SiteActionTypes from 'redux/constants/siteActionTypes';

export const fetchSiteRequest = () => {
  return { type: SiteActionTypes.FETCH_SITE_REQUEST };
};
export const fetchSiteSuccess = (data: Site) => {
  return {
    type: SiteActionTypes.FETCH_SITE_SUCCESS,
    payload: data,
  };
};
export const fetchSiteFailure = (err: string) => {
  notifyError('Error while fetching sites', err);
  return {
    type: SiteActionTypes.FETCH_SITE_FAILURE,
    payload: err,
  };
};
export const fetchAllSites = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchSiteRequest());
    getSites()
      .then((res) => {
        let _list = res.data;
        dispatch(fetchSiteSuccess(_list));
      })
      .catch((err) => {
        dispatch(
          fetchSiteFailure(
            err.message === 'Request failed with status code 401'
              ? '401 - Unauthorized!'
              : err?.data?.message ??
                  err?.message ??
                  'Unknown error arrived from the server',
          ),
        );
      });
  };
};

import { AxiosError } from 'axios';
import { CommandCategory, TimeGroupCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import TimeGroup from 'model/TimeGroup';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const notifyTimeOfGroupsError = (error: AxiosError | null) => {
    notifyCommandError('Error while executing get time groups command', error);
};

  export const getTimeGroups = async (siteName: string) => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.TimeGroupService,
        TimeGroupCommand.GetTimeGroups,
        [],
      );
      if (!data || !data.data) {
        notifyCommandError('No time of groups found or the server timed out.', null);
        return [];
      }
      const timeGroupList: TimeGroup[] = data.data;
      return timeGroupList;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyTimeOfGroupsError(error);
      return null;
    }
  }
import React, { useState } from 'react';
import styled from 'styled-components';
import { lightGrayBackground, white, mainBlue} from 'constants/theme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Divider, InputAdornment, MenuList, OutlinedInput, Typography } from '@mui/material';
import { StyledSvgIconDisplayer } from 'components/controls/selects/SelectField';
import add from 'media/add.svg';

type Props = {
    selectOptions: string[];
    setSelectedOption: (cardTypes: string) => void;
    isIconDisplayed?: boolean;
};

const CustomAddCardSelect = ({
    selectOptions,
    setSelectedOption,
    isIconDisplayed = false,
}: Props) => {

    const [open, setOpen] = useState(false);

    function handleOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    
    return (
        <CustomFilter>
            <CustomFormControl>
                <StyledSelect
                    IconComponent={(component: any) => StyledSvgIconDisplayer(component)}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    open={open}
                    MenuProps={{disableScrollLock: true}}
                    input={
                        <OutlinedInput
                        id="simple-menu"
                        startAdornment={
                            <InputAdornment 
                                position="start"
                                onClick={open ? handleClose : handleOpen}
                            >
                                <StyledTypography noWrap>
                                    { isIconDisplayed ?
                                        <StyledIconHolder>
                                            <img src={add} alt="Add Card Icon" />
                                        </StyledIconHolder>
                                    : null}
                                    <div>
                                        Add Card
                                    </div>
                                </StyledTypography>
                            </InputAdornment>
                        }
                        />
                    }
                >
                    <StyledMenuList disablePadding={true}>
                        {selectOptions.map((optionElement, index) => (
                            <StyledMenuItemHolder
                                key={index}
                            >
                                <StyledMenuItem
                                    onClick={() => setSelectedOption( selectOptions[index])}  
                                    value={optionElement} 
                                > 
                                    <strong>{optionElement}</strong>
                                </StyledMenuItem>
                                <StyledDivider />
                            </StyledMenuItemHolder>
                        ))}
                    </StyledMenuList>
                </StyledSelect>
            </CustomFormControl>
        </CustomFilter>
    );
};

export default CustomAddCardSelect;

const StyledDivider = styled(Divider)`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
`;

const CustomFilter = styled.div<{}>((props) => ({ display: 'flex', height: '4vh', width: '10vw', justifyContent: 'center'}));

const CustomFormControl = styled.form`
    display: flex;
`;

const StyledTypography = styled(Typography)`
    display: flex;
    color: ${white};
    line-height: 24.35px;
    font-size: 17px;
    font-weight: 400;
`

const StyledSelect = styled(Select)`
    width: 9vw;
    background-color: ${mainBlue};
    border-radius: 8px;
    display: flex;
    height: 4vh;
    cursor: pointer;
`;

const StyledMenuItem = styled(MenuItem)`
    max-width: 100%;
    background-color: ${white};
    width: 186px;
    height: 48px;
`;

const StyledIconHolder = styled.div<{}>((props) => ({
    paddingRight: "5px",
    display: "flex",
    alignItems: "center"
}));

const StyledMenuItemHolder = styled.div<{ }>((props) => ({ '& :hover' : { backgroundColor: lightGrayBackground }}));

const StyledMenuList = styled(MenuList)<{ }>((props) => ({
    paddingTop: '0px !important', 
    paddingBottom: '0px !important',
 }));
import { getOperators } from 'API/commands/OperatorCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { notifyError } from 'helper/NotificationService';
import { AuthenticationToken } from 'model/AuthenticationToken';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOperator } from 'redux/actions/userActions';

export const missingOperatorNotification = () => notifyError("Operator is not found.", "");

export const useOperatorInit = () => {
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  useEffect(() => {
    setOperatorIdByAuthenticatedUser();
  }, []);

  const setOperatorIdByAuthenticatedUser = async () => {
    const userStorageKey = Object.keys(localStorage).find((key) =>
      key.includes('@@user@@'),
    );

    if (!userStorageKey) {
      return;
    }

    const localStorageItem = localStorage.getItem(userStorageKey);

    if (!localStorageItem) {
      return;
    }

    const tokenModel: AuthenticationToken = JSON.parse(localStorageItem);
    const operators = await getOperators(actualSite);

    if (!operators || operators.length === 0) {
      return;
    }

    const operatorModel = operators.find(
      (operator) => operator.Username === tokenModel.decodedToken.user.email,
    );

    if (!operatorModel) {
      return;
    }

    dispatch(setOperator(operatorModel.Id));
  };
};

import * as React from 'react';
import { ListItemButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { getBackgroundColorByCardStatus, getCardImageByCardStatus, getCardTextByCardStatus, getColorByCardStatus } from 'constants/cardIcons';
import { StyledListItemTextHeader } from '../UserWindowCommonStyle';
import CardModel from 'model/CardModel';

type Props = {
  isCardDetailsOpen: boolean;
  cardModelToDisplay: CardModel | null;
  isCardDetailsClicked: (isOpen: boolean) => void;
};

const CardDetailsWindowHeaderCard = ({
    isCardDetailsOpen,
    cardModelToDisplay,
    isCardDetailsClicked,
}: Props) => {

  const [openCardDetails, setOpenCardDetails] = React.useState<boolean>(isCardDetailsOpen);

  const handleClickCardDetailsMenu = () => {
    const isCardDetailsOpen = !openCardDetails;
    setOpenCardDetails(isCardDetailsOpen);
    isCardDetailsClicked(isCardDetailsOpen);
  };

  return (
    <ListItemButton onClick={handleClickCardDetailsMenu}>
        <StyledListItemTextHeader>
            Card Details
            <StyledChipContainer $backgroundColor={getBackgroundColorByCardStatus(cardModelToDisplay?.Status)}>
                <StyledChipBody>
                    <StyledMiniIcon 
                        src={getCardImageByCardStatus(cardModelToDisplay?.Status)} 
                        alt="Card Status"
                    />
                    <StyledTextHolder color={getColorByCardStatus(cardModelToDisplay?.Status)}>
                        {getCardTextByCardStatus(cardModelToDisplay?.Status)}
                    </StyledTextHolder>
                </StyledChipBody>
            </StyledChipContainer>
        </StyledListItemTextHeader>
        {openCardDetails ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );
};

export default CardDetailsWindowHeaderCard;

const StyledTextHolder = styled.span<{ color?: string }>((props) => ({
  color: `${props.color}`,
  lineHeight: `21.31px`,
  fontFamily: `Inter`,
  fontSize: `14px`,
  fontWeight: `400`,
  display: `contents`,
}));

const StyledChipContainer = styled.span<{ $backgroundColor?: string }>((props) => ({
    backgroundColor: `${props.$backgroundColor}`,
    display: 'inline-flex',
    borderRadius: '6px',
    marginLeft: '10px',
    marginRight: '10px',
}));

const StyledChipBody = styled.span`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
`;

const StyledMiniIcon = styled.img`
  margin-right: 5px;
  margin-top: 5px;
  width: 16px;
  height: 16px;
`;
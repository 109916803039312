import * as React from 'react';
import styled from 'styled-components';
import { StyledContentRowHolder } from '../../userCardDialog/UserDialogCommonStyle';
import { blue } from 'constants/theme';
import StyledButton from 'components/controls/button/StyledButton';
import add from 'media/add.svg';
import SearchBarField from 'components/controls/searchBar/SearchBarField';
import { CardDialogCardDetailsInputFieldNames, DialogToOpen } from 'model/enums';
import { useSelector } from 'react-redux';
import { selectLastUserToAddedToNewUsersOnPending, selectOpenedDialog, selectSelectedUserRow } from 'redux/selectors/userSelector';
import OptionModel from 'model/OptionModel';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { isUserDialogOpened } from 'helper/DialogUtils';
import { useDispatch } from 'react-redux';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import UserCardDialog from '../../userCardDialog/UserCardDialog';
import { CardDetailsForm } from 'model/CardDialogForm';
import { convertSingleUserModelToOptionModel, useConvertUserModelsToOptionModels } from 'hooks/useConversion';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { searchbarAndDepartmentInputFieldWidth } from 'constants/globalConstants';

const SearchAndAddUserRow = () => {
    const [dialogToOpen, setDialogToOpen] = React.useState<DialogToOpen>();
    const { form, formError, isEdited } = useSelector(selectCardDialogStorage);
    const openedDialog = useSelector(selectOpenedDialog);
    const lastUserToAddedToNewUsersOnPending = useSelector(selectLastUserToAddedToNewUsersOnPending);
    const listOfUserOptions = useConvertUserModelsToOptionModels();
    const isButtonDisabled = form?.cardDetails.isUserSelected ?? false;
    const selectedUserRow = useSelector(selectSelectedUserRow);
    const disabled = isEdited || selectedUserRow !== undefined;
    const fieldWidth = searchbarAndDepartmentInputFieldWidth;
    const dispatch = useDispatch();

    const setForm = (cardDetails?: CardDetailsForm) => {
        if(form && cardDetails) {
            dispatch(setCardDialogForm({...form, cardDetails}));
        }
    }

    React.useEffect(() => {
        if (!lastUserToAddedToNewUsersOnPending || !form?.cardDetails.isFormOverridableByUser) {
            return;
        }

        setForm({
            ...form?.cardDetails,
            user: convertSingleUserModelToOptionModel(lastUserToAddedToNewUsersOnPending),
            isUserSelected: true,
            isFormOverridableByUser: true,
        });
    }, [lastUserToAddedToNewUsersOnPending]);
    
    const fetchSearchBarWidth = () => {
        switch(openedDialog) {
            case DialogToOpen.CreateFloorCardDialog:
            case DialogToOpen.CreateEmergencyCardDialog:
                return "741px";
            default:
                return fieldWidth;
        }
    }

    const handleInputChange = (user: OptionModel) => {
        setForm({...form?.cardDetails, user, isUserSelected: true, isFormOverridableByUser: true})
    }

    const handleOnEnter = () => {
        setForm({...form?.cardDetails, user: undefined, department: undefined, isUserSelected: false, isFormOverridableByUser: true})
    }

    return (
        <React.Fragment>
            <SearchBarField
                searchBarHeight={"42px"}
                searchBarWidth={fetchSearchBarWidth()}
                searchbarLabel={CardDialogCardDetailsInputFieldNames.UserSearchBar}
                setValue={(user) => handleInputChange(user)}
                selectOptions={listOfUserOptions}
                inputValue={form?.cardDetails.user}
                setInputChange={(user) => user.label === '' ? handleOnEnter() : handleInputChange(user)}
                handleOnEnter={() => handleOnEnter()}
                invalidFormMessage={formError?.cardDetails.user}
                disabled={disabled}
                forceOptionSelection
                isRequired
            />
            <StyledButtonHolder
                $isItemsAlignToCenter={!!formError?.cardDetails.user}
            >
                <StyledButton
                    handleOnClick={() => setDialogToOpen(DialogToOpen.CreateUserDialog)}
                    displayedName={CardDialogCardDetailsInputFieldNames.AddUser}
                    buttonHeight={40}
                    buttonWidth={171}
                    isFilledButton
                    backgroundColor={blue}
                    iconLeft={add}
                    disabled={disabled || isButtonDisabled}
                />
            </StyledButtonHolder>
            <UserCardDialog
                openAddUserDialog={isUserDialogOpened(dialogToOpen)}
                setOpenAddUserDialog={() => setDialogToOpen(undefined)}
                dialogToOpen={dialogToOpen}
                setRefreshUserList={() => {
                    // In this case, the users will only be refreshed when the opened CardDialog is Closed.
                    dispatch(setUsersIsRefreshUsersList(true));
                }}
            />
        </React.Fragment>
    );
};

export default SearchAndAddUserRow;

const StyledButtonHolder = styled(StyledContentRowHolder)<{ $isItemsAlignToCenter?: boolean }>((props) => ({
    display: "flex",
    alignItems: props.$isItemsAlignToCenter ? "center" : "end",
}));

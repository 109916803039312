import { BleCommand, CommandCategory } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import BluetoothResponse from 'model/BluetoothResponse';
import { notifySuccess } from 'helper/NotificationService';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

export const addUpdateBleUser = async (siteName: string, userId: number, displayNotification: boolean = false) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.AddUpdateUser,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user cannot be added to the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    if (displayNotification) notifySuccess("The user is successfully added to the NSP Ble Cloud", "");
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService AddUpdateUser command', error)
    return null;
  }
};

export const activateBleUser = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.ActivateUser,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user cannot be activated on the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService activateUser command', error)
    return null;
  }
};

export const suspendBleUser = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.SuspendUser,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user cannot be suspended on the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService suspendUser command', error)
    return null;
  }
};

export const deleteBleUser = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.DeleteUser,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user cannot be deleted on the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService deleteUser command', error)
    return null;
  }
};

export const deactivateDeviceOfBleUser = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.DeactivateDevice,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The ble user device cannot be deactivated on the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService deactivateDevice command', error)
    return null;
  }
};

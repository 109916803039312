import MasterLevel from 'model/MasterLevel';
import { MasterLevelActions } from 'redux/constants/masterLevelActions';
import MasterLevelTypes from 'redux/constants/masterLevelTypes';

type MasterLevelListType = {
  loading: boolean;
  masterLevels: MasterLevel[];
  error: string;
};

const initialState: MasterLevelListType = {
  loading: true,
  masterLevels: [],
  error: '',
};

export const masterLevelReducer = (
  state: MasterLevelListType = initialState,
  action: MasterLevelActions,
): MasterLevelListType => {
  switch (action.type) {
    case MasterLevelTypes.MASTER_LEVELS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case MasterLevelTypes.REQUEST_MASTER_LEVELS_SUCCESS:
      return {
        loading: false,
        masterLevels: action.payload,
        error: '',
      };
    case MasterLevelTypes.REQUEST_MASTER_LEVELS_FAILURE:
      return {
        loading: false,
        masterLevels: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

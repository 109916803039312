import * as React from 'react';
import CardInUseColumnCard from '../CardInUseColumnCard';
import UserTableRecord from 'model/UserTableRecord';

type Props = {
    userTableRecord: UserTableRecord
}

const CardStatusCell = ({userTableRecord}: Props) => {
  return <CardInUseColumnCard userTableRecord={userTableRecord} />;
};

export default CardStatusCell;
import { useEffect } from 'react';
import { isAllFormInputsValid } from 'helper/DialogUtils';
import CardDialogFormError, { CardDetailsFormError } from 'model/CardDialogFormError';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'redux/store';
import { setCardDialogFormError, setCardDialogIsValidationOpen, setCardDialogShouldConfirmationDialogSubmit } from 'redux/actions/cardDialogActions';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { CardFormError } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';
import { selectOpenedDialog, selectOperatorId } from 'redux/selectors/userSelector';
import { DialogToOpen, EncoderResponseDescription, SignalrResponseStatuses } from 'model/enums';
import { dateFieldValidators, endDateTimeBiggerThenStartDateTimeValidator, selectorFieldValidators, validate } from 'helper/CommonInputFieldValidators';
import { useIsCardFieldDisplayed } from './useIsInputDisplayed';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { makeEmergencyCard, makeFloorCard, makeKeyCard, makeMasterCard, updateExpiryDate, readCard } from 'API/commands/CardCommands';
import { DatesUtil } from 'helper/DatesUtil';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import { notifyErrorEncoderIsNotSelected } from 'helper/EncoderUtils';
import { selectUserCardModel } from 'redux/selectors/cardModelSelector';
import { notifyError } from 'helper/NotificationService';
import CardDialogForm from 'model/CardDialogForm';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { CardStartDateAndExpiryDate } from 'model/CardStartDateAndExpiryDate';
import { missingOperatorNotification } from './useOperatorInit';
import { ignoreSignalrResponseStatus, setEncoderRequestToActive, setEncoderRequestToInactive } from './useSignalrEncoder';

export const useCardDialogValidation = () => {

  const dispatch = useDispatch();
  const openedDialog = useSelector(selectOpenedDialog);
  const { form, isEdited, isValidationOpen } = useSelector((state: IRootState) => state.cardDialogStorage);
  const cardModelToDisplay = useSelector(selectUserCardModel);
  const inputDisplayer = useIsCardFieldDisplayed();
  const actualSite = ActualSiteLocator();
  const {selectedEncoder, signalrResponseStatus, isAwaitsEncoderResponse, encoderCardResponse} = useSelector(selectEncoderStorage);
  const operatorId = useSelector(selectOperatorId);

  const geCardDetailsFormErrorModel = (): CardDetailsFormError => {
    if (form) {
      const cardStartDateAndExpiryDate = getCardStartDateAndExpiryDate(form);

      return {
        user: validate(selectorFieldValidators({isRequired: true}), form.cardDetails.user?.userGlobalId),
        startDate: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
          ? validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startDate) 
          : undefined,
        startTime: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
          ? validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startTime) 
          : undefined,
        lastDate: !form.cardDetails.isUnlimitedDateChecked 
          ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastDate)
          : undefined,
        lastTime: !form.cardDetails.isUnlimitedDateChecked 
          ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastTime) 
          : undefined,
        building: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
          ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.building) 
          : undefined,
        floor: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
          ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.floor) 
          : undefined,
        isLastDateTimeBiggerThenStartDatetime: !form.cardDetails.isUnlimitedDateChecked
          ? validate(
              endDateTimeBiggerThenStartDateTimeValidator(), 
              cardStartDateAndExpiryDate.cardStartDate.combinedStartDateTime,
              cardStartDateAndExpiryDate.cardExpiryDate.combinedExpiryDateTime,
            )
          : undefined,
      }
    } else {
      return CardFormError.INIT_CARD_DETAILS_FORM_ERROR;
    }
  };

  const handleClickOnSubmit = () => {
    const dialogFormError: CardDialogFormError = { cardDetails: geCardDetailsFormErrorModel() };
    dispatch(setCardDialogFormError(dialogFormError));

    if (isAllFormInputsValid(dialogFormError)) {
      handleAPICall();
    } else {
      closeValidation();
    }
  };

  const getCardStartDateAndExpiryDate = (form: CardDialogForm): CardStartDateAndExpiryDate => {
    const combinedStartDateTime = DatesUtil.combineDateAndTime(form?.cardDetails.startDate, form?.cardDetails.startTime);
    const startDate = combinedStartDateTime && form.cardDetails.isStartDateChecked
      ? combinedStartDateTime.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
      : "";

    const combinedExpiryDateTime = DatesUtil.combineDateAndTime(form?.cardDetails.lastDate, form?.cardDetails.lastTime);
    const expiryDate = combinedExpiryDateTime && !form.cardDetails.isUnlimitedDateChecked
      ? combinedExpiryDateTime.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
      : "";

    return {
      cardStartDate: {
        combinedStartDateTime,
        startDate,
      },
      cardExpiryDate: {
        combinedExpiryDateTime,
        expiryDate,
      }
    }
  }

  const handleAPICall = async () => {
    if (!selectedEncoder) {
        notifyErrorEncoderIsNotSelected();
        closeValidation();
        return;
    }

    if (!form) {
      closeValidation();
      return;
    }

    if (!operatorId) {
      handleMissingOperatorId();
      closeValidation();
      return;
    }

    if (!isEdited) {
      isACKResponseArrived(await readCard(actualSite, selectedEncoder?.Id ?? -1));
    } else {
      const cardStartDateAndExpiryDate = getCardStartDateAndExpiryDate(form);
      const originalCardExpiryDate = DatesUtil.convertFormatToDayjs(cardModelToDisplay?.ExpiryDate, DatesUtil.CUSTOM_DATE_TIME_FORMAT);

      if (originalCardExpiryDate?.toISOString() !== cardStartDateAndExpiryDate.cardExpiryDate.combinedExpiryDateTime?.toISOString()) {
        // A new expiry date was set on the card, so we are calling the UpdateExpiryDate command to modify the expiry date of the card.
        isACKResponseArrived(await updateExpiryDate(
          actualSite,
          operatorId,
          selectedEncoder?.Id ?? 0,
          Number(form.userCard?.Id),
          cardStartDateAndExpiryDate.cardExpiryDate.expiryDate,
        ));
      }
    }
  };

  const handleCardCreationCommand = async () => {
    if (!form) {
      closeValidation();
      return;
    }

    if (!operatorId) {
      handleMissingOperatorId();
      closeValidation();
      return;
    }

    const userId = form.cardDetails.user?.userGlobalId ?? -1;
    const encoderId = selectedEncoder?.Id ?? -1;
    const cardStartDateAndExpiryDate = getCardStartDateAndExpiryDate(form);

    switch(openedDialog) {
      case DialogToOpen.CreateKeyCardDialog:
        isACKResponseArrived(await makeKeyCard(
          actualSite,
          operatorId,
          userId,
          encoderId,
          form.cardDoors.map(door => door.Id),
          cardStartDateAndExpiryDate.cardStartDate.startDate,
          cardStartDateAndExpiryDate.cardExpiryDate.expiryDate,
        ));
        break;
      case DialogToOpen.CreateMasterCardDialog:
        isACKResponseArrived(await makeMasterCard(
          actualSite,
          operatorId,
          userId,
          encoderId,
          form.cardLevels.map(level => level.isChecked ? '1' : '0').join(''),
          cardStartDateAndExpiryDate.cardStartDate.startDate,
          cardStartDateAndExpiryDate.cardExpiryDate.expiryDate,
        ));
        break;
      case DialogToOpen.CreateFloorCardDialog:
        isACKResponseArrived(await makeFloorCard(
          actualSite,
          operatorId,
          userId,
          encoderId,
          form.cardDetails.building?.Id ?? -1,
          form.cardDetails.floor?.Id ?? -1,
          cardStartDateAndExpiryDate.cardStartDate.startDate,
          cardStartDateAndExpiryDate.cardExpiryDate.expiryDate,
        ));
        break;
      case DialogToOpen.CreateEmergencyCardDialog:
        isACKResponseArrived(await makeEmergencyCard(
          actualSite,
          operatorId,
          userId,
          encoderId,
          cardStartDateAndExpiryDate.cardStartDate.startDate,
          cardStartDateAndExpiryDate.cardExpiryDate.expiryDate,
        ));
        break;
    }
  }

  const isACKResponseArrived = (response: any) => {
    if (!response) {
      closeValidation();
      return;
    }

    setEncoderToAwaitRequest();
  }

  const setEncoderToAwaitRequest = () => {
    setEncoderRequestToActive(dispatch);
  }

  const handleSuccessfulValidationEnd = () => {
    dispatch(setCardDialogFormError(CardFormError.INIT_CARD_DIALOG_FORM_ERROR));
    dispatch(setUsersIsRefreshUsersList(true));
    dispatch(setCardDialogShouldConfirmationDialogSubmit(true));
    dispatch(setOpenedDialog(undefined));
    closeValidation();
  };

  const closeValidation = () => {
    setEncoderRequestToInactive(dispatch);
    dispatch(setCardDialogIsValidationOpen(false));
  }

  const handleMissingOperatorId = () => {
    missingOperatorNotification();
    closeValidation();
  }

  useEffect(() => {
    if (!isValidationOpen) {
      return;
    }
    
    setEncoderToAwaitRequest();
    handleClickOnSubmit();
  }, [isValidationOpen]);

  const handleMakeOrEditCardSignalrResponse = () => {
    switch(signalrResponseStatus) {
      case SignalrResponseStatuses.Success:
        handleSuccessfulValidationEnd();
        break;
      default:
        closeValidation();
        break;
    }
  }

  const handleReadCardSignalrResponse = () => {
    switch(signalrResponseStatus) {
      case SignalrResponseStatuses.CardIsBlank:
        handleCardCreationCommand();
        break;
      case SignalrResponseStatuses.Success:
        notifyError(`${ConversionsUtil.getCardTypeFromOpenedDialog(openedDialog)} cannot be made as the physical card is not a blank card.`, "");
        closeValidation();
        break;
      case SignalrResponseStatuses.Failed:
        notifyError(`Encoder request failed due to unknown issue.`, "");
        closeValidation();
        break;
      default:
        closeValidation();
        break;
    }
  }

  useEffect(() => {
    if (!isValidationOpen 
      || isAwaitsEncoderResponse 
      || ignoreSignalrResponseStatus.includes(signalrResponseStatus)) {
      return;
    }

    encoderCardResponse?.Command === EncoderResponseDescription.ReadCard
      ? handleReadCardSignalrResponse()
      : handleMakeOrEditCardSignalrResponse();
  }, [isValidationOpen, isAwaitsEncoderResponse, signalrResponseStatus, encoderCardResponse]);
}

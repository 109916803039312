import * as React from 'react';
import { Autocomplete, TextField } from "@mui/material";
import { black, dialogMenuOptionBorderGray, listItemLightGray, red } from "constants/theme";
import { StyledSvgIconDisplayer } from './SelectField';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import InputFieldError from '../commons/InputFieldError';
import InputFieldLabel from '../commons/InputFieldLabel';
import styled from 'styled-components';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string;
    isDisabled?: boolean;
    selectOptions: string[];
    isRequired?: boolean;
    invalidFormMessage?: string;
    popupIcon?: JSX.Element;
    setValue: (value: string, index: number) => void;
};



const WritableSelectField = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName = "",
    placeholderInput = "",
    inputValue,
    isDisabled = false,
    selectOptions,
    isRequired = false,
    invalidFormMessage,
    popupIcon = <StyledSvgIconDisplayer top={"4px"}/>,
    setValue,
}: Props) => {
    const IS_FORM_INVALID = (!!invalidFormMessage);

    const IS_EMPTY = !inputValue || inputValue === placeholderInput;

    const handleChange = (event, newValue) => {
      setValue(newValue, Number(event.target.dataset.optionIndex));
    };
  
    const defaultProps = {
      options: selectOptions,
      getOptionLabel: (option) => option,
    };

    return (
      <div>
          <InputFieldLabelHolder>
            <InputFieldLabel 
              inputLabel={displayedName}
              isRequired={isRequired}
            />
          </InputFieldLabelHolder>
          <div>
            <StyledAutocomplete
              {...defaultProps}
              renderInput={(params) => (
                <StyledTextField
                {...params}
                placeholder={placeholderInput}
                variant="outlined"
                error={IS_FORM_INVALID}
              />
              )}
              height={inputFieldHeight}
              width={inputFieldWidth}
              disableClearable
              placeholder={placeholderInput}
              disabled={isDisabled}
              value={inputValue ?? placeholderInput}
              onChange={handleChange}
              $isError={IS_FORM_INVALID}
              $isEmpty={IS_EMPTY}
              ListboxProps={{ sx: customScrollBarCSSProperties({width: "10px"}) }}
              popupIcon={popupIcon}
            />
          </div>
          <InputFieldError invalidFormMessage={invalidFormMessage}/>
    </div>
    )
}

export default WritableSelectField;

export const StyledAutocomplete = styled(Autocomplete)<{height?: string, width?: string, $isEmpty?: boolean, $isError?: boolean}>((props) => ({
  height: `${props.height}`,
  width: `${props.width}`,
  borderRadius: `8px`,
  fontSize: `16px`,
  fontWeight: `400`,
  lineHeight: `24.35px`,
  color: props.$isEmpty ? `${listItemLightGray}` : `${black}`,
  '& fieldset': { border: props.$isError ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}` },
  '& .MuiAutocomplete-popupIndicator: hover': {
    cursor: 'pointer',
    background: 'none',
  },
}));

const InputFieldLabelHolder = styled.div`
  padding-bottom: 8px;
`;

export const StyledTextField = styled(TextField)`
  margin: 0;
  padding: 0;

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiOutlinedInput-root {
    padding: 0;
    border-radius: 8px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0 !important;
  }

  .MuiInputLabel-root {
    padding: 0;
    margin: 0;
  }

  .MuiFormControl-root {
    padding: 0;
    margin: 0;
  }
`;
import React, { useEffect} from 'react';
import styled from 'styled-components';
import SiteCard from '../cards/SiteCard';
import { connect } from 'react-redux';
import { fetchAllSites } from 'redux/actions/siteActions';
import GeneralTopLoadingBar from 'components/GeneralTopLoadingBar';

function SystemDashboard(props: any) {
  
  useEffect(() => {
    props.fetchSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return props.siteList.loading ? (
    <LoadingBarStickToTop>
      <GeneralTopLoadingBar />
    </LoadingBarStickToTop>
  ) : props.siteList.error ? (
    <h2>{props.siteList.error}</h2>
  ) : (
    <Container>
      {props.siteList &&
        props.siteList.data &&
        props.siteList.data.map((item, index) => (
          <SiteCard key={index} site={item} />
        ))}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return { siteList: state.siteList };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchSites: () => dispatch(fetchAllSites()) };
};
export default connect(mapStateToProps, mapDispatchToProps)(SystemDashboard);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: center;
`;

const LoadingBarStickToTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

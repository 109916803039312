import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import { CardActions, CardTypesToCreate, DialogToOpen } from 'model/enums';
import createCard from 'media/create-card.svg';
import chevronRightArrow from 'media/chevron-right-arrow.svg';
import { CardActionImage } from './CardActionsCommonStyle';

type Props = {
    readonly ITEM_HEIGHT: number;
    setAnchorEl:(value: React.SetStateAction<HTMLElement | null>) => void;
    setDialogToOpen: (dialog: DialogToOpen | undefined) => void;
};

const CreateCardAction = ({
    ITEM_HEIGHT,
    setAnchorEl,
    setDialogToOpen,
}: Props) => {

    const [anchorElSub, setAnchorElSub] = React.useState<null | HTMLElement>(null);
    const openSub = Boolean(anchorElSub);

    const handleClickOnMenuItem = () => {
        if (!anchorElSub) {
            const iconButton = document.getElementById("long-button2");

            if (!iconButton) {
                return;
            }

            setAnchorElSub(iconButton);
        } else {
            handleCloseSub();
        }
    };

    const handleClickSub = (event: React.MouseEvent<HTMLElement>) => setAnchorElSub(event.currentTarget);

    const handleCloseSub = () => setAnchorElSub(null);

    const handleCloseAllMenu = () => {
        handleCloseSub();
        setAnchorEl(null);
    }

    const handleCardTypes = (cardAction: CardActions) => {
        const handler = Object.freeze({
            [CardActions.CreateKeyCard]: () => setDialogToOpen(DialogToOpen.CreateKeyCardDialog),
            [CardActions.CreateMasterCard]: () => setDialogToOpen(DialogToOpen.CreateMasterCardDialog),
            [CardActions.CreateEmergencyCard]: () => setDialogToOpen(DialogToOpen.CreateEmergencyCardDialog),
            [CardActions.CreateFloorCard]: () => setDialogToOpen(DialogToOpen.CreateFloorCardDialog),
        });
        handler[cardAction].call();
        handleCloseAllMenu();
    }

    return (
        <MenuItem onClick={handleClickOnMenuItem}>
            <CardActionImage src={createCard} alt={CardActions.CreateCard}/>
            {CardActions.CreateCard}
            <IconButton
                aria-label="more2"
                id="long-button2"
                aria-controls={openSub ? 'long-menu2' : undefined}
                aria-expanded={openSub ? 'true' : undefined}
                aria-haspopup="true"
                onMouseEnter={handleClickSub}
            >
                <CardActionImage src={chevronRightArrow} alt="Arrow that points to the right"/>
            </IconButton>
            <Menu
                id="long-menu2"
                anchorEl={anchorElSub}
                open={openSub}
                onClose={handleCloseAllMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20vh',
                    }
                }}
            >
                {CardTypesToCreate.map((card: CardActions) => (
                    <span key={card}>
                        <MenuItem onClick={() => handleCardTypes(card)}>{card}</MenuItem>
                    </span>
                ))}
            </Menu>
        </MenuItem>
    );
};

export default CreateCardAction;
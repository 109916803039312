import Command from 'model/Command';
import API from '../axios/AxiosInstance';
import { AnyCommand, CommandCategory } from 'model/enums';
import { AxiosError } from 'axios';
import { notifyError } from 'helper/NotificationService';

const config = { headers: { 'Content-Type': 'application/json' } };

interface ErrorResponse {
  Message?: string;
  Result?: number;
  Description?: string;
}

const postCommand = async (
  siteName: string,
  category: CommandCategory,
  commandType: AnyCommand,
  parameters: any[],
) =>
  API.post(
    `/command`,
    JSON.stringify(getCommand(siteName, category, commandType, parameters)),
    config,
  );

const getCommand = (
  siteName: string,
  category: CommandCategory,
  commandType: AnyCommand,
  parameters: any[],
): Command => {
  return {
    SiteName: siteName,
    Category: category,
    Command: commandType,
    Parameters: parameters,
  };
};

export const notifyCommandError = (title: string, error: AxiosError | null) => {
  if (error) {
    const data = error?.response?.data as ErrorResponse;
    if (data && typeof data === 'object') {
      if (data.Message) {
        notifyError(title, data?.Message);
      } else if (data.Description) {
        notifyError(title, data?.Description);
      } else {
        notifyError(
          title,
          'Unknown error occured! Status code: ' + error.status,
        );
      }
    } else {
      notifyError(title, data);
    }
  } else {
    notifyError('Unknown error occured!', title);
  }
};

export default postCommand;

import Floor, { TestGroupData } from 'model/Floor';
import { EventProps, filterProp } from '../model/event';
import Building from 'model/Building';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import { CardStatus, CardTypes, LockStatus } from 'model/enums';
import OnlineDoor from 'model/OnlineDoor';
import UserTableRecord from 'model/UserTableRecord';
import UserModel from 'model/UserModel';
import { TestRoomData } from 'model/Room';

const lockStatus: LockStatus = LockStatus.Normal;
export const doors: OnlineDoor[] = [
  {
    Building: 'Cedar Ridge Prep School',
    Floor: 'Floor 5',
    Hub: 'Main',
    Id: 1,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'ME',
    Floor: 'Floor 10',
    Hub: 'Main',
    Id: 2,
    Name: 'Language Lab',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'ME',
    Floor: 'Floor 11',
    Hub: 'Not Main :D',
    Id: 3,
    Name: 'Math Maze',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'ME',
    Floor: 'Floor 10',
    Hub: 'Main',
    Id: 4,
    Name: 'Main Door',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Cedar Ridge Prep School',
    Floor: 'Floor 10',
    Hub: 'Main',
    Id: 5,
    Name: 'Library',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: "Adam's Hotel",
    Floor: 'Floor 5',
    Hub: 'Main',
    Id: 9,
    Name: 'Classroom 1',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: "Adam's Hotel",
    Floor: 'Floor 6',
    Hub: 'Main',
    Id: 10,
    Name: 'Classroom 2',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Cedar Ridge Prep School',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 11,
    Name: 'Classroom 3',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: "Adam's Hotel",
    Floor: 'Floor 9',
    Hub: 'Main',
    Id: 12,
    Name: 'Room 1',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: "Adam's Hotel",
    Floor: 'Floor 9',
    Hub: 'Main',
    Id: 13,
    Name: 'Room 2',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
];

const b1floors: Floor[] = [
  {
    Id: 1,
    Name: 'Floor 1',
    FloorNo: 1,
  },
  {
    Id: 2,
    Name: 'Floor 2',
    FloorNo: 2,
  },
];
const b2floors: Floor[] = [
  {
    Id: 1,
    Name: 'Floor 1',
    FloorNo: 1,
  },
  {
    Id: 2,
    Name: 'Floor 2',
    FloorNo: 2,
  },
  {
    Id: 3,
    Name: 'Floor 3',
    FloorNo: 3,
  },
];
const b3floors: Floor[] = [
  {
    Id: 1,
    Name: 'Floor 1',
    FloorNo: 1,
  },
];
const b4floors: Floor[] = [
  {
    Id: 1,
    Name: 'Floor 1',
    FloorNo: 1,
  },
  {
    Id: 2,
    Name: 'Floor 2',
    FloorNo: 2,
  },
];
export const buildings: Building[] = [
  {
    Id: 1,
    Name: 'Queen Elisabeth II. Primary School',
    BuildingNo: 1,
    Floors: b1floors,
  },
  {
    Id: 2,
    Name: 'River Valley Elementary School',
    BuildingNo: 2,
    Floors: b2floors,
  },
  {
    Id: 3,
    Name: 'Mapplewood High School',
    BuildingNo: 3,
    Floors: b3floors,
  },
  {
    Id: 4,
    Name: 'ME',
    BuildingNo: 4,
    Floors: b4floors,
  },
];
export const testDoors: OnlineDoor[] = [
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 1,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 2,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 3,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 4,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 5,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 6,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 7,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 8,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 9,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 10,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 11,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 12,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 13,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 14,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 15,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 16,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 17,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 18,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 19,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 20,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 21,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 22,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 23,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 24,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },
  {
    Building: 'Building 1',
    Floor: 'Floor 1',
    Hub: 'Main',
    Id: 25,
    Name: 'Science Hub',
    Status: lockStatus,
    TimePattern: 'something time pattern',
  },

]
export const testFloors: Floor[] = [
  {
    Id: 1,
    Name: 'Floor 1',
    FloorNo: 1,
  },
  {
    Id: 2,
    Name: 'Floor 2',
    FloorNo: 2,
  },
  {
    Id: 3,
    Name: 'Floor 3',
    FloorNo: 3,
  },
  {
    Id: 4,
    Name: 'Floor 4',
    FloorNo: 4,
  },
  {
    Id: 5,
    Name: 'Floor 5',
    FloorNo: 5,
  },
  {
    Id: 6,
    Name: 'Floor 6',
    FloorNo: 6,
  },
  {
    Id: 7,
    Name: 'Floor 7',
    FloorNo: 7,
  },
  {
    Id: 8,
    Name: 'Floor 8',
    FloorNo: 8,
  },
  {
    Id: 9,
    Name: 'Floor 9',
    FloorNo: 9,
  },
  {
    Id: 10,
    Name: 'Floor 10',
    FloorNo: 10,
  },
  {
    Id: 11,
    Name: 'Floor 11',
    FloorNo: 11,
  },
  {
    Id: 12,
    Name: 'Floor 12',
    FloorNo: 12,
  },
  {
    Id: 13,
    Name: 'Floor 13',
    FloorNo: 13,
  },
  {
    Id: 14,
    Name: 'Floor 14',
    FloorNo: 14,
  },
  {
    Id: 15,
    Name: 'Floor 15',
    FloorNo: 15,
  },
  {
    Id: 16,
    Name: 'Floor 16',
    FloorNo: 16,
  },
  {
    Id: 17,
    Name: 'Floor 17',
    FloorNo: 17,
  },
  {
    Id: 18,
    Name: 'Floor 18',
    FloorNo: 18,
  },
  {
    Id: 19,
    Name: 'Floor 19',
    FloorNo: 19,
  },
  {
    Id: 20,
    Name: 'Floor 20',
    FloorNo: 20,
  },
];
export const testBulding: Building = {
  Id: 1,
  Name: 'Building 1',
  BuildingNo: 1,
  Floors: testFloors
}
export const floorsWithBuildingName: FloorWithBuildingName[] = [
  {
    floor: {
      Id: 3,
      Name: 'Floor 3',
      FloorNo: 3,
    },
    buildingName: 'Pine Grove Acadenym'
  },
  {
    floor: {
      Id: 4,
      Name: 'Floor 4',
      FloorNo: 4,
    },
    buildingName: 'Z. Parking'
  },
  {
    floor: {
      Id: 8,
      Name: 'Floor 8',
      FloorNo: 8,
    },
    buildingName: 'Z. Parking'
  },
  {
    floor: {
      Id: 9,
      Name: 'Floor 9',
      FloorNo: 9,
    },
    buildingName: 'King Castle'
  },
  {
    floor: {
      Id: 15,
      Name: 'Floor 15',
      FloorNo: 15,
    },
    buildingName: 'Pine Grove Acadenym'
  },
  {
    floor: {
      Id: 16,
      Name: 'Floor 8',
      FloorNo: 8,
    },
    buildingName: 'River Valley Elementary School'
  },
  {
    floor: {
      Id: 17,
      Name: 'Floor 9',
      FloorNo: 9,
    },
    buildingName: 'River Valley Elementary School'
  },
  {
    floor: {
      Id: 18,
      Name: 'Floor 8',
      FloorNo: 8,
    },
    buildingName: 'Queen Elisabeth II. Primary School'
  },
  {
    floor: {
      Id: 18,
      Name: 'Floor 11',
      FloorNo: 11,
    },
    buildingName: 'ME'
  },
]

export const allBuildings: Building[] = [
  {
    Id: 1,
    Name: 'Queen Elisabeth II. Primary School',
    BuildingNo: 1,
    Floors: b1floors,
  },
  {
    Id: 2,
    Name: 'River Valley Elementary School',
    BuildingNo: 2,
    Floors: b2floors,
  },
  {
    Id: 3,
    Name: 'Mapplewood High School',
    BuildingNo: 3,
    Floors: b3floors,
  },
  {
    Id: 4,
    Name: 'ME',
    BuildingNo: 4,
    Floors: b4floors,
  },
  {
    Id: 5,
    Name: 'Pine Grove Acadenym',
    BuildingNo: 5,
    Floors: b4floors,
  },
  {
    Id: 6,
    Name: 'Cedar Ridge Prep School',
    BuildingNo: 6,
    Floors: b4floors,
  },
  {
    Id: 7,
    Name: 'Z. Parking',
    BuildingNo: 7,
    Floors: b4floors,
  },
  {
    Id: 8,
    Name: 'King Castle',
    BuildingNo: 8,
    Floors: b4floors,
  },
  {
    Id: 9,
    Name: "Adam's Hotel",
    BuildingNo: 9,
    Floors: b4floors,
  },
];

export const oDoorName: filterProp[] = [
  /* { doorName: 'Science Hub' },
  { doorName: 'Language Lab' }, */
];
export const emptyEvent: EventProps[] = [];
export const emptyBuilding: Building[] = [];
export const events: EventProps[] = [
  {
    EventDate: '22/02/2023 17:53:30',
    EventId: '1',
    RoomId: '128',
    CardNo: 'ff7',
    Description: 'Lorem ipsum as lefi adff'
  },
  {
    EventDate: '22/02/2023 17:53:30',
    EventId: '2',
    RoomId: '127',
    CardNo: 'ff2',
    Description: 'Lorem ipsum as lefi adff'
  },
  {
    EventDate: '22/02/2023 17:53:30',
    EventId: '4',
    RoomId: '12',
    CardNo: 'ff2',
    Description: 'Lorem ipsum as lefi adff'
  },
  {
    EventDate: '22/02/2023 17:53:30',
    EventId: '1',
    RoomId: '178',
    CardNo: 'ff5',
    Description: 'Lorem ipsum as lefi adff'
  },
  {
    EventDate: '22/02/2023 17:53:30',
    EventId: '2',
    RoomId: '127',
    CardNo: 'fe4',
    Description: 'Lorem ipsum as lefi adff'
  }
];
/*
export const oldevents: EventProps[] = [
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Science Hub',
    cardHolderName: 'Sarah Johnson',
    cardType: 'Clock Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Language Lab',
    cardHolderName: 'Emily Thompson',
    cardType: 'Key Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 2',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 3',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 4',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Math Maze',
    cardHolderName: 'Michael Davis',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library',
    cardHolderName: 'Michael David',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Maze',
    cardHolderName: 'Michael Jackson',
    cardType: 'Configuration Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 8',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 15',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
  {
    eventDate: '26/02/2022 17:53:30',
    onlineDoorName: 'Library Lane',
    cardHolderName: 'John Smith 9',
    cardType: 'Site Card',
    eventDescription:
      'Fetches a list of all online doors. See appendix 1 for an example reply',
  },
];
*/
// buildings.js

export const testBuildings = [
  {
    Id: 1,
    Name: 'Block A',
    Type: 1,
    TypeId: 1,
    ParentId: 1,
    Floors: [
      {
        Id: 1,
        Name: 'Ground Floor',
        Type: 1,
        TypeId: 1,
        ParentId: 1,
      },
      {
        Id: 2,
        Name: 'Level 01',
        Type: 1,
        TypeId: 1,
        ParentId: 1,
      },
      {
        Id: 3,
        Name: 'Level 02',
        Type: 1,
        TypeId: 1,
        ParentId: 1,
      },
    ],
  },
  {
    Id: 2,
    Name: 'BuildingB',
    Type: 2,
    TypeId: 2,
    ParentId: 1,
    Floors: [
      {
        Id: 4,
        Name: 'Ground Floor',
        Type: 2,
        TypeId: 2,
        ParentId: 2,
      },
      {
        Id: 5,
        Name: 'level 01',
        Type: 2,
        TypeId: 2,
        ParentId: 2,
      },
      {
        Id: 6,
        Name: 'Level 02',
        Type: 2,
        TypeId: 2,
        ParentId: 2,
      },
      {
        Id: 7,
        Name: 'ThirdFloor',
        Type: 2,
        TypeId: 2,
        ParentId: 2,
      },
    ],
  },
  {
    Id: 3,
    Name: 'BuildingC',
    Type: 3,
    TypeId: 3,
    ParentId: 3,
    Floors: [
      {
        Id: 8,
        Name: 'Ground Floor',
        Type: 3,
        TypeId: 3,
        ParentId: 3,
      },
    ],
  },
];

export const testOnlineDoors = [
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub1',
    Id: 1,
    Name: 'Door 1',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern1',
  },
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub2',
    Id: 2,
    Name: 'Door 2',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern2',
  },
  {
    Building: 'Block A',
    Floor: 'Level 01',
    Hub: 'Hub3',
    Id: 3,
    Name: 'Door 3',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern3',
  },
  {
    Building: 'Block A',
    Floor: 'Level 02',
    Hub: 'Hub4',
    Id: 4,
    Name: 'Door 4',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern4',
  },
  {
    Building: 'BuildingB',
    Floor: 'Ground Floor',
    Hub: 'Hub5',
    Id: 5,
    Name: 'Door 5',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern5',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 01',
    Hub: 'Hub6',
    Id: 6,
    Name: 'Door 6',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern6',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 02',
    Hub: 'Hub7',
    Id: 7,
    Name: 'Door 7',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern7',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 03',
    Hub: 'Hub8',
    Id: 8,
    Name: 'Door 8',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern8',
  },
  {
    Building: 'BuildingC',
    Floor: 'Ground Floor',
    Hub: 'Hub9',
    Id: 9,
    Name: 'Door 9',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern9',
  },
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub10',
    Id: 10,
    Name: 'Door 10',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern10',
  },
  {
    Building: 'Block A',
    Floor: 'Level 01',
    Hub: 'Hub11',
    Id: 11,
    Name: 'Door 11',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern11',
  },
  {
    Building: 'Block A',
    Floor: 'Level 02',
    Hub: 'Hub12',
    Id: 12,
    Name: 'Door 12',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern12',
  },
  {
    Building: 'BuildingB',
    Floor: 'Ground Floor',
    Hub: 'Hub13',
    Id: 13,
    Name: 'Door 13',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern13',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 01',
    Hub: 'Hub14',
    Id: 14,
    Name: 'Door 14',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern14',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 02',
    Hub: 'Hub15',
    Id: 15,
    Name: 'Door 15',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern15',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 03',
    Hub: 'Hub16',
    Id: 16,
    Name: 'Door 16',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern16',
  },
  {
    Building: 'BuildingC',
    Floor: 'Ground Floor',
    Hub: 'Hub17',
    Id: 17,
    Name: 'Door 17',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern17',
  },
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub18',
    Id: 18,
    Name: 'Door 18',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern18',
  },
  {
    Building: 'Block A',
    Floor: 'Level 01',
    Hub: 'Hub19',
    Id: 19,
    Name: 'Door 19',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern19',
  },
  {
    Building: 'Block A',
    Floor: 'Level 02',
    Hub: 'Hub20',
    Id: 20,
    Name: 'Door 20',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern20',
  },
  {
    Building: 'BuildingB',
    Floor: 'Ground Floor',
    Hub: 'Hub21',
    Id: 21,
    Name: 'Door 21',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern21',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 01',
    Hub: 'Hub22',
    Id: 22,
    Name: 'Door 22',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern22',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 02',
    Hub: 'Hub23',
    Id: 23,
    Name: 'Door 23',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern23',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 03',
    Hub: 'Hub24',
    Id: 24,
    Name: 'Door 24',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern24',
  },
  {
    Building: 'BuildingC',
    Floor: 'Ground Floor',
    Hub: 'Hub25',
    Id: 25,
    Name: 'Door 25',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern25',
  },
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub26',
    Id: 26,
    Name: 'Door 26',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern26',
  },
  {
    Building: 'Block A',
    Floor: 'Level 01',
    Hub: 'Hub27',
    Id: 27,
    Name: 'Door 27',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern27',
  },
  {
    Building: 'Block A',
    Floor: 'Level 02',
    Hub: 'Hub28',
    Id: 28,
    Name: 'Door 28',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern28',
  },
  {
    Building: 'BuildingB',
    Floor: 'Ground Floor',
    Hub: 'Hub29',
    Id: 29,
    Name: 'Door 29',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern29',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 01',
    Hub: 'Hub30',
    Id: 30,
    Name: 'Door 30',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern30',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 02',
    Hub: 'Hub31',
    Id: 31,
    Name: 'Door 31',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern31',
  },
  {
    Building: 'BuildingB',
    Floor: 'Level 03',
    Hub: 'Hub32',
    Id: 32,
    Name: 'Door 32',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern32',
  },
  {
    Building: 'BuildingC',
    Floor: 'Ground Floor',
    Hub: 'Hub33',
    Id: 33,
    Name: 'Door 33',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern33',
  },
  {
    Building: 'Block A',
    Floor: 'Ground Floor',
    Hub: 'Hub34',
    Id: 34,
    Name: 'Door 34',
    Status: LockStatus.Unlocked,
    TimePattern: 'Pattern34',
  },
  {
    Building: 'Block A',
    Floor: 'Level 01',
    Hub: 'Hub35',
    Id: 35,
    Name: 'Door 35',
    Status: LockStatus.OpenMode,
    TimePattern: 'Pattern35',
  },
  {
    Building: 'Block A',
    Floor: 'Level 02',
    Hub: 'Hub36',
    Id: 36,
    Name: 'Door 36',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern36',
  },
  {
    Building: 'BuildingB',
    Floor: 'Ground Floor',
    Hub: 'Hub37',
    Id: 37,
    Name: 'Door 37',
    Status: LockStatus.Normal,
    TimePattern: 'Pattern37',
  },
];

export const testUserDetails: UserTableRecord[] = [
  {
    userId: 1000002,
    name: "Contractor 1",
    department: "NSP",
    group: "24/7",
    globalId: 1,
    cardId: 1,
    cardInUse: CardStatus.Overwritten
  },
  {
    userId: 1000003,
    name: "Contractor 2",
    department: "NSP",
    group: "24/7",
    globalId: 2,
    cardId: 2,
    cardInUse: CardStatus.Lost
  },
  {
    userId: 1000004,
    name: "Contractor 3",
    department: "NSP",
    group: "24/7",
    globalId: 3,
    cardId: 3,
    cardInUse: CardStatus.Expired
  },
  {
    userId: 1000005,
    name: "Contractor 4",
    department: "NSP",
    group: "24/7",
    globalId: 4,
    cardId: 4,
    //cardInUse: CardStatus.Expired
  },
  {
    userId: 1000006,
    name: "Contractor 5",
    department: "NSP",
    group: "24/7",
    globalId: 5,
    cardId: 5,
    cardInUse: CardStatus.InUse
  },
  {
    userId: 1000007,
    name: "Contractor 6",
    department: "NSP",
    group: "24/7",
    globalId: 6,
    cardId: 6,
    cardInUse: CardStatus.Lost
  },
  {
    userId: 1000008,
    name: "Contractor 7",
    department: "NSP",
    group: "24/7",
    globalId: 7,
    cardId: 7,
    cardInUse: CardStatus.Erased
  },
  {
    userId: 1000009,
    name: "Contractor 8",
    department: "NSP",
    group: "24/7",
    globalId: 8,
    cardId: 8,
    cardInUse: CardStatus.Deleted
  },
  {
    userId: 1000010,
    name: "Contractor 9",
    department: "NSP",
    group: "24/7",
    globalId: 9,
    cardId: 9,
    cardInUse: CardStatus.PendingUpdate
  },
  {
    userId: 1000011,
    name: "Contractor 10",
    department: "NSP",
    group: "24/7",
    globalId: 10,
    cardId: 10,
    cardInUse: CardStatus.InUse
  },
  {
    userId: 1000012,
    name: "Contractor 11",
    department: "NSP",
    group: "24/7",
    globalId: 11,
    cardId: 11,
    cardInUse: CardStatus.Lost
  },
  {
    userId: 1000013,
    name: "Contractor 12",
    department: "NSP",
    group: "24/7",
    globalId: 12,
    cardId: 12,
    cardInUse: CardStatus.Erased
  },
  {
    userId: 1000014,
    name: "Contractor 13",
    department: "NSP",
    group: "24/7",
    globalId: 13,
    cardId: 13,
    cardInUse: CardStatus.Deleted
  },
  {
    userId: 1000015,
    name: "Contractor 14",
    department: "NSP",
    group: "24/7",
    globalId: 14,
    cardId: 14,
    cardInUse: CardStatus.PendingUpdate
  },
  {
    userId: 1000016,
    name: "Contractor 15",
    department: "NSP",
    group: "24/7",
    globalId: 15,
    cardId: 15,
    cardInUse: CardStatus.Lost
  },
  {
    userId: 1000017,
    name: "Contractor 16",
    department: "NSP",
    group: "24/7",
    globalId: 16,
    cardId: 16,
    cardInUse: CardStatus.Erased
  },
  {
    userId: 1000018,
    name: "Contractor 17",
    department: "NSP",
    group: "24/7",
    globalId: 17,
    cardId: 17,
    cardInUse: CardStatus.Deleted
  },
  {
    userId: 1000019,
    name: "Contractor 18",
    department: "NSP",
    group: "24/7",
    globalId: 18,
    cardId: 18,
    cardInUse: CardStatus.PendingUpdate
  },
];

export const testUsers: UserModel[] = [
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    CardID: 1,
    DoorGroups: null,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami1@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 1",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000002",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "1",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Overwritten,
      Expiry: "",
    },
    DateOfBirth: "1980.01.01",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    CardID: 2,
    DoorGroups: null,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami2@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 2,
    Name: "Contractor 2",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000003",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "2",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Lost,
      Expiry: "",
    },
    DateOfBirth: "1981.02.02",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    CardID: 3,
    DoorGroups: null,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami3@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 3,
    Name: "Contractor 3",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000004",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "3",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Expired,
      Expiry: "",
    },
    DateOfBirth: "1983.03.03",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami4@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 4",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000005",
    WorkStatus: 0,
    WorkType: "",
    Card: null,
    DateOfBirth: "1984.04.04",
    Postion: "asd",
    Nationality: "HUN",
    CardID: null,
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    CardID: 4,
    DoorGroups: null,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami5@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 5",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000006",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "4",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.InUse,
      Expiry: "",
    },
    DateOfBirth: "1985.05.05",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 5,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami6@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 6",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000007",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "5",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Lost,
      Expiry: "",
    },
    DateOfBirth: "1986.06.06",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 6,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami7@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 7",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000008",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "6",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Erased,
      Expiry: "",
    },
    DateOfBirth: "1987.07.07",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 7,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami8@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 8",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000009",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "7",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Deleted,
      Expiry: "",
    },
    DateOfBirth: "1988.08.08",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 8,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami9@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 9",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000010",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "8",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.PendingUpdate,
      Expiry: "",
    },
    DateOfBirth: "1989.09.09",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 9,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami10@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 10",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000011",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "9",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.InUse,
      Expiry: "",
    },
    DateOfBirth: "1990.10.10",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 10,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami11@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 11",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000012",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "10",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Lost,
      Expiry: "",
    },
    DateOfBirth: "1991.11.11",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 11,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami12@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 12",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000013",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "11",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Erased,
      Expiry: "",
    },
    DateOfBirth: "1992.12.12",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 12,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami13@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 13",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000014",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "12",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Deleted,
      Expiry: "",
    },
    DateOfBirth: "1993.11.16",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 13,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami14@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 14",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000015",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "13",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.PendingUpdate,
      Expiry: "",
    },
    DateOfBirth: "1994.04.23",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 14,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami15@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 15",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000016",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "14",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Lost,
      Expiry: "",
    },
    DateOfBirth: "1995.07.30",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 15,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami16@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 16",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000017",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "15",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Erased,
      Expiry: "",
    },
    DateOfBirth: "1996.06.06",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 16,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami17@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 17",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000018",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "16",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.Deleted,
      Expiry: "",
    },
    DateOfBirth: "1997.11.21",
    Postion: "asd",
    Nationality: "HUN",
  },
  {
    BleMobileNumber: null,
    BleUserName: null,
    BleUserState: 0,
    BleUserType: 0,
    DoorGroups: null,
    CardID: 17,
    Department: {
      DepartmentName: "NSP",
      Id: 1,
    },
    DepartmentID: 1,
    Email: "valami18@asd.hu",
    Group: {
      GroupName: "24/7",
      Id: 1,
    },
    GroupID: 1,
    Id: 1,
    Name: "Contractor 18",
    PermissionGroup: null,
    PermissionGroupId: null,
    Telphone: "",
    TypeOfUser: 0,
    UserNumber: "1000019",
    WorkStatus: 0,
    WorkType: "",
    Card: {
      CardID: "17",
      CardType: CardTypes.FloorCard,
      CardStatus: CardStatus.PendingUpdate,
      Expiry: "",
    },
    DateOfBirth: "1998.03.18",
    Postion: "asd",
    Nationality: "HUN",
  },
];

export const testRooms: TestRoomData[] = [
  {
    roomName: "Room Name 1",
    roomNo: 1,
    roomId: 17
  },
  {
    roomName: "Room Name 2",
    roomNo: 1222,
    roomId: 18
  },
  {
    roomName: "Room Name 3",
    roomNo: 3,
    roomId: 19
  },
  {
    roomName: "Room Name 4",
    roomNo: 4,
    roomId: 20
  },
  {
    roomName: "Room Name 5",
    roomNo: 5,
    roomId: 21
  },
  {
    roomName: "Room Name 6",
    roomNo: 6,
    roomId: 22
  },
  {
    roomName: "Room Name 7",
    roomNo: 7,
    roomId: 23
  },
  {
    roomName: "Room Name 1",
    roomNo: 1,
    roomId: 17
  },
  {
    roomName: "Room Name 2",
    roomNo: 1222,
    roomId: 18
  },
  {
    roomName: "Room Name 3",
    roomNo: 3,
    roomId: 19
  },
  {
    roomName: "Room Name 4",
    roomNo: 4,
    roomId: 20
  },
  {
    roomName: "Room Name 5",
    roomNo: 5,
    roomId: 21
  },
  {
    roomName: "Room Name 6",
    roomNo: 6,
    roomId: 22
  },
  {
    roomName: "Room Name 7",
    roomNo: 7,
    roomId: 23
  },
];

export const testGroups: TestGroupData[] = [
  {
    groupName: "001 Ground Floor",
    doors: 15
  },
  {
    groupName: "002 First Floor",
    doors: 10
  },
];
import { CardToolTipViewOptions, DialogToOpen, PageNavigation } from 'model/enums';
import React from 'react';
import styled from 'styled-components';
import threeDots from 'media/three-dots.svg';
import UserTableRecord from 'model/UserTableRecord';
import { IconButton, Menu } from '@mui/material';
import CreateCardAction from './cardActions/CreateCardAction';
import CardItemTemplate from './cardActions/CardItemTemplate';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { getCardToolTipsByCardStatus } from 'constants/cardIcons';

type Props = {
    userTableRecord: UserTableRecord;
    setOptionsOpen: (optionsOpen: boolean) => void;
    optionsOpen: boolean;
    setDialogToOpen: (dialog?: DialogToOpen) => void;
    pageNavigation: PageNavigation;
};

const TableRowSettings = ({
    userTableRecord, 
    setOptionsOpen, 
    optionsOpen, 
    setDialogToOpen,
    pageNavigation,
}: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOptionsOpen(!optionsOpen);
    };

    const handleClose = () => setAnchorEl(null);

    const handleSelectedIcon = (selectedIcon?: CardToolTipViewOptions) => {
        setDialogToOpen(ConversionsUtil.cardToolTipToDialogToOpen(selectedIcon));
        handleClose();
    }

    return (
        <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
                <StyledButton src={threeDots} alt="Options"/>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20vh',
                }
              }}
              disableScrollLock={true}
            >
                { getCardToolTipsByCardStatus(userTableRecord.cardInUse, pageNavigation, false).map((value, key) => (
                    <div key={key}>
                        { value === CardToolTipViewOptions.CreateCard ? 
                            <CreateCardAction
                                ITEM_HEIGHT={ITEM_HEIGHT}
                                setAnchorEl={setAnchorEl}
                                setDialogToOpen={setDialogToOpen}
                            />
                        : 
                            <CardItemTemplate
                                setOptionsOpen={setOptionsOpen}
                                handleOnClick={handleSelectedIcon}
                                iconToolTipTitle={value}
                                optionsOpen={optionsOpen}
                            />  
                        }
                    </div>
                ))}
            </Menu>
        </div>
    );
};

export default TableRowSettings;

const StyledButton = styled.img`
  cursor: pointer;
`;
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import moment, { Moment } from 'moment';
import 'react-dates/initialize';
import { TimePicker as MUITimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker as DatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'style/DatePicker_overrides.css';
import styled from 'styled-components';
import calenarImg from 'media/Calendar.png';
import {
  black,
  darkGreyTransparent,
  datePickerGrey,
  lighterGrey,
  white,
} from 'constants/theme';

/*
  This component wraps AirBnB's DateRangePicker and MUI's DatePicker
  to create a single component that can handle start and end date
  and time in one component.
  -
  Some elements were hidden with css and some functionalities where
  overridden so the different components works as one.
  Hints: toggleSelector, onFocusChange, onDatesChange
  -
  Style customization is possible with overriding the CSS provided in
  "import './DatePicker_custom_css.css';"
  You can look for elements and their applied styles in the browser inspector.
*/

type Props = {
  todayStart: Dayjs;
  todayEnd: Dayjs;
  setDateRangeStart: Dispatch<SetStateAction<Date>>;
  setDateRangeEnd: Dispatch<SetStateAction<Date>>;
};

export default function DateRangePicker({todayStart, todayEnd, setDateRangeStart, setDateRangeEnd}: Props) {
  const [opened, setOpened] = useState<boolean>(false);
  const [startDateMoment, setStartDateMoment] = useState<Moment>(moment());
  const [endDateMoment, setEndDateMoment] = useState<Moment>(moment());
  const [startTime, setStartTime] = useState<Dayjs | null>(todayStart);
  const [endTime, setEndTime] = useState<Dayjs | null>(todayEnd);
  const [focusedInput, setFocusedInput] = useState<
    null | 'startDate' | 'endDate'
  >(null);
  const [readableTimeSetting, setReadableTimeSetting] = useState('Today');
  /* TODO: dates and times are in seperate states.
    dates are in moment.js and times are in days.js
    createRangeString already handles formatting
  */

  useEffect(() => {
    const actualStartDate = new Date(startDateMoment?.year(), 
    startDateMoment?.month(), startDateMoment?.date(), startTime?.hour(), startTime?.minute(), startTime?.second())

    const actualEndDate = new Date(endDateMoment?.year(), 
    endDateMoment?.month(), endDateMoment?.date(), endTime?.hour(), endTime?.minute(), endTime?.second())

    setDateRangeStart(actualStartDate);
    setDateRangeEnd(actualEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateMoment, startTime, endDateMoment, endTime])
  
  useEffect(() => {
    const newString = createRangeString(
      startDateMoment,
      endDateMoment,
      startTime,
      endTime,
    );
    if (
      newString ===
      createRangeString(todayStart, todayEnd, todayStart, todayEnd)
    ) {
      setReadableTimeSetting('Today');
    } else if (
      newString ===
      createRangeString(
        todayStart.subtract(1, 'day'),
        todayEnd.subtract(1, 'day'),
        todayStart,
        todayEnd,
      )
    ) {
      setReadableTimeSetting('Yesterday');
    } else {
      setReadableTimeSetting(newString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateMoment, endDateMoment, startTime, endTime]);

  const toggleSelector = () => {
    setFocusedInput('startDate');
    setOpened(!opened);
  };

  const createRangeString = (startDate, endDate, startTime, endTime) => {
    const startDateString = startDate?.format('DD/MM/YYYY');
    const endDateString = endDate?.format('DD/MM/YYYY');
    const startTimeString = startTime?.format('HH:mm');
    const endTimeString = endTime?.format('HH:mm');

    return `${startDateString} ${startTimeString} - ${endDateString} ${endTimeString}`;
  };

  return (
    <Container>
      {!opened && (
        <CalendarButton onClick={() => toggleSelector()}>
          <p>{readableTimeSetting}</p>
          <img src={calenarImg} alt="" />
        </CalendarButton>
      )}
      {opened && (
        <Selector>
          <DatePicker
            isOutsideRange={() => false}
            startDate={startDateMoment}
            startDateId="your_unique_start_date_id"
            endDate={endDateMoment}
            endDateId="your_unique_end_date_id"
            keepOpenOnDateSelect
            minimumNights={0}
            onClose={() => {}}
            onDatesChange={({ startDate, endDate }) => {
              if (startDate === startDateMoment) {
                setFocusedInput('startDate');
              }
              setStartDateMoment(startDate);
              setEndDateMoment(endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => {
              if (focusedInput === null) {
                if (
                  document.activeElement?.classList.contains(
                    'MuiInputBase-input',
                  ) ||
                  document.activeElement?.classList.contains(
                    'MuiButtonBase-root',
                  ) ||
                  document.activeElement?.classList.contains('MuiPaper-root') ||
                  document.activeElement?.classList.contains('MuiList-root')
                ) {
                  setFocusedInput('startDate');
                } else {
                  if (!startDateMoment) {
                    setFocusedInput('startDate');
                  } else {
                    if (!endDateMoment) {
                      setEndDateMoment(startDateMoment);
                    }
                    toggleSelector();
                  }
                }
              } else {
                setFocusedInput(focusedInput);
              }
            }}
          />
          <TimePicker>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <OwnHorizontalContainer>
                <p>Start Time</p>
                <MUITimePicker
                  ampm={false}
                  value={startTime}
                  onChange={(newValue) => setStartTime(newValue)}
                />
              </OwnHorizontalContainer>
              <OwnHorizontalContainer>
                <p>End Time</p>
                <MUITimePicker
                  ampm={false}
                  value={endTime}
                  onChange={(newValue) => setEndTime(newValue)}
                />
              </OwnHorizontalContainer>
            </LocalizationProvider>
          </TimePicker>
        </Selector>
      )}
    </Container>
  );
}

const CalendarButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 4px;

  min-width: 70px;
  height: 40px;
  background: ${white};
  border: 1px solid ${lighterGrey};
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Selector = styled.div`
  background-color: ${black};
  width: 620px;
  height: 380px;
  overflow: hidden;
  background-color: ${white};
  border: 1px solid ${datePickerGrey};
  z-index: 100;
  border-radius: 20px;
`;

const TimePicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const HorizontalContainer = styled.div`
  width: 50%;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: space-evenly;
`;
const OwnHorizontalContainer = styled(HorizontalContainer)`
  .MuiOutlinedInput-notchedOutline {
    border: 0px solid ${white} !important;
    background: ${darkGreyTransparent};
    border-radius: 6px;
    width: 50px;
    padding: 3px 3px !important;
  }

  .MuiInputBase-input {
    padding: 5px 5px !important;
    width: 50px !important;
  }
`;

import * as React from 'react';
import styled from 'styled-components';
import { Dialog, DialogTitle, LinearProgress } from '@mui/material';
import { black, blue, datePickerGrey, dialogMenuOptionBorderGray, listItemLightGray, white } from 'constants/theme';
import { DialogToOpen, ReissueCardDialogFieldName, SignalrResponseStatuses } from 'model/enums';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import ReissueCardData from 'model/ReissueCardData';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import { StyledContentRowHolder, StyledUserDialogContent } from './userCardDialog/UserDialogCommonStyle';
import SingleDatePicker from 'components/controls/datePicker/SingleDatePicker';
import StyledButton from 'components/controls/button/StyledButton';
import { reissueCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import SingleTimePicker from 'components/controls/datePicker/SingleTimePicker';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import { DatesUtil } from 'helper/DatesUtil';
import { useSelector } from 'react-redux';
import { notifyError } from 'helper/NotificationService';
import { selectEncoderStorage, selectIsSignalrResponseStatusPending, selectSelectedEncoder } from 'redux/selectors/encoderSelector';
import { notifyErrorEncoderIsNotSelected } from 'helper/EncoderUtils';
import { useDispatch } from 'react-redux';
import { selectUserCardModel } from 'redux/selectors/cardModelSelector';
import { selectOpenedDialog, selectOperatorId } from 'redux/selectors/userSelector';
import { missingOperatorNotification } from 'hooks/useOperatorInit';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { handleEncoderNotRespondedYetError, ignoreSignalrResponseStatus, setEncoderRequestToActive, setEncoderRequestToInactive } from 'hooks/useSignalrEncoder';

const ReissueCardDialog = () => {
    const { isAwaitsEncoderResponse, signalrResponseStatus } = useSelector(selectEncoderStorage);
    const operatorId = useSelector(selectOperatorId);
    const dialogToOpen = useSelector(selectOpenedDialog);
    const selectedEncoder = useSelector(selectSelectedEncoder);
    const cardModelToDisplay = useSelector(selectUserCardModel);
    const isSignalrResponseStatusPending = useSelector(selectIsSignalrResponseStatusPending);
    const [isLimitedDateChecked, setIsLimitedDateChecked] = React.useState<boolean>(false);
    const [isUnlimitedDateChecked, setIsUnlimitedDateChecked] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [reissueCardData, setReissueCardData] = React.useState<ReissueCardData | null>(null);
    const [cardCreatedDate, setCardCreatedDate] = React.useState<dayjs.Dayjs | null>();
    const actualSite = ActualSiteLocator();
    const CARD_DATE = cardCreatedDate ? DatesUtil.convertDayjsToDateFormat(cardCreatedDate, DatesUtil.CUSTOM_DATE_FORMAT_REVERSED) : undefined;
    const CARD_TIME = cardCreatedDate ? DatesUtil.convertDayjsToDateFormat(cardCreatedDate, DatesUtil.CUSTOM_TIME_FORMAT) : undefined;
    const open = dialogToOpen === DialogToOpen.ReissueCardDialog;
    const dispatch = useDispatch();

    const handleSubmit = () => {
        handleClose();
        dispatch(setUsersIsRefreshUsersList(true));
    }
    
    const handleClose = () => {
        if (isSignalrResponseStatusPending) {
            handleEncoderNotRespondedYetError();
            return;
        }

        dispatch(setOpenedDialog());
        setEncoderRequestToInactive(dispatch);
        setErrorMessage(undefined);
        setIsLimitedDateChecked(reissueCardData?.expiryDateTime !== null);
        setIsUnlimitedDateChecked(false);
    }

    React.useEffect(() => {
        if (!open || ignoreSignalrResponseStatus.includes(signalrResponseStatus)) {
            return;
        }

        if (signalrResponseStatus !== SignalrResponseStatuses.Success) {
            setEncoderRequestToInactive(dispatch);
            return;
        }

        handleSubmit();
        handleClose();
    }, [open, signalrResponseStatus]);

    const setCreationDateInformation = () => {
        if (!cardModelToDisplay?.Id) {
            handleClose();
            return;
        }

        setCardCreatedDate(DatesUtil.convertFormatToDayjs(cardModelToDisplay.CreatedOn, DatesUtil.CUSTOM_DATE_TIME_FORMAT));
    }

    const setReissueCardInformation = () => {
        if (!cardModelToDisplay) {
            return;
        }

        const expiryDateTime = DatesUtil.convertFormatToDayjs(cardModelToDisplay.ExpiryDate, DatesUtil.CUSTOM_DATE_TIME_FORMAT)
        setReissueCardData({
            cardId: cardModelToDisplay.Id ?? 'No Card ID',
            cardType: cardModelToDisplay.Type ?? 'No Card Type',
            expiryDateTime: expiryDateTime,
        });
    }

    React.useEffect(() => {
        if (open) {
            setCreationDateInformation();
            setReissueCardInformation();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    React.useEffect(() => {
        if (open) {
            setIsLimitedDateChecked(reissueCardData?.expiryDateTime !== null);
            setIsUnlimitedDateChecked(reissueCardData?.expiryDateTime === null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reissueCardData])

    const handleLimitedDateOnCheck = () => {
        setIsLimitedDateChecked((prev) => !prev);
        setIsUnlimitedDateChecked(false);
        setErrorMessage(undefined);
    }

    const handleUnlimitedDateOnCheck = () => {
        if (!isUnlimitedDateChecked) {
            setIsLimitedDateChecked(false);
            setErrorMessage(undefined);
        };
        setIsUnlimitedDateChecked((prev) => !prev);
    }

    const handleSubmitOnClick = async () => {
        if (!operatorId) {
            missingOperatorNotification();
            return;
        }

        if (!isUnlimitedDateChecked && !isLimitedDateChecked) {
            notifyError("Either the Limited Date or the Unlimited Date must be checked!", "");
            return;
        }

        if (!selectedEncoder) {
            notifyErrorEncoderIsNotSelected();
            return;
        }

        const formattedExpiryDate = reissueCardData?.expiryDateTime ? reissueCardData.expiryDateTime.format(DatesUtil.CUSTOM_DATE_FORMAT_REVERSED) : null;
        const formattedExpiryTime = reissueCardData?.expiryDateTime ? reissueCardData.expiryDateTime.format(DatesUtil.CUSTOM_TIME_FORMAT) : null;
        if (!isUnlimitedDateChecked
            && formattedExpiryDate !== null
            && formattedExpiryTime!== null
            && (reissueCardData?.expiryDateTime?.isBefore(dayjs()) || !reissueCardData?.expiryDateTime?.isValid())
        ) {
            setErrorMessage("The Limited Date must be in the future!");
            return;
        }

        setErrorMessage(undefined);
        const expiryDate = reissueCardData?.expiryDateTime && !isUnlimitedDateChecked
            ? reissueCardData?.expiryDateTime.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
            : "";

        const response = await reissueCard(actualSite, operatorId, selectedEncoder.Id, Number(reissueCardData?.cardId), expiryDate);
        
        if (!response) {
            return;
        }
        
        setEncoderRequestToActive(dispatch);
    }

    return (
        <StyledDialog
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          PaperProps={{ component: 'form' }}
        >
            <CustomUserDialogContent
                height={500}
                width={536}
            >
                <StyledDialogTitle>Reissue Card</StyledDialogTitle>
                <StyledContentRowHolder>
                    <StyledMessageHolder>
                        <div>
                            Please, place your card on the encoder
                        </div>
                        <div>
                            after pressing the
                            <StyledBoldMessageHolder> `Reissue Card` </StyledBoldMessageHolder>
                            button to perform the action.
                        </div>
                    </StyledMessageHolder>
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                    <MultiTypeInputField
                        inputFieldHeight={"40px"}
                        inputFieldWidth={"536px"}
                        displayedName={ReissueCardDialogFieldName.CardType}
                        inputValue={reissueCardData?.cardType}
                        inputType='string'
                        isDisabled={true}
                    />
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                    <StyledCardCreationDateHolder>
                        <StyledBoldContent width={"200px"}>
                            {ReissueCardDialogFieldName.StartDate}
                        </StyledBoldContent>
                        <StyledContentWrapper width='336px'>
                            <StyledContent>
                                {CARD_DATE}
                            </StyledContent>
                            <StyledContent>
                                {CARD_TIME}
                            </StyledContent>
                        </StyledContentWrapper>
                    </StyledCardCreationDateHolder>
                </StyledContentRowHolder>
                <StyledDivider />
                <StyledContentRowHolder>
                    <StyledCheckBoxHolder>
                        <CheckboxField
                            boxWidth={"200px"}
                            boxHeight={"40px"}
                            displayedName={ReissueCardDialogFieldName.LimitedDate}
                            setValue={handleLimitedDateOnCheck}
                            inputValue={isLimitedDateChecked}
                        />
                    </StyledCheckBoxHolder>
                    <StyledDateHolder>
                        <SingleDatePicker 
                            inputFieldWidth={"160px"}
                            inputFieldHeight={"40px"}
                            setValue={(expiryDateTime) => {
                                if (reissueCardData) setReissueCardData({...reissueCardData, expiryDateTime})
                            }}
                            inputValue={reissueCardData?.expiryDateTime}
                            hasLabel={false}
                            isDisabled={!isLimitedDateChecked}
                            invalidFormMessage={errorMessage}
                        />
                        <StyledDateTimePickerContainer $isError={!!errorMessage}>
                            <SingleTimePicker
                                inputFieldWidth={"160px"}
                                inputFieldHeight={"40px"}
                                setValue={(expiryDateTime) => {
                                    if (reissueCardData) setReissueCardData({...reissueCardData, expiryDateTime})
                                }}
                                inputValue={reissueCardData?.expiryDateTime}
                                hasLabel={false}
                                isDisabled={!isLimitedDateChecked}
                            />
                        </StyledDateTimePickerContainer>
                    </StyledDateHolder>
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                    <StyledCheckBoxHolder>
                        <CheckboxField
                            boxWidth={"220px"}
                            boxHeight={"40px"}
                            displayedName={ReissueCardDialogFieldName.UnlimitedDate}
                            setValue={handleUnlimitedDateOnCheck}
                            inputValue={isUnlimitedDateChecked}
                        />
                    </StyledCheckBoxHolder>
                </StyledContentRowHolder>
                <CustomContentRowHolder>
                    <ButtonContainer>
                        <StyledButton
                            buttonHeight={37}
                            buttonWidth={95}
                            displayedName={"Cancel"}
                            handleOnClick={handleClose}
                            backgroundColor={white}
                        />
                        <StyledProgressBarAndButtonHolder>
                            <StyledButton
                                handleOnClick={handleSubmitOnClick}
                                displayedName={"Reissue Card"}
                                buttonHeight={37}
                                buttonWidth={137}
                                isFilledButton={true}
                                backgroundColor={blue}
                                style={{ marginRight: '0' }}
                            />
                            { isAwaitsEncoderResponse ? <StyledLinearProgressBar /> : null}
                        </StyledProgressBarAndButtonHolder>
                    </ButtonContainer>
                </CustomContentRowHolder>
            </CustomUserDialogContent>
        </StyledDialog>
    );
};

export default ReissueCardDialog;

const StyledCheckBoxHolder = styled.div` padding-top: 16px `;

const StyledContent = styled.div<{ width?: string }>((props) => ({ width: props.width ?? "160px"}));

const StyledBoldContent = styled(StyledContent)((props) => ({ fontWeight: 'bold' }))

const StyledContentWrapper = styled.div<{ width: string }>((props) => ({
    width: props.width,
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledDateTimePickerContainer = styled.div<{ $isError?: boolean }>((props) => ({ paddingBottom: props.$isError ? "16px" : "0px" }));

const StyledCardCreationDateHolder = styled.div`
    padding-top: 32px;
    display: flex;
    height: 40px;
    font-weight: 400 !important;
    line-height: 24.35px !important;
`;

export const StyledDivider = styled(Divider)`
    height: 16px;
    margin-bottom: 16px;
    border-color: ${dialogMenuOptionBorderGray};
`;

const StyledDateHolder = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const StyledDialog = styled(Dialog)((props) => ({
    justifyContent: "center",
    "form" : {
        width: "600px",
        borderRadius: "20px",
        border: `1px solid ${datePickerGrey}`,
        backgroundColor: `${white}`,
        overflowX: 'hidden',
    }
}));

const CustomUserDialogContent = styled(StyledUserDialogContent)((props) => ({
    display: 'flex', 
    flexDirection: 'column', 
    margin: 'auto'
}));

const StyledProgressBarAndButtonHolder = styled.div((props) => ({
    display: 'grid', 
    gap: '2px'
}));

const StyledLinearProgressBar = styled(LinearProgress)((props) => ({ borderRadius: '5px'}))

const CustomContentRowHolder = styled(StyledContentRowHolder)((props) => ({
    display: "flex", 
    paddingTop: "16px", 
    justifyContent: "flex-end",
}));

const StyledMessageHolder = styled.div((props) => ({
    color: listItemLightGray, 
    display: "flex", 
    justifyContent: "center", 
    flexWrap: "wrap", 
    paddingBottom: "24px"
}));

const StyledBoldMessageHolder = styled.span((props) => ({
    color: black, 
    fontWeight: "bold",
}));

const StyledDialogTitle = styled(DialogTitle)`
    padding: 32px 24px 16px 24px;
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 16px;
`;
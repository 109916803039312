import * as React from 'react';
import { StyledDialogContentHolder } from '../userCardDialog/UserCardDialog';
import { stopPropagationForTab } from 'helper/DialogUtils';
import DialogActionButtons from './cardComponents/DialogActionButtons';
import { useSelector } from 'react-redux';
import { setCardDialogIsDialogCloseTriggered, setCardDialogIsValidationOpen } from 'redux/actions/cardDialogActions';
import { useDispatch } from 'react-redux';
import { selectCardDialogIsOpen } from 'redux/selectors/cardDialogSelector';
import { dialogContainer } from 'constants/globalStyle';
import Dialog from '@mui/material/Dialog';
import { notifyInfo } from 'helper/NotificationService';
import styled from 'styled-components';
import { CardDialogMenuOptions } from './cardComponents/CardDialogMenuOptions';
import { CardDialogMenuPage } from './cardComponents/CardDialogMenuPage';
import { CardDialogTitleAndBoxText } from './cardComponents/CardDialogTitleAndBoxText';
import { CardDialogBackgroundProcess } from './cardComponents/CardDialogBackgroundProcess';
import { CardDialogConfirmationDialog } from './cardComponents/CardDialogConfirmationDialog';

const CardDialog = () => {

    const isDialogOpen = useSelector(selectCardDialogIsOpen);
    const dispatch = useDispatch();

    const handleDeleteDialogClose = () => {
        notifyInfo("Card deletion will be implemented later!", "");
    }

    const handleClickOnClose = () => dispatch(setCardDialogIsDialogCloseTriggered(true));

    return (
    <>
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            onKeyDown={stopPropagationForTab}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
                <CardDialogTitleAndBoxText />
                <StyledDialogContentHolder>
                    <CardDialogMenuOptions />
                    <CardDialogMenuPage />
                </StyledDialogContentHolder>
                <DialogActionButtons 
                    displayButtonName={"Card"}
                    isDeleteAllowed={false}
                    handleClickOnClose={handleClickOnClose}
                    setSubmitClicked={() => dispatch(setCardDialogIsValidationOpen(true))}
                    setIsDeleteDialogOpen={() => handleDeleteDialogClose()}
                />
            </StyledDialogCardHolder>
            <CardDialogConfirmationDialog/>
            <CardDialogBackgroundProcess />
        </Dialog>
    </>
    );
};

export default CardDialog;

const StyledDialogCardHolder = styled.div(() => ({
    height: "800px",
    width: '1000px',
}));

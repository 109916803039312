import { Message } from "model/event"
import EventActionTypes from "redux/constants/eventActionTypes"

export const addEventToStore = (messageObject: Message, siteName: string) => {
    return {
        type: EventActionTypes.ADD_EVENT,
        payload: {
            siteName: siteName,
            messages: {
                CardNo: messageObject.CardNo,
                Description: messageObject.Description,
                EventDate: messageObject.EventDate,
                EventId: messageObject.EventId,
                RoomId: messageObject.RoomId,
            }
        }
    }
}
import React from 'react';
import { EventProps } from 'model/event';
import { veryLightGrey, white } from 'constants/theme';
import styled from 'styled-components';

function Event({
  EventDate,
  EventId,
  RoomId,
  CardNo,
  Description,
}: EventProps) {
  return (
    <TableRow>
      <TableColumn>{EventDate}</TableColumn>
      <TableColumn>{EventId}</TableColumn>
      <TableColumn>{RoomId}</TableColumn>
      <TableColumn>{CardNo}</TableColumn>
      <TableColumn>{Description}</TableColumn>
    </TableRow>
  );
}

export default Event;

const TableRow = styled.tr`
  background-color: ${veryLightGrey};
  padding: 20px 0px;
  &:nth-child(odd) td {
    background-color: ${white};
  }
`;

const TableColumn = styled.td`
  padding: 18px 0px;
  padding-left: 20px;
  font-weight: 300;
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

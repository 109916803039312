import React from 'react';
import styled from 'styled-components';
import UserTableRecord from 'model/UserTableRecord';
import { getCardImageByCardStatus, getCardTextByCardStatus, getColorByCardStatus } from 'constants/cardIcons';

type Props = {
    userTableRecord: UserTableRecord;
};

const CardInUseColumnCard = ({userTableRecord}: Props) => {

    return (
        <CardInUseColumnContent>
            <CardStatusDisplay color={getColorByCardStatus(userTableRecord.cardInUse)}>
                <img src={getCardImageByCardStatus(userTableRecord.cardInUse)} alt="Card status"/>
                {getCardTextByCardStatus(userTableRecord.cardInUse)}
            </CardStatusDisplay>
        </CardInUseColumnContent>
    );
};

export default CardInUseColumnCard;

const CardInUseColumnContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CardStatusDisplay = styled.div<{ color?: string }>((props) => ({
    color: `${props.color}`,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
}));
import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { red } from 'constants/theme';
import { useSelector } from 'react-redux';
import { DialogToOpen, EraseCardDialogTexts, SignalrResponseStatuses } from 'model/enums';
import { useDispatch } from 'react-redux';
import { eraseCardDialogConfirmationAction } from 'constants/dialogTexts';
import { eraseCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { selectEncoderStorage, selectIsSignalrResponseStatusPending } from 'redux/selectors/encoderSelector';
import { selectOpenedDialog, selectOperatorId, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { notifyError } from 'helper/NotificationService';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { missingOperatorNotification } from 'hooks/useOperatorInit';
import { handleEncoderNotRespondedYetError, setEncoderRequestToActive, setEncoderRequestToInactive } from 'hooks/useSignalrEncoder';
import { notifyErrorEncoderIsNotSelected } from 'helper/EncoderUtils';

const EraseCardConfActionDialog = () => {

    const openedDialog = useSelector(selectOpenedDialog);
    const isOpen = openedDialog === DialogToOpen.EraseCardConfirmationActionDialog;
    const { signalrResponseStatus, selectedEncoder } = useSelector(selectEncoderStorage);
    const selectedUserRow = useSelector(selectSelectedUserRow);
    const operatorId = useSelector(selectOperatorId);
    const isSignalrResponseStatusPending = useSelector(selectIsSignalrResponseStatusPending);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isOpen || signalrResponseStatus !== SignalrResponseStatuses.Success) {
            return;
        }

        dispatch(setUsersIsRefreshUsersList(true));
        handleDialogClose();
    }, [isOpen, signalrResponseStatus])

    const handleDialogClose = () => {
        if (isSignalrResponseStatusPending) {
            handleEncoderNotRespondedYetError();
            return;
        }

        dispatch(setOpenedDialog());
        setEncoderRequestToInactive(dispatch);
    }  
    

    const handleEraseCardSubmit = async () => {
        if (!operatorId) {
            missingOperatorNotification();
            return;
        }

        if (!selectedUserRow?.cardId) {
            notifyError("The user does not possess a card to erase!", "");
            return;
        }

        if (!selectedEncoder) {
            notifyErrorEncoderIsNotSelected();
            return;
        }

        const cardResponse = await eraseCard(actualSite, operatorId, selectedUserRow.cardId, selectedEncoder);

        if (!cardResponse) {
            return;
        }

        setEncoderRequestToActive(dispatch);
    }

    return (
        <DialogTemplate
            handleSubmit={handleEraseCardSubmit}
            handleClose={handleDialogClose}
            open={isOpen}
            dialogText={eraseCardDialogConfirmationAction}
            submitButtonColor={red}
            dialogId={EraseCardDialogTexts.EraseCardTitle}
        />
    );
}

export default EraseCardConfActionDialog;
import { CustomColData } from 'model/CustomColData';
import { listItemLightGray } from './theme';
import { formatNumber } from 'helper/FormatHelper';

export const doorsColData: CustomColData[] = [
  {
    header: 'Door Name',
    color: listItemLightGray,
    key: 'Name',
  },
  {
    header: 'Building',
    color: listItemLightGray,
    key: 'BuildingNo',
  },
  {
    header: 'Floor',
    color: listItemLightGray,
    key: 'FloorNo',
  },
  {
    header: 'Lock Number',
    color: listItemLightGray,
    key: 'Id',
  },
  {
    header: 'Lock Type',
    color: listItemLightGray,
    key: 'RoomType',
  },
];

export const roomsColData: CustomColData[] = [
  {
    header: 'Room Name',
    key: 'Name',
  },
  {
    header: 'Room Number',
    color: listItemLightGray,
    key: 'RoomNo',
    dataFormatter: (value: number) => formatNumber(value, 3),
  },
  {
    header: 'Room ID',
    color: listItemLightGray,
    key: 'Id',
  },
];

export const groupsColData: CustomColData[] = [
  {
    header: 'Group Name',
    key: 'groupName',
  },
  {
    header: 'Doors',
    key: 'doors',
    align: 'right',
  },
];

export const addRoomsColData: CustomColData[] = [
  {
      header: "Door Name",
      key: "doorName",
      headerWeight: "bold",
      color: listItemLightGray,
  },
  {
      header: "Lock Type",
      key: "lockType",
      headerWeight: "bold",
      color: listItemLightGray,
  },
  {
      header: "Building",
      key: "buildingNo",
      headerWeight: "bold",
      color: listItemLightGray,
  },
  {
      header: "Floor",
      key: "floorNo",
      headerWeight: "bold",
      color: listItemLightGray,
  },
  {
      header: "Lock Number",
      key: "LockNo",
      headerWeight: "bold",
      color: listItemLightGray,
  },
  {
      header: "Master Card Level",
      key: "masterCardLevel",
      headerWeight: "bold",
      color: listItemLightGray,
  },
];

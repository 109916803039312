import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { DialogToOpen, UserDialogPages } from 'model/enums';
import UserImage from './contentLeftSide/UserImage';
import MenuOptionDialog from './contentLeftSide/MenuOptionDialog';
import UserInformationPage from './contentRightSide/UserInformationPage';
import UserAdditionalInformationPage from './contentRightSide/UserAdditionalInformationPage';
import UserAccessPage from './contentRightSide/UserAccessPage';
import UserBluetoothPage from './contentRightSide/UserBluetoothPage';
import UserTableRecord from 'model/UserTableRecord';
import ConfirmationDialog from '../ConfirmationDialog';
import ValidateUserForm from './ValidateUserForm';
import { AccessError, AdditionalInformationError, BluetoothError, InformationError } from 'model/UserDialogFormError';
import DeleteDialog from '../DeleteDialog';
import { isInfoCircleDisplayed, UserForm, UserFormError } from './UserDialogConsts';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsBleUserLoading, selectUser } from 'redux/selectors/userSelector';
import { customScrollBarCSSProperties, dialogContainer } from 'constants/globalStyle';
import { PhonesUtil } from 'helper/PhonesUtil';
import { PHONE_COUNTRY_CODE_LIST } from 'constants/arrays';
import { selectMatchedDoorGroups } from 'redux/selectors/doorGroupSelector';
import { clearBuildings, fetchAllBuildings } from 'redux/actions/buildingActions';
import { fetchAllDoorGroups } from 'redux/actions/doorGroupActions';
import { clearRooms, fetchAllRooms } from 'redux/actions/roomActions';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { isNotFilledOutAnything, stopPropagationForTab } from 'helper/DialogUtils';
import DialogActionButtons from '../cardDialog/cardComponents/DialogActionButtons';
import { notifyInfo } from 'helper/NotificationService';
import { useUserFormBuilder } from 'hooks/useFormBuilder';
import { setUserDialogForm, setUserDialogFormError, setUserDialogPage, setUserDialogTableRecord } from 'redux/actions/userDialogActions';
import { selectUserDialogStorage } from 'redux/selectors/userDialogSelector';

type Props = {
    selectedUserTableRecordRow?: UserTableRecord;
    openAddUserDialog: boolean;
    setOpenAddUserDialog: (setOpenAddUserDialog: boolean) => void;
    setRefreshUserList: () => void;
    dialogToOpen: DialogToOpen | undefined;
};

const UserCardDialog = ({
    selectedUserTableRecordRow,
    openAddUserDialog,
    setOpenAddUserDialog,
    setRefreshUserList,
    dialogToOpen,
}: Props) => {

    const USER_DIALOG_MENU_OPTIONS = Object.values(UserDialogPages);
    const IS_USER_DIALOG_OPEN_AS_EDIT = dialogToOpen === DialogToOpen.EditUserDialog;
    const {selectedUserRecord, userDialogForm, selectedPageName, userDialogFormError} = useSelector(selectUserDialogStorage);
    const [isConfirmationDialogOpen, setIsConformationDialogOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [isSubmitClicked, setSubmitClicked] = React.useState<boolean>(false);
    const [isSaveButtonDisabled, setSaveButtonDisabled] = React.useState<boolean>(true);
    const selectedDoorGroups = useSelector(selectMatchedDoorGroups(userDialogForm?.accessForm?.doorGroups));
    const actualSite = ActualSiteLocator();
    const initUserForm = useUserFormBuilder();
    const dispatch = useDispatch();
    const IS_BLUETOOTH_PAGE_DISABLED = 
        (selectedDoorGroups.length === 0 || selectedDoorGroups.every(doorGroup => doorGroup.Rooms.length === 0))
            && userDialogForm.accessForm.userRooms.length === 0;
    
    const bleUserState = useSelector(selectUser(selectedUserRecord?.globalId))?.UserAccess?.BleUserState;
    const isBleUserLoading = useSelector(selectIsBleUserLoading);

    React.useEffect(() => {
        dispatch(setUserDialogTableRecord(IS_USER_DIALOG_OPEN_AS_EDIT ? selectedUserTableRecordRow : undefined))
    }, [IS_USER_DIALOG_OPEN_AS_EDIT, selectedUserTableRecordRow])

    React.useEffect(() => {
        dispatch(setUserDialogForm(initUserForm));
        dispatch(setUserDialogFormError(UserFormError.INIT_USER_DIALOG_FORM_ERROR))
        dispatch(setUserDialogPage(UserDialogPages.Information));
    }, [initUserForm]);

    React.useEffect(() => {
        if (!openAddUserDialog) {
            return
        }

        // Loading the Buildings and storing them in the redux storage.
        dispatch(clearBuildings());
        fetchAllBuildings(actualSite, dispatch);

        // Loading the Door Groups and storing them in the redux storage.
        fetchAllDoorGroups(actualSite, dispatch);

        // Loading the Rooms and storing them in the redux storage.
        dispatch(clearRooms());
        fetchAllRooms(actualSite, dispatch);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAddUserDialog]);

    React.useEffect(() => {
        if (!openAddUserDialog) {
            return;
        }

        setSaveButtonDisabled(isNotFilledOutAnything(userDialogForm, initUserForm));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDialogForm]);

    const handleClickOnClose = () => { isNotFilledOutAnything(userDialogForm, initUserForm) ? handleConfirmDialogSubmit() : setIsConformationDialogOpen(true); };

    const isOptionDisabled = (menuOption: string): boolean => {
        return (menuOption === UserDialogPages.Bluetooth && IS_BLUETOOTH_PAGE_DISABLED);
    }

    const handleOnSubmit = () => {
        setOpenAddUserDialog(false);
        dispatch(setUserDialogForm(UserForm.initEmptyUserForm()));
    }

    const handleConfirmDialogSubmit = () => {
        if (!isBleUserLoading) {
            handleOnSubmit();
            handleConfirmDialogClose();
        } else {
            handleWaitForBleServiceToResponse();
            handleConfirmDialogClose();
        }
    }

    const handleConfirmDialogClose = () => {
        setIsConformationDialogOpen(false);
    }

    const handleDeleteDialogSubmit = () => {
        if (!isBleUserLoading) {
            handleOnSubmit();
            setRefreshUserList();
            handleDeleteDialogClose();
        } else {
            handleWaitForBleServiceToResponse();
            handleDeleteDialogClose();
        }
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false);
    }

    const isThereAnyErrorMessageInForm = (form: InformationError | AdditionalInformationError | AccessError | BluetoothError) => {
        return Object.values(form).some(error => Boolean(error));
    }

    const isThereAnyErrorInTheSelectedPage = (): boolean => {
        const handler = Object.freeze({
            [UserDialogPages.Information]: () => isThereAnyErrorMessageInForm(userDialogFormError.informationError),
            [UserDialogPages.AdditionalInformation]: () => isThereAnyErrorMessageInForm(userDialogFormError.additionalInformationError),
            [UserDialogPages.Access]: () => isThereAnyErrorMessageInForm(userDialogFormError.accessError),
            [UserDialogPages.Bluetooth]: () => isThereAnyErrorMessageInForm(userDialogFormError.bluetoothError),
        });
        return handler[selectedPageName.toString()].call();
    }

    const setSelectedPageToPageWhereErrorIsDisplayed = () => {
        const pagesToCheck = [
            {
                error: selectedPageName !== UserDialogPages.Bluetooth ? userDialogFormError.bluetoothError : {},
                page: UserDialogPages.Bluetooth
            },
            {
                error: selectedPageName !== UserDialogPages.Access ? userDialogFormError.accessError : {},
                page: UserDialogPages.Access
            },
            {
                error: selectedPageName !== UserDialogPages.AdditionalInformation ? userDialogFormError.additionalInformationError : {},
                page: UserDialogPages.AdditionalInformation
            },
            {
                error: selectedPageName !== UserDialogPages.Information ? userDialogFormError.informationError : {},
                page: UserDialogPages.Information
            },
        ];
        pagesToCheck.forEach(pageToCheck => isThereAnyErrorMessageInForm(pageToCheck.error) 
            ? dispatch(setUserDialogPage(pageToCheck.page)) 
            : null);
    }

    React.useEffect(() => {
        if (!openAddUserDialog || isThereAnyErrorInTheSelectedPage()) {
            return;
        }

        setSelectedPageToPageWhereErrorIsDisplayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDialogFormError]);

    const handleWaitForBleServiceToResponse = () => {
        notifyInfo("Please wait for the response of the Bluetooth Service before proceeding further.", "");
    }

    return (
      <>
      <Dialog
        open={openAddUserDialog}
        onClose={handleClickOnClose}
        disableScrollLock={true}
        onKeyDown={stopPropagationForTab}
        sx={{".MuiPaper-root" : dialogContainer()}}
      >
        <StyledDialogCardHolder>
            <StyledDialogCardTitle>{selectedUserRecord ? 'Edit' : 'Add'} User - {selectedPageName}</StyledDialogCardTitle>
            <StyledDialogContentHolder>
                <StyledDialogContentLeftSide>
                    <UserImage
                        selectedUserTableRecordRow={selectedUserRecord}
                        setForm={(imageForm) => dispatch(setUserDialogForm({...userDialogForm, imageForm}))}
                    />

                    {USER_DIALOG_MENU_OPTIONS.map((menuOption, index) => (
                        <MenuOptionDialog
                            key={index} 
                            dialogMenuName={menuOption}
                            selectedPageName={selectedPageName}
                            setSelectedPageName={(page) => dispatch(setUserDialogPage(page as UserDialogPages))}
                            isDisabled={isOptionDisabled(menuOption)}
                            isInfoCircleDisplayed={
                                menuOption === UserDialogPages.Bluetooth &&
                                isInfoCircleDisplayed(bleUserState)
                            }
                        />
                    ))}

                </StyledDialogContentLeftSide>
                <StyledDialogContentRightSide>
                    {selectedPageName === UserDialogPages.Information ? 
                        <UserInformationPage />
                    : null}

                    {selectedPageName === UserDialogPages.AdditionalInformation ?
                        // TODO: In a later task, use the react redux storage instead of passing the states through the props.
                        <UserAdditionalInformationPage
                            error={userDialogFormError.additionalInformationError}
                            form={userDialogForm.additionalInformation}
                            initForm={initUserForm.additionalInformation}
                            setForm={(additionalInformation) => dispatch(setUserDialogForm({
                                ...userDialogForm,
                                additionalInformation,
                                bluetoothForm: {
                                    ...userDialogForm.bluetoothForm,
                                    mobileCountryCode: additionalInformation.telephone
                                        ? PhonesUtil.extractCountryCodeFromPhoneNumber(additionalInformation.telephone)
                                        : PHONE_COUNTRY_CODE_LIST[0],
                                    mobileNumber: PhonesUtil.extractMobileNumberFromPhoneNumber(additionalInformation.telephone)
                                }
                            }))}
                        />
                    : null}

                    {selectedPageName === UserDialogPages.Access ? 
                        <UserAccessPage isDateSelectorDisplayed />
                    : null}

                    {selectedPageName === UserDialogPages.Bluetooth ?
                        // TODO: In a later task, use the react redux storage instead of passing the states through the props.
                        <UserBluetoothPage 
                            isEdited={!!selectedUserRecord}
                            error={userDialogFormError.bluetoothError}
                            form={userDialogForm.bluetoothForm}
                            userTableRecord={selectedUserTableRecordRow}
                            initBluetoothForm={initUserForm.bluetoothForm}
                            setForm={(bluetoothForm) => dispatch(setUserDialogForm({
                                ...userDialogForm,
                                bluetoothForm,
                                information: {
                                    ...userDialogForm.information,
                                    name: bluetoothForm.username
                                },
                                additionalInformation: {
                                    ...userDialogForm.additionalInformation,
                                    telephone: PhonesUtil.convertCountryCodeAndNumbersToPhoneNumber(bluetoothForm.mobileCountryCode, bluetoothForm.mobileNumber)
                                }
                            }))}
                        />
                    : null}
                </StyledDialogContentRightSide>
            </StyledDialogContentHolder>
            <DialogActionButtons 
                displayButtonName={"User"}
                isDialogOpenAsEdit={IS_USER_DIALOG_OPEN_AS_EDIT}
                isSaveButtonDisabled={isSaveButtonDisabled || isBleUserLoading}
                isDeleteAllowed={true}
                handleClickOnClose={handleClickOnClose}
                setSubmitClicked={() => setSubmitClicked(true)}
                setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
            />
        </StyledDialogCardHolder>
        <ConfirmationDialog open={isConfirmationDialogOpen} handleSubmit={handleConfirmDialogSubmit} handleClose={handleConfirmDialogClose} />
        <DeleteDialog open={isDeleteDialogOpen} userId={selectedUserRecord?.globalId} handleSubmit={handleDeleteDialogSubmit} handleClose={handleDeleteDialogClose} />
      </Dialog>
        <ValidateUserForm
            // TODO: In a later task, refactor this component and replace it with a custom react hook instead of displaying it as a JSX component.
            isUserEdited={!!selectedUserRecord}
            isSubmitClicked={isSubmitClicked}
            userDialogForm={userDialogForm}
            isBluetoothPageDisabled={IS_BLUETOOTH_PAGE_DISABLED}
            setUserDialogFormError={(error) => dispatch(setUserDialogFormError(error))}
            handleClickOnClose={handleConfirmDialogSubmit}
            setSubmitClicked={setSubmitClicked}
            setRefreshUserList={setRefreshUserList}
        />
      </>
    );
};

export default UserCardDialog;

export const StyledButtonHolder = styled.div`
    display: flex;
    gap: 15px;
`;

export const StyledDialogActions = styled(DialogActions)`
    height: 37px;
    width: 936px;
    max-height: 37px;
    max-width: 936px;
    padding: 0px 0px 16px 0px;
    margin: auto;
`;

export const StyledDialogActionsHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

export const DeleteButtonStyle = { marginRight: 'auto' };

export const StyledDialogCardHolder = styled.div`
    height: 700px;
    width: 1000px;
`;

export const StyledDialogCardTitle = styled(DialogTitle)`
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
    padding: 24px;
`;

export const StyledDialogContentHolder = styled.div`
    width: 968px;
    max-width: 968px;
    height: 550px;
    padding: 0px 0px 16px 0px;
    margin: 0 32px;
    display: flex;
    gap: 24px;
`;

export const CustomStyledDialogContentHolder = styled(StyledDialogContentHolder)<{ }>((props) => ({height: '550px'}));

export const StyledDialogContentLeftSide = styled(DialogContent)`
    width: 228px;
    height: 550px;
    max-width: 228px;
    max-height: 550px;
    padding: 0px;
`;

export const StyledDialogContentRightSide = styled.div`
    overflow-x: hidden;
    max-height: 550px;
    padding-right: 5px;
    ${customScrollBarCSSProperties()}
`;
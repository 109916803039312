import CardDialogForm from 'model/CardDialogForm';
import CardDialogFormError from 'model/CardDialogFormError';
import { CardDialogPages } from 'model/enums';
import CardDialogTypes from 'redux/constants/cardDialogTypes';

export const setCardDialogForm = (data?: CardDialogForm) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_FORM,
    payload: data,
  };
};
export const setCardDialogFormError = (data?: CardDialogFormError) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_FORM_ERROR,
    payload: data,
  };
};
export const setCardDialogIsEdited = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_EDITED,
    payload: data,
  };
};
export const setCardDialogIsValidationOpen = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_VALIDATION_OPEN,
    payload: data,
  };
};
export const setCardDialogShouldConfirmationDialogSubmit = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT,
    payload: data,
  };
};
export const setCardDialogIsOpen = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_OPEN,
    payload: data,
  };
};
export const setCardDialogSelectedPage = (data: CardDialogPages) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_SELECTED_PAGE_NAME,
    payload: data,
  };
};
export const setCardDialogInitForm = (data?: CardDialogForm) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_INIT_FORM,
    payload: data,
  };
};
export const setCardDialogSelectedUserId = (data?: number) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_SELECTED_USER_ID,
    payload: data,
  };
};
export const setCardDialogIsDialogCloseTriggered = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_DIALOG_CLOSE_TRIGGERED,
    payload: data,
  };
};
export const setCardDialogIsConfirmationDialogOpen = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_CONFIRMATION_DIALOG_OPEN,
    payload: data,
  };
};
export const setCardDialogIsSaveButtonDisabled = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_SAVE_BUTTON_DISABLED,
    payload: data,
  };
};
export const setCardDialogStorageReset = () => {
  return { type: CardDialogTypes.SET_CARD_DIALOG_STORAGE_RESET };
}
export const setCardDialogFormBuilder = (data: CardDialogForm) => {
  return { 
    type: CardDialogTypes.SET_CARD_DIALOG_FORM_BUILDER,
    payload: data,
  };
}

import React from 'react';
import { mainBlue } from 'constants/theme';
import { Box, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FloorFilterCheckBox from './FloorFilterCheckbox';
import { FloorFilterProp } from 'model/FloorFilterProp';

type Props = {
  onChange: (e) => void;
  element: any;
  checkedValues: any[];
};

const BuildingFilterCheckBox = ({ element, onChange, checkedValues }: Props) => {

    const getFloorsWithFilterProp = () => {
      const floorsWithFilterProps:FloorFilterProp[] = []
      element.building.Floors.forEach(floor => {
        let setChecked = false;
        checkedValues.forEach(checkedValue => {
          if (floor.Id.toString() === checkedValue){
            setChecked = true;
          }
        })
        floorsWithFilterProps.push({floor: floor, checked: setChecked})
      })
      return floorsWithFilterProps;
    }
    const listFloors = getFloorsWithFilterProp().map((element, index) => (
      <ul key={index}>
        <FloorFilterCheckBox
          element={element} 
          onChange={onChange}
          />
      </ul>
    ));
  return (<>
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon
              sx={{ '&, &.Mui-checked': { color: mainBlue } }}
            />
          }
          checked={element.checked}
          onChange={(e) => onChange(e)}
          value={element.building.Name}
          id={element.building.Name}
        />
      }
      label={
        <Box component="div" fontWeight={300}>
          {element.building.Name}
        </Box>
      }
    />
    {element.checked && listFloors}
    </>
  );
};
export default BuildingFilterCheckBox;
import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { LocalStorageKeys, EncoderTestingDialogTexts} from 'model/enums';
import { useDispatch, useSelector } from 'react-redux';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import { blue } from 'constants/theme';
import { setEncoderConfirmationOpen } from 'redux/actions/encoderActions';
import EncoderLocalStorageModel from 'model/EncoderLocalStorageModel';
import { GetEncoderBeepSound } from 'API/commands/EncoderCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { encoderConfirmationDialog } from 'constants/dialogTexts';

const EncoderConfirmationDialog = () => {
  const {selectedEncoder, isEncoderConfirmationDialogOpen} = useSelector(selectEncoderStorage);
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isEncoderConfirmationDialogOpen || !selectedEncoder) {
      return;
    }

    // We only want to send out this request while not processing the result of the request.
    GetEncoderBeepSound(actualSite, selectedEncoder.Id);
  }, [isEncoderConfirmationDialogOpen])

  const handleClickOnConfirmationSubmit = () => {
    if (selectedEncoder) {
      const encoderLocalStorageModel: EncoderLocalStorageModel = { encoderId: selectedEncoder.Id };
      localStorage.setItem(LocalStorageKeys.Encoder, JSON.stringify(encoderLocalStorageModel));
    }

    handleCloseOfConfirmationDialog();
  }

  const handleCloseOfConfirmationDialog = () => {
    dispatch(setEncoderConfirmationOpen(false));
  }

  return (
      <DialogTemplate
        handleSubmit={handleClickOnConfirmationSubmit}
        handleClose={handleCloseOfConfirmationDialog}
        open={isEncoderConfirmationDialogOpen}
        dialogText={encoderConfirmationDialog(selectedEncoder?.Name)}
        submitButtonColor={blue}
        dialogId={EncoderTestingDialogTexts.ConfirmationDialogTitle}
      />
  );
};

export default EncoderConfirmationDialog;

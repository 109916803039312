import React from "react";
import styled from "styled-components";
import CheckboxField from "components/controls/checkboxes/CheckboxField";
import { ReissueCardDialogFieldName } from "model/enums";
import { AccessForm } from "model/UserDialogForm";
import { AccessError } from "model/UserDialogFormError";
import DateAndTimePickerContainer from "components/controls/datePicker/DateAndTimePickerContainer";

type Props = {
    form: AccessForm;
    setForm: (form: AccessForm) => void;
    error: AccessError;
};

const UserAccessDateSelectorCard = ({ 
    form,
    setForm,
    error,
}: Props) => {

    return (
        <FooterContainer>
            <div>
              <CheckboxField
                boxWidth={"220px"}
                boxHeight={"40px"}
                displayedName={ReissueCardDialogFieldName.UnlimitedDate}
                setValue={(isUnlimitedDateChecked) => {
                  setForm({...form, isUnlimitedDateChecked})
                }}
                inputValue={form.isUnlimitedDateChecked}
              />
            </div>
            <DateAndTimePickerContainer 
              isUnlimitedDateChecked={form.isUnlimitedDateChecked}
              setDate={(startDate) => setForm({...form, startDate})}
              setTime={(startTime) => setForm({...form, startTime})}
              dateForm={{date: form.startDate, error: error.startDateError}}
              timeForm={{time: form.startTime, error: error.startTimeError}}
              titleForm={{name: "Start Date"}}
              isRequired
            />
            <DateAndTimePickerContainer 
              isUnlimitedDateChecked={form.isUnlimitedDateChecked}
              startAfterEndError={error.startAfterEndError}
              setDate={(endDate) => setForm({...form, endDate})}
              setTime={(endTime) => setForm({...form, endTime})}
              dateForm={{date: form.endDate, error: error.endDateError}}
              timeForm={{time: form.endTime, error: error.endTimeError}}
              titleForm={{name: "End Date"}}
              isRequired
            />
          </FooterContainer>
    )
};

export default UserAccessDateSelectorCard;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

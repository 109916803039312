import { useEffect, useState } from 'react';
import CardDialogForm, {
  CardDetailsForm,
  CardLevelsForm,
} from 'model/CardDialogForm';
import { useDispatch, useSelector } from 'react-redux';
import { selectOpenedDialog } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import { convertSingleUserModelToOptionModel } from './useConversion';
import { DatesUtil } from 'helper/DatesUtil';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import { getCardById } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import CardModel from 'model/CardModel';
import { useBuildingsInit, useRoomsInit } from './useStorageInits';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { useCardDialogFormConst } from './useCardDialogForm';
import { selectLastUsedDate } from 'redux/selectors/lastUsedDateSelector';
import { DialogToOpen } from 'model/enums';
import Building from 'model/Building';
import Floor from 'model/Floor';
import { IGNORE_FIRST_CHARACTERS_OF_MASTER_LEVEL_REMARK } from 'constants/globalConstants';
import { getUserById } from 'API/commands/UserCommands';
import { setCardDialogForm, setCardDialogFormBuilder, setCardDialogInitForm } from 'redux/actions/cardDialogActions';
import { isUserDialogOpened } from 'helper/DialogUtils';
import UserDialogForm from 'model/UserDialogForm';
import { UserForm } from 'components/cards/dialogs/userCardDialog/UserDialogConsts';
import { selectUserDialogUserRecord } from 'redux/selectors/userDialogSelector';

export const getSelectedBuildingByCardModel = (buildings: Building[], cardModel: CardModel | null) => {
  return buildings.find(building => building.Id === cardModel?.Rooms[0]?.BuildingNo);
}

export const getSelectedFloorByCardModel = (floors: Floor[], cardModel: CardModel | null) => {
  return floors.find(floor => floor.Id === cardModel?.Rooms[0]?.FloorNo);
}

export const extractMasterCardLevelsFromCardModel = (cardModel?: CardModel | null) => {
  return cardModel 
    ? cardModel.Remark.slice(IGNORE_FIRST_CHARACTERS_OF_MASTER_LEVEL_REMARK).split(' ') 
    : [];
}

export function useCardFormBuilder(selectedUserId?: number) {
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const listOfRooms = useRoomsInit();
  const listOfDepartments = useSelector(selectDepartmentList);
  const actualSite = ActualSiteLocator();
  const { isEdited, isDialogOpen } = useSelector(selectCardDialogStorage);
  const initCardDialogForm = useCardDialogFormConst();
  const lastUsedDate = useSelector(selectLastUsedDate);
  const listOfBuildings = useBuildingsInit();
  const openedDialog = useSelector(selectOpenedDialog);
  const [lastSelectedUserId, setLastSelectedUserId] = useState<number>();
  const [isUserProcessed, setIsUserProcessed] = useState(false);
  const dispatch = useDispatch();

  const initCardLevels = (cardModel: CardModel | null, user?: UserModel): CardLevelsForm[] => {
    return (user && user?.Card === null) || (user && isEdited)
      ? listOfMasterLevels.map((masterLevel) => ({
        masterLevel,
        isChecked: cardModel
        ? extractMasterCardLevelsFromCardModel(cardModel).includes(masterLevel.ID.toString())
        : false,
      }))
      : initCardDialogForm.cardLevels;
  };

  const initCardDetails = (cardModel: CardModel | null, user?: UserModel): CardDetailsForm => {
    const beginDateAndTime = isEdited 
      ? DatesUtil.convertFormatToDayjs(cardModel?.CreatedOn, DatesUtil.CUSTOM_DATE_TIME_FORMAT)
      : DatesUtil.convertFormatToDayjs(user?.UserAccess?.BeginDate, DatesUtil.CUSTOM_ISO_FORMAT);

    const lastDateAndTime = isEdited 
      ? DatesUtil.convertFormatToDayjs(cardModel?.ExpiryDate, DatesUtil.CUSTOM_DATE_TIME_FORMAT)
      : DatesUtil.convertFormatToDayjs(user?.UserAccess?.EndDate, DatesUtil.CUSTOM_ISO_FORMAT);

    const isFloorCardEdited = isEdited && openedDialog === DialogToOpen.CreateFloorCardDialog;
    let selectedBuilding: Building | undefined;
    let selectedFloor: Floor | undefined;

    if (isFloorCardEdited) {
      selectedBuilding = getSelectedBuildingByCardModel(listOfBuildings, cardModel);
      selectedFloor = getSelectedFloorByCardModel(selectedBuilding?.Floors ?? [], cardModel);
    }

    const isLastDateChecked = !!lastDateAndTime;
    const isStartDateChecked = isEdited ? false : !!beginDateAndTime;

    return (user && user?.Card === null) || (user && isEdited)
      ? {
          user: convertSingleUserModelToOptionModel(user),
          startDate: beginDateAndTime ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
          startTime: beginDateAndTime ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
          lastDate: lastDateAndTime ?? lastUsedDate,
          lastTime: lastDateAndTime ?? lastUsedDate,
          department: listOfDepartments.find(department => department.Id === user.Department.Id),
          lockReleaseTime: user.UserAccess?.ReleaseTime?.toString(),
          building: isFloorCardEdited ? selectedBuilding : undefined,
          floor: isFloorCardEdited ? selectedFloor : undefined,
          isLastDateChecked,
          isStartDateChecked,
          isUnlimitedDateChecked: !isLastDateChecked && !isStartDateChecked,
          isUserSelected: !!user,
        }
      : initCardDialogForm.cardDetails;
  };

  const initSelectedUser = async () => {
    if (!selectedUserId) {
      return;
    }

    const response = await getUserById(actualSite, selectedUserId);
    return response;
  }

  const initUserCard =  async (selectedUser?: UserModel) => {
    if (!selectedUser?.Card) {
      return null;
    }

    const response = await getCardById(actualSite, Number(selectedUser?.Card?.CardID));
    return response;
  }

  useEffect(() => {
    if (!isDialogOpen) {
      dispatch(setCardDialogInitForm(undefined));
      dispatch(setCardDialogForm(undefined));
      return;
    }

    if (
      (isUserProcessed && lastSelectedUserId === selectedUserId)
      || listOfRooms.length === 0 
      || listOfBuildings.length === 0) {
      return;
    }

    buildCardDialogForm();
  }, [isDialogOpen, selectedUserId, listOfRooms, listOfBuildings, isUserProcessed, lastSelectedUserId]);

  const buildCardDialogForm = async () => {

    const selectedUser = await initSelectedUser();
    const cardModel = await initUserCard(selectedUser);

    const initForm: CardDialogForm = ({
      cardDetails: initCardDetails(cardModel, selectedUser),
      cardLevels: initCardLevels(cardModel, selectedUser),
      cardDoors: listOfRooms.filter((room) =>
        cardModel?.Rooms.some((roomModel) => roomModel.Id === room.Id),
      ),
      // TODO: Once we can retrieve the door groups written on the cards, read those groups and assign them to the doorGroups attribute.
      doorGroups: [],
      userCard: cardModel ?? undefined
    });

    dispatch(setCardDialogFormBuilder(initForm));
    setIsUserProcessed(true);
    setLastSelectedUserId(selectedUserId);
  }
}

export const useUserFormBuilder = (): UserDialogForm => {
  const accessLastUsedDate = useSelector(selectLastUsedDate);
  const dialogToOpen = useSelector(selectOpenedDialog);
  const selectedUserRecord = useSelector(selectUserDialogUserRecord);
  const [form, setForm] = useState<UserDialogForm>(UserForm.initEmptyUserForm(accessLastUsedDate));
  const actualSite = ActualSiteLocator();

  useEffect(() => {
    if (!isUserDialogOpened(dialogToOpen)) {
      return;
    }

    if (!selectedUserRecord) {
      setForm(UserForm.initEmptyUserForm(accessLastUsedDate));
      return;
    }

    getUserById(actualSite, selectedUserRecord.globalId).then(user => {
      if (!user) {
        return;
      }

      setForm({
        information: UserForm.getUserDialogInformationForm(user),
        additionalInformation: UserForm.getUserDialogAdditionalInformationForm(user),
        accessForm: UserForm.getUserDialogAccessForm(user, accessLastUsedDate),
        bluetoothForm: UserForm.getUserDialogBluetoothForm(user),
      });
    })
  }, [dialogToOpen, selectedUserRecord])

  return form;
}

export const getStyleByPosition = (positionProp) => {
    switch(positionProp) {
      case 'left': {
        return LeftContainerStyle;
      }
      case 'right': {
        return RightContainerStyle;
      }
      case 'center': {
        return CenteredContainerStyle;
      }
    }
}
const LeftContainerStyle = {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  };
  const RightContainerStyle = {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  };
  const CenteredContainerStyle = {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  };
import * as React from 'react';
import { BluetoothForm } from 'model/UserDialogForm';
import { BluetoothError } from 'model/UserDialogFormError';
import styled from 'styled-components';
import { UserDialogBluetoothFormText, UserDialogFormBluetoothFormPlaceHolderText } from 'model/enums';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import SelectField from 'components/controls/selects/SelectField';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import { restrictedKeyCodes } from 'constants/RestrictedKeyCodes';
import NumberInputField from 'components/controls/inputs/NumberInputField';
import { LIST_OF_MOBILE_USER_TYPES, PHONE_COUNTRY_CODE_LIST } from 'constants/arrays';
import WritableSelectField from 'components/controls/selects/WritableSelectField';
import { NumberLimitsUtil } from 'helper/NumberLimitsUtil';
import { StyledContentRowHolder } from '../../UserDialogCommonStyle';

type Props = {
  error: BluetoothError;
  form: BluetoothForm;
  isEdited: boolean;
  initBluetoothForm: BluetoothForm;
  setForm: (form: BluetoothForm) => void;
};

const BluetoothBodyCard = ({
  error,
  form,
  isEdited,
  initBluetoothForm,
  setForm,
}: Props) => {
  return (
    <React.Fragment>
      <UpperContainer>
        <StyledContentRowHolder>
          <MultiTypeInputField
            inputFieldWidth={'330px'}
            inputFieldHeight={'40px'}
            displayedName={UserDialogBluetoothFormText.Username}
            placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.Username}
            setValue={(username) => setForm({ ...form, username })}
            inputValue={form.username}
            invalidFormMessage={
              form.username === initBluetoothForm.username
                ? undefined
                : error.usernameError
            }
            inputProps={{maxLength: NumberLimitsUtil.INPUT_TEXT_CHARACTER_MAX_LENGTH,}}
            isDisabled={isEdited}
          />
          <MultiTypeInputField
            inputFieldWidth={'329px'}
            inputFieldHeight={'40px'}
            displayedName={UserDialogBluetoothFormText.Email}
            placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.Email}
            setValue={(email) => setForm({ ...form, email })}
            inputValue={form.email}
            invalidFormMessage={error.emailError}
            inputProps={{maxLength: NumberLimitsUtil.INPUT_TEXT_CHARACTER_MAX_LENGTH,}}
            inputType="email"
            isRequired
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <StyledContentRowHolder>
            <WritableSelectField
              inputFieldHeight={'40px'}
              inputFieldWidth={'87px'}
              displayedName={UserDialogBluetoothFormText.MobileNumber}
              placeholderInput={PHONE_COUNTRY_CODE_LIST[0]}
              setValue={(mobileCountryCode) =>setForm({ ...form, mobileCountryCode })}
              inputValue={form.mobileCountryCode}
              selectOptions={PHONE_COUNTRY_CODE_LIST}
              isRequired
            />
            <NumberInputField
              inputFieldWidth={'223px'}
              inputFieldHeight={'40px'}
              placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.MobileNumber}
              inputValue={form.mobileNumber}
              setValue={(mobileNumber) => setForm({ ...form, mobileNumber })}
              invalidFormMessage={error.mobileNumberError}
              restrictKeysToPress={restrictedKeyCodes}
              isStepperDisplayed={false}
              inputProps={{
                min: 0,
                max: NumberLimitsUtil.INT32_UPPER_LIMIT,
                maxLength: NumberLimitsUtil.PHONE_NUMBER_UPPER_LIMIT,
              }}
            />
          </StyledContentRowHolder>
          <SelectField
            inputFieldHeight={'40px'}
            inputFieldWidth={'329px'}
            displayedName={UserDialogBluetoothFormText.MobileUser}
            selectOptions={LIST_OF_MOBILE_USER_TYPES}
            setValue={(mobileUser) => setForm({ ...form, mobileUser })}
            inputValue={
              LIST_OF_MOBILE_USER_TYPES.find(
                (type) => type === form.mobileUser,
              ) ?? LIST_OF_MOBILE_USER_TYPES[0]
            }
          />
        </StyledContentRowHolder>
      </UpperContainer>
      <LowerContainer>
        <CheckboxField
          boxWidth={'100%'}
          boxHeight={'40px'}
          displayedName={UserDialogBluetoothFormText.RemoteOpenDoors}
          setValue={(remoteOpenDoors) => setForm({ ...form, remoteOpenDoors })}
          inputValue={form.remoteOpenDoors}
        />
        <CheckboxField
          boxWidth={'100%'}
          boxHeight={'40px'}
          displayedName={UserDialogBluetoothFormText.TemporaryAccess}
          setValue={(temporaryAccess) => setForm({ ...form, temporaryAccess })}
          inputValue={form.temporaryAccess}
        />
        <CheckboxField
          boxWidth={'100%'}
          boxHeight={'40px'}
          displayedName={UserDialogBluetoothFormText.MeetingRoom}
          setValue={(meetingRoom) => setForm({ ...form, meetingRoom })}
          inputValue={form.meetingRoom}
        />
        <NumberInputField
          inputFieldHeight={'40px'}
          inputFieldWidth={'100%'}
          displayedName={UserDialogBluetoothFormText.LockReleaseTime}
          placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.LockReleaseTime}
          inputValue={form.lockReleaseTime}
          setValue={(lockReleaseTime) => setForm({ ...form, lockReleaseTime })}
          invalidFormMessage={error.lockReleaseTimeError}
          restrictKeysToPress={restrictedKeyCodes}
          inputProps={{
            min: 0,
            max: NumberLimitsUtil.UBYTE_UPPER_LIMIT,
            maxLength: NumberLimitsUtil.UBYTE_UPPER_LIMIT_MAX_LENGTH,
          }}
        />
      </LowerContainer>
    </React.Fragment>
  );
};

export default BluetoothBodyCard;

const UpperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 26px;
`;

const LowerContainer = styled.div`
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 14px;
`;

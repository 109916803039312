import { NotificationProps } from 'model/Notification';
import { Store } from 'react-notifications-component';
import 'style/Notification.css';

const pushNotification = ({
  title,
  message,
  type,
  insert = 'bottom',
}: NotificationProps) => {
  Store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: insert === 'bottom' ? 'bottom-right' : 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};

// Call with these functions
export const notifySuccess = (title: string, message: string) => {
  pushNotification({ title: title, message: message, type: 'success' });
};

export const notifyError = (title: string, message: string) => {
  pushNotification({ title: title, message: message, type: 'danger' });
};

export const notifyWarning = (title: string, message: string) => {
  pushNotification({ title: title, message: message, type: 'warning' });
};

export const notifyInfo = (title: string, message: string) => {
  pushNotification({ title: title, message: message, type: 'info' });
};

import * as React from 'react';
import styled from 'styled-components';
import { blue, lightBlue, listItemLightGray, red, veryLightGrey, white} from 'constants/theme';
import { ReactComponent as InfoCircle } from 'media/info-circle.svg';
import deleteCard from 'media/deleteCard.svg';
import changeIcon from 'media/change.svg';
import suspendIcon from 'media/suspend.svg';
import phoneIcon from 'media/phone.svg';
import { BluetoothMenuItems, BluetoothMenuItemDescriptions, BluetoothUserStatuses, BluetoothSuspendedUserInfo, BluetoothDeletedUserInfo, BluetoothMenuDialogs } from 'model/enums';
import { StyledMenuItem, StyledSvg } from '../../contentLeftSide/UserImage';
import { LinearProgress, Menu } from '@mui/material';
import { CardActionImage } from 'components/cards/cardActions/CardActionsCommonStyle';
import { StyledFont } from '../accessPageDialogs/AddGroupsDialog';
import DialogTemplate from '../../../DialogTemplate';
import { ACTIVATE_USER_DIALOG_TEXT, CHANGE_MOBILE_DIALOG_TEXT, DELETE_USER_DIALOG_TEXT, RE_ACTIVATE_USER_DIALOG_TEXT, SMS_SENT_OUT_DIALOG_TEXT, SUSPEND_USER_DIALOG_TEXT } from 'constants/dialogTexts';
import { isInfoCircleDisplayed } from '../../UserDialogConsts';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsBleUserLoading, selectUser, selectUserList } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import bluetoothIcon from 'media/bluetoothIcon.svg';
import StyledButton from 'components/controls/button/StyledButton';
import { StyledContentRowHolder } from '../../UserDialogCommonStyle';
import UserTableRecord from 'model/UserTableRecord';
import { activateBleUser, addUpdateBleUser, deactivateDeviceOfBleUser, deleteBleUser, suspendBleUser} from 'API/commands/BluetoothCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { setBleUserLoading, setUsersIsRefreshUsersList, setUsersSuccess } from 'redux/actions/userActions';
import { getUserById } from 'API/commands/UserCommands';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { notifySuccess } from 'helper/NotificationService';
import BleMenuItem from 'model/BleMenuItem';

type Props = {
  isEdited: boolean;
  userTableRecord?: UserTableRecord;
};

const BluetoothHeaderCard = ({
  isEdited,
  userTableRecord,
}: Props) => {
  const [bleMenuItem, setBleMenuItem] = React.useState<BleMenuItem>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDialogTemplateOpen, setDialogTemplateOpen] = React.useState<boolean>(false);
  const editingUser: UserModel | undefined = useSelector(selectUser(userTableRecord?.globalId));
  const listOfUsers = useSelector(selectUserList);
  const bleUserState = editingUser?.UserAccess?.BleUserState;
  const IS_INFO_CIRCLE_DISPLAYED = isInfoCircleDisplayed(bleUserState);
  const open = Boolean(anchorEl);
  const BLUETOOTH_MENU_ITEMS = Object.values(BluetoothMenuItems);
  const BLUETOOTH_MENU_ITEM_DESCRIPTIONS = Object.values(BluetoothMenuItemDescriptions);
  const isBleUserLoading = useSelector(selectIsBleUserLoading);
  const maximumSentOutUserRequestCount = 20;
  const isBluetoothHeaderDisplayed = isEdited && editingUser && !!editingUser.BleMobileNumber;
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  const forceToWaitOnBleUserStateUpdate = (bleMenuDialog: BluetoothMenuDialogs) => {
    const expectedBleUserState = ConversionsUtil.getBleUserStateFromBluetoothMenuDialog(bleMenuDialog);
    if (
      editingUser?.Id
      && editingUser.UserAccess?.BleUserState !== expectedBleUserState
    ) {
      let remainingUserRequestCount = maximumSentOutUserRequestCount;
      const myInterval = setInterval(() => {
        getUserById(actualSite, editingUser.Id).then(userResponse => {
          remainingUserRequestCount--;
          if (!userResponse
            || (
              editingUser.UserAccess?.BleUserState !== userResponse.UserAccess?.BleUserState
              && userResponse.UserAccess?.BleUserState === expectedBleUserState
            )
            || remainingUserRequestCount <= 0
          ) {
            clearInterval(myInterval);
            dispatch(setBleUserLoading(false));
            notifyUserAboutBleServiceActions(bleMenuDialog);
            if (userResponse) {
              dispatch(setUsersSuccess([
                ...listOfUsers.filter(user => user.Id !== userResponse?.Id),
                userResponse
              ]));
            }
          } 
        });
      }, 3000);
    } else {
      dispatch(setBleUserLoading(false));
    }
  }

  const notifyUserAboutBleServiceActions = (bleMenuDialog?: BluetoothMenuDialogs) =>{
    const notificationMessage = ConversionsUtil.getNotificationFromBleUserState(bleMenuDialog);
    if (notificationMessage) {
      notifySuccess(notificationMessage, "");
    }
  }

  const getMenuItemIcon = (menuItem: BluetoothMenuItems) => {
    switch (menuItem) {
      case BluetoothMenuItems.ChangeMobile:
        return phoneIcon;
      case BluetoothMenuItems.ReSend:
        return changeIcon;
      case BluetoothMenuItems.Suspend:
        return suspendIcon;
      case BluetoothMenuItems.Delete:
        return deleteCard;
    }
  };

  const handleOptionOnClick = (menuItem: BluetoothMenuItems) => {
    const handler = Object.freeze({
      [BluetoothMenuItems.ChangeMobile]: () => setBleMenuItem({
        id: BluetoothMenuDialogs.ChangeMobile,
        text: CHANGE_MOBILE_DIALOG_TEXT,
        isSubmitHidden: false,
        submitColor: red
      }),
      [BluetoothMenuItems.ReSend]: () => {
        setBleMenuItem({
          id: BluetoothMenuDialogs.ReSend,
          text: SMS_SENT_OUT_DIALOG_TEXT,
          isSubmitHidden: true,
          submitColor: blue
        });
        handleBleServiceApiCalls(BluetoothMenuDialogs.ReSend);
      },
      [BluetoothMenuItems.Suspend]: () => setBleMenuItem({
        id: BluetoothMenuDialogs.Suspend,
        text: SUSPEND_USER_DIALOG_TEXT,
        isSubmitHidden: false,
        submitColor: red
      }),
      [BluetoothMenuItems.Delete]: () => setBleMenuItem({
        id: BluetoothMenuDialogs.Delete,
        text: DELETE_USER_DIALOG_TEXT,
        isSubmitHidden: false,
        submitColor: red
      }),
    });
    handler[menuItem.toString()].call();
    handleDialogTemplateWindow(true);
  };

  const handleDialogTemplateWindow = (isDialogOpen: boolean) => {
    setDialogTemplateOpen(isDialogOpen);
    setAnchorEl(null);
  };

  const handleAddUpdateBleUser = () => {
    if (editingUser?.Id) {
      addUpdateBleUser(actualSite, editingUser.Id)
        .then((addUserBleResponse) => {
          if (addUserBleResponse) {
            activateBleUser(actualSite, editingUser.Id);
          }
        })
    }
  }

  const handleBleServiceApiCalls = (menuItem: BluetoothMenuDialogs) => {
    if (editingUser?.Id) {
      dispatch(setBleUserLoading(true));
      const handler = Object.freeze({
        [BluetoothMenuDialogs.ChangeMobile]: () => deactivateDeviceOfBleUser(actualSite, editingUser.Id),
        [BluetoothMenuDialogs.Suspend]: () => suspendBleUser(actualSite, editingUser.Id),
        [BluetoothMenuDialogs.Delete]: () => deleteBleUser(actualSite, editingUser.Id),
        [BluetoothMenuDialogs.Suspended]: () => handleAddUpdateBleUser(),
        [BluetoothMenuDialogs.Deleted]: () => handleAddUpdateBleUser(),
        [BluetoothMenuDialogs.ReSend]: () => handleAddUpdateBleUser()
      });
      handler[menuItem.toString()].call();
      forceToWaitOnBleUserStateUpdate(menuItem);
      if (![BluetoothMenuDialogs.ReSend, BluetoothMenuDialogs.ChangeMobile].some((bleMenu) => bleMenu === menuItem)) {
        dispatch(setUsersIsRefreshUsersList(true));
      }
    }
  };

  const getInfoMessage = (): string => {
    switch (bleUserState) {
      case BluetoothUserStatuses.Suspended:
        return BluetoothSuspendedUserInfo.Message;
      case BluetoothUserStatuses.Deleted:
        return BluetoothDeletedUserInfo.Message;
      default:
        return ``;
    }
  };

  const getInfoMessageAction = (): string => {
    switch (bleUserState) {
      case BluetoothUserStatuses.Suspended:
        return BluetoothSuspendedUserInfo.Action;
      case BluetoothUserStatuses.Deleted:
        return BluetoothDeletedUserInfo.Action;
      default:
        return ``;
    }
  };

  const handleUnderlinedElementOnClick = () => {
    const handler = Object.freeze({
      [BluetoothUserStatuses.Suspended]: () => setBleMenuItem({
        id: BluetoothMenuDialogs.Suspended,
        text: ACTIVATE_USER_DIALOG_TEXT,
        isSubmitHidden: false,
        submitColor: blue
      }),
      [BluetoothUserStatuses.Deleted]: () => setBleMenuItem({
        id: BluetoothMenuDialogs.Deleted,
        text: RE_ACTIVATE_USER_DIALOG_TEXT,
        isSubmitHidden: false,
        submitColor: blue
      }),
    });
    if (bleUserState) {
      handler[bleUserState.toString()].call();
      handleDialogTemplateWindow(true);
    }
  };

  const isMenuOptionHidden = (item: BluetoothMenuItems) =>
    (item === BluetoothMenuItems.Suspend &&
      bleUserState === BluetoothUserStatuses.Suspended) ||
    (item === BluetoothMenuItems.Delete &&
      bleUserState === BluetoothUserStatuses.Deleted);

  return (
    <CustomContentRowHolder
      $isInfoTextDisplayed={IS_INFO_CIRCLE_DISPLAYED}
      $isHidden={isBluetoothHeaderDisplayed}
    >
      <InfoContainer $isInfoTextDisplayed={IS_INFO_CIRCLE_DISPLAYED}>
        <InfoCircle />
        <span>
          <InfoMessage>{`${getInfoMessage()} `}</InfoMessage>
          <UnderlinedElement onClick={() => handleUnderlinedElementOnClick()}>
            {getInfoMessageAction()}
          </UnderlinedElement>
        </span>
      </InfoContainer>
      <div>
        {isBluetoothHeaderDisplayed && (
          <StyledButton
            buttonHeight={40}
            buttonWidth={199}
            displayedName="Bluetooth Actions"
            handleOnClick={(e) => {
              if (!isBleUserLoading) setAnchorEl(e.currentTarget)
            }}
            backgroundColor={white}
            iconLeft={bluetoothIcon}
            disabled={isBleUserLoading}
          />
        )}
        <BluetoothActionsButtonHolder>
          <div>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              disableScrollLock={true}
              sx={{ top: '10px' }}
            >
              {BLUETOOTH_MENU_ITEMS.map((item, index) => (
                <div key={index} hidden={isMenuOptionHidden(item)}>
                  <StyledMenuItem
                    key={index}
                    onClick={() => handleOptionOnClick(item)}
                    sx={{ borderTop: `2px solid ${veryLightGrey}`, height: '69px' }}
                  >
                    <StyledSvg>
                      <CardActionImage src={getMenuItemIcon(item)} alt={item} />
                    </StyledSvg>
                    <div>
                      <StyledFont $fontSize={'14px'} $lineHeight={'24.35px'}>
                        {item}
                      </StyledFont>
                      <StyledFont $fontSize={'14px'} $fontColor={listItemLightGray}>
                        {BLUETOOTH_MENU_ITEM_DESCRIPTIONS[index]}
                      </StyledFont>
                    </div>
                  </StyledMenuItem>
                </div>
              ))}
            </Menu>
          </div>
          { isBleUserLoading ?
            <div>
              <LinearProgress sx={{ borderRadius: "5px" }}/>
            </div>
          : null}
        </BluetoothActionsButtonHolder>
      </div>
      { bleMenuItem ? 
        <DialogTemplate
          handleSubmit={(dialogId) => {
            handleBleServiceApiCalls(dialogId as BluetoothMenuDialogs);
            handleDialogTemplateWindow(false);
          }}
          handleClose={() => handleDialogTemplateWindow(false)}
          open={isDialogTemplateOpen}
          dialogText={bleMenuItem?.text}
          submitButtonColor={bleMenuItem?.submitColor}
          isSubmitButtonHidden={bleMenuItem?.isSubmitHidden}
          dialogId={bleMenuItem?.id}
        />
      : null }
    </CustomContentRowHolder>
  );
};

export default BluetoothHeaderCard;

const CustomContentRowHolder = styled(StyledContentRowHolder)<{
  $isInfoTextDisplayed?: boolean;
  $isHidden?: boolean;
}>((props) => ({
  justifyContent: props.$isInfoTextDisplayed ? 'space-between' : 'end',
  display: props.$isHidden ? 'flex' : 'none',
  marginBottom: '24px',
  height: "50px",
}));

const InfoContainer = styled.div<{ $isInfoTextDisplayed?: boolean }>(
  (props) => ({
    display: props.$isInfoTextDisplayed ? 'flex' : 'none',
    gap: '6px',
    alignItems: 'center',
    padding: '8px 12px',
    backgroundColor: lightBlue,
    color: blue,
    borderRadius: '8px',
    fontSize: '14px',
  }),
);

const BluetoothActionsButtonHolder = styled.div`
  display: grid;
  gap: 2px;
`;

const InfoMessage = styled.span`
  font-weight: 400;
`;

const UnderlinedElement = styled.u`
  font-weight: 500;
  cursor: pointer;
`;

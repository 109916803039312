import { CardStatus } from 'model/enums';
import OptionModel from 'model/OptionModel';
import UserModel from 'model/UserModel';
import UserTableRecord from 'model/UserTableRecord';
import { useSelector } from 'react-redux';
import { selectNewUsersOnPending, selectUserList } from 'redux/selectors/userSelector';

const sortOptionModels = (optionModels: OptionModel[]): OptionModel[] =>
  optionModels.sort((user1, user2) => user1.label.localeCompare(user2.label));

export const convertSingleUserModelToOptionModel = (user: UserModel) => 
  (new OptionModel(
    user.Name,
    Number(user.UserNumber),
    user.Id,
    user.Email,
    user.Department.DepartmentName,
  ));

export const useConvertUserModelsToOptionModels = (): OptionModel[] => {
  const userModels = useSelector(selectUserList);
  const listOfNewUsersOnPending = useSelector(selectNewUsersOnPending);

  return sortOptionModels(
    [...userModels, ...listOfNewUsersOnPending]
      .filter(userModel => !userModel.Card || userModel.Card?.CardStatus === CardStatus.Deleted)
      .map((user) => convertSingleUserModelToOptionModel(user))
  );
};

export const useConversionUserRecordsToOptionModels = (
  userTableRecordRows: UserTableRecord[],
): OptionModel[] => {
  const userModels = useSelector(selectUserList);

  return sortOptionModels(
    userTableRecordRows.map((row) => (new OptionModel(
      row.name,
      Number(row.userId),
      row.globalId,
      userModels.find(user => user.Id === row.globalId)?.Email,
      row.department,
    ))),
  );
};

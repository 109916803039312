import React, { useState, useEffect } from 'react';
import OnlineDoor from 'model/OnlineDoor';
import { styled } from 'styled-components';
import MultiStateCheckBox from '../controls/MultiStateCheckBox';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import Building from 'model/Building';
import { Checked } from 'model/MultiStateCheckbox';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Floor from 'model/Floor';
import DoorCard from './DoorCard';
import { veryLightGrey, white } from 'constants/theme';
import { OnlineDoorCommand } from 'model/enums';

type Props = {
  floor: Floor;
  doors: OnlineDoor[];
  buildingName: string;
  buildings: Building[];
  setSelectedFloors: (floor: FloorWithBuildingName) => void;
  setSelectedDoors: (door: OnlineDoor) => void;
  selectedBuildings: Building[];
  selectedFloors: FloorWithBuildingName[];
  selectedDoors: OnlineDoor[];
  opened: boolean;
  openFloor: (floor: Floor) => void;
  confirmCommand: (
    commandType?: OnlineDoorCommand,
    buildings?: Building[],
    floorsWithBuildingId?: FloorWithBuildingName[],
    doors?: OnlineDoor[],
  ) => void;
  siteName: string;
};

const FloorCard = ({
  floor,
  doors,
  buildingName,
  buildings,
  setSelectedFloors,
  setSelectedDoors,
  selectedFloors,
  selectedDoors,
  opened,
  openFloor,
  confirmCommand,
  siteName
}: Props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Select the floor with this method
  const handleClick = () => {
    setSelectedFloors({ floor, buildingName });
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const getSelectedValue = (): Checked => {
    if (
      selectedFloors.find(
        (selectedFloor) => selectedFloor.floor.Id === floor.Id,
      )
    ) {
      return 2;
    }
    if (
      selectedDoors.find(
        (door) => door.Floor === floor.Name && door.Building === buildingName,
      )
    ) {
      return 1;
    }
    return 0;
  };

  const renderDoors = (doors: OnlineDoor[], position: 'left' | 'right' | 'center') =>
    doors?.map((door, index) => {
      return (
        <DoorCard
          key={'door' + index}
          door={door}
          backgroundColor={index % 2 === 0 ? white : veryLightGrey}
          position={position}
          setSelectedDoors={setSelectedDoors}
          selectedDoors={selectedDoors}
          confirmCommand={confirmCommand}
          siteName={siteName}
        />
      );
    });

    const filterDoors = () => {
      return (doors.filter(
        (door) =>
          door.Building === buildingName &&
          door.Floor === floor?.Name,
      ))
    }

    const defineDoorGroups = () => {
      const doorsInBuilding = doors.filter(
        (door) =>
          door.Building === buildingName && door.Floor === floor?.Name,
      );
      const midpoint = Math.ceil(doorsInBuilding.length / 2);
      const firstHalf = doorsInBuilding.slice(0, midpoint);
      const secondHalf = doorsInBuilding.slice(midpoint);
      return [firstHalf, secondHalf];
    };



  return (
    <Container>
      <MainComponent>
        <MultiStateCheckBox onChange={handleClick} value={getSelectedValue()} />
        <p>{floor.Name}</p>
        <ListItemButton onClick={() => openFloor(floor)}>
          {opened ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </MainComponent>
      {opened && floor && (
        <DoorRows>
          {windowWidth < 1500 ? (
            <>
              <DoorsCenteredContainer>
                {renderDoors(filterDoors(), 'center')}
              </DoorsCenteredContainer>
            </>
          ) : (
          <>
          <DoorsContainer>
            {renderDoors(defineDoorGroups()[0], 'left')}
          </DoorsContainer>
          <DoorsContainer>
            {renderDoors(defineDoorGroups()[1], 'right')}
          </DoorsContainer>
          </>
          )}
        </DoorRows>
      )}
    </Container>
  );
};
export default FloorCard;

const Container = styled.div`
  width: 100%;
  margin: 0px 10px;
`;

const MainComponent = styled.div`
  display: flex;
  align-items: center;
`;

const DoorRows = styled.div`
  display: flex;
  margin: 0px 20px;
  width: 100%;
`;

const DoorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
`;

const DoorsCenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 96%;
`;
import React from 'react';
import { StyledDialogCardTitle } from '../../userCardDialog/UserCardDialog';
import styled from 'styled-components';
import { selectUserListStorage } from 'redux/selectors/userSelector';
import { useSelector } from 'react-redux';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { useConversionOpenedDialogToCardType } from 'hooks/useCardTypes';
import { DialogToOpen } from 'model/enums';

export const CardDialogTitleAndBoxText = () => {
  const { isEdited, selectedPageName } = useSelector(selectCardDialogStorage);
  const { selectedUserRow, openedDialog } = useSelector(selectUserListStorage);
  const cardTypeFromDialogToOpen = useConversionOpenedDialogToCardType();

  const getCardDialogTitle = () => {
    return `${selectedUserRow && isEdited ? 'Edit ' : 'Add '}
            ${cardTypeFromDialogToOpen}
            ${
              openedDialog === DialogToOpen.CreateKeyCardDialog ||
              openedDialog === DialogToOpen.CreateMasterCardDialog
                ? ` - ${selectedPageName}`
                : ``
            }`;
  };

  const getInfoBoxText = () => {
    const buttonText = isEdited ? 'Save' : 'Add Card';
    return (
      <>
        <p>Please, place your card on the encoder</p>
        <p>
          after pressing the <strong>{`'${buttonText}'`}</strong> button to
          perform the action.
        </p>
      </>
    );
  };

  return (
    <React.Fragment>
      <StyledDialogCardTitle>{getCardDialogTitle()}</StyledDialogCardTitle>
      <StyledInfoBox>{getInfoBoxText()}</StyledInfoBox>
    </React.Fragment>
  );
};

const StyledInfoBox = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 5vh;
  strong {
    font-weight: bold;
  }
  p {
    margin: 0;
  }
`;

import { black, lightBlue, red, veryLightGrey, white } from 'constants/theme';
import * as React from 'react';
import styled from 'styled-components';
import { ReactComponent as UploadFile } from 'media/uploadFile.svg';
import uploadFile from 'media/uploadFile.svg';
import deleteCard from 'media/deleteCard.svg';
import threeDots from 'media/three-dots.svg';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ImageActions, UploadPhotoMenuItems } from 'model/enums';
import { CardActionImage } from 'components/cards/cardActions/CardActionsCommonStyle';
import { FilesUtil } from 'helper/FilesUtil';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { getUserImage } from 'API/commands/UserCommands';
import UserTableRecord from 'model/UserTableRecord';
import { ImageForm } from 'model/UserDialogForm';
import { OuterImageContainer, StyledInnerImage } from 'components/cards/userWindow/ImageCard';

type Props = {
    selectedUserTableRecordRow?: UserTableRecord;
    setForm: (imageForm: ImageForm | undefined) => void;
};

const UserImage = ({
    selectedUserTableRecordRow,
    setForm,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [base64ProfilePicture, setBase64ProfilePicture] = React.useState<string>();
    const [originalBase64ProfilePicture, setOriginalBase64ProfilePicture] = React.useState<string>();
    const [profileImage, setProfileImage] = React.useState<HTMLImageElement>();
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const actualSite = ActualSiteLocator();

    React.useEffect(() => {
        if (selectedUserTableRecordRow?.globalId) {
            getUserImage(actualSite, Number(selectedUserTableRecordRow.globalId)).then(image => setOriginalBase64ProfilePicture(image ? image.Base64Image : undefined));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => { setBase64ProfilePicture(originalBase64ProfilePicture) }, [originalBase64ProfilePicture]);

    React.useEffect(() => {
        if (base64ProfilePicture) {
            FilesUtil.convertBase64ToImage(base64ProfilePicture, (img) => setProfileImage(img));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64ProfilePicture]);

    const handleUploadClick = (e) => {
        const file: File | undefined = e?.target?.files?.[0];
        setAnchorEl(null);
        if (FilesUtil.isFileSizeIsInTheThreshold(file, FilesUtil.MAXIMUM_FILE_SIZE) && file){
            setForm({imageFile: file, imageAction: originalBase64ProfilePicture ? ImageActions.ImageUpload : ImageActions.ImageAddition});
            FilesUtil.convertImageToBase64(file, (base64String) => setBase64ProfilePicture(base64String));
        }
    }

    const handleDeleteClick = () => {
        if (originalBase64ProfilePicture) {
            setForm({imageFile: undefined, imageAction: ImageActions.ImageDeletion});
        } else {
            setForm(undefined);
        }
        setBase64ProfilePicture(undefined);
        setProfileImage(undefined);
    }

    return (
        <StyledImageUploaderHolder>
            {base64ProfilePicture && profileImage
                ? <ProfilePictureContainer>
                    <StyledButtonContainer>
                        <IconButton
                            aria-label="more"
                            id="button"
                            aria-controls={open ? 'menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <StyledButton src={threeDots} alt="Options" />
                        </IconButton>
                        <Menu
                            id="menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '167px',
                                }
                            }}
                            disableScrollLock={true}
                        >
                            <StyledMenuItem onChange={handleUploadClick}>
                                <VisuallyHiddenInput 
                                    type="file" 
                                    accept="image/*" 
                                    width={167}
                                    height={42}
                                    inset={0}
                                />
                                <StyledSvg>
                                    <UploadFile />
                                </StyledSvg>
                                {UploadPhotoMenuItems.UploadPhoto}
                            </StyledMenuItem>
                            <StyledMenuItem onClick={handleDeleteClick} color={red} sx={{ borderTop: `2px solid ${veryLightGrey}` }}>
                                <CardActionImage src={deleteCard} alt={UploadPhotoMenuItems.Delete}/>
                                {UploadPhotoMenuItems.Delete}
                            </StyledMenuItem>
                        </Menu>
                    </StyledButtonContainer>
                    <OuterImageContainer>
                        <StyledInnerImage 
                            src={profileImage.src} alt={"Profile Picture"}
                            $borderRadius={"10px"}
                            $imageWidth={"228px"}
                            $imageHeight={"228px"}
                        />                    
                    </OuterImageContainer>
                </ProfilePictureContainer>
                : <StyledIconHolder onChange={handleUploadClick}>
                    <VisuallyHiddenInput type="file" accept="image/*" />
                    <StyledImg alt="Upload" src={uploadFile} />
                </StyledIconHolder>
            }
        </StyledImageUploaderHolder>
    );
};

export default UserImage;

const VisuallyHiddenInput = styled.input<{ width?: number, height?: number, inset?: number }>((props) => ({
    opacity: 0,
    cursor: "pointer",
    width: props.width ? `${props.width}px` : `48px`,
    height: props.height ? `${props.height}px` : `48px`,
    inset: props.inset !== undefined ? `${props.inset}px` : ``,
    fontSize: 0,
    position: "absolute",
    display: "block",
    borderRadius: "50%",
}));

const StyledImageUploaderHolder = styled.div`
    height: 228px;
    width: 228px;
    text-align: center;
    align-content: center;
    background-color: ${lightBlue};
    border-radius: 10px;
`;

const StyledIconHolder = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 55px;
    background-color: ${white};
    margin: auto;
`;

const StyledImg = styled.img`
    height: 32px;
    width: 32px;
    padding-top: 6px;
`;

const ProfilePictureContainer = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const StyledButtonContainer = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    height: 32px;
    width: 32px;
    background-color: ${lightBlue};
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

const StyledButton = styled.img`
    cursor: pointer;
    height: 20px;
    width: 20px;
`;

export const StyledMenuItem = styled(MenuItem)<{ color?: string }>((props) => ({ color: `${props.color}`}));

export const StyledSvg = styled.div`
    margin-right: 10px;
    svg {
        width: 20px;
        height: 20px;
        path {
            stroke: ${black};
        }
    }
`;
import * as React from 'react';
import styled from 'styled-components';
import { black, blue, lightBlue, OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import StyledButton from 'components/controls/button/StyledButton';
import CustomDataGridTable from 'components/controls/dataGridTable/CustomDataGridTable';
import RoomModel from 'model/RoomModel';
import RoomTableRecords from 'model/RoomTableRecords';
import chevronLeft from 'media/chevron-left.svg';
import { StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder, StyledDialogCardHolder, StyledDialogContentHolder } from '../../UserCardDialog';
import { CustomDialogTitleComponent, CustomStyledDialogCardTitle } from './DoorGroupDialog';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import DoorGroupModel from 'model/DoorGroupModel';
import { alpha } from '@mui/material/styles';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import StyledIconButton from 'components/controls/button/StyledIconButton';
import { useSelector } from 'react-redux';
import { selectMatchedRooms } from 'redux/selectors/roomSelector';
import { IRootState } from 'redux/store';
import { customScrollBarCSSProperties, dialogContainer } from 'constants/globalStyle';
import { Dialog } from '@mui/material';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';

type Props = {
    isDialogOpen: boolean;
    setDialogOpen: (isDialogOpen: boolean) => void;
    doorGroupList: DoorGroupModel[];
    setDoorGroupList: (doorGroupList: DoorGroupModel[]) => void;
    setDoorGroupsToDefault: () => void;
};

const AccessGroupDialog = ({ 
    isDialogOpen,
    setDialogOpen,
    doorGroupList,
    setDoorGroupList,
    setDoorGroupsToDefault,
}: Props) => {

    const [numberOfSelectedGroups, setNumberOfSelectedGroups] = React.useState<number>(0);
    const [rooms, setRooms] = React.useState<RoomModel[]>([]);
    const [roomRows, setRoomRows] = React.useState<RoomTableRecords[]>([]);
    const [selectedDoorGroupId, setSelectedDoorGroupId] = React.useState<number>();
    const selectedDoorGroup = React.useMemo(() => 
        doorGroupList.find((doorGroup) => doorGroup.doorGroup.Id === selectedDoorGroupId),
        [doorGroupList, selectedDoorGroupId]
    );
    const selectRooms = React.useMemo(() => selectedDoorGroup ? selectMatchedRooms(selectedDoorGroup.doorGroup) : () => [], [selectedDoorGroup]);
    const matchedRooms = useSelector((state: IRootState) => selectRooms(state));
    const listOfMasterLevels = useSelector(selectMasterLevelList);

    React.useEffect(() => {
        if (isDialogOpen) {
            if (!selectedDoorGroupId) {
                setSelectedDoorGroupId(doorGroupList.length > 0 ? doorGroupList[0].doorGroup.Id : undefined);
            } else {
                setNumberOfSelectedGroups(filterByActiveDoorGroups().length);
                if (selectedDoorGroup) {
                    setRooms(matchedRooms);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doorGroupList, selectedDoorGroupId, selectedDoorGroup, matchedRooms]);

    React.useEffect(() => {
        setRoomRows(ConversionsUtil.mapToRooms(rooms, listOfMasterLevels));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rooms]);

    const filterByActiveDoorGroups = () => doorGroupList.filter((doorGroup) => doorGroup.isChecked);

    const setDialogToDefault = () => {
        setNumberOfSelectedGroups(0);
        setSelectedDoorGroupId(undefined);
    }

    const handleClickOnClose = () => {
        setDialogOpen(false);
        setDialogToDefault();
        setDoorGroupsToDefault();
    };

    const handleCheckBoxOnClick = (isChecked: boolean, doorGroupId: number) => {
        setDoorGroupList(
            doorGroupList.map((item) => ({
                doorGroup: item.doorGroup,
                isChecked: (item.doorGroup.Id === doorGroupId ? isChecked : item.isChecked),
            }))
        );
    }

    const handleAddGroupsOnClick = () => {
        setDialogOpen(false);
        setDialogToDefault();
    }

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
                <CustomStyledDialogCardTitle>
                    <CustomDialogTitleComponent>
                        <StyledIconButton
                            handleOnClick={handleClickOnClose}
                            icon={chevronLeft}
                        />
                    </CustomDialogTitleComponent>
                    <div>
                        Groups
                    </div>
                    <CustomDialogTitleComponent />
                </CustomStyledDialogCardTitle>
                <StyledDialogContentHolder>
                    <StyledListViewOuter>
                        <StyledDoorGroupsHolder>
                            <StyledContent width={97} height={24}>
                                Door Groups
                            </StyledContent>
                            <StyledDoorGroupListOuterHolder>
                                <StyledListViewInner>
                                    <StyledDoorGroupListInnerHolder>
                                        {doorGroupList.map((value) => (
                                            <StyledFloorAndCheckboxHolder
                                                key={value.doorGroup.Id}
                                            >
                                                <StyledDoorGroupElement
                                                    onClick={() => setSelectedDoorGroupId(value.doorGroup.Id)}
                                                    $isSelected={selectedDoorGroupId === value.doorGroup.Id}
                                                >
                                                    <StyledFont 
                                                        $fontSize={"14px"}
                                                        $fontColor={selectedDoorGroupId === value.doorGroup.Id ? blue: black}
                                                        $fontWeight={selectedDoorGroupId === value.doorGroup.Id ? "bold" : "400"}
                                                    >
                                                        <StyledTextDoorGroupTextHolder>
                                                            {value.doorGroup.GroupName}
                                                        </StyledTextDoorGroupTextHolder>
                                                    </StyledFont>
                                                </StyledDoorGroupElement>
                                                <StyledCheckBoxHolder>
                                                    <CheckboxField
                                                        boxWidth={"16px"}
                                                        boxHeight={"16px"}
                                                        inputValue={value.isChecked}
                                                        setValue={(isChecked) => handleCheckBoxOnClick(isChecked, value.doorGroup.Id)}
                                                    />
                                                </StyledCheckBoxHolder>
                                            </StyledFloorAndCheckboxHolder>
                                        ))}
                                    </StyledDoorGroupListInnerHolder>
                                </StyledListViewInner>
                            </StyledDoorGroupListOuterHolder>
                        </StyledDoorGroupsHolder>
                    </StyledListViewOuter>
                    <StyledContent
                        width={684}
                        height={440}
                    >
                        <CustomDataGridTable 
                            dataRows={roomRows}
                            rowHeight={38}
                            height='100%'
                        />
                    </StyledContent>
                </StyledDialogContentHolder>
                <StyledDialogActions>
                    <StyledDialogActionsHolder>
                        <StyledButtonHolder>
                            <StyledButton
                                buttonHeight={37}
                                buttonWidth={95}
                                displayedName={"Cancel"}
                                handleOnClick={handleClickOnClose}
                                backgroundColor={white}
                            />
                            <StyledButton
                                buttonHeight={37}
                                buttonWidth={151}
                                displayedName={`Add groups${numberOfSelectedGroups ? ` (${numberOfSelectedGroups})` : ''}`}
                                handleOnClick={handleAddGroupsOnClick}
                                isFilledButton
                                backgroundColor={blue}
                            />
                        </StyledButtonHolder>
                    </StyledDialogActionsHolder>
                </StyledDialogActions>
            </StyledDialogCardHolder>
        </Dialog>
    );
};

export default AccessGroupDialog;

const StyledListViewOuter = styled.div`
    height: 100%;
    width: 228px;
`;

const StyledListViewInner = styled.div`
    height: 100%;
    width: 218px;
    ${customScrollBarCSSProperties()}
`;

const StyledFloorAndCheckboxHolder = styled.div<{}>((props) => ({ display: 'flex'}));

const StyledDoorGroupListInnerHolder = styled.div<{}>((props) => ({ 
    display: 'grid', 
    gap: '3px',
}));

const StyledDoorGroupListOuterHolder = styled.div<{}>((props) => ({ 
    overflowY: "auto", 
    overflowX: "hidden",
}));

const StyledCheckBoxHolder = styled.div<{}>((props) => ({ margin: '3px 8px'}));

export const StyledFont = styled.div<{
    $fontSize?: string,
    $fontWeight?: string,
    $lineHeight?: string,
    $fontColor?: string,
}>((props) => ({
    fontFamily: 'Inter',
    fontSize: props.$fontSize ?? `16px`,
    fontWeight: props.$fontWeight ?? `400px`,
    lineHeight: props.$lineHeight ?? `21.31px`,
    color: props.$fontColor,
}));

const StyledDoorGroupElement = styled.div<{$isSelected?: boolean}>((props) => ({
    cursor: 'pointer',
    width: '175px',
    height: '29px',
    display: 'flex', 
    alignItems: 'center',
    backgroundColor: (props.$isSelected ? lightBlue : white),
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: props.$isSelected ? alpha(lightBlue, OPACITY_OF_ROWS*2) : alpha(veryLightGrey, OPACITY_OF_ROWS),
        '@media (hover: none)': { backgroundColor: 'transparent'},
    },
}));

const StyledTextDoorGroupTextHolder = styled.div<{}>((props) => ({
    textAlign: 'left',
    paddingLeft: '12px'
}));

const StyledContent = styled.div<{ 
    width: number, 
    height: number, 
}>((props) => ({
    width: `${props.width}px`,
    height: `${props.height}px`,
}));

const StyledDoorGroupsHolder = styled.div<{}>((props) => ({
    display: "grid",
    gap: '16px',
    height: '100%',
}));
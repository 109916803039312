import * as React from 'react';
import { DialogToOpen, EncoderResponseDescription, EncoderStatus, EventMessageType, SignalrResponseStatuses, Status, LocalStorageKeys } from 'model/enums';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'helper/NotificationService';
import { useSelector } from 'react-redux';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import { setAwaitsEncoderResponse, setEncoderCardResponse, setEncoderConfirmationOpen, setSelectedEncoderStatus, setSignalrResponseStatus } from 'redux/actions/encoderActions';
import { selectOpenedDialog, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { setOpenedDialog } from 'redux/actions/userActions';
import Connector from 'helper/SignalRConnection';
import { addEventToStore } from 'redux/actions/eventActions';
import { addDoorStatusToStore } from 'redux/actions/doorStatusActions';
import EncoderMessageModel, { EncoderStatusUpdateModel } from 'model/EncoderMessageModel';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { selectCardDialogIsEdited } from 'redux/selectors/cardDialogSelector';
import { Dispatch } from 'redux';

export const ignoreSignalrResponseStatus = [SignalrResponseStatuses.Pending, SignalrResponseStatuses.None];

export const handleCardIsNotDetectedError = () => notifyError("Card is not detected! Please place a card on the Encoder.", "");

export const handleEncoderNotRespondedYetError = () => notifyError("Please wait for the encoder's response before leaving.", "");

export const isEncoderResponded = (isAwaitsEncoderResponse: boolean, command?: EncoderResponseDescription ) => {
  return !isAwaitsEncoderResponse && command;
}

export const setEncoderRequestToInactive = (dispatch: Dispatch) => {
  setEncoderRequestByParams(false, SignalrResponseStatuses.None, dispatch);
}

export const setEncoderRequestToActive = (dispatch: Dispatch) => {
  setEncoderRequestByParams(true, SignalrResponseStatuses.Pending, dispatch);
}

const setEncoderRequestByParams = (isAwaitsEncoderResponse: boolean, signalrResponseStatus: SignalrResponseStatuses, dispatch: Dispatch) => {
  dispatch(setEncoderCardResponse());
  dispatch(setAwaitsEncoderResponse(isAwaitsEncoderResponse));
  dispatch(setSignalrResponseStatus(signalrResponseStatus));
}

export const useSignalrEncoderEvents = () => {
  const openedDialog = useSelector(selectOpenedDialog);
  const selectedUserRow = useSelector(selectSelectedUserRow);
  const isCardEdited = useSelector(selectCardDialogIsEdited)
  const {encoderCardResponse, isEncoderConfirmationDialogOpen, selectedEncoder, isAwaitsEncoderResponse, selectedEncoderStatus } = useSelector(selectEncoderStorage);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setEncoderStatusUpdateFromLocalStorage(() => clearInterval(interval));
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [selectedEncoder, selectedEncoderStatus]);

  /**
   * The method will fetch the encoder status update stored in the localStorage and checks
   * whether the react redux storage needs to be updated or not. If these two values are equal, 
   * then we do not need to update the state in the redux storage, as it will save us unnecessary rerenders.
   * 
   * @param callback Called when the {@link selectedEncoderStatus} is updated on the redux storage.
   * @returns 
   */
  const setEncoderStatusUpdateFromLocalStorage = (callback: () => void) => {
    const storageItem = localStorage.getItem(LocalStorageKeys.EncoderStatusUpdate);

    if (!storageItem) {
      return;
    }

    const encoderStatusUpdate: EncoderStatusUpdateModel = JSON.parse(storageItem);

    if (!encoderStatusUpdate) {
      return;
    }

    const encoderInLocalStorage = localStorage.getItem(LocalStorageKeys.Encoder);

    if (!encoderInLocalStorage && selectedEncoder?.Id !== encoderStatusUpdate.Id) {
      dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsNotSelectedYet));
      return;
    }

    if (encoderStatusUpdate.Status === ConversionsUtil.getStatusFromEncoderStatus(selectedEncoderStatus)) {
      return;
    }

    dispatch(setSelectedEncoderStatus(encoderStatusUpdate.Status === Status.Online
      ? EncoderStatus.EncoderIsActivated
      : EncoderStatus.EncoderIsInactive
    ));
    callback();
  }

  React.useEffect(() => {
    if (!isEncoderResponded(isAwaitsEncoderResponse, encoderCardResponse?.Command)) {
      return;
    }

    switch(encoderCardResponse?.Command) {
      case EncoderResponseDescription.EraseCard:
        handleEraseCardSignalrEvents();
        break;
      case EncoderResponseDescription.ReadCard:
        handleReadCardSignalrEvents();
        break;
      case EncoderResponseDescription.WriteCard:
        if (openedDialog === DialogToOpen.ReissueCardDialog) {
          handleReissueCardSignalrEvents(); 
        } else {
          handleWriteCardSignalrEvents();
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encoderCardResponse, isAwaitsEncoderResponse]);

  const handleReissueCardSignalrEvents = () => {
    switch(encoderCardResponse?.Description) {
      case EncoderResponseDescription.CardNotDetected:
        handleCardIsNotDetectedError();
        dispatch(setSignalrResponseStatus(SignalrResponseStatuses.None));
        break;
      case EncoderResponseDescription.Success:
        notifySuccess("Card has been successfully reissued!", "");
        dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Success));
        break;
      default:
        dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Failed));
        break;
    }
  }

  const handleEraseCardSignalrEvents = () => {
    if (encoderCardResponse?.Description) {
      switch(encoderCardResponse.Description){
        case EncoderResponseDescription.CardNotDetected:
          dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Failed));
          handleCardIsNotDetectedError();
          break;
        case EncoderResponseDescription.Success:
          notifySuccess("Card has been successfully erased!", "");
          dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Success));
          break;
      }
    }
  }

  const handleReadCardSignalrEvents = () => {
    switch(openedDialog) {
      case DialogToOpen.EraseCardReadActionDialog:
        eraseCardReadActionDialogReadCardEvents();
        break;
      case DialogToOpen.EncoderTesterDialog:
        encoderTesterDialogReadCardEvents();
        break;
      default:
        readCardDialogEvents();
        break;
    }
  }

  const handleWriteCardSignalrEvents = () => {
    if (!encoderCardResponse?.Description) {
      return;
    }

    switch(encoderCardResponse?.Description) {
      case EncoderResponseDescription.CardNotDetected:
        dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Failed));
        handleCardIsNotDetectedError();
        break;
      case EncoderResponseDescription.Success:
        dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Success));
        const cardType = ConversionsUtil.getCardTypeFromOpenedDialog(openedDialog);

        if (isCardEdited) {
          notifySuccess(`${cardType}'s expiry date has been successfully updated.`, "");
        } else {
          notifySuccess(`${cardType} has been successfully created!`, "");
        }
        break;
    }
  }

  const eraseCardReadActionDialogReadCardEvents = () => {
    if (encoderCardResponse?.Command) {
      switch(encoderCardResponse.Description) {
        case EncoderResponseDescription.CardIsBlank:
          notifyError("The read card is blank! Please read again a card on the encoder!", "");
          break;
        case EncoderResponseDescription.RequestFailed:
          dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Failed));
          handleCardIsNotDetectedError();
          break;
        default:
          const isReadPhysicalCardTheSameAsTheSelectedUserCard = Number(encoderCardResponse?.UserId) === selectedUserRow?.globalId;
          if (isReadPhysicalCardTheSameAsTheSelectedUserCard) {
            dispatch(setSignalrResponseStatus(SignalrResponseStatuses.None));
            dispatch(setOpenedDialog(DialogToOpen.EraseCardConfirmationActionDialog));
          } else {
            dispatch(setSignalrResponseStatus(SignalrResponseStatuses.Failed));
            notifyError("The selected card and the read physical card are not the same! Please read again a card on the encoder!", "");
          }
          break;
      }
    }
  }

  const encoderTesterDialogReadCardEvents = () => {
    dispatch(setAwaitsEncoderResponse(false));
    if (encoderCardResponse?.Description === EncoderResponseDescription.RequestFailed) {
      if (!isEncoderConfirmationDialogOpen) {
          dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsInactive));
      }
    } else {
      dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsActivated));
      dispatch(setEncoderConfirmationOpen(false));
    }
  }

  const readCardDialogEvents = () => {
    if (!encoderCardResponse?.Command) {
      return;
    }

    const signalrResponseStatus = ConversionsUtil.getSignalrResponseStatusesFromEncoderResponseDescription(encoderCardResponse.Description);

    dispatch(setSignalrResponseStatus(!signalrResponseStatus
      // If card is successfully read, then those card models does not have description attributes.
      ? SignalrResponseStatuses.Success
      : signalrResponseStatus
    ));
  }
}

export const useSignalrConnectionToHub = () => {
  const { signalrEvents } = Connector();
  const dispatch = useDispatch();

  const handleSignalrMessageTypes = (messageObject: EncoderMessageModel) => {
    switch(messageObject.MessageType) {
      case EventMessageType.DoorEvent:
        dispatch(addEventToStore(messageObject.Message, messageObject.SiteName));
        break;
      case EventMessageType.DoorStatus:
        dispatch(addDoorStatusToStore(messageObject.Message));
        break;
      case EventMessageType.EncoderStatus:
        /**
         * Instead of updating the react redux storage, we are storing the encoder status updates on the localStorage,
         * because if we update this state in the redux storage, then those react components where this state is used and fetched
         * via a selector will be forced to rerender. The encoder status updated is fired every 5 secs from the signalr, and due to this,
         * it is not a good practice to update the react components every 5 secs.
         */
        const encoderStatusUpdateModel: EncoderStatusUpdateModel = messageObject.Message;
        localStorage.setItem(LocalStorageKeys.EncoderStatusUpdate, JSON.stringify(encoderStatusUpdateModel));
        break;
      default:
        handleSignalrCommands(messageObject);
        break;
    }
  }

  const handleSignalrCommands = (messageObject: any) => {
    switch(messageObject.Command) {
      case EncoderResponseDescription.WriteCard:
      case EncoderResponseDescription.EraseCard:
      case EncoderResponseDescription.ReadCard:
        // Tell the react components, that they do not need to wait any longer, as the signalR gave back a response.
        dispatch(setAwaitsEncoderResponse(false));
        // Storing the message of the signalR in the redux store.
        dispatch(setEncoderCardResponse(messageObject));
        break;
    }
  }

  React.useEffect(() => {
    // Establishing connection with the signalr.
    signalrEvents((message) => {
      const messageObject = JSON.parse(message);
      if (messageObject) {
        handleSignalrMessageTypes(messageObject);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
import TimeGroup from "model/TimeGroup";
import { GroupNameActions } from "redux/constants/groupNameActions";
import GroupNameActionTypes from "redux/constants/groupNameActionTypes";

type GroupNameListType = {
    loading: boolean,
    groupNameList: TimeGroup[],
    error: string
}

const initialState: GroupNameListType = {
    loading: true,
    groupNameList: [],
    error: ''
}

export const groupNameReducer = (
    state: GroupNameListType = initialState,
    action: GroupNameActions,
  ) => {
    switch (action.type) {
      case GroupNameActionTypes.FETCH_GROUP_NAME_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GroupNameActionTypes.FETCH_GROUP_NAME_SUCCESS:
        return {
          loading: false,
          groupNameList: action.payload,
          error: '',
        };
      case GroupNameActionTypes.FETCH_GROUP_NAME_FAILURE:
        return {
          loading: false,
          groupNameList: [],
          error: action.payload,
        };
      default:
        return state;
    }
};
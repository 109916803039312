import React  from 'react';
import { black, white } from "constants/theme";
import DialogTexts from "model/DialogTexts";
import TemplateDialog from "./TemplateDialog";

type Props = {
    handleSubmit: (dialogId: string) => void;
    handleClose: () => void;
    open: boolean;
    dialogText: DialogTexts;
    submitButtonColor: string;
    isCancelButtonHidden?: boolean;
    isSubmitButtonHidden?: boolean;
    dialogId: string;
    additionalFooterRowContent?: JSX.Element;
};

const DialogTemplate = ({
    handleSubmit, 
    handleClose, 
    open, 
    dialogText,
    submitButtonColor,
    isCancelButtonHidden,
    isSubmitButtonHidden,
    dialogId,
    additionalFooterRowContent,
}: Props) => {
    const ConfirmationDialogStyle = {
        dialog: { color: black, width: '600px', borderRadius: '20px', textAlign: 'center' as const, bottom: '20vh'},
        title: { alignSelf: 'center', fontWeight: 'bold' },
        content: { paddingBottom: '0' },
        contentText: { 
            color: 'rgba(0, 0, 0, 1)',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24.35px',
        },
        actions: { display: 'flex', justifyContent: 'end', padding: '24px 32px' },
        submitButton: {
            backgroundColor: submitButtonColor,
            color: white,
            borderRadius: '8px',
            padding: '8px 24px',
            textTransform: 'none' as const,
            display: isSubmitButtonHidden ? 'none' : 'block',
        },
        cancelButton: {
            color: black,
            border: '1px solid rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            padding: '8px 24px',
            textTransform: 'none' as const,
            display: isCancelButtonHidden ? 'none' : 'block',
        },
      };

    return (<TemplateDialog 
        dialogText={dialogText} 
        open={open} 
        handleSubmit={() => handleSubmit(dialogId)} 
        handleClose={handleClose} 
        style={ConfirmationDialogStyle} 
        additionalFooterRowContent={additionalFooterRowContent}
    />);
}

export default DialogTemplate;
import * as React from 'react';
import { StyledContentRowHolder } from '../../userCardDialog/UserDialogCommonStyle';
import { CardDialogCardDetailsInputFieldNames, InputFieldPlaceholderValues, DialogToOpen, UserDialogFormBluetoothFormPlaceHolderText, CardStatus } from 'model/enums';
import styled from 'styled-components';
import { restrictedKeyCodes } from 'constants/RestrictedKeyCodes';
import { NumberLimitsUtil } from 'helper/NumberLimitsUtil';
import NumberInputField from 'components/controls/inputs/NumberInputField';
import { useSelector } from 'react-redux';
import { selectDepartmentList, selectDepartmentListNames } from 'redux/selectors/departmentSelector';
import SelectField from 'components/controls/selects/SelectField';
import DateAndTimePickerContainer from 'components/controls/datePicker/DateAndTimePickerContainer';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import { selectBuildingList } from 'redux/selectors/buildingSelector';
import { useDispatch } from 'react-redux';
import { selectCardDialogIsEdited, selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { CardDetailsForm } from 'model/CardDialogForm';
import { selectOpenedDialog, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { useListFloorsByBuildingId } from 'hooks/useBuildings';
import { useIsCardFieldDisplayed } from 'hooks/useIsInputDisplayed';
import { useCardDialogFormFiller } from 'hooks/useFormFiller';
import SearchAndAddUserRow from '../cardComponents/SearchAndAddUserRow';
import { searchbarAndDepartmentInputFieldWidth } from 'constants/globalConstants';


const CardDetailsDialog = () => {
    const { form, formError } = useSelector(selectCardDialogStorage);
    const listOfDepartments = useSelector(selectDepartmentList);
    const departmentListNames = useSelector(selectDepartmentListNames);
    const listOfBuildings = useSelector(selectBuildingList);
    const openedDialog = useSelector(selectOpenedDialog);
    const listOfFloors = useListFloorsByBuildingId(form?.cardDetails.building?.Id);
    const inputDisplayer = useIsCardFieldDisplayed();
    const isEdited = useSelector(selectCardDialogIsEdited);
    const dispatch = useDispatch();
    useCardDialogFormFiller();
    const selectedUserRow = useSelector(selectSelectedUserRow);
    const isLostCard = selectedUserRow?.cardInUse === CardStatus.Lost;
    const checkboxTextWidth = [DialogToOpen.CreateEmergencyCardDialog, DialogToOpen.CreateFloorCardDialog].some(dialogToOpen => dialogToOpen === openedDialog) ? "936px" : "220px";
    const dateAndTimePickerWidth = "220px";
    const floorAndBuildingSelectFieldWidth = "456px";
    const commonFieldHeight = "40px";

    const setForm = (cardDetails?: CardDetailsForm) => {
        if(form && cardDetails) {
            dispatch(setCardDialogForm({...form, cardDetails}));
        }
    }

    return (
        <StyledCardDetailsDialogHolder>
            <CustomRowHolder>
                <SearchAndAddUserRow />
            </CustomRowHolder>

            <CustomRowHolder>
                { inputDisplayer.isDepartmentDisplayed ?
                    <SelectField
                        inputFieldHeight={commonFieldHeight}
                        inputFieldWidth={openedDialog === DialogToOpen.CreateFloorCardDialog ? "937px" : searchbarAndDepartmentInputFieldWidth}
                        displayedName={CardDialogCardDetailsInputFieldNames.Department}
                        placeholderInput={InputFieldPlaceholderValues.Select}
                        selectOptions={departmentListNames}
                        setValue={(_, index) => setForm({...form?.cardDetails, department: listOfDepartments[index]})}
                        inputValue={form?.cardDetails.department?.Name}
                        isRequired
                        invalidFormMessage={formError?.cardDetails.department}
                        isDisabled
                    />
                : null}

                { inputDisplayer.isLockReleaseDisplayed ?
                    <NumberInputField
                        inputFieldHeight={commonFieldHeight}
                        inputFieldWidth={"100%"}
                        displayedName={CardDialogCardDetailsInputFieldNames.LockReleaseTime}
                        placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.LockReleaseTime}
                        inputValue={form?.cardDetails.lockReleaseTime}
                        setValue={(lockReleaseTime) => setForm({...form?.cardDetails, lockReleaseTime})}
                        invalidFormMessage={formError?.cardDetails.lockReleaseTime}
                        restrictKeysToPress={restrictedKeyCodes}
                        inputProps={{min: 0, max: NumberLimitsUtil.UBYTE_UPPER_LIMIT, maxLength: NumberLimitsUtil.UBYTE_UPPER_LIMIT_MAX_LENGTH}}
                        isStepperDisplayed={false}
                        isRequired
                        disabled
                    />
                : null}
            </CustomRowHolder>

            <CustomRowHolder display={"flow"}>
                <StyledPickerHolder>
                    <DateAndTimePickerContainer
                        isUnlimitedDateChecked={form?.cardDetails.isUnlimitedDateChecked || isEdited}
                        dateAndTimePickerWidth={dateAndTimePickerWidth}
                        setDate={startDate => setForm({...form?.cardDetails, startDate})}
                        setTime={startTime => setForm({...form?.cardDetails, startTime})}
                        setCheckbox={isStartDateChecked => setForm({
                            ...form?.cardDetails,
                            isStartDateChecked,
                            isUnlimitedDateChecked: !isStartDateChecked && !form?.cardDetails.isLastDateChecked,
                        })}
                        dateForm={{date: form?.cardDetails.startDate, error: formError?.cardDetails.startDate, disabled: !form?.cardDetails.isStartDateChecked || isEdited}}
                        timeForm={{time: form?.cardDetails.startTime, error: formError?.cardDetails.startTime, disabled: !form?.cardDetails.isStartDateChecked || isEdited}}
                        titleForm={{name: CardDialogCardDetailsInputFieldNames.StartDate, isCheckboxDisplayed: true, checkboxValue: form?.cardDetails.isStartDateChecked}}
                        isRequired={openedDialog === DialogToOpen.CreateKeyCardDialog}
                    />
                    <DateAndTimePickerContainer
                        isUnlimitedDateChecked={form?.cardDetails.isUnlimitedDateChecked || isLostCard}
                        dateAndTimePickerWidth={dateAndTimePickerWidth}
                        setDate={(lastDate) => setForm({...form?.cardDetails, lastDate})}
                        setTime={(lastTime) => setForm({...form?.cardDetails, lastTime})}
                        setCheckbox={isLastDateChecked => setForm({
                            ...form?.cardDetails, 
                            isLastDateChecked,
                            isUnlimitedDateChecked: !isLastDateChecked && !form?.cardDetails.isStartDateChecked,
                        })}
                        dateForm={{date: form?.cardDetails.lastDate, error: formError?.cardDetails.lastDate || formError?.cardDetails.isLastDateTimeBiggerThenStartDatetime, disabled: !form?.cardDetails.isLastDateChecked}}
                        timeForm={{time: form?.cardDetails.lastTime, error: formError?.cardDetails.lastTime, disabled: !form?.cardDetails.isLastDateChecked}}
                        titleForm={{ name: CardDialogCardDetailsInputFieldNames.LimitedDate, isCheckboxDisplayed: true, checkboxValue: form?.cardDetails.isLastDateChecked}}
                        isRequired
                    />
                    <CheckboxField
                        boxWidth={checkboxTextWidth}
                        boxHeight={"68px"}
                        displayedName={CardDialogCardDetailsInputFieldNames.UnlimitedDate}
                        setValue={(isUnlimitedDateChecked) => setForm({
                            ...form?.cardDetails,
                            isUnlimitedDateChecked,
                            isStartDateChecked: isEdited ? false : !isUnlimitedDateChecked,
                            isLastDateChecked: !isUnlimitedDateChecked,
                        })}
                        inputValue={form?.cardDetails.isUnlimitedDateChecked}
                        isDisabled={isLostCard || form?.cardDetails.isStartDateChecked || form?.cardDetails.isLastDateChecked}
                    />
                </StyledPickerHolder>
            </CustomRowHolder>

            { inputDisplayer.isBuildingAndFloorSelectorDisplayed ?
                <CustomRowHolder>
                    <SelectField
                        inputFieldHeight={commonFieldHeight}
                        inputFieldWidth={floorAndBuildingSelectFieldWidth}
                        displayedName={CardDialogCardDetailsInputFieldNames.Building}
                        placeholderInput={InputFieldPlaceholderValues.Select}
                        selectOptions={listOfBuildings.map(building => building.Name)}
                        setValue={(_, index) => setForm({...form?.cardDetails, building: listOfBuildings[index], floor: undefined})}
                        inputValue={form?.cardDetails.building?.Name}
                        isRequired
                        invalidFormMessage={formError?.cardDetails.building}
                        isDisabled={isLostCard || isEdited}
                    />
                    <SelectField
                        inputFieldHeight={commonFieldHeight}
                        inputFieldWidth={floorAndBuildingSelectFieldWidth}
                        displayedName={CardDialogCardDetailsInputFieldNames.Floor}
                        placeholderInput={InputFieldPlaceholderValues.Select}
                        selectOptions={listOfFloors.map(floor => floor.Name)}
                        setValue={(_, index) => setForm({...form?.cardDetails, floor: listOfFloors[index]})}
                        inputValue={form?.cardDetails.floor?.Name}
                        isDisabled={!form?.cardDetails.building || isLostCard || isEdited}
                        isRequired
                        invalidFormMessage={formError?.cardDetails.floor}
                    />
                </CustomRowHolder>
            : null }
        </StyledCardDetailsDialogHolder>
    );
};

export default CardDetailsDialog;

const StyledPickerHolder = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledCardDetailsDialogHolder = styled.div`
    display: grid;
    gap: 24px;
`;

const CustomRowHolder = styled(StyledContentRowHolder)<{ display?: string }>((props) => ({
    gap: "24px",
    display: props.display ?? "flex",
}));

import { CardDialogPages } from 'model/enums';
import React, { useMemo } from 'react';
import CardDetailsDialog from '../menuOptions/CardDetailsDialog';
import UserAccessPage from '../../userCardDialog/contentRightSide/UserAccessPage';
import CardLevelsDialog from '../menuOptions/CardLevelsDialog';
import { useSelector } from 'react-redux';
import { selectCardDialogSelectedPage } from 'redux/selectors/cardDialogSelector';
import { StyledDialogContentRightSide } from '../../userCardDialog/UserCardDialog';

export const CardDialogMenuPage = () => {
  const selectedPageName = useSelector(selectCardDialogSelectedPage);

  const selectedMenuPage = useMemo(() => {
    const handler = {
      [CardDialogPages.CardDetails]: <CardDetailsDialog />,
      [CardDialogPages.Doors]: <UserAccessPage />,
      [CardDialogPages.Levels]: <CardLevelsDialog />,
    };
    return(handler[selectedPageName.toString()]);
  }, [selectedPageName])

  return (
    <StyledDialogContentRightSide>
      {selectedMenuPage}
    </StyledDialogContentRightSide>
  );
};

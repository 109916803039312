import * as React from 'react';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';
import { displayInputName } from 'helper/Util';

type Props = {
    inputLabel?: string;
    isRequired?: boolean;
};

const InputFieldLabel = ({ 
    inputLabel, 
    isRequired
}: Props) => {

return (
    <React.Fragment>
        { inputLabel ? 
            <StyledInputFieldComponents.StyledTextLabelHolder>
                {displayInputName(inputLabel, isRequired)}
            </StyledInputFieldComponents.StyledTextLabelHolder>
        : null}
    </React.Fragment>
  );
};

export default InputFieldLabel;

import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectUsersToDelete } from 'redux/selectors/userSelector';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import { useDispatch } from 'react-redux';
import { setUsersToDelete } from 'redux/actions/userActions';

const CardCheckboxHeaderCell = () => {
  const usersToDelete = useSelector(selectUsersToDelete);
  const isChecked = usersToDelete.length > 0 && usersToDelete.every((user) => user.isCheckboxChecked);
  const dispatch = useDispatch();

  return (
    <CheckboxField
      boxWidth={'60px'}
      boxHeight={'24px'}
      inputValue={isChecked}
      isIndeterminate={!isChecked && usersToDelete.length > 0 && usersToDelete.some((user) => user.isCheckboxChecked)}
      setValue={isChecked => dispatch(setUsersToDelete(usersToDelete.map(user => ({ userId: user.userId, isCheckboxChecked: isChecked }))))}
    />
  );
};

export default CardCheckboxHeaderCell;

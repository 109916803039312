import React from 'react';
import styled from 'styled-components';
import { white } from 'constants/theme';

export default function NetworkError() {
  return (
    <Container>
      <Message>
        <h1>Network error</h1>
        <p>Servers are currently down!</p>
      </Message>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 60px 100px;
  align-items: center;
  padding: 100px 50px;
  justify-content: center;
  background-color: ${white};
  border-radius: 10px;
`;

const Message = styled.div`
  padding: 50px 250px 50px 50px;
`;
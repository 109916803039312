import { DialogToOpen, PageNavigation } from "model/enums";
import { UserDeletion } from "model/UserDeletion";
import UserModel from "model/UserModel";
import UserTableRecord from "model/UserTableRecord";
import { UserActions } from "redux/constants/userActions";
import UserActionTypes from "redux/constants/userActionTypes";

type UserListType = {
    loading: boolean,
    isBleUserLoading: boolean,
    isRefreshUserList: boolean;
    selectedUserRow?: UserTableRecord;
    openedDialog?: DialogToOpen;
    userModels: UserModel[],
    newUsersOnPendingModels: UserModel[],
    usersToDelete: UserDeletion[];
    pageNavigation: PageNavigation;
    operatorId?: number;
    error: string
}

const initialState: UserListType = {
    loading: true,
    isBleUserLoading: false,
    isRefreshUserList: false,
    selectedUserRow: undefined,
    openedDialog: undefined,
    userModels: [],
    newUsersOnPendingModels: [],
    usersToDelete: [],
    pageNavigation: PageNavigation.Overview,
    operatorId: undefined,
    error: ''
}

export const userReducer = (
    state: UserListType = initialState,
    action: UserActions,
  ): UserListType => {
    switch (action.type) {
      case UserActionTypes.USERS_REQUESTED:
        return {
          ...state,
          loading: true,
        };
      case UserActionTypes.REQUEST_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          userModels: action.payload,
          error: '',
        };
    case UserActionTypes.SET_EMPTY_USER_LIST:
        return {
            ...state,
            loading: false,
            userModels: [],
        };
      case UserActionTypes.REQUEST_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          userModels: [],
          error: action.payload,
        };
      case UserActionTypes.SET_USERS_LIST_REFRESH:
        return {
          ...state,
          loading: false,
          isRefreshUserList: action.payload,
        };
      case UserActionTypes.SET_SELECTED_USER:
        return {
          ...state,
          loading: false,
          selectedUserRow: action.payload,
        };
      case UserActionTypes.SET_OPENED_DIALOG:
        return {
          ...state,
          openedDialog: action.payload,
        }
      case UserActionTypes.SET_USERS_TO_DELETE:
        return {
          ...state,
          usersToDelete: action.payload,
        }
      case UserActionTypes.SET_PAGE_NAVIGATION:
        return {
          ...state,
          pageNavigation: action.payload,
        }
      case UserActionTypes.SET_NEW_USERS_ON_PENDING:
        return {
          ...state,
          newUsersOnPendingModels: action.payload
        }
      case UserActionTypes.SET_OPERATOR:
        return {
          ...state,
          operatorId: action.payload,
        }
      case UserActionTypes.SET_BLE_USER_LOADING:
        return {
          ...state,
          isBleUserLoading: action.payload,
        }
      default:
        return state;
    }
};
import React from 'react';
import { Divider } from '@mui/material';
import { CardToolTipViewOptions } from 'model/enums';
import styled from 'styled-components';
import { dividerGray } from 'constants/theme';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import ViewTemplateCard from './ViewTemplateCard';
import { getCardToolTipsByCardStatus } from 'constants/cardIcons';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenedDialog, setUsersSelectedUserRow } from 'redux/actions/userActions';
import { selectUserListStorage } from 'redux/selectors/userSelector';

const ViewOptionWindowCard = () => {
  const dispatch = useDispatch();
  const {pageNavigation, selectedUserRow} = useSelector(selectUserListStorage);

  const handleSelectedIcon = (selectedIcon: CardToolTipViewOptions) => {
    if (selectedIcon === CardToolTipViewOptions.Back) {
      dispatch(setUsersSelectedUserRow(undefined));
      dispatch(setOpenedDialog(undefined));
      return;
    }
    dispatch(setOpenedDialog(ConversionsUtil.cardToolTipToDialogToOpen(selectedIcon)));
  }

  return (
    <StyledCardHolder>
      <StyledParentCard>
        <div>
          <StyledTemplateContainer>
            <ViewTemplateCard
              setSelectedIcon={handleSelectedIcon}
              renderIconTemplate={[CardToolTipViewOptions.Back]}
            />
          </StyledTemplateContainer>
        </div>
        <StyledRight>
          <ViewTemplateCard 
            setSelectedIcon={handleSelectedIcon}
            renderIconTemplate={getCardToolTipsByCardStatus(selectedUserRow?.cardInUse, pageNavigation, true)} 
          />
        </StyledRight>
      </StyledParentCard>
      <StyledDivider />
    </StyledCardHolder>
  );
};

export default ViewOptionWindowCard;

const StyledCardHolder = styled.div`
  margin-left: 5px;
`;

const StyledDivider = styled(Divider)`
  height: 16px;
  border-color: ${dividerGray};
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledParentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTemplateContainer = styled.div`
  width: 40px;
  margin-left: 5px;
`;

const StyledRight = styled.div`
  display: flex;
`;
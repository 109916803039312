enum RoomActionTypes {
    ROOMS_REQUESTED = "ROOMS_REQUESTED",
    REQUEST_ROOMS_SUCCESS = "REQUEST_ROOMS_SUCCESS",
    REQUEST_ROOMS_FAILURE = "REQUEST_ROOMS_FAILURE",
    LOCK_TYPES_REQUESTED = "LOCK_TYPES_REQUESTED",
    REQUEST_LOCK_TYPES_SUCCESS = "REQUEST_LOCK_TYPES_SUCCESS",
    REQUEST_LOCK_TYPES_FAILURE = "REQUEST_LOCK_TYPES_FAILURE",
    SET_EMPTY_ROOMS = "SET_EMPTY_ROOMS",
};

export default RoomActionTypes;
import * as React from 'react';
import InputFieldTemplate from './InputFieldTemplate';
import { KeyCode } from 'model/enums';
import { InputProps } from 'model/InputProps';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    invalidFormMessage?: string;
    inputType?: string,
    restrictKeysToPress?: KeyCode[];
    inputProps?: InputProps;
    setValue?: (value?: string) => void;
};

const MultiTypeInputField = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName,
    placeholderInput,
    inputValue,
    isDisabled,
    isRequired,
    invalidFormMessage,
    inputType,
    restrictKeysToPress = [],
    inputProps,
    setValue,
}: Props) => {

    return (
        <InputFieldTemplate
            inputFieldHeight={inputFieldHeight}
            inputFieldWidth={inputFieldWidth}
            displayedName={displayedName}
            placeholderInput={placeholderInput}
            inputValue={inputValue}
            setValue={setValue}
            invalidFormMessage={invalidFormMessage}
            inputType={inputType}
            isRequired={isRequired}
            isDisabled={isDisabled}
            restrictKeysToPress={restrictKeysToPress}
            inputProps={inputProps}
        />
    );
};

export default MultiTypeInputField;
import { getDoorGroupsDetails } from "API/commands/DoorGroupCommands";
import DoorGroup from "model/DoorGroup";
import { Dispatch } from "redux";
import DoorGroupActionTypes from "redux/constants/doorGroupActionTypes";

export const fetchDoorGroupsRequest = () => {
    return { type: DoorGroupActionTypes.FETCH_DOOR_GROUPS_REQUEST };
  };
  export const fetchDoorGroupsSuccess = (data: DoorGroup[] | null) => {
    return {
      type: DoorGroupActionTypes.FETCH_DOOR_GROUPS_SUCCESS,
      payload: data,
    };
  };
  export const fetchDoorGroupsFailure = (err: any) => {
    return {
      type: DoorGroupActionTypes.FETCH_DOOR_GROUPS_FAILURE,
      payload: err,
    };
  };
  export const fetchAllDoorGroups = (siteName: string, dispatch: Dispatch) => {
    dispatch(fetchDoorGroupsRequest());
    dispatch(clearDoorGroups());
    getDoorGroupsDetails(siteName)
      .then((res) => dispatch(fetchDoorGroupsSuccess(res)))
      .catch((err) => dispatch(fetchDoorGroupsFailure(err)));
  };
  export const clearDoorGroups = () => {
    return { type: DoorGroupActionTypes.CLEAR_DOOR_GROUPS };
  };
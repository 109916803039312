import styled from "styled-components";
import { datePickerGrey, dividerGray, lighterGrey, white } from "./theme";
import { heightOfButtonAndLinearProgressBarHolder } from "./globalConstants";

export const UnusedGlobalStylePLaceholder = styled.div``;

type ScrollBarProps = {
    width: string,
}

/**
 * Replace the default scroll bar with a custom made scroll bar.
 * @returns Returns the css properties of the newly created scroll bar.
 */
export const customScrollBarCSSProperties = (props?: ScrollBarProps) => ({
    overflowY: 'scroll',
    '&::-webkit-scrollbar' : { width: props?.width ?? "5px" },
    '&::-webkit-scrollbar-track' : { background: 'none' },
    '&::-webkit-scrollbar-thumb' : {
        background: dividerGray,
        borderRadius: '12px',
        '&:hover': { background: lighterGrey }
    },
});

export const dialogContainer = () => ({
    maxWidth: `1000px`,
    borderRadius: `20px`,
    border: `1px solid ${datePickerGrey}`,
    backgroundColor: `${white}`,
});

export const buttonAndLinearProgressBarCommonStyles = { height: heightOfButtonAndLinearProgressBarHolder };

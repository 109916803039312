import dayjs from 'dayjs';
import RoomModel from './RoomModel';
import MasterLevel from './MasterLevel';
import Building from './Building';
import Floor from './Floor';
import OptionModel from './OptionModel';
import CardModel from './CardModel';
import DepartmentDetail from './DepartmentDetail';
import { DoorGroupType } from './NewUserModel';

export default class CardDialogForm {
  constructor(
    public cardDetails: CardDetailsForm,
    public cardLevels: CardLevelsForm[],
    public cardDoors: RoomModel[],
    public doorGroups: DoorGroupType[] | null,
    public userCard?: CardModel,
  ) {}
}

export type CardDetailsForm = {
    user?: OptionModel,
    startDate?: dayjs.Dayjs | null | undefined,
    startTime?: dayjs.Dayjs | null | undefined,
    lastDate?: dayjs.Dayjs | null | undefined,
    lastTime?: dayjs.Dayjs | null | undefined,
    department?: DepartmentDetail,
    lockReleaseTime?: string,
    isStartDateChecked?: boolean,
    isLastDateChecked?: boolean,
    isUnlimitedDateChecked?: boolean,
    building?: Building,
    floor?: Floor,
    isUserSelected?: boolean;
    // Tells whether to update the card dialog form with the data of the user.
    isFormOverridableByUser?: boolean;
};

export type CardLevelsForm = {
  masterLevel?: MasterLevel;
  isChecked?: boolean;
};


import * as React from 'react';
import CustomDataGridTable from 'components/controls/dataGridTable/CustomDataGridTable';
import RoomModel from 'model/RoomModel';
import RoomTableRecords from 'model/RoomTableRecords';
import chevronLeft from 'media/chevron-left.svg';
import { StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder, StyledDialogCardHolder, StyledDialogContentHolder } from '../../UserCardDialog';
import { Dialog, DialogTitle } from '@mui/material';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import StyledIconButton from 'components/controls/button/StyledIconButton';
import styled from 'styled-components';
import { white } from 'constants/theme';
import StyledButton from 'components/controls/button/StyledButton';
import DoorGroup from 'model/DoorGroup';
import { useSelector } from 'react-redux';
import { selectMatchedRooms } from 'redux/selectors/roomSelector';
import { dialogContainer } from 'constants/globalStyle';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';

type Props = {
    isDialogOpen: boolean;
    setDialogOpen: (isDialogOpen: boolean) => void;
    selectedDoorGroup: DoorGroup;
};

const DoorGroupDialog = ({ 
    isDialogOpen,
    setDialogOpen,
    selectedDoorGroup,
}: Props) => {

    const matchedRooms = useSelector(selectMatchedRooms(selectedDoorGroup));
    const [rooms, setRooms] = React.useState<RoomModel[]>([]);
    const [roomRows, setRoomRows] = React.useState<RoomTableRecords[]>([]);
    const [dialogTitle, setDialogTitle] = React.useState<string>("");
    const listOfMasterLevels = useSelector(selectMasterLevelList);

    React.useEffect(() => {
        if (isDialogOpen) {
            setRooms(matchedRooms);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen, matchedRooms]);

    React.useEffect(() => {
        if (isDialogOpen) {
            setDoorGroupDialogRows();
            setDialogTitle(selectedDoorGroup.GroupName);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rooms]);

    const setDoorGroupDialogRows = () => setRoomRows(ConversionsUtil.mapToRooms(rooms, listOfMasterLevels));

    const handleClickOnClose = () => {
        setDialogOpen(false);
    };
    
    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
                <CustomStyledDialogCardTitle>
                    <CustomDialogTitleComponent>
                        <StyledIconButton
                            handleOnClick={handleClickOnClose}
                            icon={chevronLeft}
                        />
                    </CustomDialogTitleComponent>
                    <div>
                        {dialogTitle}
                    </div>
                    <CustomDialogTitleComponent />
                </CustomStyledDialogCardTitle>
                <StyledDialogContentHolder>
                    <CustomDataGridTable
                        dataRows={roomRows}
                        rowHeight={38}
                        height='100%'
                    />
                </StyledDialogContentHolder>
                <StyledDialogActions>
                    <StyledDialogActionsHolder>
                        <StyledButtonHolder>
                            <StyledButton
                                buttonHeight={37}
                                buttonWidth={95}
                                displayedName={"Cancel"}
                                handleOnClick={handleClickOnClose}
                                backgroundColor={white}
                            />
                        </StyledButtonHolder>
                    </StyledDialogActionsHolder>
                </StyledDialogActions>
            </StyledDialogCardHolder>
        </Dialog>
    );
};

export default DoorGroupDialog;

export const CustomStyledDialogCardTitle = styled(DialogTitle)`
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
    display: flex;
    padding: 12px 16px 12px 16px;
    justify-content: space-between;
    align-items: center;
`;

export const CustomDialogTitleComponent = styled(CustomStyledDialogCardTitle)`
    height: 24px;
    width: 24px;
`;
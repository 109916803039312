import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { Checked } from 'model/MultiStateCheckbox';
import { lightGrey, mainBlue, veryLightGrey } from 'constants/theme';

type Props = {
  onChange: () => void;
  value: Checked;
};

const MultiStateCheckBox = ({ onChange, value }: Props) => {
  const [checked, setChecked] = useState<Checked>(0);
  useState<Checked | null>(null);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleClick = () => {
    onChange();
  };

  const renderCheckData = () => {
    switch (checked) {
      case 1:
        return <HalfChecked />;
      case 2:
        return <FullyChecked />;
      default:
        return null;
    }
  };

  return (
    <Container onClick={() => handleClick()}>{renderCheckData()}</Container>
  );
};
export default MultiStateCheckBox;

const Container = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${lightGrey};
  border-radius: 5px;
  margin: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const HalfChecked = styled.div`
  width: 10px;
  height: 10px;
  left: 119px;
  top: 826px;

  background: ${mainBlue};
  border-radius: 5px;
`;
const FullyChecked = styled.div`
  width: 18px;
  height: 18px;
  left: 113px;
  top: 820px;
  border: 1px solid ${veryLightGrey};
  border-radius: 5px;
  background-color: ${mainBlue};

  display: flex;
  align-items: center;
  justify-content: center;
`;

import DepartmentDetail from "model/DepartmentDetail";
import { DepartmentActions } from "redux/constants/departmentActions";
import DepartmentActionTypes from "redux/constants/departmentActionTypes";

type DepartmentListType = {
    loading: boolean,
    departmentList: DepartmentDetail[],
    error: string
}

const initialState: DepartmentListType = {
    loading: true,
    departmentList: [],
    error: ''
}

export const departmentReducer = (
    state: DepartmentListType = initialState,
    action: DepartmentActions,
  ):DepartmentListType => {
    switch (action.type) {
      case DepartmentActionTypes.FETCH_DEPARTMENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DepartmentActionTypes.FETCH_DEPARTMENT_SUCCESS:
        return {
          loading: false,
          departmentList: action.payload,
          error: '',
        };
      case DepartmentActionTypes.FETCH_DEPARTMENT_FAILURE:
        return {
          loading: false,
          departmentList: [],
          error: action.payload,
        };
      default:
        return state;
    }
};
import { CommandCategory, OnlineDoorCommand } from 'model/enums';
import OnlineDoor from 'model/OnlineDoor';
import postCommand from './Command';
import { AxiosError } from 'axios';
import { notifyCommandError } from './Command';
import DoorSummary from 'model/DoorSummary';
import { notifyError } from 'helper/NotificationService';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const notifyDoorError = (error: AxiosError | null) => {
  notifyCommandError('Error while executing door command', error);
};

export const getOnlineDoors = async (siteName: string) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.OnlineDoorService,
      OnlineDoorCommand.GetOnlineDoors,
      [{}],
    );
    if (data && data?.data) {
      const doors: OnlineDoor[] = data.data;
      return doors;
    } else {
      notifyCommandError('No doors found or the server timed out.', null);
      throw new Error('No doors found or the server timed out.');
    }
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyDoorError(error);
    return [];
  }
};

export const postDoorCommand = async (
  siteName: string,
  roomId: number,
  commandType: OnlineDoorCommand,
) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.OnlineDoorService,
      commandType,
      [roomId],
    );
    if (data && data?.data) {
    if(data?.status !== 200) {
        notifyError(
          `${commandType} command error.`,
          `Status code ${data?.status} recieved from server.`,
        );
      }
      return data?.data;
    } else {
      notifyCommandError(
        `Command did not return a reply (${commandType})`,
        null,
      );
      return null;
    }
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyDoorError(error);
    return null;
  }
};

export const getSummary = async (
  siteName: string,
) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.SystemService,
      OnlineDoorCommand.GetSummary,
      []
    );
    if (data && data?.data) {
      const doorSummary: DoorSummary = data.data;
      return doorSummary;
    } else {
      notifyCommandError(
        'No door summary found or the server timed out.',
        null,
      );
      return null;
    }
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyDoorError(error);
    return null;
  }
}

import * as React from 'react';
import EraseCardReadActionDialog from './EraseCardReadActionDialog';
import EraseCardConfActionDialog from './EraseCardConfActionDialog';

const EraseCardDialog = () => {

    return (
        <React.Fragment>
            <EraseCardConfActionDialog />
            <EraseCardReadActionDialog />
        </React.Fragment>
    );
}

export default EraseCardDialog;
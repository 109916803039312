import DoorGroup from "model/DoorGroup"
import { DoorGroupAction } from "redux/constants/doorGroupActions"
import DoorGroupActionTypes from "redux/constants/doorGroupActionTypes"

type DoorGroupType = {
    loading: boolean,
    doorGroups: DoorGroup[],
    error: string
}

const initialState: DoorGroupType = {
    loading: true,
    doorGroups: [],
    error: ''
}

export default function doorGroupReducer(state: DoorGroupType = initialState, action: DoorGroupAction) {
    switch (action.type) {
        case DoorGroupActionTypes.FETCH_DOOR_GROUPS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DoorGroupActionTypes.FETCH_DOOR_GROUPS_SUCCESS:
            return {
                loading: false,
                doorGroups: action.payload,
                error: ''
            }
        case DoorGroupActionTypes.FETCH_DOOR_GROUPS_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        case DoorGroupActionTypes.CLEAR_DOOR_GROUPS:
            return {
                ...state,
                data: [],
            }
        default:
            return state;
    }
}
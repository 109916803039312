import * as React from 'react';
import { DeleteButtonStyle, StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder } from '../../userCardDialog/UserCardDialog';
import StyledButton from 'components/controls/button/StyledButton';
import { blue, red, white } from 'constants/theme';
import { ButtonHolder, StyledLinearProgressBar } from '../../TemplateDialog';
import { selectIsAwaitsEncoderResponse } from 'redux/selectors/encoderSelector';
import { useSelector } from 'react-redux';
import { selectCardDialogIsEdited, selectCardDialogIsOpen, selectCarDialogIsSaveButtonDisabled } from 'redux/selectors/cardDialogSelector';


type Props = {
    displayButtonName: string,
    isDialogOpenAsEdit?: boolean;
    isSaveButtonDisabled?: boolean;
    isDeleteAllowed: boolean;
    handleClickOnClose: () => void;
    setSubmitClicked: () => void;
    setIsDeleteDialogOpen: () => void;
};

const DialogActionButtons = ({
    displayButtonName,
    isDialogOpenAsEdit,
    isSaveButtonDisabled,
    isDeleteAllowed,
    handleClickOnClose,
    setSubmitClicked,
    setIsDeleteDialogOpen,
}: Props) => {
    const isAwaitsEncoderResponse = useSelector(selectIsAwaitsEncoderResponse);
    const cardDialogIsSaveButtonDisabled = useSelector(selectCarDialogIsSaveButtonDisabled);
    const cardDialogIsEdited = useSelector(selectCardDialogIsEdited);
    const isCardDialogOpen = useSelector(selectCardDialogIsOpen);
    const isOpenAsEdit = cardDialogIsEdited || isDialogOpenAsEdit;
    const isDisabled = (cardDialogIsSaveButtonDisabled && isCardDialogOpen) || isSaveButtonDisabled;

    return (
        <StyledDialogActions>
            <StyledDialogActionsHolder>
                {isDeleteAllowed && isDialogOpenAsEdit &&
                    <StyledButton
                        buttonHeight={37}
                        buttonWidth={120}
                        displayedName={`Delete ${displayButtonName}`}
                        handleOnClick={setIsDeleteDialogOpen}
                        isFilledButton
                        backgroundColor={red}
                        style={DeleteButtonStyle}
                    />
                }
                <StyledButtonHolder>
                    <StyledButton
                        buttonHeight={37}
                        buttonWidth={95}
                        displayedName={"Cancel"}
                        handleOnClick={handleClickOnClose}
                        backgroundColor={white}
                    />
                    <ButtonHolder> 
                        <StyledButton
                            handleOnClick={setSubmitClicked}
                            displayedName={isOpenAsEdit ? "Save" : `Add ${displayButtonName}`}
                            buttonHeight={37}
                            buttonWidth={111}
                            isFilledButton
                            backgroundColor={blue}
                            disabled={isDisabled}
                        />
                        { isAwaitsEncoderResponse ? <StyledLinearProgressBar /> : null}
                    </ButtonHolder>
                </StyledButtonHolder>
            </StyledDialogActionsHolder>
        </StyledDialogActions>
    );
};

export default DialogActionButtons;

import React from 'react';
import { styled } from 'styled-components';
import Button from './Button';
import { OnlineDoorCommand } from 'model/enums';
import Building from 'model/Building';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import OnlineDoor from 'model/OnlineDoor';
import { useDispatch } from 'react-redux';
import { addLoadingToLockDownStatus, addLoadingToLockStatus } from 'redux/actions/doorStatusActions';

type Props = {
  lock: OnlineDoorCommand,
  access: OnlineDoorCommand,
  commandDoor: (command: OnlineDoorCommand) => void,
  confirmCommand: (
      commandType?: OnlineDoorCommand,
      buildings?: Building[],
      floorsWithBuildingId?: FloorWithBuildingName[],
      doors?: OnlineDoor[],
    ) => void,
  door: OnlineDoor
}

const DoorCardButtons = ( {lock, access, commandDoor, confirmCommand, door}: Props) => {
  const dispatch = useDispatch();

  const handleLockClick = (action: OnlineDoorCommand) => {
    dispatch(addLoadingToLockStatus(door.Id))
    commandDoor(action)
  }
  const handleAccessClick = (action: OnlineDoorCommand) => {
    dispatch(addLoadingToLockDownStatus(door.Id))
    commandDoor(action)
  }

  return (
    <>
        <Coupler>
            <Button
              onClick={() => commandDoor(OnlineDoorCommand.ReleaseRoom)}
              text={'Release'}
            />
            <Button
            onClick={() => handleLockClick(lock)}
            text={'Lock/Unlock'}
            />
            <Button
            onClick={() => handleAccessClick(access)}
            text={'Enable/Deny Access'}
            />
        </Coupler>
    </>
  )
}

export default DoorCardButtons

// Desired compoennts stays close to each other
const Coupler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
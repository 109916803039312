import { InputFieldValidator } from 'model/enums';
import InputFieldValidatorModel from 'model/InputFieldValidatorModel';
import { DatesUtil } from './DatesUtil';
import { emailRegex } from 'constants/Regex';
import { PhonesUtil } from './PhonesUtil';

export class CustomInputFieldValidator{

    private static _inputToValidate: string = "";

    private static _bound: string = "";

    private static _secondInputToValidate: string | undefined;

    private static readonly validatorHandler = Object.freeze({
        [InputFieldValidator.SKIP_VALIDATION_IF_EMPTY]:
            () => undefined,

        [InputFieldValidator.ERROR_IF_NULL]:
            () => (!this._inputToValidate 
                ? `Field is mandatory!` : undefined),

        [InputFieldValidator.ERROR_IF_VALUE_IS_LOWER_THEN_X]: 
            () => (Number(this._inputToValidate) < Number(this._bound)
                ? `Value must be greater than ${Number(this._bound)}!` : undefined),

        [InputFieldValidator.ERROR_IF_VALUE_IS_MORE_OR_EQUAL_THEN_X]: 
            () => (Number(this._inputToValidate) >= Number(this._bound) 
                ? `Value must be less than ${Number(this._bound)}!` : undefined),

        [InputFieldValidator.ERROR_IF_TEXT_LENGTH_IS_TOO_LONG_THEN_X]: 
            () => (this._inputToValidate 
                && this._bound 
                && this._inputToValidate.length > Number(this._bound)
                ? `Provided input cannot exceed ${this._bound} characters!` : undefined),

        [InputFieldValidator.ERROR_IF_TEXT_EQUAL_TO_X]:
            () => (this._inputToValidate 
                && this._bound 
                && this._inputToValidate.trim() === this._bound.trim() 
                ? `Field has invalid name!` : undefined),

        [InputFieldValidator.ERROR_IF_DATE_NOT_VALID_DATE]: 
            () => (this._inputToValidate 
                && this._bound 
                && DatesUtil.getDateFormatFromDate(new Date(this._inputToValidate))
                ? `The provided date is invalid!` : undefined),

        [InputFieldValidator.ERROR_IF_DATE_NOT_X_YEARS_OLDER_THEN_CURRENT_DATE]: 
            () => (this._inputToValidate 
                && this._bound 
                && DatesUtil.isSelectedDateIsInRangeOfXYearsFromCurrentDate(this._inputToValidate, Number(this._bound))
                ? `The user must be at least ${Number(this._bound)} years old!` : undefined),

        [InputFieldValidator.ERROR_IF_DATE_IS_IN_THE_PAST]:
            () => (this._inputToValidate
                && DatesUtil.isDateInThePast(this._inputToValidate)
                ? `The date cannot be set to the past!` : undefined),

        [InputFieldValidator.ERROR_IF_FIRST_DATE_IS_BIGGER_THAN_SECOND]:
            () => (this._inputToValidate
                && this._secondInputToValidate
                && !DatesUtil.isFirstDateBeforeSecond(this._inputToValidate, this._secondInputToValidate)
                ? `The provided date must be after the Start Date!` : undefined),

        [InputFieldValidator.ERROR_IF_PHONE_NUMBER_NOT_VALID]: 
            () => (this._inputToValidate 
                && !PhonesUtil.isPhoneNumberValid(this._inputToValidate)
                ? `The provided phone number is invalid!` : undefined),

        [InputFieldValidator.ERROR_IF_REGEX_NOT_MATCHING]:
            () => (this._inputToValidate
                && this._bound
                && !(this._inputToValidate.match(this._bound))
                ? `The provided value is invalid!` : undefined),

        [InputFieldValidator.ERROR_IF_EMAIL_IS_INVALID_FORMAT]:
            () => (this._inputToValidate
                && !(this._inputToValidate.match(emailRegex))
                ? `The provided input is not an email!` : undefined),
    });

    private static getErrorMessageFromValidator = (inputFieldValidator: InputFieldValidator, bound: any) => {
        this._bound = bound;
        return this.validatorHandler[inputFieldValidator].call(this.validatorHandler);
    }

    private static isValidationSkipAble = (executor: InputFieldValidator) => {
        return (executor === InputFieldValidator.SKIP_VALIDATION_IF_EMPTY && !this._inputToValidate);
    }
      
    public static validateInputFieldByValidators = (inputFieldValidatorModels: InputFieldValidatorModel[], inputToValidate: any, secondInputToValidate?: any) => {
        this._inputToValidate = inputToValidate;
        this._secondInputToValidate = secondInputToValidate ?? undefined;
        const filteredInputFieldValidatorModels = inputFieldValidatorModels.filter((validatorModel) => 
            !this.isValidationSkipAble(validatorModel.validator)
            && this.getErrorMessageFromValidator(validatorModel.validator, validatorModel.validatorBound)
        );

        return this.runValidation(filteredInputFieldValidatorModels);
    }

    public static validateInputFieldByRegex = (inputFieldValidatorModels: InputFieldValidatorModel[], inputToValidate: any) => {
        this._inputToValidate = inputToValidate;
        const filteredInputFieldValidatorModels = inputFieldValidatorModels.filter((validatorModel) => validatorModel.validator === InputFieldValidator.ERROR_IF_REGEX_NOT_MATCHING);
        return this.runValidation(filteredInputFieldValidatorModels);
    }

    private static runValidation = (filteredInputFieldValidatorModels: InputFieldValidatorModel[]): string | undefined => {
        if (filteredInputFieldValidatorModels.length > 0) {
            return this.getErrorMessageFromValidator(filteredInputFieldValidatorModels[0].validator, filteredInputFieldValidatorModels[0].validatorBound);
        }
        return undefined;
    }
}
import postCommand, { notifyCommandError } from './Command';
import { CommandCategory, EncoderCommand } from 'model/enums';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';
import EncoderModel from 'model/EncoderModel';
import CardResponse from 'model/CardResponse';

export const GetEncoders = async (siteName: string) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.EncoderService,
      EncoderCommand.GetEncoders,
      [],
    );
    if (!data || !data.data) {
      notifyCommandError('No encoder found or the server timed out.', null);
      return [];
    }
    const listOfEncoders: EncoderModel[] = data.data;
    return listOfEncoders;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing GetEncoder command', error);
    return [];
  }
};

export const GetEncoderBeepSound = async (siteName: string, encoderId: number): Promise<CardResponse | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.EncoderService,
      EncoderCommand.Beep,
      [encoderId],
    );

    if (!data || !data.data) {
      notifyCommandError('No encoder found or the server timed out.', null);
      return null;
    }

    const response: CardResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing GetEncoderBeepSound command', error);
    return null;
  }
};

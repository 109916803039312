import * as React from 'react';
import { List } from '@mui/material';
import CardDetailsWindowHeaderCard from './viewCardDetailsWindowCards/CardDetailsWindowHeaderCard';
import CardDetailsWindowCardStatusBody from './viewCardDetailsWindowCards/CardDetailsWindowCardStatusBody';
import CardDetailsWindowNoCardBody from './viewCardDetailsWindowCards/CardDetailsWindowNoCardBody';
import { StyledCollapse } from './UserWindowCommonStyle';
import { PageNavigation } from 'model/enums';
import CardModel from 'model/CardModel';
import { selectPageNavigation } from 'redux/selectors/userSelector';
import { useSelector } from 'react-redux';

type Props = {
  cardModelToDisplay: CardModel | null;
  handleFetchOfUserCards: (isDialogOpen: boolean) => void;
};

const CardDetailCard = ({cardModelToDisplay, handleFetchOfUserCards}: Props) => {

  const pageNavigation = useSelector(selectPageNavigation);
  const [openCardDetails, setOpenCardDetails] = React.useState(pageNavigation === PageNavigation.ManageCards);

  const handleOnClick = (isOpen: boolean) => {
    handleFetchOfUserCards(isOpen);
    setOpenCardDetails(isOpen);
  };

  return (
    <List
      sx={{ width: '100%' }}
      component="nav"
    >
      <CardDetailsWindowHeaderCard
        isCardDetailsOpen={openCardDetails}
        cardModelToDisplay={cardModelToDisplay}
        isCardDetailsClicked={handleOnClick}
      />
      <StyledCollapse in={openCardDetails} timeout="auto" unmountOnExit>
        { cardModelToDisplay?.Status ? 
          <CardDetailsWindowCardStatusBody cardModelToDisplay={cardModelToDisplay}/>
        : 
          <CardDetailsWindowNoCardBody/>
        }
      </StyledCollapse>
    </List>
  );
};

export default CardDetailCard;
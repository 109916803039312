import phone from "phone";

export class PhonesUtil {

    public static convertingBlePhoneFormatToReadablePhoneFormat = (phoneNumber: string) => {
        if (phoneNumber.includes("+") && phoneNumber.includes(",")) {
            const [ countryCode, mobileNumber ] = phoneNumber.split('+')[1].split(',');
            return `${countryCode}${mobileNumber}`;
        } else {
            return phoneNumber;
        }
    }

    public static convertPhoneNumberToPhoneFormat = (phoneNumber: string) => {
        return `+${this.convertingBlePhoneFormatToReadablePhoneFormat(phoneNumber)}`;
    } 

    public static isPhoneNumberValid = (phoneNumber: string) => {
        return phone(this.convertPhoneNumberToPhoneFormat(this.convertingBlePhoneFormatToReadablePhoneFormat(phoneNumber))).isValid;
    }

    public static convertPhoneNumberToPhoneObj = (phoneNumber: string) => {
        return phone(this.convertPhoneNumberToPhoneFormat(this.convertingBlePhoneFormatToReadablePhoneFormat(phoneNumber)));
    }

    public static extractCountryCodeFromPhoneNumber = (phoneNumber: string | undefined) => {
        if (phoneNumber) {
            const phoneObj = this.convertPhoneNumberToPhoneObj(this.convertingBlePhoneFormatToReadablePhoneFormat(phoneNumber));
            return phoneObj.countryCode ? phoneObj.countryCode : undefined;
        }
        return undefined;
    }

    public static extractMobileNumberFromPhoneNumber = (phoneNumber: string | undefined) => {
        if (phoneNumber) {
            const phoneObj = this.convertPhoneNumberToPhoneObj(phoneNumber);
            if (phoneObj.countryCode) {
                const splitPhoneNumber = this.convertPhoneNumberToPhoneFormat(this.convertingBlePhoneFormatToReadablePhoneFormat(phoneNumber)).split(phoneObj.countryCode);
                if (splitPhoneNumber.length > 1) {
                    return splitPhoneNumber[1];
                }
            }
        }
        return undefined;
    }

    public static convertCountryCodeAndNumbersToPhoneNumber = (countryCody: string | undefined, mobileNumber: string | undefined) => {
        return countryCody && countryCody.split('+').length > 0 && mobileNumber ? `${countryCody.split('+')[1]}${mobileNumber}` : undefined;
    }

    public static convertCountryCodeAndNumbersToBlePhoneNumber = (countryCody: string | undefined, mobileNumber: string | undefined) =>
        countryCody && countryCody.split('+').length > 0 && mobileNumber 
            ? `+${countryCody.split('+')[1]},${mobileNumber}` 
            : undefined;
}
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import NavigationBarCard from 'components/cards/NavigationBarCard';
import nspLogo from '../media/nsp_logo.png';
import siteIcon from '../media/Site.png';
import { useSelector } from 'react-redux';
import { selectIsSitesLoading } from 'redux/selectors/siteSelector';
import { PageNavigation } from 'model/enums';

type HeaderProps = {
  handleLocation: (requestedPage) => void
}

/* eslint-disable camelcase */
export default function Header({handleLocation}: HeaderProps) {
  const auth0CallbackURL = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const match = useMatch('site/:id');
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const { logout, user } = useAuth0();
  const loading = useSelector(selectIsSitesLoading);

  const [headerTitle, setHeaderTitle] = useState('System Dashboard');

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // Set title
    switch (location.pathname) {
      case '/':
        setHeaderTitle('System Dashboard');
        break;
      case '/404':
        setHeaderTitle('');
        break;
      default:
        setHeaderTitle(
          match?.params?.id && !loading ? match?.params?.id : 'Site Dashboard',
        );
        handleLocation(PageNavigation.Overview);
        break;
    }
    // Allow/Disable site icon
    setShowIcon(location?.pathname.startsWith('/site'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, location, loading]);

  const handleLocationClick = (location: any) => {
    handleLocation(location);
  };

  return (
    <Container>
      <LeftMenu>
        <img
          src={nspLogo}
          alt="NSP Logo"
          style={{ width: '7vw', marginBottom: '2vw' }}
        />
        {location?.pathname !== '/' && (
          <ToDashboard onClick={() => navigate('/')}>
            <BackToSystemDashboard>
              <KeyboardArrowLeftIcon />
              System Dashboard
            </BackToSystemDashboard>
          </ToDashboard>
        )}
      </LeftMenu>
      <TitleContainer>
        {showIcon && <SiteIcon src={siteIcon} alt="Site icon" />}
        <HedaerTitle>{headerTitle}</HedaerTitle>
      </TitleContainer>
      <RightMenu>
      <List
        aria-labelledby="nested-list-subheader"
      >
        <Logout>
        <ListItemButton onClick={handleClick} >
          <Stack direction="row" spacing={2}>
            <Avatar
              alt={user?.name}
              src={user?.picture}
              sx={{ marginRight: '10px' }}
            />
          </Stack>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItemButton
            onClick={() =>
              logout({ logoutParams: { returnTo: auth0CallbackURL } })
            }
          >
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </Collapse>
        </Logout>
        {location?.pathname !== '/' && (
        <NavigationBarCard location={location} handleLocation={handleLocationClick}/>
        )}
      </List>
      </RightMenu>
    </Container>
  );
}

const HedaerTitle = styled.h2`
  font-weight: bold;
`;

const BackToSystemDashboard = styled.div`
  display: flex;
  position: relative;
  padding-top: 10%;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  height: 9rem;
  overflow: hidden;
  padding-bottom: 2rem;
  margin: auto;
`;

const LeftMenu = styled.div`
  height: 100%;
  padding-top: 3vh;
`;

const ToDashboard = styled.div`
  margin-top: 1.5vh;
  font-weight: 500;
  font-size: 16px;
  margin: auto;

  &:hover {
    cursor: pointer;
    transform: scale(1.025);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 10%;
`;

const SiteIcon = styled.img`
  width: 25px;
  height: 23px;
  padding: 10px;
  webkit-filter: brightness(0);
  filter: brightness(0);
`;

const RightMenu = styled.div`
  padding-top: 3%;
  margin-bottom: 2rem;
`;

const Logout = styled.div`
height: 7rem;
padding-left: 75%;
width: 400px;
`;
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { CardActionImage } from './CardActionsCommonStyle';
import { CardToolTipViewOptions } from 'model/enums';
import { getIconByCardToolTip } from 'constants/cardIcons';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { red } from 'constants/theme';
import { useIsUserNotDeletable } from 'hooks/useIsUserNotDeletable';

type Props = {
    setOptionsOpen: (optionsOpen: boolean) => void;
    handleOnClick: (selectedIcon: CardToolTipViewOptions | undefined) => void;
    iconToolTipTitle: CardToolTipViewOptions | undefined;
    optionsOpen: boolean;
};
const CardItemTemplate = ({
    setOptionsOpen,
    handleOnClick,
    iconToolTipTitle,
    optionsOpen,
}: Props) => {
    
    const isUserNotDeletable = useIsUserNotDeletable() && iconToolTipTitle === CardToolTipViewOptions.DeleteUser;
    const menuName = ConversionsUtil.cardToolTipToCardActions(iconToolTipTitle);

    return (
        <StyledMenuItem 
            onClick={() => !isUserNotDeletable ? handleOnClick(iconToolTipTitle) : null} 
            color={iconToolTipTitle === CardToolTipViewOptions.DeleteUser || iconToolTipTitle === CardToolTipViewOptions.EraseCard 
                ? red 
                : undefined
            }
            disabled={isUserNotDeletable}
            >
            <CardActionImage 
                src={getIconByCardToolTip(iconToolTipTitle, false)} 
                alt={menuName} 
                onClick={() => setOptionsOpen(!optionsOpen)}
            />
            {menuName}
        </StyledMenuItem>
    );
};

export default CardItemTemplate;

const StyledMenuItem = styled(MenuItem)<{ color?: string }>((props) => ({ color: `${props.color}`}));
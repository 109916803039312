import LockType from "model/LockType"
import RoomModel from "model/RoomModel"
import { RoomAction } from "redux/constants/roomActions"
import RoomActionTypes from "redux/constants/roomActionTypes"

type RoomType = {
    loading: boolean,
    loadingLockTypes: boolean,
    rooms: RoomModel[],
    lockTypes: LockType[],
    error: string
}

const initialState: RoomType = {
    loading: true,
    loadingLockTypes: true,
    rooms: [],
    lockTypes: [],
    error: ''
}

export default function roomReducer(state: RoomType = initialState, action: RoomAction): RoomType {
    switch (action.type) {
        case RoomActionTypes.ROOMS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case RoomActionTypes.REQUEST_ROOMS_SUCCESS:
            return {
                ...state,
                loading: false,
                rooms: action.payload,
                error: ''
            }
        case RoomActionTypes.REQUEST_ROOMS_FAILURE:
            return {
                ...state,
                loading: false,
                rooms: [],
                error: action.payload
            }
        case RoomActionTypes.SET_EMPTY_ROOMS:
            return {
                ...state,
                rooms: [],
            }
        case RoomActionTypes.LOCK_TYPES_REQUESTED:
            return {
                ...state,
                loadingLockTypes: true,
            }
        case RoomActionTypes.REQUEST_LOCK_TYPES_FAILURE:
            return {
                ...state,
                loadingLockTypes: false,
                error: action.payload,
                lockTypes: [],
            }
        case RoomActionTypes.REQUEST_LOCK_TYPES_SUCCESS:
            return {
                ...state,
                loadingLockTypes: false,
                lockTypes: action.payload,
            }
        default:
            return state;
    }
}
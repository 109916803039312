import * as React from 'react';
import { DialogToOpen } from 'model/enums';
import add from 'media/add.svg';
import styled from 'styled-components';
import { mainBlue, white } from 'constants/theme';

type Props = {
  setDialogToOpen: (dialogToOpen: DialogToOpen) => void;
};

const AddUserButtonCard = ({ setDialogToOpen }: Props) => {
  return (
    <ButtonDiv>
      <AddUserButton
        onClick={() => setDialogToOpen(DialogToOpen.CreateUserDialog)}
      >
        <img src={add} alt="" />
        <p>Add User</p>
      </AddUserButton>
    </ButtonDiv>
  );
};

export default AddUserButtonCard;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5vh;
  width: 10vw;
`;

export const AddUserButton = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${mainBlue};
  border: none;
  color: ${white};
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  border-radius: 8px;
  width: 9vw;
  height: 4vh;
  font-weight: 250;
  gap: 8px;
  cursor: pointer;
`;

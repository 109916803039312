import React from 'react';
import { useSignalrEncoderEvents } from 'hooks/useSignalrEncoder';

const SignalrProvider = () => {
  useSignalrEncoderEvents();

  return <React.Fragment></React.Fragment>;
};

export default SignalrProvider;

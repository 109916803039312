import * as React from 'react';
import { StyledWindowContainer } from '../WindowCard';
import { listItemLightGray } from 'constants/theme';
import styled from 'styled-components';

type Props = {
  cardContent: JSX.Element,
};

const NoSelectionCard = ({cardContent}: Props) => {
  
  return (
    <StyledWindowContainer>
      <StyledInfoWindow>
          {cardContent}
      </StyledInfoWindow>
    </StyledWindowContainer>
  );
};

export default NoSelectionCard;

const StyledInfoWindow = styled.div`
  color: ${listItemLightGray};
  font-weight: 400;
  font-size: 16px;
  line-height: 24.35px;
`
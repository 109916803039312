import { notifyError } from "helper/NotificationService";
import { BluetoothUserStatuses, CardStatus } from "model/enums";
import UserModel from "model/UserModel";
import { useSelector } from "react-redux"
import { selectSelectedUser } from "redux/selectors/userSelector"

export const isCardInUse = (userModel?: UserModel) => userModel?.Card?.CardStatus === CardStatus.InUse;

export const isBluetoothCredentialExist = (userModel?: UserModel) => !!userModel?.BleMobileNumber && userModel?.UserAccess?.BleUserState !== BluetoothUserStatuses.Undefined;

export const isUserDeleteDialogShow = (userModel?: UserModel): boolean => {
    if (isCardInUse(userModel)) {
      notifyError("You can not delete a user with an In Use card status!", "");
      return false;
    }
  
    if (isBluetoothCredentialExist(userModel)) {
      notifyError("You can not delete a user with a Suspended or Deleted Bluetooth credential!", "");
      return false;
    }
    return true;
  }

export const useIsUserNotDeletable = () => {
    const selectedUser = useSelector(selectSelectedUser);
    return (isCardInUse(selectedUser) || isBluetoothCredentialExist(selectedUser));
}
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';

export const StyledCollapse = styled(Collapse)<{ width?: string }>((props) => ({
    '.MuiCollapse-wrapper ' : {
      textAlign: 'left',
      marginLeft: '16px !important',
    },
  }));
  
  export const StyledListGrid = styled(Grid)<{ width?: string }>((props) => ({
    '.MuiGrid-root' : {
      fontSize: '14px',
      width: `${props.width} !important`,
    },
  }));
  
  export const StyledListItemTextHeader = styled(ListItemText)<{ width?: string }>((props) => ({ 
    '.MuiTypography-body1 ' : { 
      fontWeight: 'bold', 
      display: 'flex' ,
    }
  }));

  export const StyledCardViewHolder = styled.div`
    width: 40px;
  `;
  
  export const StyledGrid = styled(Grid)<{ color?: string }>((props) => ({ 
    color: `${props.color}`,
    maxHeight: '125px',
    overflowY: 'auto',
    overflowX: 'hidden',

    '&.MuiGrid-item' : { ...customScrollBarCSSProperties({width: "5px"}) }
  }));
import * as React from 'react';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';

type Props = {
  invalidFormMessage?: string;
};

const InputFieldError = ({ invalidFormMessage}: Props) => {

return (
    <React.Fragment>
      { invalidFormMessage ?
        <StyledInputFieldComponents.StyledErrorTextHolder>
            {invalidFormMessage}
        </StyledInputFieldComponents.StyledErrorTextHolder>
      : null }
    </React.Fragment>
  );
};

export default InputFieldError;

import dayjs from 'dayjs';
import { Dispatch } from 'redux';
import LastUsedDateActionTypes from 'redux/constants/lastUsedDateActionTypes';

export const fetchLastUsedDateRequest = () => {
  return { type: LastUsedDateActionTypes.FETCH_LAST_USED_DATE_REQUEST };
};
export const fetchLastUsedDateSuccess = (data: dayjs.Dayjs | undefined) => {
  return {
    type: LastUsedDateActionTypes.FETCH_LAST_USED_DATE_SUCCESS,
    payload: data,
  };
};
export const fetchLastUsedDateRequester = (
    date: dayjs.Dayjs | undefined
) => {
    return (dispatch: Dispatch) => {
        dispatch(fetchLastUsedDateRequest());
        dispatch(fetchLastUsedDateSuccess(date));
  };
};
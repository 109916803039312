import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import styled from "styled-components";

export const CustomizableDiv = styled.div<{ 
  width?: number, 
  color?: string, 
  align?: string, 
  display?: string, 
  $justifyContent?: string, 
  $alignItems?: string, 
  $fontWeight?: string,
  padding?: string,
}>((props) => ({
  width: `${props.width}%`,
  color: props.color,
  fontSize: '14px',
  textAlign: props.align,
  display: props.display,
  justifyContent: props.$justifyContent,
  alignItems: props.$alignItems,
  fontWeight: props.$fontWeight,
  padding: props.padding,
}));

export const StyledUserDialogContent = styled.div<{ height?: number, width?: number, padding?: number }>((props) => ({
  width: `${props.width}px`,
  maxWidth: `${props.width}px`,
  padding: `${props.padding ? props.padding : 0}px`,
  height: `${props.height}px`,
}));

export const StyledContentHolder = styled.div`
  gap: 24px;
  display: grid;
`;

export const StyledContentRowHolder = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CustomStyledDialogCardTitle = styled(DialogTitle)`
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
    display: flex;
    padding: 12px 16px 12px 16px;
    justify-content: space-between;
    align-items: center;
`;

export const CustomDialogTitleComponent = styled(CustomStyledDialogCardTitle)`
    height: 24px;
    width: 24px;
`;

export const CustomDialogBackIcon = styled.div`
    cursor: pointer;
`;
import React, { useState } from 'react';
import { mainBlue } from 'constants/theme';
import { Box, FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FloorFilterProp } from 'model/FloorFilterProp';

type Props = {
  element: FloorFilterProp;
  onChange: (e) => void;
};

const FloorFilterCheckBox = ({ element, onChange }: Props) => {
  const [isChecked, setIsChecked] = useState(Boolean);
  const onChanges = (e) => {
    setIsChecked(!isChecked);
    onChange(e);
  } 
  return (<>
    <FormControlLabel
      control={
        <Checkbox
          disableRipple
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={
            <RadioButtonCheckedIcon
              sx={{ '&, &.Mui-checked': { color: mainBlue } }}
            />
          }
          checked={element.checked}
          onChange={(e) => onChanges(e)}
          value={element.floor.Id}
          id={element.floor.Name}
        />
      }
      label={
        <Box component="div" fontWeight={300}>
          {element.floor.Name}
        </Box>
      }
    />
    </>
  );
};
export default FloorFilterCheckBox;
import { red } from "constants/theme";
import styled from "styled-components";

export class StyledInputFieldComponents {

  public static StyledInputFieldHolder = styled.div<{ height?: string, width?: string, $textAlign?: string }>((props) => ({
    height: props.height,
    width: props.width,
    textAlign: props.$textAlign,
    alignContent: 'center'
  }));

  public static StyledInputOuterContentHolder = styled.div<{ width?: string }>((props) => ({
    display: 'inline-block',
    width: `${props.width}`,
    maxWidth: `${props.width}`,
  }));

  public static StyledInputInnerContentHolder = styled.div<{ $hasLabel?: boolean}>((props) => ({
    display: 'grid',
    gap: props.$hasLabel? '8px' : '0',
    overflowX: 'visible',
  }));

  public static TextLabelHolder = styled.div`
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    height: 16px;
  `;
  
  public static StyledTextLabelHolder = styled(this.TextLabelHolder)`
    width: max-content;
  `;

  public static StyledErrorTextHolder = styled(this.TextLabelHolder)`
    color: ${red};
    padding-left: 10px;
  `;

  public static StyledInputHolder = styled.div<{ height?: string, width?: string, $isInputSelected?: boolean }>((props) => ({
    height: `${props.height}`,
    width: `${props.width}`,
    'div' : { cursor: `${props.$isInputSelected ? 'pointer' : 'text'}`},
  }));
}
import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import RoomTableRecords from 'model/RoomTableRecords';
import { HeaderCellContent, StyledDataGrid } from './DataTableBase';
import { lightGrayBackground } from 'constants/theme';
import { formatHeaderNames } from 'helper/DataTableUtils';

type Props = {
  dataRows: RoomTableRecords[];
  height: string;
  rowHeight: number;
  isFooterDisplayed?: boolean;
};

const CustomDataGridTable = ({ 
  dataRows,
  height,
  rowHeight,
  isFooterDisplayed,
 }: Props) => {

  const PAGINATION_SIZE = dataRows.length;

  const CustomHeader = (params: any) => {
    const colDef = params.colDef;

    return (
      <HeaderCellContent
        $fontWeight={500}
        $fontSize={14}
        cursor='context-menu'
      >
        {colDef.headerName}
      </HeaderCellContent>
    );
  };

  const generateColumns = (): GridColDef[] => {
    const demoUserTableRecord: RoomTableRecords = {
      doorName: "Room - 101",
      lockType: "Non Passage",
      buildingNo: 1,
      floorNo: 2,
      LockNo: 1,
      masterCardLevel: 15,
    };
    const fields = Object.keys(demoUserTableRecord);
    return fields.map((field) => {
      const columnDef: GridColDef = {
        field,
        headerName: formatHeaderNames(field),
        renderHeader: (params) => <CustomHeader {...params}/>,
        disableColumnMenu: true,
        sortable: false,
        resizable: false,
        filterable: false,
        flex: 1,
      };
      return columnDef;
    });
  };

  return (
    <StyledDataGrid
        rows={dataRows.map((row, index) => ({ ...row, id: index }))}
        columns={generateColumns()}
        pageSizeOptions={[PAGINATION_SIZE]}
        disableMultipleRowSelection
        sx={{borderColor: 'transparent'}}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
        $isFooterDisplayed={isFooterDisplayed}
        height={height}
        $oddRowColor={lightGrayBackground}
        rowHeight={rowHeight}
      />
  );
};

export default CustomDataGridTable;
import * as React from 'react';
import { List } from '@mui/material';
import styled from 'styled-components';
import { listItemLightGray } from 'constants/theme';
import CustomAddCardSelect from 'components/cards/CustomAddCardSelect';
import { CardTypeUtils } from 'helper/CardTypeUtils';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';
import { DialogToOpen } from 'model/enums';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { useSelector } from 'react-redux';
import { selectSelectedUser } from 'redux/selectors/userSelector';
import { CardForm } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';
import { convertSingleUserModelToOptionModel } from 'hooks/useConversion';

const CardDetailsWindowNoCardBody = () => {

  const selectedUserModel = useSelector(selectSelectedUser);
  const dispatch = useDispatch();

  const handleSelectedCardTypeOption = (cardType: string) => {
    if (!selectedUserModel) {
      return;
    }

    CardTypeUtils.convertCardTypeToDialogToOpenType(cardType, (dialogToOpen: DialogToOpen) => {
      dispatch(setCardDialogForm({
        ...CardForm.INIT_CARD_DETAILS_FORM,
        cardDetails: {
          user: convertSingleUserModelToOptionModel(selectedUserModel),
          isUserSelected: true,
        }
      }));
      dispatch(setOpenedDialog(dialogToOpen));
    })
  }

  return (
    <List component="div" >
        <StyledText>
            User has no card assigned.
        </StyledText>
        <CustomAddCardSelect 
            selectOptions={CardTypeUtils.CARD_TYPE_OPTIONS}
            setSelectedOption={handleSelectedCardTypeOption}
        />
    </List>
  );
};

export default CardDetailsWindowNoCardBody;

const StyledText = styled.div`
    font-size: 14px;
    color: ${listItemLightGray};
    width: 352px;
    height: 40px;
    font-weight: 400;
    font-family: 'Inter';
    line-height: 24.35px;
`;
import React, { useState, useEffect }  from 'react';
import styled from 'styled-components';
import { white, lightGrey } from 'constants/theme';
import { CardStatus, DefaultFilterValues, FilterName, PageNavigation } from 'model/enums';
import DataTableBase from 'components/controls/dataGridTable/DataTableBase';
import UserTableRecord from 'model/UserTableRecord';
import UserFilter from 'model/UserFilter';
import CustomUserFilter, { FILTER_MAX_WIDTH, FILTER_MIN_WIDTH } from 'components/cards/CustomUserFilter';
import WindowCard from 'components/cards/WindowCard';
import { fetchAllUsers, setNewUsersToPending, setOpenedDialog, setPageNavigation, setUsersIsRefreshUsersList, setUsersSelectedUserRow, setUsersToDelete } from 'redux/actions/userActions';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectOpenedDialog, selectPageNavigation, selectRefreshUsersList, selectUserList } from 'redux/selectors/userSelector';
import { fetchAllCardStatus } from 'redux/actions/cardStatusActions';
import { isCardStatusListLoaded, selectCardStatusList } from 'redux/selectors/cardStatusSelector';
import { fetchAllGroupName } from 'redux/actions/groupNameActions';
import TimeGroup from 'model/TimeGroup';
import { isGroupNameListLoaded, selectGroupNameList } from 'redux/selectors/groupNameSelector';
import { fetchAllDepartment } from 'redux/actions/departmentActions';
import { isDepartmentListLoaded, selectDepartmentList } from 'redux/selectors/departmentSelector';
import DepartmentDetail from 'model/DepartmentDetail';
import UserModel from 'model/UserModel';
import { fetchUserCardsRequester } from 'redux/actions/cardModelActions';
import DialogGroup from 'components/cards/dialogs/DialogGroup';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { filterByTheUserFilters } from 'helper/FilterUtils';
import { INITIAL_SORT, PAGINATION_SIZE, generateColumns } from 'helper/DataTableUtils';
import AddUserButtonCard from 'components/cards/AddUserButtonCard';
import SearchBarField from 'components/controls/searchBar/SearchBarField';
import OptionModel from 'model/OptionModel';
import { useConversionUserRecordsToOptionModels } from 'hooks/useConversion';
import { requestMasterLevels } from 'redux/actions/masterLevelActions';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';

export function ManageUsers(props: any) {
  const [departmentFilter, setDepartmentFilter] = useState<string[]>([DefaultFilterValues.DepartmentFilter]);
  const [groupNameFilter, setGroupNameFilter] = useState<string[]>([DefaultFilterValues.TimeGroupFilter]);
  const [cardStatusFilter, setCardStatusFilter] = useState<string[]>([DefaultFilterValues.CardStatusFilter]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>(DefaultFilterValues.DepartmentFilter);
  const [selectedGroupName, setSelectedGroupName] = useState<string>(DefaultFilterValues.TimeGroupFilter);
  const [selectedCardStatus, setSelectedCardStatus] = useState<string>(DefaultFilterValues.CardStatusFilter);
  const [userTableRecordRows, setUserTableRecordRows] = useState<UserTableRecord[]>([]);
  const [selectedSearchInput, setSelectedSearchInput] = useState<OptionModel>();
  const [selectedIndexDepartment, setSelectedIndexDepartment] = useState<number|null>(null);
  const [selectedIndexGroupName, setSelectedIndexGroupName] = useState<number|null>(null);
  const [selectedIndexCardStatus, setSelectedIndexCardStatus] = useState<number|null>(null);
  const [departmentFilterIsOpen, setDepartmentFilterIsOpen] = useState(false);
  const [groupNameFilterIsOpen, setGroupNameFilterIsOpen] = useState(false);
  const [cardStatusFilterIsOpen, setCardStatusFilterIsOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const listOfUserOptions = useConversionUserRecordsToOptionModels(userTableRecordRows);

  const listOfUsers: UserModel[] = useSelector(selectUserList);
  const loadingCardStatusList: boolean = useSelector(isCardStatusListLoaded);
  const cardStatusList: CardStatus[] = useSelector(selectCardStatusList);
  const loadingGroupNameList: boolean = useSelector(isGroupNameListLoaded);
  const groupNameList: TimeGroup[] = useSelector(selectGroupNameList);
  const loadingDepartmentList: boolean = useSelector(isDepartmentListLoaded);
  const departmentList: DepartmentDetail[] = useSelector(selectDepartmentList);
  const isRefreshRequested = useSelector(selectRefreshUsersList);
  const openedDialog = useSelector(selectOpenedDialog);
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const pageNavigation = useSelector(selectPageNavigation);
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  const applyNewFilterToUsers = () => {
    props.fetchUsers(actualSite);
  }

  const handleSetOfMasterLevels = () => {
    if (listOfMasterLevels && listOfMasterLevels.length === 0) {
      // Loading the Users and storing them in the redux storage.
      requestMasterLevels(actualSite, dispatch);
    }
  }

  useEffect(()=> {
    // Loading the Departments and storing them in the redux storage.
    props.fetchDepartments(actualSite);

    // Loading the Group Names and storing them in the redux storage.
    fetchAllGroupName(actualSite, dispatch);

    // Loading the Card Statuses and storing them in the redux storage.
    props.fetchCardStatus();

    // Loading the Users and storing them in the redux storage.
    props.fetchUsers(actualSite);

    handleSetOfMasterLevels();
    dispatch(setPageNavigation(PageNavigation.ManageUsers));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (isRefreshRequested && !openedDialog) {
      handleRefreshUserList();
      dispatch(setUsersIsRefreshUsersList(false));
    }
  }, [isRefreshRequested, openedDialog]);

  useEffect(() => {
    if (pageNavigation) {
      const userFilter: UserFilter = {
        searchModel: selectedSearchInput,
        department: selectedDepartment,
        cardStatus: selectedCardStatus,
        cardType: DefaultFilterValues.CardTypeFilter,
        groupName: selectedGroupName,
      };
      const userTableRecordList: UserTableRecord[] = filterByTheUserFilters(userFilter, listOfUsers, pageNavigation);
      setUserTableRecordRows(userTableRecordList);
      dispatch(setUsersToDelete(userTableRecordList.map(user => ({ userId: user.globalId, isCheckboxChecked: false }))));
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [listOfUsers, pageNavigation]);

  useEffect(() => {
    if (!loadingCardStatusList && cardStatusList.length > 0) {
      setCardStatusFilter([
        DefaultFilterValues.CardStatusFilter,
        ...cardStatusList
      ]);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadingCardStatusList, userTableRecordRows]);

  useEffect(() => {
    if (!loadingGroupNameList && groupNameList.length > 0) {
      setGroupNameFilter((_) => [
        DefaultFilterValues.TimeGroupFilter, 
        ...groupNameList.map((item) => item.GroupName)
      ]);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadingGroupNameList, userTableRecordRows]);

  useEffect(() => {
    if (!loadingDepartmentList && departmentList.length > 0) {
      setDepartmentFilter((_) => [
        DefaultFilterValues.DepartmentFilter, 
        ...departmentList.map((item) => item.Name)
      ]);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadingDepartmentList, userTableRecordRows]);

  useEffect(() => {
    setSelectedIndexGroupName(groupNameFilter.findIndex(groupName => groupName === selectedGroupName));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupNameFilter]);

  useEffect(() => {
    setSelectedIndexCardStatus(cardStatusFilter.findIndex(cardStatus => cardStatus === selectedCardStatus));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardStatusFilter]);

  useEffect(() => {
    setSelectedIndexDepartment(departmentFilter.findIndex(department => department === selectedDepartment));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentFilter]);

const departmentChangeHandle = (event: any) => {
  const value = event.target.value;
  setSelectedDepartment(value);
  applyNewFilterToUsers();
};

const groupNameHandleChange = (event: any) => {
  const value = event.target.value;
  setSelectedGroupName(value);
  applyNewFilterToUsers();
};

const cardStatusHandleChange = (event: any) => {
  const value = event.target.value;
  setSelectedCardStatus(value);
  applyNewFilterToUsers();
};

const handleRefreshUserList = () => {
  dispatch(setUsersSelectedUserRow(undefined));
  dispatch(setOpenedDialog(undefined));
  dispatch(setNewUsersToPending([]));
  applyNewFilterToUsers();
}

return(
  <>
    <StyledPageContainer>
      <Header>
        <AddUserButtonCard 
          setDialogToOpen={(dialogToOpen) => dispatch(setOpenedDialog(dialogToOpen))}
        />
        <SearchBarField 
          setValue={(optionModel) => {
            setSelectedSearchInput(optionModel)
            applyNewFilterToUsers();
          }}
          selectOptions={listOfUserOptions}
          inputValue={selectedSearchInput}
          setInputChange={setSelectedSearchInput}
          handleOnEnter={applyNewFilterToUsers}
        />
        <FilterDiv>
          <CustomUserFilter
              filterValue={selectedDepartment}
              filterOnChangeHandle={departmentChangeHandle}
              filterOnOpenHandle={() => setDepartmentFilterIsOpen(true)}
              filterOnCloseHandle={() => setDepartmentFilterIsOpen(false)}
              filterName={FilterName.DepartmentFilter}
              filterOptions={departmentFilter}
              isFilterOpen={departmentFilterIsOpen}
              setSelectedFilterOptionIndex={setSelectedIndexDepartment}
              selectedFilterOptionIndex={selectedIndexDepartment}
              minWidth={FILTER_MIN_WIDTH}
              maxWidth={FILTER_MAX_WIDTH}
          />
          <CustomUserFilter
              filterValue={selectedGroupName}
              filterOnChangeHandle={groupNameHandleChange}
              filterOnOpenHandle={() => setGroupNameFilterIsOpen(true)}
              filterOnCloseHandle={() => setGroupNameFilterIsOpen(false)}
              filterName={FilterName.GroupNameFilter}
              filterOptions={groupNameFilter}
              isFilterOpen={groupNameFilterIsOpen}
              setSelectedFilterOptionIndex={setSelectedIndexGroupName}
              selectedFilterOptionIndex={selectedIndexGroupName}
              minWidth={FILTER_MIN_WIDTH}
              maxWidth={FILTER_MAX_WIDTH}
          />
          <CustomUserFilter
              filterValue={selectedCardStatus}
              filterOnChangeHandle={cardStatusHandleChange}
              filterOnOpenHandle={() => setCardStatusFilterIsOpen(true)}
              filterOnCloseHandle={() => setCardStatusFilterIsOpen(false)}
              filterName={FilterName.CardStatusFilter}
              filterOptions={cardStatusFilter}
              isFilterOpen={cardStatusFilterIsOpen}
              setSelectedFilterOptionIndex={setSelectedIndexCardStatus}
              selectedFilterOptionIndex={selectedIndexCardStatus}
              minWidth={FILTER_MIN_WIDTH}
              maxWidth={240}
          />
        </FilterDiv>
      </Header>
      <StyledPageDisplayContainer>
        <Left>
          <TableWrapper>
            <DataTableBase
              userTableRecordRows={userTableRecordRows}
              columns={generateColumns(
                optionsOpen,
                setOptionsOpen,
                pageNavigation,
              )}
              initialSort={INITIAL_SORT}
              paginationSize={PAGINATION_SIZE}
              isFooterDisplayed
            />
          </TableWrapper>
        </Left>
        <Right>
          <Separator/>
          <WindowCard/>
        </Right>
      </StyledPageDisplayContainer>
    </StyledPageContainer>
    <React.Fragment>
      <DialogGroup/>
    </React.Fragment>
  </>
  );
}

const MapStateToProps = (state) => {
  return { siteList: state.userList.userDetails };
};

const MapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (actualSite: string) => dispatch(fetchAllUsers(actualSite)),
    fetchCardStatus: () => dispatch(fetchAllCardStatus()),
    fetchDepartments: (actualSite: string) => dispatch(fetchAllDepartment(actualSite)),
    fetchUserCards: (actualSite: string, listOfUsers: UserModel[]) => dispatch(fetchUserCardsRequester(actualSite, listOfUsers)),
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(ManageUsers);

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 3vh 2vw 0 2vw;
  gap: 14px;
  margin-bottom: 1vh;
`;

export const StyledPageContainer = styled.div`
  margin: auto;
  background-color: ${white};
  border-radius: 8px;
  position: relative;
  height: 45vw;
  min-height: 45vw;
  width: 90vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 2vw;
`;

export const FilterDiv = styled.div`
  width: 43vw;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const StyledPageDisplayContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 0 40px 20px 40px;
  box-sizing: border-box;
`;

export const Left = styled.div`
  width: 75%;
  float: left;
`;

export const Right = styled.div`
  width: 25%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding-left: 5px;
`;

export const Separator = styled.div`
  width: 0.1%;
  margin: 0 1%;
  height: 90%;
  align-self: end;
  background-color: ${lightGrey};
`;
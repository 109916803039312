import { PhonesUtil } from "helper/PhonesUtil";
import iso from "iso-3166-1";
import { getCountryCallingCode, getCountries } from 'libphonenumber-js';
import { MobileUserType } from 'model/enums';

export const LIST_OF_MOBILE_USER_TYPES = Object.values(MobileUserType);

export const PHONE_COUNTRY_CODE_LIST = Array.from(
  new Set(getCountries().map(country => PhonesUtil.convertPhoneNumberToPhoneFormat(getCountryCallingCode(country))))
).sort((a, b) => Number(a.substring(1)) - Number(b.substring(1)));

export const NATIONALITY_COUNTRY_NAME_LIST = iso.all().map(item => item.country);
import CardDialogForm from 'model/CardDialogForm';
import CardDialogFormError, { CardDetailsFormError } from 'model/CardDialogFormError';

export class CardForm {
    public static readonly INIT_CARD_DETAILS_FORM: CardDialogForm = {
        cardDetails: {},
        cardLevels: [],
        cardDoors: [],
        doorGroups: [] ,
        userCard: undefined,
    }
}

export class CardFormError {
    public static readonly INIT_CARD_DETAILS_FORM_ERROR: CardDetailsFormError = {}
    
    public static readonly INIT_CARD_DIALOG_FORM_ERROR: CardDialogFormError = { cardDetails: this.INIT_CARD_DETAILS_FORM_ERROR};
}

export class NumberLimitsUtil {
    public static readonly INT32_MAX = 2147483647;

    public static readonly INT32_UPPER_LIMIT = 1000000000;

    public static readonly UBYTE_UPPER_LIMIT = 255;

    public static readonly UBYTE_UPPER_LIMIT_OVERFLOW = this.UBYTE_UPPER_LIMIT + 1;

    public static readonly INT32_UPPER_LIMIT_MAX_LENGTH = 10;

    public static readonly UBYTE_UPPER_LIMIT_MAX_LENGTH = 3;

    public static readonly INPUT_TEXT_CHARACTER_MAX_LENGTH = 50;

    public static readonly PHONE_NUMBER_UPPER_LIMIT = 20;

    public static readonly INPUT_SMALL_TEXT_MAX_LENGTH = 2;
}
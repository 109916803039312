import * as React from 'react';
import { groupsColData, roomsColData } from 'constants/customColData';
import { StyledContentHolder, StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import styled from 'styled-components';
import StyledButton from 'components/controls/button/StyledButton';
import { OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import addBlack from 'media/addBlack.svg';
import TabsField from 'components/controls/tabs/TabsField';
import CustomTable from 'components/cards/CustomTable';
import BaseUserAccessDialog from './accessPageDialogs/BaseUserAccessDialog';
import AddRoomsDialog from './accessPageDialogs/AddRoomsDialog';
import { fetchAllBuildings } from 'redux/actions/buildingActions';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import UserAccessDateSelectorCard from './accessPageCards/UserAccessDateSelectorCard';
import RoomModel from 'model/RoomModel';
import { alpha } from '@mui/material/styles';
import { DoorGroupType, UserRoom } from 'model/NewUserModel';
import AccessGroupDialog from './accessPageDialogs/AddGroupsDialog';
import { fetchAllRooms } from 'redux/actions/roomActions';
import { selectRoomList } from 'redux/selectors/roomSelector';
import DoorGroupModel from 'model/DoorGroupModel';
import { DoorGroupTableRecord } from 'model/DoorGroupTableRecord';
import { fetchAllDoorGroups } from 'redux/actions/doorGroupActions';
import { notifyError } from 'helper/NotificationService';
import { getUserDoorGroupRoomsNames } from 'helper/CommonUtils';
import { AccessTabs } from 'model/enums';
import { useBuildingsInit } from 'hooks/useStorageInits';
import DoorGroupDialog from './accessPageDialogs/DoorGroupDialog';
import DoorGroup from 'model/DoorGroup';
import { AccessForm } from 'model/UserDialogForm';
import { selectDoorGroupsList } from 'redux/selectors/doorGroupSelector';
import { selectCardDialogForm, selectCardDialogIsEdited, selectCardDialogIsOpen } from 'redux/selectors/cardDialogSelector';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { selectIsLostCard } from 'redux/selectors/userSelector';
import { selectUserDialogAccessForm, selectUserDialogForm, selectUserDialogFormAccessError } from 'redux/selectors/userDialogSelector';
import { setUserDialogForm } from 'redux/actions/userDialogActions';

type Props = {
  isDateSelectorDisplayed?: boolean;
}

export const UserAccessPage = ({ isDateSelectorDisplayed }: Props) => {

  const TABS_FIELD_OPTIONS = ['Groups', 'Rooms'];
  const ADD_BUTTON_TEXT = ['Add Groups', 'Add Rooms'];

  const dispatch = useDispatch();
  const form = useSelector(selectUserDialogAccessForm);
  const error = useSelector(selectUserDialogFormAccessError);
  const userDialogForm = useSelector(selectUserDialogForm);
  const buildings = useBuildingsInit();
  const listOfAllRooms = useSelector(selectRoomList);
  const actualSite = ActualSiteLocator();
  const [selectedTab, setSelectedTab] = React.useState<AccessTabs>(AccessTabs.GroupsTab);
  const [selectedRooms, setSelectedRooms] = React.useState<RoomModel[]>([]);
  const [isAddRoomsDialogOpen, setAddRoomsDialogOpen] = React.useState(false);
  const [isAddGroupsDialogOpen, setAddGroupsDialogOpen] = React.useState<boolean>(false);
  const [displayedRoomModels, setDisplayedRoomModels] = React.useState<RoomModel[]>([]);
  const [displayedDoorGroups, setDisplayedDoorGroups] = React.useState<DoorGroupTableRecord[]>([]);
  const [selectedDoorGroup, setSelectedDoorGroup] = React.useState<DoorGroup>();
  const [isFloorDialogOpen, setFloorDialogOpen] = React.useState<boolean>(false);
  const listOfDoorGroups = useSelector(selectDoorGroupsList);
  const isEditedOnCardDialog = useSelector(selectCardDialogIsEdited);
  const cardDialogForm = useSelector(selectCardDialogForm);
  const isOpenedCardDialog = useSelector(selectCardDialogIsOpen) && !!cardDialogForm;
  const formDoorGroups = (!isOpenedCardDialog ? form?.doorGroups : cardDialogForm.doorGroups) ?? [];
  const isLostCard = useSelector(selectIsLostCard);
  const isDisabledByLostCard = (isOpenedCardDialog && isLostCard);

  const setForm = (accessForm: AccessForm) => {
    dispatch(setUserDialogForm({...userDialogForm, accessForm}))
  }

  const roomModelToUserRoom = (roomModel: RoomModel[]): UserRoom[] => {
    return roomModel.map((item: RoomModel) => ({
      RoomId: item.Id,
      RoomName: item.Name,
    }));
  }

  const getDoorGroupListItems = (): DoorGroupModel[] => {
    const doorGroupNames = formDoorGroups?.map(doorGroup => doorGroup.GroupName);
    return doorGroupNames
      ? listOfDoorGroups.map((doorGroup) => ({doorGroup, isChecked : doorGroupNames.includes(doorGroup.GroupName)}))
      : [];
  }

  const [doorGroupList, setDoorGroupList] = React.useState<DoorGroupModel[]>(getDoorGroupListItems());
  const formUserRooms = !isOpenedCardDialog ? (form?.userRooms ?? []) : roomModelToUserRoom(cardDialogForm.cardDoors);

  const handleGroupsTabFetchRequest = () => {
    // Loading the Door Groups and storing them in the redux storage.
    fetchAllDoorGroups(actualSite, dispatch);
  };

  const handleRoomsTabFetchRequest = () => {
    
    // Loading the Buildings and storing them in the redux storage.
    fetchAllBuildings(actualSite, dispatch);

    // Loading the Rooms and storing them in the redux storage.
    fetchAllRooms(actualSite, dispatch);
  };

  React.useEffect(() => {
    if (selectedDoorGroup && !isFloorDialogOpen) {
        setFloorDialogOpen(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDoorGroup]);

  React.useEffect(() => {
    return selectedTab === AccessTabs.GroupsTab 
      ? handleGroupsTabFetchRequest()
      : handleRoomsTabFetchRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  React.useEffect(() => {
    if (listOfAllRooms && listOfAllRooms.length > 0 && formUserRooms) {
      const userRoomIds = formUserRooms
          .filter((room) => !getUserDoorGroupRoomsNames(doorGroupList).map(uRooom => uRooom.RoomId).includes(room.RoomId))
          .map(userRoom => userRoom.RoomId);

      const roomsToDisplay: RoomModel[] = listOfAllRooms.filter((room) => userRoomIds.includes(room.Id));     
      setDisplayedRoomModels(roomsToDisplay);
      setSelectedRooms(roomsToDisplay);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfAllRooms, doorGroupList]);

  React.useEffect(() => {
    if(!isAddRoomsDialogOpen) {
      setSelectedRooms(displayedRoomModels);
    }
  }, [isAddRoomsDialogOpen, displayedRoomModels])

  React.useEffect(() => {
    const userRooms: UserRoom[] = roomModelToUserRoom(displayedRoomModels);
    if (isOpenedCardDialog) {
      dispatch(setCardDialogForm({ ...cardDialogForm, cardDoors: displayedRoomModels}));
    } else if (form && setForm) {
      setForm({...form, userRooms});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedRoomModels]);

  React.useEffect(() => {
    if (listOfDoorGroups && formDoorGroups) {
      setDoorGroupListBasedOnForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfDoorGroups])

  React.useEffect(() => {
    if (doorGroupList) {
      setDisplayedDoorGroups(doorGroupList.filter((item) => item.isChecked).map((item) => ({
        groupName: item.doorGroup.GroupName,
        doors: item.doorGroup.Rooms.length,
      })));
    }
  }, [doorGroupList]);

  React.useEffect(() => {
    if (!isAddGroupsDialogOpen) {
      const doorGroups: DoorGroupType[] = doorGroupList
      .filter((item: DoorGroupModel) => item.isChecked)
      .map((item: DoorGroupModel) => ({
        Id: item.doorGroup.Id,
        GroupName: item.doorGroup.GroupName,
      }));
      if (isOpenedCardDialog) {
        dispatch(setCardDialogForm({ ...cardDialogForm, doorGroups}));
      } else if (setForm && form) {
        setForm({...form, doorGroups})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDoorGroups, isAddGroupsDialogOpen]);

  const handleClickOnAddRooms = () => {setAddRoomsDialogOpen(true);}

  const handleClickOnRoomsConfirm = () => {
    setDisplayedRoomModels(listOfAllRooms.filter((room) => selectedRooms.some(selectedRoom => selectedRoom.Id === room.Id)));
    setAddRoomsDialogOpen(false);
  };

  const handleClickOnRoomRemove = (row: RoomModel) => {
    setDisplayedRoomModels(prev => prev.filter((item) => item.Name !== row.Name));
  }

  const handleClickOnAddGroups = () => {setAddGroupsDialogOpen(true);}

  const handleClickOnGroupRemove = (row: DoorGroupTableRecord) => {
    setDoorGroupList(doorGroupList.map((item) => {
      if (item.doorGroup.GroupName === row.groupName) {
        return { ...item, isChecked: false };
      }
      return item;
    }));
  }

  const setDoorGroupsToDefault = () => {setDoorGroupListBasedOnForm();}

  const handleClickOnClearAll = () => {
    selectedTab === AccessTabs.GroupsTab ? setDoorGroupList(doorGroupList.map((item) => ({ ...item, isChecked: false }))) : setDisplayedRoomModels([]);
  }

  const handleDoorGroupOpen = (row: DoorGroupTableRecord) => {
    const selectedDoorGroup = listOfDoorGroups.find((doorGroup) => doorGroup.GroupName === row.groupName);
    if (selectedDoorGroup) {
      setSelectedDoorGroup(selectedDoorGroup);
    } else {
      notifyError("Couldn't find a group with the given name!", "");
    }
  }

  const setDoorGroupListBasedOnForm = () => {
    const doorGroupListItems = getDoorGroupListItems();
    setDoorGroupList(doorGroupListItems);
  }

  const renderRoomsTable = () => {
    return (
      <>
        <CustomTable
          tableHeight={isOpenedCardDialog && !isDateSelectorDisplayed ? "auto" : undefined}
          customColDataList={roomsColData}
          customColRecords={displayedRoomModels}
          handleClickOnRowRemove={handleClickOnRoomRemove}
          resourceName={"room"}
          evenRowColor={white}
          oddRowColor={alpha(veryLightGrey, OPACITY_OF_ROWS)}
          disabled={isDisabledByLostCard}
        />
      </>
    )
  }

  const renderGroupsTable = () => {
    return (
      <>
        <CustomTable
          tableHeight={isOpenedCardDialog && !isDateSelectorDisplayed ? "auto" : undefined}
          customColDataList={groupsColData}
          customColRecords={displayedDoorGroups}
          handleClickOnRowRemove={handleClickOnGroupRemove}
          resourceName={"group"}
          onRowClickHandler={handleDoorGroupOpen}
        />
      </>
    )
  }

  const isClearAllDisabled = (): boolean => {
    return (selectedTab === AccessTabs.GroupsTab && displayedDoorGroups.length === 0)
    || (selectedTab === AccessTabs.RoomsTab && displayedRoomModels.length === 0);
  }

  return (
    <StyledUserDialogContent
      width={684}
      height={550}
    >
      <StyledContentHolder>
        <StyledContentRowHolder style={{ alignItems: 'center' }}>
          <StyledButtonHolder>
            {buildings && <StyledButton
                buttonHeight={37}
                buttonWidth={144}
                displayedName={ADD_BUTTON_TEXT[selectedTab]}
                handleOnClick={() => (selectedTab === AccessTabs.GroupsTab ? handleClickOnAddGroups() : handleClickOnAddRooms())}
                backgroundColor={white}
                iconRight={addBlack}
                disabled={isDisabledByLostCard || isEditedOnCardDialog}
            />}
            <StyledButton
                buttonHeight={37}
                buttonWidth={104}
                displayedName={"Clear All"}
                handleOnClick={handleClickOnClearAll}
                backgroundColor={white}
                disabled={isClearAllDisabled() || isDisabledByLostCard || isEditedOnCardDialog}
            />
          </StyledButtonHolder>
            <TabsField 
              width={187}
              height={48}
              tabs={TABS_FIELD_OPTIONS}
              isBorder
              selectedTab={selectedTab}
              handleOnClick={(index) => setSelectedTab(index)}
            />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <TableContainer>
            {selectedTab === AccessTabs.RoomsTab ? renderRoomsTable() : renderGroupsTable()}
          </TableContainer>
        </StyledContentRowHolder>
        { isDateSelectorDisplayed && form && error?
          <StyledContentRowHolder>
            <UserAccessDateSelectorCard
              form={form}
              setForm={setForm}
              error={error}
            />
          </StyledContentRowHolder>
        : null}
      </StyledContentHolder>
      <BaseUserAccessDialog
          isDialogOpen={isAddRoomsDialogOpen}
          setDialogOpen={setAddRoomsDialogOpen}
          isNoActionDialog={false}
          dialogResourceName={"Rooms"}
          MainDialogComponent={
            <AddRoomsDialog
              setSelectedRooms={setSelectedRooms}
              selectedRooms={selectedRooms}
              doorGroupList={doorGroupList}
            />
          }
          handleConfirm={handleClickOnRoomsConfirm}
          selectedResourceNumber={selectedRooms.length}
        />
      <AccessGroupDialog 
        isDialogOpen={isAddGroupsDialogOpen} 
        setDialogOpen={setAddGroupsDialogOpen}
        doorGroupList={doorGroupList}
        setDoorGroupList={setDoorGroupList}
        setDoorGroupsToDefault={setDoorGroupsToDefault}
      />
      {selectedDoorGroup && <DoorGroupDialog
        isDialogOpen={isFloorDialogOpen}
        setDialogOpen={setFloorDialogOpen}
        selectedDoorGroup={selectedDoorGroup}
      />}
    </StyledUserDialogContent>
  );
};

export default UserAccessPage;

export const StyledButtonHolder = styled.div`
    display: flex;
    gap: 15px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 195px;
`;
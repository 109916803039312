import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { useSelector } from 'react-redux';
import { selectDialogIsOpen } from 'redux/selectors/userSelector';
import {
  DialogToOpen,
  LocalStorageKeys,
  EncoderStatus,
  EncoderTestingDialogTexts,
} from 'model/enums';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';
import { black, blue, green, lightBlue, red, yellow } from 'constants/theme';
import DialogTexts from 'model/DialogTexts';
import CustomUserFilter from 'components/cards/CustomUserFilter';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import StyledButton from 'components/controls/button/StyledButton';
import { setEncoderConfirmationOpen, setSelectedEncoder } from 'redux/actions/encoderActions';
import { notifyError } from 'helper/NotificationService';
import styled from 'styled-components';
import EncoderLocalStorageModel from 'model/EncoderLocalStorageModel';

const EncoderSelectionDialog = () => {
  const {selectedEncoder, selectedEncoderStatus, listOfEncoders} = useSelector(selectEncoderStorage);
  const [isEncoderDialogOpen, setEncoderDialogOpen] = React.useState(false);
  const isDialogOpen = useSelector(selectDialogIsOpen(DialogToOpen.EncoderTesterDialog));
  const selectedEncoderIndex = listOfEncoders.findIndex((encoder) => encoder.Id === selectedEncoder?.Id);
  const encoderOptions = listOfEncoders.map((encoder) => encoder.Name);
  const dispatch = useDispatch();

  const handleClickOnTestButton = () => {
    if (!selectedEncoder?.Id) {
      handleNotSelectedEncoderErrorMessage();
    }

    dispatch(setEncoderConfirmationOpen(true));
  };

  const encoderTestingDialogBody: DialogTexts = {
    title: EncoderTestingDialogTexts.DialogTitle,
    contentText: (
      <React.Fragment>
        <div>Select the encoder from the dropdown.</div>
        <div>To make sure it works you can test it with the button.</div>
        <div>
          <EncoderBodyHolder>
            <CustomUserFilter
              filterValue={selectedEncoder?.Name}
              filterOnOpenHandle={() => setEncoderDialogOpen(true)}
              filterOnCloseHandle={() => setEncoderDialogOpen(false)}
              filterName={EncoderTestingDialogTexts.EncoderFilterName}
              filterOptions={encoderOptions}
              isFilterOpen={isEncoderDialogOpen}
              setSelectedFilterOptionIndex={(index) => dispatch(setSelectedEncoder(listOfEncoders[index]))}
              selectedFilterOptionIndex={selectedEncoderIndex}
              maxWidth={407}
              height={'50px'}
            />
            <StyledButtonHolder>
              <StyledButton
                handleOnClick={handleClickOnTestButton}
                displayedName={EncoderTestingDialogTexts.EncoderTestButtonName}
                buttonHeight={48}
                buttonWidth={121}
                isFilledButton={false}
                backgroundColor={lightBlue}
                disabled={!selectedEncoder}
              />
            </StyledButtonHolder>
          </EncoderBodyHolder>
        </div>
      </React.Fragment>
    ),
    submitButton: 'Save',
    cancelButton: 'Cancel',
  };

  const additionalFooterRowContent = () => {
    return (
      <EncoderStatusHolder>
        <EncoderStatusIconHolder color={getEncoderStatusColorByMessage(selectedEncoderStatus)}/>
        <EncoderStatusMessageHolder color={getEncoderStatusColorByMessage(selectedEncoderStatus)}>
          {selectedEncoderStatus}
        </EncoderStatusMessageHolder>
      </EncoderStatusHolder>
    );
  };

  const saveEncoderDetailsInLocalStorage = () => {
    if (!selectedEncoder) {
      handleNotSelectedEncoderErrorMessage();
      return;
    }

    if (selectedEncoderStatus !== EncoderStatus.EncoderIsActivated) {
      handleNotActivatedEncoderErrorMessage();
      return;
    }

    const encoderLocalStorageModel: EncoderLocalStorageModel = { encoderId: selectedEncoder.Id };
    localStorage.setItem(LocalStorageKeys.Encoder, JSON.stringify(encoderLocalStorageModel));
    handleDialogToClose();
  }

  const revertChangesOnSelectedEncoder = () => {
    const localStorageItem = localStorage.getItem(LocalStorageKeys.Encoder);

    if (!localStorageItem) {
      handleDialogToClose();
      return;
    }

    const encoderLocalStorageModel: EncoderLocalStorageModel = JSON.parse(localStorageItem);

    if (!(encoderLocalStorageModel.encoderId && !!Number(encoderLocalStorageModel.encoderId))) {
      handleDialogToClose();
      return;
    }

    const selectedEncoderFromLocalStorage = listOfEncoders.find(encoder => encoder.Id === encoderLocalStorageModel.encoderId);
    dispatch(setSelectedEncoder(selectedEncoderFromLocalStorage ?? undefined));
    handleDialogToClose();
  }

  const handleDialogToClose = () => dispatch(setOpenedDialog(undefined));

  const handleNotActivatedEncoderErrorMessage = () => {
    notifyError('Please select an activated Encoder!', '');
  }

  const handleNotSelectedEncoderErrorMessage = () => {
    notifyError('Please select an Encoder!', '');
  }

  return (
    <DialogTemplate
      handleSubmit={saveEncoderDetailsInLocalStorage}
      handleClose={revertChangesOnSelectedEncoder}
      open={isDialogOpen}
      dialogText={encoderTestingDialogBody}
      submitButtonColor={blue}
      dialogId={EncoderTestingDialogTexts.DialogTitle}
      additionalFooterRowContent={additionalFooterRowContent()}
    />
  );
};

export default EncoderSelectionDialog;

export const getEncoderStatusColorByMessage = (encoderStatus: EncoderStatus) => {
  switch(encoderStatus) {
    case EncoderStatus.EncoderIsActivated:
      return green;
    case EncoderStatus.EncoderIsInactive:
      return red;
    case EncoderStatus.EncoderIsNotSelectedYet:
      return yellow;
    default: 
      return black;
  }
}

const StyledButtonHolder = styled.div<{}>((props) => ({ 
  display: 'grid',
  gap: '2px',
  height: '60px',
}));

const EncoderBodyHolder = styled.div<{}>((props) => ({ 
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  paddingTop: '32px',
}));

const EncoderStatusHolder = styled.div((props) => ({ 
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '24px 32px',
}));

export const EncoderStatusIconHolder = styled.div<{ color: string }>((props) => ({ 
  backgroundColor: props.color,
  borderRadius: '50%',
  height: '10px',
  width: '10px',
}));

const EncoderStatusMessageHolder = styled.div<{ color: string }>((props) => ({ 
  color: props.color, 
  fontWeight: 400, 
  fontSize: '14px' 
}));
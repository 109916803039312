import { CardDialogPages, DialogToOpen } from 'model/enums';
import React from 'react';
import { StyledDialogContentLeftSide } from '../../userCardDialog/UserCardDialog';
import { useDispatch } from 'react-redux';
import { setCardDialogSelectedPage } from 'redux/actions/cardDialogActions';
import MenuOptionDialog from '../../userCardDialog/contentLeftSide/MenuOptionDialog';
import { useSelector } from 'react-redux';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { selectOpenedDialog } from 'redux/selectors/userSelector';

export const CardDialogMenuOptions = () => {
  const [dialogMenuOptions, setDialogMenuOptions] = React.useState<CardDialogPages[]>([]);
  const { isDialogOpen, selectedPageName } = useSelector(selectCardDialogStorage,);
  const openedDialog = useSelector(selectOpenedDialog);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isDialogOpen || !openedDialog) {
      return;
    }

    const handler = {
      [DialogToOpen.CreateKeyCardDialog]: [CardDialogPages.CardDetails, CardDialogPages.Doors],
      [DialogToOpen.CreateMasterCardDialog]: [CardDialogPages.CardDetails, CardDialogPages.Levels],
      [DialogToOpen.CreateEmergencyCardDialog]: [],
      [DialogToOpen.CreateFloorCardDialog]: [],
    };
    setDialogMenuOptions(handler[openedDialog.toString()]);
  }, [isDialogOpen, openedDialog]);

  const setSelectedPageOnStorage = (selectedPageName: string) => {
    dispatch(setCardDialogSelectedPage(selectedPageName as CardDialogPages));
  }

  return (
    <>
      {dialogMenuOptions.length > 0 ? (
        <StyledDialogContentLeftSide>
          {dialogMenuOptions.map((menuOption, index) => (
            <MenuOptionDialog
              key={index}
              dialogMenuName={menuOption}
              selectedPageName={selectedPageName}
              setSelectedPageName={setSelectedPageOnStorage}
            />
          ))}
        </StyledDialogContentLeftSide>
      ) : null}
    </>
  );
};

import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { blue } from 'constants/theme';
import { useSelector } from 'react-redux';
import { DialogToOpen, EraseCardDialogTexts } from 'model/enums';
import { useDispatch } from 'react-redux';
import { eraseCardDialogReadAction } from 'constants/dialogTexts';
import { readCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { selectIsSignalrResponseStatusPending, selectSelectedEncoder } from 'redux/selectors/encoderSelector';
import { notifyErrorEncoderIsNotSelected } from 'helper/EncoderUtils';
import { selectDialogIsOpen } from 'redux/selectors/userSelector';
import { setOpenedDialog } from 'redux/actions/userActions';
import { handleEncoderNotRespondedYetError, setEncoderRequestToActive, setEncoderRequestToInactive } from 'hooks/useSignalrEncoder';

const EraseCardReadActionDialog = () => {

    const isOpen = useSelector(selectDialogIsOpen(DialogToOpen.EraseCardReadActionDialog));
    const selectedEncoder = useSelector(selectSelectedEncoder);
    const isSignalrResponseStatusPending = useSelector(selectIsSignalrResponseStatusPending);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    const handleReadCardSubmit = async () => {
        if (!selectedEncoder) {
            notifyErrorEncoderIsNotSelected();
            return;
        }

        const response = await readCard(actualSite, selectedEncoder.Id);

        if (!response) {
            return;
        }

        setEncoderRequestToActive(dispatch);
    }

    const handleDialogClose = () => {
        if (isSignalrResponseStatusPending) {
            handleEncoderNotRespondedYetError();
            return;
        }

        dispatch(setOpenedDialog());
        setEncoderRequestToInactive(dispatch);
    }

    return (
        <DialogTemplate
            handleSubmit={handleReadCardSubmit}
            handleClose={handleDialogClose}
            open={isOpen}
            dialogText={eraseCardDialogReadAction}
            submitButtonColor={blue}
            dialogId={EraseCardDialogTexts.ReadCardTitle}
        />
    );
}

export default EraseCardReadActionDialog;
import MasterLevel from 'model/MasterLevel';

export const formatNumber = (num: number, totalLength: number): string => {
    const numStr = num.toString();

    return numStr.length > totalLength ? numStr : numStr.padStart(totalLength, '0');
};

export const giveBackMasterLevelLastElement = (masterLevel: string | null, listOfMasterLevels: MasterLevel[]): number => {
    return masterLevel && listOfMasterLevels.length > 0 ? Math.max(
        ...listOfMasterLevels
            .filter(level => masterLevel.includes(level.ID.toString()))
            .map(level => level.ID)
    ) : 0;
}
import { AxiosError } from 'axios';
import { CommandCategory, DepartmentCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import DepartmentDetail from 'model/DepartmentDetail';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const notifyDepartmentsError = (error: AxiosError | null) => {
    notifyCommandError('Error while executing get departments command', error);
};

export const getDepartments = async (siteName: string) => {
    try {
        const data = await postCommand(
        siteName,
        CommandCategory.DepartmentService,
        DepartmentCommand.GetDepartments,
        [],
    );
    if (!data || !data.data) {
        notifyCommandError('No departments found or the server timed out.', null);
        return [];
    }
    const departmentsList: DepartmentDetail[] = data.data;
    return departmentsList;
    } catch (err) {
        const error = getAxiosErrorOrNull(err);
        notifyDepartmentsError(error);
        return null;
    }
}
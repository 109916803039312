import * as React from 'react';
import { List, ListItemButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { StyledCollapse, StyledListItemTextHeader } from '../UserWindowCommonStyle';
import CardModel from 'model/CardModel';
import DoorListCardDoorsCard from './DoorListCardDoorsCard';
import NoSelectionCard from '../NoSelectionCard';
import { renderNoDoorToDisplayContent } from 'constants/dialogTexts';

type Props = {
  cardModelToDisplay: CardModel | null;
  handleFetchOfUserCards: (isOpen: boolean) => void;
};

const DoorListCard = ({cardModelToDisplay, handleFetchOfUserCards}: Props) => {
  
    const [isTabOpen, setTabOpen] = React.useState(false);

    const handleClickInformationMenu = () => {
        const isOpen = !isTabOpen;
        setTabOpen(isOpen);
        handleFetchOfUserCards(isOpen);
    };

  return (
    <List component="nav">
        <ListItemButton onClick={handleClickInformationMenu}>
            <StyledListItemTextHeader >
                Door {`${cardModelToDisplay ? `(${cardModelToDisplay?.Rooms.length})` : ""}`}
            </StyledListItemTextHeader>
            {isTabOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <StyledCollapse in={isTabOpen} timeout="auto" unmountOnExit>
            <List component="div">
                { cardModelToDisplay && cardModelToDisplay?.Rooms.length > 0 ? 
                    <DoorListCardDoorsCard cardModelToDisplay={cardModelToDisplay} />
                : 
                    <NoSelectionCard cardContent={renderNoDoorToDisplayContent()}/>
                } 
            </List>
        </StyledCollapse>
    </List>
  );
};

export default DoorListCard;

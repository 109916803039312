import React  from 'react';
import { black, blue, white } from "constants/theme";
import DialogTexts from "model/DialogTexts";
import TemplateDialog from "./TemplateDialog";

type Props = {
    handleSubmit: () => void;
    handleClose: () => void;
    open: boolean;
  };

const ConfirmationDialog = ({handleSubmit, handleClose, open}: Props) => {
    return <TemplateDialog dialogText={ConfirmationDialogText} open={open} handleSubmit={handleSubmit} handleClose={handleClose} style={ConfirmationDialogStyle} isLinearProgressBarHidden />;
}

export default ConfirmationDialog;

const ConfirmationDialogText = new DialogTexts(
    "Confirming Cancel",
    "Are you sure you want to cancel? All information will be lost.",
    "Confirm",
    "Cancel"
);

const ConfirmationDialogStyle = {
  dialog: { color: black, width: '29vw', borderRadius: '20px', textAlign: 'center' as const},
  title: { alignSelf: 'center', fontWeight: 'bold' },
  content: { paddingBottom: '0' },
  contentText: { color: 'rgba(0, 0, 0, 1)' },
  actions: { justifyContent: 'end', padding: '24px 32px' },
  submitButton: {
      backgroundColor: blue,
      color: white,
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
  },
  cancelButton: {
      color: black,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
      fontWeight: 'bold'
  },
};
enum EncoderActionTypes {
    ENCODERS_REQUESTED = "ENCODERS_REQUESTED",
    REQUEST_ENCODERS_SUCCESS = "REQUEST_ENCODERS_SUCCESS",
    REQUEST_ENCODERS_FAILURE = "REQUEST_ENCODERS_FAILURE",
    SET_SELECTED_ENCODER = "SET_SELECTED_ENCODER",
    SET_SELECTED_ENCODER_STATUS = "SET_SELECTED_ENCODER_STATUS",
    SET_ENCODER_CONFIRMATION_OPEN = "SET_ENCODER_CONFIRMATION_OPEN",
    SET_AWAITS_ENCODER_RESPONSE = "SET_AWAITS_ENCODER_RESPONSE",
    SET_ENCODER_CARD_RESPONSE = "SET_ENCODER_CARD_RESPONSE",
    SET_SIGNALR_RESPONSE_STATUS = "SET_SIGNALR_RESPONSE_STATUS",
};

export default EncoderActionTypes;
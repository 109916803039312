import * as React from 'react';
import { Box, List } from '@mui/material';
import { grey } from 'constants/theme';
import CardModel from 'model/CardModel';
import { setOverflowContainerWithText} from 'helper/Util';
import { CardDetailWindow } from 'model/enums';
import { StyledGrid, StyledListGrid } from '../UserWindowCommonStyle';
import { useSelector } from 'react-redux';
import { selectPageNavigation } from 'redux/selectors/userSelector';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { useBuildingsInit, useMasterLevelsInit } from 'hooks/useStorageInits';
import { extractMasterCardLevelsFromCardModel, getSelectedBuildingByCardModel, getSelectedFloorByCardModel } from 'hooks/useFormBuilder';
import styled from 'styled-components';
import { cardDetailWindowComponentGridSize, windowCardsEmptyData } from 'constants/globalConstants';
import { UNLIMITED_DATE_TEXT } from 'helper/DataTableUtils';

type Props = {
  cardModelToDisplay: CardModel | null;
};

const CardDetailsWindowCardStatusBody = ({cardModelToDisplay}: Props) => {

  const pageNavigation = useSelector(selectPageNavigation);
  const cardDetailWindowComponents = ConversionsUtil.pageNavigationToCardDetailWindowComponents(pageNavigation, cardModelToDisplay?.Type);
  const listOfMasterLevels = useMasterLevelsInit();
  const listOfBuildings = useBuildingsInit();
  const selectedBuilding = getSelectedBuildingByCardModel(listOfBuildings, cardModelToDisplay);
  const selectedFloor = getSelectedFloorByCardModel(selectedBuilding?.Floors ?? [], cardModelToDisplay);

  const getMasterLevelNameById = (levelId: number) => {
    return listOfMasterLevels.find(level => level.ID === levelId)?.Name;
  }

  const displayMasterLevels = (): JSX.Element => {
    if (!cardModelToDisplay?.Remark) {
      return <>{windowCardsEmptyData}</>;
    }

    const selectedMasterCardLevels = extractMasterCardLevelsFromCardModel(cardModelToDisplay).map(id => Number(id));
    
    return (<StyledMasterLevelsHolder>
      { selectedMasterCardLevels.map(level => (
        <div key={level}>
          {getMasterLevelNameById(level)}
        </div>
      ))}
    </StyledMasterLevelsHolder>)
  }

  const windowElementHandler = Object.freeze({
    [CardDetailWindow.CardType]: () => setOverflowContainerWithText(CardDetailWindow.CardType, cardModelToDisplay?.Type),
    [CardDetailWindow.MasterNumber]: () => setOverflowContainerWithText(CardDetailWindow.MasterNumber, cardModelToDisplay?.MasterNo),
    [CardDetailWindow.WriteCardTime]: () => setOverflowContainerWithText(CardDetailWindow.WriteCardTime, cardModelToDisplay?.CreatedOn),
    [CardDetailWindow.LimitedDate]: () => setOverflowContainerWithText(CardDetailWindow.LimitedDate, cardModelToDisplay?.ExpiryDate ? cardModelToDisplay?.ExpiryDate : UNLIMITED_DATE_TEXT),
    [CardDetailWindow.OperatorLogonCode]: () => setOverflowContainerWithText(CardDetailWindow.OperatorLogonCode, cardModelToDisplay?.CreatedBy),
    [CardDetailWindow.Issue]: () => setOverflowContainerWithText(CardDetailWindow.Issue, cardModelToDisplay?.IssueNo),
    [CardDetailWindow.Remark]: () => setOverflowContainerWithText(CardDetailWindow.Remark, cardModelToDisplay?.Remark),
    [CardDetailWindow.Building]: () => setOverflowContainerWithText(CardDetailWindow.Building, selectedBuilding?.Name),
    [CardDetailWindow.Floor]: () => setOverflowContainerWithText(CardDetailWindow.Building, selectedFloor?.Name),
    [CardDetailWindow.MasterCardLevels]: () => displayMasterLevels(),
  });

  return (
    <List component="div" >
        <Box sx={{ width: '100%' }}>
            <StyledListGrid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3}}>
                { cardDetailWindowComponents.map((optionElement, index) => (
                  <React.Fragment key={index}>
                    <StyledGrid item xs={cardDetailWindowComponentGridSize.leftComponent}>
                      {optionElement}
                    </StyledGrid>
                    <StyledGrid item xs={cardDetailWindowComponentGridSize.rightComponent} color={grey}>
                      {windowElementHandler[optionElement as string].call()}
                    </StyledGrid>
                  </React.Fragment>
                ))}
            </StyledListGrid>
        </Box>
    </List>
  );
};

export default CardDetailsWindowCardStatusBody;

const StyledMasterLevelsHolder = styled.div((props) => ({
  display: "grid",
  gap: "8px",
}));

import { DoorStatus, DoorStatuses } from "model/event";
import DoorStatusActionTypes from "redux/constants/doorStatusActionTypes";

const initDoorStatuesEvent: DoorStatus[] = []

const initState: DoorStatuses = { doorStatuses: initDoorStatuesEvent }


export const doorStatusReducer = function(state: DoorStatuses = initState, action) {
    let index;
    switch (action.type) {
        case DoorStatusActionTypes.ADD_DOOR_STATUS:
            index = state.doorStatuses.findIndex((event) => event.Id === action.payload.Id);
            if(index > -1) {
                state.doorStatuses[index] = {
                    Id: action.payload.Id,
                    Status: action.payload.Status,
                    LockDownStatus: action.payload.LockDownStatus,
                    DoorStatus: action.payload.DoorStatus,
                    LockStatus: action.payload.LockStatus,
                    Loading: {
                        LockDownStatusLoading: false,
                        LockStatusLoading: false
                    }
                }
                return {
                    ...state,
                    doorStatuses: [...state.doorStatuses]
                }
            } else {
                return {
                    ...state,
                    doorStatuses: [...state.doorStatuses, action.payload]
                } 
            }
        case DoorStatusActionTypes.ADD_LOADING_LOCK_DOWN_STATUS:
            index = state.doorStatuses.findIndex((event) => event.Id === action.payload.Id);
            state.doorStatuses[index].Loading.LockDownStatusLoading = true;
            return {
                ...state,
                doorStatuses: [...state.doorStatuses]
            }

        case DoorStatusActionTypes.ADD_LOADING_LOCK_STATUS:
            index = state.doorStatuses.findIndex((event) => event.Id === action.payload.Id);
            state.doorStatuses[index].Loading.LockStatusLoading = true;
            return {
                ...state,
                doorStatuses: [...state.doorStatuses]
            }

        default:
            return state;
    }
}
export const mainBlue = '#015aa3';
export const lightGrey = '#c8c8c8';
export const lighterGrey = '#d2d2d2';
export const veryLightGrey = '#f0f0f0';
export const datePickerGrey = '#e7e8eb';
export const screenSmallForCard = '1100px';
export const grey = '#a0a0a0';
export const offlineHouse = '#DBDBDD';
export const white = '#ffffff';
export const black = '#000000';
export const darkGreyTransparent = 'rgba(118, 118, 128, 0.12)';
export const opaqueBlack = 'rgba(0, 0, 0, 0.7)';
export const green = '#1EA03B';
export const lightGreen = '#1EA03B1A';
export const blue = '#0264B2';
export const red = '#EB2222';
export const lightRed = '#EB22221A'
export const yellow = '#EB8222';
export const lightYellow = '#EB82221A';
export const lightBlue = '#E4EBF3';
export const darkGray = '#465057';
export const dividerGray = '#D9D9D9';
export const listItemLightGray = '#71747D';
export const lightGrayBackground = '#F2F2F280';
export const dialogMenuOptionBorderGray = '#C4C4C4';
export const OPACITY_OF_ROWS = 0.4;
import { createSelector } from "@reduxjs/toolkit";
import DoorGroup from "model/DoorGroup";
import { DoorGroupType } from "model/NewUserModel";
import { IRootState } from "redux/store";

export const selectDoorGroupsList = (state): DoorGroup[] => state.doorGroupsList.doorGroups;

const selectMatchedRoomsByDoorGroup = createSelector(
    [selectDoorGroupsList, (_: IRootState, doorGroupsTypes: DoorGroupType[]) => doorGroupsTypes],
    (doorGroups, doorGroupsTypes: DoorGroupType[]) => {
        return doorGroupsTypes.map(doorGroupType => 
            doorGroups?.find(group => group.Id === doorGroupType.Id)
        )?.filter(group => group !== undefined) as DoorGroup[] ?? [];
    }
);

export const selectMatchedDoorGroups = (doorGroupsTypes: DoorGroupType[] | null) => (state: IRootState) => {
    return doorGroupsTypes ? selectMatchedRoomsByDoorGroup(state, doorGroupsTypes) : [];
}
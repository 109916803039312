import CardDialogFormError from 'model/CardDialogFormError';
import { DialogToOpen } from 'model/enums';
import UserDialogFormError from 'model/UserDialogFormError';

export const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
        event.stopPropagation();
    }
}

export const isNotFilledOutAnything = (form: any, formToCompareWith) => JSON.stringify(form) === JSON.stringify(formToCompareWith);

export const isUserDialogOpened = (dialogToOpen: DialogToOpen | undefined) => {
    return [DialogToOpen.CreateUserDialog, DialogToOpen.EditUserDialog].some(dialog => dialog === dialogToOpen);
}

export const isCardDialogOpened = (dialogToOpen: DialogToOpen | undefined) => {
    return [DialogToOpen.CreateEmergencyCardDialog, DialogToOpen.CreateKeyCardDialog, DialogToOpen.CreateMasterCardDialog, DialogToOpen.CreateFloorCardDialog].some(dialog => dialog === dialogToOpen);
}

export const isAllFormInputsValid = (dialogFormError: UserDialogFormError | CardDialogFormError) => Object.values(dialogFormError).every(formError => Object.values(formError).every(error => error === undefined));
import React  from 'react';
import { black, white, yellow } from "constants/theme";
import DialogTexts from "model/DialogTexts";
import TemplateDialog from "./TemplateDialog";
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { reportLostCard } from 'API/commands/CardCommands';
import { useDispatch, useSelector } from 'react-redux';
import { selectOpenedDialog, selectOperatorId, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { missingOperatorNotification } from 'hooks/useOperatorInit';
import { DialogToOpen } from 'model/enums';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';

const ReportLostCardDialog = () => {
    const selectedUserTableRecordRow = useSelector(selectSelectedUserRow);
    const operatorId = useSelector(selectOperatorId);
    const dialogToOpen = useSelector(selectOpenedDialog);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();
    const open = dialogToOpen === DialogToOpen.ReportLostDialog;
    
    const handleSubmit = () => {
        handleClose();
        dispatch(setUsersIsRefreshUsersList(true));
    }
    
    const handleClose = () => dispatch(setOpenedDialog(undefined));

    const handleDialogSubmit = async () => {
        if (!selectedUserTableRecordRow?.cardId) {
            return;
        }

        if (!operatorId) {
            missingOperatorNotification();
            return;
        }

        await reportLostCard(actualSite, operatorId, selectedUserTableRecordRow.cardId);
        handleSubmit();
    }

    return <TemplateDialog dialogText={LostCardDialogText} open={open} handleSubmit={handleDialogSubmit} handleClose={handleClose} style={LostCardDialogStyle} isLinearProgressBarHidden/>;
}

export default ReportLostCardDialog;

export const LostCardDialogText = new DialogTexts(
    "Report Lost Card",
    "You’re about to report this card as lost. Once this operation is done this card will not have access to any locks until it’s reissued.",
    "Report Lost",
    "Cancel"
);

export const LostCardDialogStyle = {
  dialog: { color: black, width: '600px', height: '214px', borderRadius: '20px', textAlign: 'center' as const, marginBottom: '38vh'},
  title: { alignSelf: 'center', fontWeight: 'bold' },
  content: { paddingBottom: '0' },
  contentText: { color: 'rgba(0, 0, 0, 1)' },
  actions: { display: 'flex', justifyContent: 'end', padding: '24px 32px' },
  submitButton: {
      backgroundColor: yellow,
      color: white,
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const
  },
  cancelButton: {
      color: black,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
  },
};
import * as React from 'react';
import UserTableRecord from 'model/UserTableRecord';
import { useDispatch, useSelector } from 'react-redux';
import { setUsersToDelete } from 'redux/actions/userActions';
import { selectUsersToDelete } from 'redux/selectors/userSelector';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import styled from 'styled-components';

type Props = {
  userTableRecord: UserTableRecord;
};

const CardSelectionCell = ({ userTableRecord }: Props) => {
  const usersToDelete = useSelector(selectUsersToDelete);
  const dispatch = useDispatch();

  const initInputValue = () => {
    return usersToDelete && usersToDelete.length > 0 && userTableRecord
      ? usersToDelete.find((user) => user.userId === userTableRecord.globalId)
          ?.isCheckboxChecked
      : false;
  };

  return (
    <StyledCheckBoxHolder>
      <CheckboxField
        boxWidth={'24px'}
        boxHeight={'24px'}
        inputValue={initInputValue()}
        setValue={(value) => dispatch(setUsersToDelete([
          ...usersToDelete.filter((user) => user.userId !== userTableRecord.globalId),
          { userId: userTableRecord.globalId, isCheckboxChecked: value },
        ]))}
      />
    </StyledCheckBoxHolder>
  );
};

export default CardSelectionCell;

const StyledCheckBoxHolder = styled.div`padding-left: 8px;`
import { getMasterLevels } from 'API/commands/MasterLevelService';
import MasterLevel from 'model/MasterLevel';
import { Dispatch } from 'redux';
import MasterLevelTypes from 'redux/constants/masterLevelTypes';

export const setMasterLevelsRequest = () => {
  return { type: MasterLevelTypes.MASTER_LEVELS_REQUESTED };
};
export const setMasterLevelsSuccess = (data: MasterLevel[]) => {
  return {
    type: MasterLevelTypes.REQUEST_MASTER_LEVELS_SUCCESS,
    payload: data,
  };
};
export const setMasterLevelsFailure = (err: any) => {
  return {
    type: MasterLevelTypes.REQUEST_MASTER_LEVELS_FAILURE,
    payload: err,
  };
};
export const requestMasterLevels = (actualSite: string, dispatch: Dispatch) => {
  dispatch(setMasterLevelsRequest());
  getMasterLevels(actualSite)
    .then((masterLevels) => {
      if (masterLevels && masterLevels.length > 0) {
        dispatch(setMasterLevelsSuccess(masterLevels));
      } else {
        dispatch(setMasterLevelsFailure([]));
      }
    })
    .catch((err) => {
      dispatch(setMasterLevelsFailure(err));
    });
};

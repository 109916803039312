import * as React from 'react';
import { StyledDivider } from '../../ReissueCardDialog';
import styled from 'styled-components';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import { CardLevelsForm } from 'model/CardDialogForm';
import MasterLevel from 'model/MasterLevel';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import { selectIsLostCard } from 'redux/selectors/userSelector';

const CardLevelsDialog = () => {
  const checkboxWidth = '300px';
  const checkboxHeight = '40px';
  const {form, isEdited} = useSelector(selectCardDialogStorage);
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const isLostCard = useSelector(selectIsLostCard);
  const cardLevelForms = form?.cardLevels;
  const atLeastOneLevelChecked = cardLevelForms ? cardLevelForms.some((masterLevel) => Boolean(masterLevel.isChecked)) : false;
  const allMasterLevelChecked = cardLevelForms ? cardLevelForms.every((masterLevel) =>Boolean(masterLevel.isChecked)) : false;
  const dispatch = useDispatch();

  const updateForm = (cardLevels: CardLevelsForm[]) => {
    if (!form) {
      return;
    }

    dispatch(setCardDialogForm({ ...form, cardLevels }));
  }

  const setSelectedMasterLevels = (
    isChecked: boolean,
    masterLevel: MasterLevel,
    cardLevelForms: CardLevelsForm[],
  ): CardLevelsForm[] =>
    cardLevelForms.map((cardLevelForm) => ({
      masterLevel: cardLevelForm.masterLevel,
      isChecked:
        cardLevelForm.masterLevel?.ID === masterLevel.ID
          ? isChecked
          : cardLevelForm.isChecked,
    }))
  ;

  const getSetValueOfAccessAllLevelsCheckbox = (isChecked: boolean) => {
    if (!cardLevelForms) {
      return;
    }

    updateForm(
      cardLevelForms.map((cardLevelForm) => ({
        masterLevel: cardLevelForm.masterLevel,
        isChecked,
      })),
    )
  }

  const getInputValueOfMasterLevelCheckbox = (masterLevel: MasterLevel) => (
    cardLevelForms?.find(
      (cardLevelForm) =>
        cardLevelForm.masterLevel?.ID === masterLevel.ID,
    )?.isChecked
  )

  return (
    <React.Fragment>
      { cardLevelForms ?
        <StyledMenuPage>
          <CheckboxField
            boxWidth={checkboxWidth}
            boxHeight={checkboxHeight}
            displayedName="Access All Master Levels"
            isIndeterminate={!allMasterLevelChecked && atLeastOneLevelChecked}
            setValue={getSetValueOfAccessAllLevelsCheckbox}
            inputValue={allMasterLevelChecked}
            isDisabled={isLostCard || isEdited}
          />
          <StyledDivider />
          {listOfMasterLevels.map(masterLevel => (
            <div key={masterLevel.ID}>
              <CheckboxField
                boxWidth={checkboxWidth}
                boxHeight={checkboxHeight}
                displayedName={masterLevel.Name}
                setValue={(isChecked: boolean) => updateForm(setSelectedMasterLevels(isChecked, masterLevel, cardLevelForms))}
                inputValue={getInputValueOfMasterLevelCheckbox(masterLevel)}
                isDisabled={isLostCard || isEdited}
              />
            </div>
          ))}
        </StyledMenuPage>
      : null }
    </React.Fragment>
  );
};

export default CardLevelsDialog;

const StyledMenuPage = styled.div`
  width: 650px;
`;

import { DoorStatus } from "model/event"
import DoorStatusActionTypes from "redux/constants/doorStatusActionTypes"

export const addDoorStatusToStore = (doorStatusObject: DoorStatus) => {
    return {
      type: DoorStatusActionTypes.ADD_DOOR_STATUS,
      payload: {
        Id: doorStatusObject.Id,
        Status: doorStatusObject.Status,
        LockDownStatus: doorStatusObject.LockDownStatus,
        DoorStatus: doorStatusObject.DoorStatus,
        LockStatus: doorStatusObject.LockStatus,
        Loading: {
          LockDownStatusLoading: false,
          LockStatusLoading: false
      }
    }
}
}
export const addLoadingToLockStatus = (Id: number) => {
  return {
    type: DoorStatusActionTypes.ADD_LOADING_LOCK_STATUS,
    payload: { Id: Id }
  }
}
export const addLoadingToLockDownStatus = (Id: number) => {
  return {
    type: DoorStatusActionTypes.ADD_LOADING_LOCK_DOWN_STATUS,
    payload: { Id: Id }
  }
}
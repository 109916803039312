import { createSelector } from "@reduxjs/toolkit";
import DoorGroup from "model/DoorGroup";
import RoomModel from "model/RoomModel";
import { IRootState } from "redux/store";

export const selectRoomList = (state: IRootState) => state.roomList.rooms;

export const selectRoomLockType = (state: IRootState) => state.roomList.lockTypes;

const selectMatchedRoomsByDoorGroup = createSelector(
    [selectRoomList, (_: IRootState, selectedDoorGroup: DoorGroup) => selectedDoorGroup],
    (rooms, selectedDoorGroup: DoorGroup) => {
        return selectedDoorGroup.Rooms?.map(room => 
            rooms?.find(allRoom => allRoom.Name === room.RoomName)
        )?.filter(room => room !== undefined) as RoomModel[] ?? [];
    }
);

export const selectMatchedRooms = (selectedDoorGroup: DoorGroup) => (state: IRootState) => {
    return selectMatchedRoomsByDoorGroup(state, selectedDoorGroup);
}
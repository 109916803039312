import React, { useState } from 'react';
import Building from 'model/Building';

import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FloorCard from './FloorCard';
import styled from 'styled-components';
import MultiStateCheckBox from 'components/controls/MultiStateCheckBox';
import Floor from 'model/Floor';
import OnlineDoor from 'model/OnlineDoor';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import { Checked } from 'model/MultiStateCheckbox';
import { OnlineDoorCommand } from 'model/enums';

type Props = {
  building: Building;
  buildings: Building[];
  doors: OnlineDoor[];
  setSelectedBuildings: (building: Building) => void;
  setSelectedFloors: (floor: FloorWithBuildingName) => void;
  setSelectedDoors: (door: OnlineDoor) => void;
  selectedBuildings: Building[];
  selectedFloors: FloorWithBuildingName[];
  selectedDoors: OnlineDoor[];
  confirmCommand: (
    commandType?: OnlineDoorCommand,
    buildings?: Building[],
    floorsWithBuildingId?: FloorWithBuildingName[],
    doors?: OnlineDoor[],
  ) => void;
  siteName: string;
};

const BuildingCard = ({
  building,
  buildings,
  doors,
  setSelectedBuildings,
  setSelectedFloors,
  setSelectedDoors,
  selectedBuildings,
  selectedFloors,
  selectedDoors,
  confirmCommand,
  siteName
}: Props) => {
  const [openedFloor, setOpenedFloor] = useState<null | Floor>(null);
  const [openFloors, setOpenFloors] = useState<boolean>(false);

  const handleClick = () => {
    setSelectedBuildings(building);
  };

  const getSelectedValue = (): Checked => {
    if (
      selectedBuildings.find(
        (selectedBuilding) => selectedBuilding.Id === building.Id,
      )
    ) {
      return 2;
    }
    if (
      selectedDoors.find((door) => door.Building === building.Name) ||
      selectedFloors.find((floor) => floor.buildingName === building.Name)
    ) {
      return 1;
    }
    return 0;
  };

  const handleOpenedFloor = (floor: Floor) => {
    if (openedFloor?.Id === floor.Id) {
      setOpenedFloor(null);
    } else {
      setOpenedFloor(floor);
    }
  };

  const handleDropDown = () => {
    if (openFloors) {
      setOpenedFloor(null);
    }
    setOpenFloors(!openFloors);
  };

  const renderFloors = building?.Floors.map((floor, index) => (
    <FloorCard
      floor={floor}
      doors={doors}
      buildings={buildings}
      buildingName={building.Name}
      key={'floor' + index}
      setSelectedFloors={setSelectedFloors}
      setSelectedDoors={setSelectedDoors}
      selectedBuildings={selectedBuildings}
      selectedFloors={selectedFloors}
      selectedDoors={selectedDoors}
      opened={openedFloor?.Id === floor.Id}
      openFloor={handleOpenedFloor}
      confirmCommand={confirmCommand}
      siteName={siteName}
    />
  ));

  return (
    <Container>
      <MainComponent>
        <MultiStateCheckBox onChange={handleClick} value={getSelectedValue()} />
        <p>{building.Name}</p>
        <StyledListItemButton onClick={handleDropDown}>
          {openFloors ? <ExpandLess /> : <ExpandMore />}
        </StyledListItemButton>
      </MainComponent>
      <div style={{ display: openFloors ? '' : 'none', width: '100%'  }}>
        {renderFloors}
      </div>
    </Container>
  );
};
export default BuildingCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MainComponent = styled.div`
  display: flex;
  align-items: center;
`;

const StyledListItemButton = styled(ListItemButton)`
  .MuiButtonBase-root {
    padding: 5px !important;
    border-radius: 5px;
  }
`;

import { AxiosError } from 'axios';
import Building from 'model/Building';
import { BuildingCommand, CommandCategory } from 'model/enums';
import Floor from 'model/Floor';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import RoomModel from 'model/RoomModel';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const notifyBuildingError = (error: AxiosError | null) => {
  notifyCommandError('Error while executing building command', error);
};

export const getBuildings = async (siteName: string) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BuildingService,
      BuildingCommand.GetBuildings,
      [{}],
    );
    if (!data || !data.data) {
      notifyCommandError('No buildings found or the server timed out.', null);
      return [];
    }
    const buildings: Building[] = data.data;
    return buildings;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyBuildingError(error);
    return null;
  }
};

export const getBuildingById = async (siteName: string, buildingId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BuildingService,
      BuildingCommand.GetBuildingById,
      [buildingId],
    );
    if (data && data?.data) {
      const building: Building = data.data;
      return building;
    } else {
      notifyCommandError('getBuildingById command did not return data.', null);
      return null;
    }
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyBuildingError(error);
    return null;
  }
};

export const getFloorById = async (siteName: string, floorId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BuildingService,
      BuildingCommand.GetFloorById,
      [floorId],
    );
    if (data && data?.data) {
      const floor: Floor = JSON.parse(data.data);
      return floor;
    } else {
      notifyCommandError('getFloorById command did not return data.', null);
      return null;
    }
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyBuildingError(error);
    return null;
  }
};

export const getRoomsByBuilding = async (
  siteName: string,
  buildingId: number,
) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BuildingService,
      BuildingCommand.GetRoomsByBuilding,
      [buildingId],
    );
    if (!data || !data.data) {
      notifyCommandError(
        'getRoomsByBuilding command did not return data.',
        null,
      );
      return [];
    }
    const rooms: RoomModel[] = data.data;
    return rooms;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyBuildingError(error);
    return null;
  }
};

export const getRoomsByBuildingAndFloor = async (
  siteName: string,
  buildingId: number,
  floorId: number,
) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BuildingService,
      BuildingCommand.GetRoomsByBuildingAndFloor,
      [buildingId, floorId],
    );
    if (!data || !data.data) {
      notifyCommandError('The rooms did not returned from the server or the server timed out.', null);
      return [];
    }
    const rooms: RoomModel[] = data.data;
    return rooms;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyBuildingError(error);
    return null;
  }
};
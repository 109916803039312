import * as React from 'react';
import UserTableRecord from 'model/UserTableRecord';
import { useDispatch } from 'react-redux';
import TableRowSettings from '../TableRowSettings';
import { setOpenedDialog } from 'redux/actions/userActions';
import { PageNavigation } from 'model/enums';

type Props = {
    userTableRecord: UserTableRecord,
    optionsOpen: boolean,
    pageNavigation: PageNavigation,
    setOptionsOpen: (isOpen: boolean) => void,
}

const CardSettingCell = ({
    userTableRecord,
    optionsOpen,
    pageNavigation,
    setOptionsOpen
}: Props) => {
    const dispatch = useDispatch();

    return (<TableRowSettings
      userTableRecord={userTableRecord}
      setOptionsOpen={setOptionsOpen}
      optionsOpen={optionsOpen}
      setDialogToOpen={dialogToOpen => dispatch(setOpenedDialog(dialogToOpen))}
      pageNavigation={pageNavigation}
    />);
};

export default CardSettingCell;
import { CardTypes, DialogToOpen } from "model/enums";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOpenedDialog } from "redux/selectors/userSelector";

export const useConversionOpenedDialogToCardType = () => {
    const openedDialog = useSelector(selectOpenedDialog);
    const [cardType, setCardType] = useState<CardTypes>();

    const getCardTypesFromDialogToOpen = () => {
        switch(openedDialog) {
            case DialogToOpen.CreateMasterCardDialog:
                return CardTypes.MasterCard;
            case DialogToOpen.CreateKeyCardDialog:
                return CardTypes.KeyCard;
            case DialogToOpen.CreateEmergencyCardDialog:
                return CardTypes.EmergencyCard;
            case DialogToOpen.CreateFloorCardDialog:
                return CardTypes.FloorCard;
            default:
                return undefined;
        }
    }

    useEffect(() => {
        if (openedDialog) {
            setCardType(getCardTypesFromDialogToOpen());
        }
    }, [openedDialog]);

    return cardType;
}
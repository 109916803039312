import * as React from 'react';
import { CardToolTipViewOptions } from 'model/enums';
import { StyledCardViewHolder } from './UserWindowCommonStyle';
import { getIconByCardToolTip } from 'constants/cardIcons';
import { LightTooltip, PLACING_TOOLTIP_TO_TOP } from 'helper/Util';
import styled from 'styled-components';
import { black } from 'constants/theme';
import { useIsUserNotDeletable } from 'hooks/useIsUserNotDeletable';

type Props = {
  renderIconTemplate: CardToolTipViewOptions[];
  setSelectedIcon: (selectedIcon: CardToolTipViewOptions) => void;
};

const ViewTemplateCard = ({ setSelectedIcon, renderIconTemplate }: Props) => {
  const isUserNotDeletable = useIsUserNotDeletable();
  return (
    <React.Fragment>
      { renderIconTemplate.map((value, index) => !(isUserNotDeletable && value === CardToolTipViewOptions.DeleteUser) ? (
        <StyledCardViewHolder key={index}>
          <LightTooltip title={value} placement={PLACING_TOOLTIP_TO_TOP}>
            <ClickableIcon src={getIconByCardToolTip(value, true)} alt={value} onClick={() => setSelectedIcon(value)} />
          </LightTooltip>
        </StyledCardViewHolder>
      ): null) }
    </React.Fragment>
  );
};

export default ViewTemplateCard;

const ClickableIcon = styled.img`
  cursor: pointer;
  margin-left: -12px;
  color: ${black};
`;
import * as React from 'react';
import styled from 'styled-components';
import { black, lightGrayBackground, lightGrey, white } from 'constants/theme';
import Button from '@mui/material/Button';

type Props = {
    buttonHeight: number;
    buttonWidth: number;
    handleOnClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isFilledButton?:boolean;
    displayedName?: string;
    backgroundColor?: string;
    style?: React.CSSProperties;
    iconLeft?: any;
    iconRight?: any;
    disabled?: boolean;
};

const StyledButton = ({
    buttonHeight,
    buttonWidth,
    handleOnClick,
    isFilledButton = false,
    displayedName = "",
    backgroundColor,
    style,
    iconLeft,
    iconRight,
    disabled,
}: Props) => {

    const renderButtonIcon = (icon: any) => (
        <StyledAlignButtonIconToCenter>
            { typeof icon === "object" 
            ? <React.Fragment>{icon}</React.Fragment>
            : <img src={icon} alt="button icon" />}
        </StyledAlignButtonIconToCenter>
    );

    return (
        <StyledButtonContainer
            height={buttonHeight}
            width={buttonWidth}
            $backgroundColor={backgroundColor}
            $isFilled={isFilledButton}
            disabled={disabled}
            style={style}
        >
            <StyledButtonTextContainer 
                height={buttonHeight}
                width={buttonWidth}
                $isFilled={isFilledButton}
                onClick={handleOnClick}
                disabled={disabled}
            >
                <StyledDisplayNameAndIconHolder>
                    { iconLeft ? renderButtonIcon(iconLeft) : null}
                    {displayedName ? 
                        <div>
                            {displayedName}
                        </div>
                    : null}
                    { iconRight ? renderButtonIcon(iconRight) : null}
                </StyledDisplayNameAndIconHolder>
            </StyledButtonTextContainer>
        </StyledButtonContainer>
    );
};

export default StyledButton;

const StyledDisplayNameAndIconHolder = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledAlignButtonIconToCenter = styled.div`
    display: flex;
    align-items: center;
`;

const StyledButtonContainer = styled.div<{ height?: number, width?: number, $isFilled?: boolean, $backgroundColor?: string, disabled?: boolean }>((props) => ({
    height: `${props.height}px`,
    width: `${props.width}px`,
    maxHeight: `${props.height}px`,
    maxWidth: `${props.width}px`,
    borderRadius: `6px`,
    backgroundColor: (props.disabled ? `${lightGrayBackground}` : `${props.$backgroundColor}`),
    border: props.disabled
    ? `1px solid ${lightGrey}`
    : props.$isFilled
        ? `1px solid ${props.$backgroundColor}`
        : `1px solid ${lightGrey}`,
    pointerEvents: props.disabled ? 'none' : 'auto',
}));

const StyledButtonTextContainer = styled(Button)<{ height?: number, width?: number, $isFilled?: boolean, disabled?: boolean }>((props) => ({
    height: `${props.height}px`,
    width: `${props.width}px`,
    maxHeight: `${props.height}px`,
    maxWidth: `${props.width}px`,
    textTransform: `none`,
    fontFamily: 'Inter',
    fontSize: `14px`,
    lineHeight: `21.31px`,
    fontWeight: 500, 
    transition: `0.1s`,
    color: props.disabled 
    ? `${lightGrey}` 
    : (props.$isFilled 
        ? `${white}` 
        : `${black}`),
    padding: `8px`,
    minWidth: `${props.width}px`,
}));
import * as React from 'react';
import CustomTable from 'components/cards/CustomTable';
import { giveBackMasterLevelLastElement } from 'helper/FormatHelper';
import Building, { RoomNumberMap } from 'model/Building';
import RoomTableRecords from 'model/RoomTableRecords';
import { ReactComponent as ChevronDownIcon } from 'media/chevron-down.svg';
import styled from 'styled-components';
import { black, blue, lightBlue, OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import { alpha } from '@mui/material/styles';
import DoorGroupModel from 'model/DoorGroupModel';
import { getUserDoorGroupRoomsNames } from 'helper/CommonUtils';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import { addRoomsColData } from 'constants/customColData';
import { useBuildingsInit, useRoomsInit } from 'hooks/useStorageInits';
import { useListRoomsByFloorIdAndBuildingId } from 'hooks/useBuildings';
import RoomModel from 'model/RoomModel';
import { useSelector } from 'react-redux';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';

type Props = {
    setSelectedRooms: (rooms: RoomModel[]) => void;
    selectedRooms: RoomModel[];
    doorGroupList?: DoorGroupModel[];
};

const AddRoomsDialog = ({
    setSelectedRooms,
    selectedRooms,
    doorGroupList = [],
}: Props) => {
    const LIST_ITEM_THRESHOLD = 10;

    const buildings = useBuildingsInit();
    const listOfAllRooms = useRoomsInit();
    const [currentBuilding, setCurrentBuilding] = React.useState<number>();
    const [currentFloor, setCurrentFloor] = React.useState<number>();
    const rooms = useListRoomsByFloorIdAndBuildingId(currentFloor, currentBuilding);
    const [roomRows, setRoomRows] = React.useState<RoomTableRecords[]>([]);
    const [isFloorsListOpen, setIsFloorsListOpen] = React.useState<boolean>(true);
    const [buildingRoomNumbers, setBuildingRoomNumbers] = React.useState<RoomNumberMap>({});
    const [numberOfDisplayedListItems, setNumberOfDisplayedListItems] = React.useState<number>(0);
    const listOfMasterLevels = useSelector(selectMasterLevelList);

    React.useEffect(() => {
        setCurrentBuilding(buildings[0]?.Id);
        setCurrentFloor(buildings[0]?.Floors[0].FloorNo);
    }, [buildings]);


    React.useEffect(() => {
        setNumberOfDisplayedListItems(buildings.length + (buildings.find((building) => building.Id === currentBuilding)?.Floors.length ?? 0));
    }, [buildings, currentBuilding])

    React.useEffect(() => {
        if (listOfAllRooms && buildings) {
            setBuildingRoomNumbers(createBuildingRoomNumberList());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfAllRooms, buildings, selectedRooms]);

    React.useEffect(() => {
        if (rooms) {
            setFloorDialogRows();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rooms]);

    const setFloorDialogRows = () => {
        setRoomRows(
            rooms.filter(room => 
                (doorGroupList.length === 0
                    || !getUserDoorGroupRoomsNames(doorGroupList).some(group => group.RoomId === room.Id))
            )
            .map((item) => ({
                doorName: item.Name,
                lockType: item.RoomType,
                buildingNo: item.BuildingNo,
                floorNo: item.FloorNo,
                LockNo: item.RoomNo,
                masterCardLevel: giveBackMasterLevelLastElement(item.MasterLevels, listOfMasterLevels),
            }))
        );
    }

    const handleClickOnRoomSelect = (row: RoomTableRecords) => {
        checkIfRoomIsSelected(row)
          ? setSelectedRooms(selectedRooms.filter(selectedRoom => !isSelectedRowAndRoomIsTheSame(selectedRoom, row)))
          : setSelectedRooms([
            ...selectedRooms, 
            ...listOfAllRooms.filter(room => isSelectedRowAndRoomIsTheSame(room, row))
          ]);
    };
    
    const isSelectedRowAndRoomIsTheSame = (room: RoomModel, row: RoomTableRecords) => {
        return room.BuildingNo === row.buildingNo
            && room.FloorNo === row.floorNo
            && room.RoomNo === row.LockNo;
    }

    const handleClickOnAllRoomCheck = () => {
        checkIfEveryRowIsSelected() 
            ? setSelectedRooms(selectedRooms.filter(selectedRoom => !roomRows.some(row => isSelectedRowAndRoomIsTheSame(selectedRoom, row)))) 
            : setSelectedRooms([
                ...selectedRooms.filter(room => !roomRows.some(row => isSelectedRowAndRoomIsTheSame(room, row))),
                ...listOfAllRooms.filter(room => roomRows.some(row => isSelectedRowAndRoomIsTheSame(room, row)))
                ]
            );
    }

    const checkIfRoomIsSelected = (row: RoomTableRecords): boolean => {
        return selectedRooms.some(room => isSelectedRowAndRoomIsTheSame(room, row));
    }

    const checkIfEveryRowIsSelected = (): boolean => {
        return roomRows.length > 0 
            && selectedRooms.length > 0 
            && roomRows.every(row => selectedRooms.some(selectedRoom => isSelectedRowAndRoomIsTheSame(selectedRoom, row)));
    }

    const checkIfRoomSelectionIsIndeterminate = (): boolean => {
        return isSelectedRoomsIncludesSomeRoomRows() && !checkIfEveryRowIsSelected();
    }

    const isSelectedRoomsIncludesSomeRoomRows = () => {
        return selectedRooms.some(selectedRoom => roomRows.some(row => isSelectedRowAndRoomIsTheSame(selectedRoom, row)));
    }

    const handleBuildingClick = (building: Building) => {
        setIsFloorsListOpen(building.Id === currentBuilding ? !isFloorsListOpen : true);
        setCurrentBuilding(building.Id);
        setCurrentFloor(building.Floors[0]?.FloorNo);
    }

    const createBuildingRoomNumberList = (): RoomNumberMap => {
        const buildingRoomCountMap: { [key: number]: number } = {};
    
        listOfAllRooms.forEach(room => {
            if (selectedRooms?.map(selectedRoom => selectedRoom.Id).includes(room.Id)) {
                if (buildingRoomCountMap[room.BuildingNo]) {
                    buildingRoomCountMap[room.BuildingNo]++;
                } else {
                    buildingRoomCountMap[room.BuildingNo] = 1;
                }
            }
        });
    
        return buildingRoomCountMap;
    }

    return (
        <AddRoomsContainer>
            <BuildingsListContainer>
                {buildings.map((building, buildingIndex) => (
                    <BuildingListItemContainer key={buildingIndex} $isFullSize={currentBuilding === building.Id && isFloorsListOpen && numberOfDisplayedListItems > LIST_ITEM_THRESHOLD}>
                        <BuildingListItem onClick={ ()=> handleBuildingClick(building)}>
                            <BuildingNameContainer>
                                <ChevronDownIcon />
                                <BuildingName>
                                    {building.Name}
                                </BuildingName>
                            </BuildingNameContainer>
                            <RoomCountHolder>
                                {buildingRoomNumbers[building.BuildingNo] ?? 0}
                            </RoomCountHolder>
                        </BuildingListItem>
                        {currentBuilding === building.Id && isFloorsListOpen && <FloorsListContainer>
                            {building.Floors.map((floor, floorIndex) => (
                                <FloorListItem 
                                    key={floorIndex} 
                                    onClick={() => setCurrentFloor(floor.FloorNo)} 
                                    $backgroundColor={floor.FloorNo === currentFloor ? lightBlue : white}
                                    color={floor.FloorNo === currentFloor ? blue : black}
                                >
                                    {floor.Name}
                                </FloorListItem>
                            ))}
                        </FloorsListContainer>}
                    </BuildingListItemContainer>
                ))}
            </BuildingsListContainer>
            <TableContainer>
                <CustomTable
                    customColDataList={addRoomsColData} 
                    customColRecords={roomRows}
                    handleClickOnRowCheck={handleClickOnRoomSelect}
                    handleClickOnAllRowCheck={handleClickOnAllRoomCheck}
                    checkRowCheckValue={checkIfRoomIsSelected}
                    checkIfEveryRowIsSelected={checkIfEveryRowIsSelected}
                    checkIfSelectAllIsIndeterminate={checkIfRoomSelectionIsIndeterminate}
                    resourceName={"room"}
                    evenRowColor={white}
                    oddRowColor={alpha(veryLightGrey, OPACITY_OF_ROWS)}
                />
            </TableContainer>
        </AddRoomsContainer>
    );
};

export default AddRoomsDialog;

const AddRoomsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const TableContainer = styled.div`
    width: 75%;
    height: 100%;
`;

const BuildingsListContainer = styled.div`
    width: 25%;
    height: 100%;
    padding: 8px 16px 8px 0px;
    box-sizing: border-box;
    ${customScrollBarCSSProperties()}
`;

const BuildingListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;
`;

const BuildingNameContainer = styled.div`
    display: flex;
    gap: 4px;
`;

const BuildingName = styled.div`
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
`;

const RoomCountHolder = styled.div`
    color: ${blue};
    text-align: center;
    width: 30px;
    background-color: ${lightBlue};
    border-radius: 5px;
`;

const FloorsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${customScrollBarCSSProperties()}
`;

const FloorListItem = styled.div<{ $backgroundColor?: string, color?: string }>((props) => ({
    cursor: 'pointer',
    padding: '6px 0px 6px 16px',
    fontSize: '14px',
    borderRadius: '5px',
    backgroundColor: props.$backgroundColor,
    color: props.color,
}));

const BuildingListItemContainer = styled.div<{ $isFullSize?: boolean, }>((props) => ({height: props.$isFullSize ? '100%' : 'auto'}));
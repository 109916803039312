export default class OptionModel {
  constructor(
    public label: string,
    public userNumber?: number,
    public userGlobalId?: number,
    public email?: string | null,
    public department?: string,
  ) {}

  public displayLabelAndId = () =>
    this && this.label.length > 0
      ? `${this.label.length > 0 ? this.label : ``}${
          Number(this.userNumber) > 0 ? ` (${this.userNumber})` : ``
        }`
      : ``;

  public displayFullOptionModel = () =>
    this
      ? `${this.label} (${this.userNumber}) ${this.department} ${this.email}`
      : ``;
}

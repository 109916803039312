import { CardDialogPages } from "model/enums";

export const heightOfButtonAndLinearProgressBarHolder = "42.5px";

export const cardDetailWindowComponentGridSize = {
    leftComponent: 5,
    rightComponent: 6,
};

export const IGNORE_FIRST_CHARACTERS_OF_MASTER_LEVEL_REMARK = 21;

export const windowCardsEmptyData = "-";

export const searchbarAndDepartmentInputFieldWidth = "487px";

export const initialCardDialogPageName = CardDialogPages.CardDetails;

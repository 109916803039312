import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { emptyBuilding } from 'constants/testDatas'; // TO DO redux
import { Button, FormControlLabel, Menu } from '@mui/material';
import { black, lightGrey, mainBlue, white } from 'constants/theme';
import magnifyingGlass from 'media/magnifyingGlass.svg';
import filter from 'media/filter.svg';
import Building from 'model/Building';
import { BuildingFilterProp } from 'model/BuildingFilterProp';
import BuildingFilterCheckBox from 'components/controls/BuildingFilterCheckBox';
import Floor from 'model/Floor';

type Props = {
  setListedBuildings: Dispatch<SetStateAction<Building[]>>;
  buildings: Building[];
  setCheckedValues: Dispatch<SetStateAction<any[]>>;
  checkedValues: any[];
};

function BuildingFilter({ setListedBuildings, buildings, setCheckedValues, checkedValues}: Props) {
  const [listedFilters, setListedFilters] = useState<BuildingFilterProp[]>([]);
  const [listedFiltersWithSearchbar, setListedFiltersWithSearchbar] = useState<BuildingFilterProp[]>([]);
  const [selectAll, setSelectAll] = useState(true);
  const [searchbarValue, setSearchbarValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    makeTheListedFilters();
    makeFilterOnBuildingsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, buildings]);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    makeFilterOnBuildingsList();
    handleClose();
  }

  const filterTheListedFiltersWithSearchBar = (e: {
    target: { value: string };
  }) => {
    if (e.target.value !== '') {
      setSearchbarValue(e.target.value);
      const filterTable = listedFilters.filter((o) =>
        Object.values({value: o.building.Name, checked: o.checked}).some((value) => {
          if (typeof value !== 'boolean')
            return String(value)
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          else return false;
        }),
      );
      setListedFiltersWithSearchbar([...filterTable]);
    } else {
      setSearchbarValue(e.target.value);
      makeTheListedFilters();
    }
  };

  const makeTheListedFilters = () => {
    let arrayAllBuildings = buildings; // TO DO from redux
    let arrayFilters = listedFilters;
    arrayAllBuildings.forEach(building => { 
      const foundBuilding = arrayFilters.find(
        filtersBuilding => building.Name === filtersBuilding.building.Name);
        if (!foundBuilding) { 
          arrayFilters.push({building, checked: false}) 
        } 
      })
    setListedFilters(arrayFilters);
  };

  const handleCheckboxChange = (e: {
    target: { value: any; checked: any };
  }) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedValues((pre): any => [...pre, value]);
      for (let i = 0; i < listedFilters.length; i += 1) {
        if (listedFilters[i].building.Name === value) {
          listedFilters[i].checked = true;
        }
      }
      setSelectAll(false);
    } 
    else {
      setCheckedValues((pre) => {
        return [...pre.filter((skill) => skill !== value)];
      });
      let lastUncheck = true;
      for (let i = 0; i < listedFilters.length; i += 1) {
        if (
          listedFilters[i].checked === true &&
          listedFilters[i].building.Name !== value
        ) {
          lastUncheck = false;
        }
        if (listedFilters[i].building.Name === value) {
          listedFilters[i].checked = false;
        }
      }
      if (lastUncheck) {
        setSelectAll(true);
      }
    }
  };
 const makeFilterOnBuildingsList = () => {
    if (checkedValues.length === 0) {
      setListedBuildings(buildings);
    } else {
      setListedBuildings(emptyBuilding);
      let allBuildings = buildings;
      allBuildings.forEach(building => {
        //check if the given building can be found in the checkedValues
        let foundBuilding = checkedValues.find(
          checkedValue => checkedValue === building.Name
        )
        //if so check which floors to list
        if(foundBuilding) {
          let floorsToList : Floor[] = [];
          building.Floors.forEach(floor => {
            //check if the floor is checked and is not included in the floorsList
            const foundFloor = checkedValues.find(
              checkedValue => checkedValue === floor.Id.toString() && !floorsToList.includes(floor,0)
            )
            if(foundFloor){
              floorsToList.push(floor)
            }
          })
          setListedBuildings((pre: Building[]): Building[] => [
            ...pre,
            { ...building, Floors: floorsToList },
          ]);
        }
      })
    }
  };

  const listFilters = listedFilters.map((element, index) => (
    <ul key={index}>
      <BuildingFilterCheckBox
        element={element}
        onChange={handleCheckboxChange}
        checkedValues={checkedValues}
        />
    </ul>
  ));

  const listFiltersWithSearchbar = listedFiltersWithSearchbar.map(
    (element, index) => (
      <ul key={index}>
        <BuildingFilterCheckBox
          element={element}
          onChange={handleCheckboxChange} 
          checkedValues={checkedValues}
          />
      </ul>
    ),
  );

  const checkSelectAll = () => {
    resetListedFiltersToDefault();
    setSelectAll(true);
    setCheckedValues([]);
  };

  const resetFilterPageToDefault = () => {
    resetListedFiltersToDefault();
    setSelectAll(true);
    setCheckedValues([]);
    setSearchbarValue('');
    setListedBuildings(buildings);
    handleClose();
  };
  const resetListedFiltersToDefault = () => {
    for (let i = 0; i < listedFilters.length; i += 1) {
      listedFilters[i].checked = false;
    }
  };
  return (
    <>
      <div>
        <Button
          id="filter-button"
          aria-controls={open ? 'filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FilterIcon id="filterIcon" src={filter} />
        </Button>
      </div>
      <MenuContainer
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        disableScrollLock= {true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SearchBar>
          <SearchbarInput
            value={searchbarValue}
            className="input"
            id="searchbar"
            type="text"
            onChange={filterTheListedFiltersWithSearchBar}
          />
        </SearchBar>

 

        <ListedFilters>
          <ul>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={
                    <RadioButtonCheckedIcon
                      sx={{ '&, &.Mui-checked': { color: mainBlue } }}
                    />
                  }
                  onChange={checkSelectAll}
                  checked={selectAll}
                  value="select all"
                />
              }
              label={'Select all (' + listedFilters.length + ')'}
            />
          </ul>
          {searchbarValue.length > 0 ? listFiltersWithSearchbar : listFilters}
        </ListedFilters>
        <Buttons>
          <Reset onClick={resetFilterPageToDefault}>Reset</Reset>
          <Apply onClick={handleApply}>Apply</Apply>
        </Buttons>
      </MenuContainer>
    </>
  );
}

export default BuildingFilter;

const FilterIcon = styled.img`
  position: relative;
  padding: 40px 15px 25px 15px;
`;

const SearchbarInput = styled.input`
  padding: 13px;
  border: 1px solid ${lightGrey};
  background-image: url(${magnifyingGlass});
  background-repeat: no-repeat;
  background-position: 95% center;
  border-radius: 0px 7px 7px 0;
`;

const ListedFilters = styled.div`
  text-align: left;
  height: 250px;
  overflow-y: scroll;
`;

const SearchBar = styled.div`
  padding: 15px;
`;
const MenuContainer = styled(Menu)`
  .MuiPaper-root {
    border-radius: 20px !important;
  }
`;

const Reset = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${black};
  background-color: ${white};
  border: 1px solid ${lightGrey};
  margin: 6px;
  cursor: pointer;
`;

const Apply = styled.button`
  padding: 8px 20px;
  border-radius: 5px;
  color: ${white};
  background-color: ${mainBlue};
  border: 1px solid ${lightGrey};
  margin: 6px;
  cursor: pointer;
`;

const Buttons = styled.div`
  text-align: center;
  margin: 10px;
`;
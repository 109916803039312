// TODO: Remove this whole file before shipping the app to the nsp team.

import { LocalStorageKeys } from 'model/enums';
import ServerConnectionModel, { ServerConnectionProperties } from 'model/ServerConnectionModel';

const ENVIRONMENT_BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_NSP_URL = process.env.REACT_APP_BASE_NSP_URL ?? '';
const BASE_RABIT_URL = process.env.REACT_APP_BASE_RABIT_URL ?? '';
const HUB_ADDRESS_NSP = process.env.REACT_APP_HUB_ADDRESS_NSP_OFFICE ?? '';
const HUB_ADDRESS_RABIT = process.env.REACT_APP_HUB_ADDRESS_RABIT_OFFICE ?? '';
const isConnectedToNspServer = ENVIRONMENT_BASE_URL === BASE_NSP_URL;

export const serverConnectionProperties: ServerConnectionProperties[] = [
  {
    baseUrl: BASE_RABIT_URL,
    hubAddress: HUB_ADDRESS_RABIT,
    name: 'Rabit VM Server',
  },
  {
    baseUrl: BASE_NSP_URL,
    hubAddress: HUB_ADDRESS_NSP,
    name: 'NSP Server',
  }
];

/**
 * Initialize the different servers where the signalr could connect to.
 */
const initServerConnectionModel = (): ServerConnectionModel => {
  const serverConnectionModel: ServerConnectionModel = {
    servers: serverConnectionProperties,
    selectedServer: serverConnectionProperties[isConnectedToNspServer ? 1 : 0],
  };

  localStorage.setItem(
    LocalStorageKeys.SignalrConnection,
    JSON.stringify(serverConnectionModel),
  );

  return serverConnectionModel;
};

export const getServerConnectionModel = () => {
  const storageItem = localStorage.getItem(LocalStorageKeys.SignalrConnection);

  if (storageItem) {
    return JSON.parse(storageItem) as ServerConnectionModel;
  } else {
    return initServerConnectionModel();
  }
};

export const updateServerConnectionModelInLocalStorage = (
  ServerConnectionModel: ServerConnectionModel,
) => {
  localStorage.setItem(
    LocalStorageKeys.SignalrConnection,
    JSON.stringify(ServerConnectionModel),
  );
};

export const getBaseUrlOfSelectedServer = () =>
  getServerConnectionModel().selectedServer.baseUrl;

import { isEncoderResponded } from "hooks/useSignalrEncoder";
import { SignalrResponseStatuses } from "model/enums";
import { IRootState } from "redux/store";

export const selectIsEncoderListLoading = (state: IRootState) => state.encoderStorage.loading;

export const selectListOfEncoders = (state: IRootState) => state.encoderStorage.listOfEncoders;

export const selectSelectedEncoder = (state: IRootState) => state.encoderStorage.selectedEncoder;

export const selectSelectedEncoderStatus = (state: IRootState) => state.encoderStorage.selectedEncoderStatus;

export const selectIsEncoderConfirmationDialogOpen = (state: IRootState) => state.encoderStorage.isEncoderConfirmationDialogOpen;

export const selectIsAwaitsEncoderResponse = (state: IRootState) => state.encoderStorage.isAwaitsEncoderResponse;

export const selectEncoderCardResponse = (state: IRootState) => state.encoderStorage.encoderCardResponse;

export const selectSignalrResponseStatus = (state: IRootState) => state.encoderStorage.signalrResponseStatus;

export const selectIsSignalrResponseStatusPending = (state: IRootState) => 
    selectSignalrResponseStatus(state) === SignalrResponseStatuses.Pending
        && !isEncoderResponded(selectIsAwaitsEncoderResponse(state), selectEncoderCardResponse(state)?.Command);

export const selectEncoderStorage = (state: IRootState) => state.encoderStorage;

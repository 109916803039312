import { UserDialogPages } from "model/enums";
import UserDialogForm from "model/UserDialogForm";
import UserDialogFormError from "model/UserDialogFormError";
import UserTableRecord from "model/UserTableRecord";
import UserDialogActionTypes from "redux/constants/userDialogActionTypes";

export const setUserDialogForm = (data: UserDialogForm) => {
  return {
    type: UserDialogActionTypes.SET_USER_DIALOG_FORM,
    payload: data
  };
};

export const setUserDialogFormError = (data: UserDialogFormError) => {
  return {
    type: UserDialogActionTypes.SET_USER_DIALOG_FORM_ERROR,
    payload: data
  };
};

export const setUserDialogPage = (data: UserDialogPages) => {
  return {
    type: UserDialogActionTypes.SET_USER_DIALOG_PAGE,
    payload: data
  };
};

export const setUserDialogTableRecord = (data?: UserTableRecord) => {
  return {
    type: UserDialogActionTypes.SET_USER_DIALOG_TABLE_RECORD,
    payload: data
  };
};

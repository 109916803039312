import * as React from 'react';
import { StyledContentHolder, StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import { UserDialogAdditionalInformationPageFormPlaceholderText, UserDialogAdditionalInformationPageFormText } from 'model/enums';
import { AdditionalInformationError } from 'model/UserDialogFormError';
import { AdditionalInformationForm } from 'model/UserDialogForm';
import SelectField from 'components/controls/selects/SelectField';
import SingleDatePicker from 'components/controls/datePicker/SingleDatePicker';
import iso from "iso-3166-1";
import MultilineInputField from 'components/controls/inputs/MultilineInputField';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import NumberInputField from 'components/controls/inputs/NumberInputField';
import { restrictedKeyCodes } from 'constants/RestrictedKeyCodes';
import { NATIONALITY_COUNTRY_NAME_LIST } from 'constants/arrays';

type Props = {
  error: AdditionalInformationError;
  form: AdditionalInformationForm;
  initForm: AdditionalInformationForm;
  setForm: (form: AdditionalInformationForm) => void;
};

const UserAdditionalInformationPage = ({
  error,
  form,
  initForm,
  setForm
}: Props)  => {

  return (
    <StyledUserDialogContent
      width={684}
      height={460}
    >
      <StyledContentHolder>
        <StyledContentRowHolder>
          <SingleDatePicker 
            inputFieldWidth={"489px"}
            inputFieldHeight={"40px"}
            displayedName={UserDialogAdditionalInformationPageFormText.BirthDate}
            setValue={(birthDate) => setForm({...form, birthDate})}
            inputValue={form.birthDate}
            invalidFormMessage={error.birthDateError}
          />
          <SelectField 
            inputFieldWidth={"171px"}
            inputFieldHeight={"40px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Nationality}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Nationality}
            selectOptions={NATIONALITY_COUNTRY_NAME_LIST}
            setValue={(nationality) => setForm({...form, nationality: iso.whereCountry(nationality)?.alpha3})}
            inputValue={
                form.nationality 
                  ? iso.whereAlpha3(form.nationality)?.country
                  : UserDialogAdditionalInformationPageFormPlaceholderText.Nationality
            }
            invalidFormMessage={error.nationalityError}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <MultiTypeInputField 
            inputFieldHeight={"40px"}
            inputFieldWidth={"489px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Address}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Address}
            inputValue={form.address}
            setValue={(address) => setForm({...form, address})}
            invalidFormMessage={error.addressError}
          />
          <NumberInputField 
            inputFieldHeight={"40px"}
            inputFieldWidth={"171px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Telephone}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Telephone}
            inputValue={form.telephone}
            setValue={(telephone) => setForm({...form, telephone})}
            invalidFormMessage={form.telephone === initForm.telephone ? undefined : error.telephoneError}
            restrictKeysToPress={restrictedKeyCodes}
            isStepperDisplayed={false}
            adornmentIcon='+'
            inputProps={{min: 0}}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <MultiTypeInputField 
            inputFieldWidth={"330px"}
            inputFieldHeight={"40px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Position}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Position}
            setValue={(position) => setForm({...form, position})}
            inputValue={form.position}
            invalidFormMessage={error.positionError}
          />
          <MultiTypeInputField 
            inputFieldWidth={"329px"}
            inputFieldHeight={"40px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Type}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Type}
            setValue={(type) => setForm({...form, type})}
            inputValue={form.type}
            invalidFormMessage={error.typeError}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <MultilineInputField 
            inputFieldHeight={"80px"}
            inputFieldWidth={"684px"}
            displayedName={UserDialogAdditionalInformationPageFormText.Comment}
            placeholderInput={UserDialogAdditionalInformationPageFormPlaceholderText.Comment}
            inputValue={form.comment}
            setValue={(comment) => setForm({...form, comment})}
            invalidFormMessage={error.commentError}
            numberOfMultilineRows={2}
          />
        </StyledContentRowHolder>
      </StyledContentHolder>
    </StyledUserDialogContent>
  );
};

export default UserAdditionalInformationPage;
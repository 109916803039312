import Site from 'model/Site';
import ActionTypes from 'redux/constants/siteActionTypes';
import { SiteAction } from 'redux/constants/siteActions';

type siteType = {
  loading: boolean;
  sites: Site[];
  error: string;
};

const initialState: siteType = {
  loading: true,
  sites: [],
  error: '',
};
export const siteReducer = (
  state: siteType = initialState,
  action: SiteAction,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_SITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.FETCH_SITE_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: '',
      };
    case ActionTypes.FETCH_SITE_FAILURE:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

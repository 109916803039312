import * as React from 'react';
import styled from 'styled-components';
import ViewOptionWindowCard from './userWindow/ViewOptionWindowCard';
import InformationCard from './userWindow/InformationCard';
import CardDetailCard from './userWindow/CardDetailCard';
import AdditionalInfoCard from './userWindow/AdditionalInfoCard';
import ImageCard from './userWindow/ImageCard';
import NoSelectionCard from './userWindow/NoSelectionCard';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import {  PageNavigation, WindowCardRenders } from 'model/enums';
import { renderNoSelectionUserContent, renderNoSelectionCardContent } from 'constants/dialogTexts';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { useSelector } from 'react-redux';
import { selectPageNavigation, selectSelectedUserRow, selectUserList } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import { getCardById } from 'API/commands/CardCommands';
import DoorListCard from './userWindow/viewDoorListCards/DoorListCard';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';
import { getUserById } from 'API/commands/UserCommands';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { setUserCardModel } from 'redux/actions/cardModelActions';
import { selectUserCardModel } from 'redux/selectors/cardModelSelector';

const WindowCard = () => {

  const pageNavigation = useSelector(selectPageNavigation);
  const selectedUserTableRecordRow = useSelector(selectSelectedUserRow);
  const actualSite = ActualSiteLocator();
  const listOfUsers: UserModel[] = useSelector(selectUserList);
  const cardModelToDisplay = useSelector(selectUserCardModel);
  const WindowCardComponentsByPageNavigation = ConversionsUtil.pageNavigationAndCardTypeToWindowCards(pageNavigation, cardModelToDisplay?.Type);
  const dispatch = useDispatch();

  const getCardByUserCardId = async() => {
    if (!selectedUserTableRecordRow) {
      dispatch(setUserCardModel(null));
      return;
    }

    const userModel = listOfUsers.find((user) => Number(user.UserNumber) === selectedUserTableRecordRow.userId);

    if (!userModel || !userModel.CardID) {
      dispatch(setUserCardModel(null));
      return;
    }

    const userData = await getUserById(actualSite, userModel.Id);

    if (userData) {
      userModel.UserRooms = userData.UserRooms;
      userModel.DoorGroups = userData.DoorGroups;
    }

    const cardModel = await getCardById(actualSite, userModel.CardID);
    dispatch(setUserCardModel(cardModel));
  }

  React.useEffect(() => {
    getCardByUserCardId();
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedUserTableRecordRow]);

  const callWindowElements = (windowCardRenders: WindowCardRenders): JSX.Element => {
    if (!selectedUserTableRecordRow) {
      return <></>;
    }

    const windowElementHandler = Object.freeze({
      [WindowCardRenders.Image]: () => <ImageCard/>,
      [WindowCardRenders.Information]: () => <InformationCard cardModelToDisplay={cardModelToDisplay}/>,
      [WindowCardRenders.CardDetails]: () => <CardDetailCard handleFetchOfUserCards={(isOpen) => isOpen ? getCardByUserCardId() : null} cardModelToDisplay={cardModelToDisplay}/>,
      [WindowCardRenders.AdditionalInformation]: () => <AdditionalInfoCard selectedUserTableRecordRow={selectedUserTableRecordRow} />,
      [WindowCardRenders.DoorList]: () => <DoorListCard handleFetchOfUserCards={(isOpen) => isOpen ? getCardByUserCardId() : null} cardModelToDisplay={cardModelToDisplay}/>,
    });
    return (<div key={windowCardRenders}>
      {windowElementHandler[windowCardRenders.toString()].call()}
    </div>);
  }

  return (
      <StyledWindowContainer>
      { selectedUserTableRecordRow === undefined ? (
        <NoSelectionCard 
          cardContent={pageNavigation === PageNavigation.ManageUsers 
            ? renderNoSelectionUserContent()
            : renderNoSelectionCardContent((dialogToOpen) => dispatch(setOpenedDialog(dialogToOpen)))}
        />
      ) :
        <React.Fragment>
          <div>
            <ViewOptionWindowCard />
          </div>
          <StyledWindowBody>
            { WindowCardComponentsByPageNavigation.map((windowCardRender) => (
              <>
                {callWindowElements(windowCardRender)}
              </>
            ))}
          </StyledWindowBody>
        </React.Fragment>
      }
      </StyledWindowContainer>
  );
};

export default WindowCard;

export const StyledWindowContainer = styled.div`
  width: 99.9%;
  height: 90%;
  align-self: end;
`;

export const StyledWindowBody = styled.div`
  height: 90%;
  overflow-x: hidden;
  margin-right: -10px;
  ${customScrollBarCSSProperties()}
`;
import { Dispatch } from 'redux';
import DepartmentActionTypes from 'redux/constants/departmentActionTypes';
import DepartmentDetail from 'model/DepartmentDetail';
import { getDepartments } from 'API/commands/DepartmentCommands';

export const fetchDepartmentRequest = () => {
  return { type: DepartmentActionTypes.FETCH_DEPARTMENT_REQUEST };
};
export const fetchDepartmentSuccess = (data: DepartmentDetail[]) => {
  return {
    type: DepartmentActionTypes.FETCH_DEPARTMENT_SUCCESS,
    payload: data,
  };
};
export const fetchDepartmentFailure = (err: any) => {
  return {
    type: DepartmentActionTypes.FETCH_DEPARTMENT_FAILURE,
    payload: err,
  };
};
export const fetchAllDepartment = (
  actualSite:string,
) => {
    return (dispatch: Dispatch) => {
      dispatch(fetchDepartmentRequest());
      getDepartments(actualSite).then((departmentList) => {
        if(departmentList && departmentList.length > 0) {
          const sortedList = departmentList.sort((current: DepartmentDetail, next: DepartmentDetail) => current.Name.localeCompare(next.Name));
          dispatch(fetchDepartmentSuccess(sortedList));
        } else {
          dispatch(fetchDepartmentFailure([]));
        }
      });
  };
};
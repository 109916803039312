import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import errorImg from 'media/page_not_found.png';
import { mainBlue, white } from 'constants/theme';

export default function NotFound() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
    if (navigate(-1) === undefined) {
      navigate('/');
    }
  };
  return (
    <Container>
      <Message>
        <h1>Page not found</h1>
        <p>This is not the web page you are looking for.</p>

        <BackButton onClick={handleBack}>Back</BackButton>
      </Message>
      <div>
        <img src={errorImg} alt="" />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 60px 100px;
  align-items: center;
  padding: 100px 50px;
  justify-content: center;
  background-color: ${white};
  border-radius: 10px;
`;

const BackButton = styled.button`
  background-color: ${mainBlue};
  border: none;
  padding: 12px 40px;
  color: ${white};
  border-radius: 7px;
  margin: 10px 0px;
  cursor: pointer;
`;

const Message = styled.div`
  text-align: left;
  padding: 50px 250px 50px 50px;
`;

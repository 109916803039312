import { AxiosError } from 'axios';
import postCommand, { notifyCommandError } from './Command';
import DoorGroup from 'model/DoorGroup';
import { CommandCategory, DoorGroupCommand } from 'model/enums';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const notifyDoorGroupError = (error: AxiosError | null) => {
    notifyCommandError('Error while executing get door groups command', error);
};

  export const getDoorGroupsDetails = async (siteName: string) => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.DoorGroupService,
        DoorGroupCommand.GetDoorGroupsDetails,
        [],
      );
      if (!data || !data.data) {
        notifyCommandError('No door groups found or the server timed out.', null);
        return [];
      }
      const doorGroupList: DoorGroup[] = data.data;
      return doorGroupList;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyDoorGroupError(error);
      return null;
    }
  }
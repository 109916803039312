import { CardTypes, DialogToOpen } from "model/enums";

export class CardTypeUtils {

    public static readonly CARD_TYPE_OPTIONS = [CardTypes.KeyCard, CardTypes.MasterCard, CardTypes.FloorCard, CardTypes.EmergencyCard];

    public static readonly listOfCardTypes = this.CARD_TYPE_OPTIONS.sort();

    public static convertCardTypeToDialogToOpenType = (cardType: string, callback) => {
        const handler = Object.freeze({
          [CardTypes.KeyCard]: () => callback(DialogToOpen.CreateKeyCardDialog),
          [CardTypes.MasterCard]: () => callback(DialogToOpen.CreateMasterCardDialog),
          [CardTypes.EmergencyCard]: () => callback(DialogToOpen.CreateEmergencyCardDialog),
          [CardTypes.FloorCard]: () => callback(DialogToOpen.CreateFloorCardDialog),    
        });
        handler[cardType].call();
    }
}
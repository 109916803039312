import * as React from 'react';
import InputFieldTemplate from './InputFieldTemplate';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string;
    invalidFormMessage?: string;
    numberOfMultilineRows?: number;
    setValue?: (value?: string) => void;
};

const MultilineInputField = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName,
    placeholderInput,
    inputValue,
    invalidFormMessage,
    numberOfMultilineRows,
    setValue,
}: Props) => {

    return (
        <InputFieldTemplate 
            inputFieldHeight={inputFieldHeight}
            inputFieldWidth={inputFieldWidth}
            displayedName={displayedName}
            placeholderInput={placeholderInput}
            inputValue={inputValue}
            setValue={setValue}
            invalidFormMessage={invalidFormMessage}
            isMultiline={true}
            numberOfMultilineRows={numberOfMultilineRows}
        />
    );
};

export default MultilineInputField;
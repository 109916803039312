import { notifyError } from "./NotificationService";

export class FilesUtil {

    /**
     * Store the maximum allowed file size that can be sent to the remote server, 
     * that is currently limited to 20 KB.
     */
    public static readonly MAXIMUM_FILE_SIZE = 20480;

    public static readonly BASE64_URL_HEADER = 'data:image/jpg;base64,';

    public static convertBase64ToImage = (base64Img: string, callback: (img: HTMLImageElement) => void) => {
        var img = new Image();
        img.onload = () => callback(img);
        img.src = `${this.BASE64_URL_HEADER}${base64Img}`;
    }

    public static convertImageToBase64 = (file: File | undefined, callback: (base64String: string) => void) => {
        if (file) {
            let reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                if (base64String) {
                    callback(base64String.toString().replace("data:", "").replace(/^.+,/, ""));
                }
            }
            reader.readAsDataURL(file);
        }
    }

    public static isFileSizeIsInTheThreshold = (file: File | undefined, maximumFileSize: number) => {
        if (file) {
            if (file && file.size <= maximumFileSize) {
                return true;
            } else {
                notifyError(`The uploaded size of the image is too big. The maximum allowed file size is ${this.MAXIMUM_FILE_SIZE/1024} KB.`, "");
                return false;
            }
        } else {
            notifyError("The uploaded file is not found.", "");
            return false;
        }
    }
}
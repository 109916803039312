import React from 'react';
import StyledButton from 'components/controls/button/StyledButton';
import { useDispatch } from 'react-redux';
import { DialogToOpen, EncoderTestingDialogTexts } from 'model/enums';
import { setOpenedDialog } from 'redux/actions/userActions';
import { white } from 'constants/theme';
import chevronRightArrow from 'media/chevron-right-arrow.svg';
import { useSelector } from 'react-redux';
import { selectSelectedEncoderStatus } from 'redux/selectors/encoderSelector';
import { useEncoderListInit } from 'hooks/useStorageInits';
import { EncoderStatusIconHolder, getEncoderStatusColorByMessage } from './EncoderSelectionDialog';
import EncoderTesterDialog from './EncoderTesterDialog';

const EncoderStatusButton = () => {
  const selectedEncoderStatus = useSelector(selectSelectedEncoderStatus);
  const dispatch = useDispatch();
  useEncoderListInit();

  return (
      <div>
        <StyledButton
          handleOnClick={() => dispatch(setOpenedDialog(DialogToOpen.EncoderTesterDialog))}
          displayedName={EncoderTestingDialogTexts.EncoderFilterName}
          buttonHeight={48}
          buttonWidth={160}
          backgroundColor={white}
          iconLeft={<EncoderStatusIconHolder color={getEncoderStatusColorByMessage(selectedEncoderStatus)} />}
          iconRight={chevronRightArrow}
        />
        <EncoderTesterDialog />
      </div>
  );
}

export default EncoderStatusButton;